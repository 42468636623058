import React from 'react';
import css from './AppMenu.module.css';
import Cart from "../AppMenu/Icons/Cart/Cart";
import Edit from "../AppMenu/Icons/Edit/Edit";
import Pay from "../AppMenu/Icons/Pay/Pay";
import Send from "./Icons/Send/Send";
import Order from "../AppMenu/Icons/Order/Order";
import Exit from "../AppMenu/Icons/Exit/Exit";

const AppMenu=({view, setView})=>{  
    return(
        <div className={css.headerMenu}>
            
            <Cart view={view} setView={setView}/>
            <Edit view={view} setView={setView}/>
            <Pay view={view} setView={setView}/>
            <Send view={view} setView={setView}/>
            <Order view={view} setView={setView}/>
            <Exit view={view} setView={setView}/>
        </div>
    )
}
export default AppMenu;