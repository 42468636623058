import p1 from './PROSIXPANICimg/p1.jpg';
import p2 from './PROSIXPANICimg/p2.jpg';
import p3 from './PROSIXPANICimg/p3.jpg';
import p4 from './PROSIXPANICimg/p4.jpg';
import cr2450 from '../../../../Batteries/cr2450.jpg';
import { getPrice } from '../../../../../../../Prices';
const PROSIXPANIC = () => {
    const sensor = {
        title: 'Panic Button',
        model: 'PROSIXPANIC',
        sku: ['PROSIXPANIC'],
        modelViewImage: p1,
        image: [p1,p2,p3,p4,cr2450],
        shortDescription: 'Allows for immediate alert and response in emergency situations.',
        price: getPrice('PROSIXPANIC'),
        group: 'sensor',
        checkListKeyword: ['Burglar'],
        infoHeader: "Panic Button",
        spec: [
            { row1: 'Model:', row2: 'PROSIXPANIC' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Portable or wall mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR2450 3V'},
            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Product Length:', row2: '3.1 in, 78.7 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Product Height:', row2: '1.4 in, 35.6 mm' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed' },
        ],
        info: [
            {
                header: 'Panic Button: Immediate Alert and Response',
                text: 'The PROSIXPANIC panic button is a crucial device for ensuring immediate alert and response in emergency situations. Designed for quick activation, this panic button provides instant notification to emergency services or monitoring personnel, ensuring the safety and protection of individuals in distress.',
                section: [
                    { title: "Immediate Alert" },
                    { text: "The PROSIXPANIC allows users to send an immediate alert with the press of a button. This instant notification can be crucial in emergencies, providing quick response and assistance." },
                    
                    { title: "Reliable Performance" },
                    { text: "Engineered for reliability, the PROSIXPANIC panic button delivers consistent performance without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The PROSIXPANIC is a wireless panic button that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROSIXPANIC provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive instant alerts and notifications on your mobile device or control panel in the event of an emergency. Stay informed and take prompt action to ensure the safety of individuals in distress." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXPANIC operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXPANIC panic button meets industry standards and certifications for safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive safety plan for residential and commercial properties." },
                    
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXPANIC panic button operates using advanced wireless communication technology to provide immediate alerts in emergency situations.',
                section: [
                    { title: "Activation" },
                    { text: "The PROSIXPANIC is designed for quick and easy activation. In an emergency, simply press the button to send an immediate alert to the security system's control panel." },
                    
                    { title: "Wireless Communication" },
                    { text: "The panic button communicates wirelessly with the control panel, ensuring that the alert is transmitted instantly without delay. This wireless communication eliminates the need for complex wiring and allows for flexible placement." },
                    
                    { title: "Alarm Activation" },
                    { text: "Upon activation, the control panel triggers an alarm, which can include audible alerts, visual indicators, and notifications to monitoring services or emergency contacts. This ensures that the appropriate response is initiated quickly." },
                    
                    { title: "Notification and Response" },
                    { text: "The control panel sends notifications to designated individuals or monitoring services, providing real-time information about the emergency. This allows for quick response and assistance to the individuals in distress." },
                    
                    { title: "Battery-Powered Operation" },
                    { text: "The PROSIXPANIC operates on long-lasting batteries, ensuring that it remains operational even during power outages. The low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of panic buttons is crucial to their effectiveness in providing immediate alerts in emergency situations. Here are some key guidelines for where to place panic buttons in your home or property:',
                section: [
                    { title: "Bedrooms" },
                    { text: "Place panic buttons in bedrooms for quick access in case of an emergency during the night. This ensures that individuals can easily activate the alarm if needed." },
                    
                    { title: "Living Areas" },
                    { text: "Install panic buttons in living rooms, family rooms, and other common areas where people spend significant time. This provides easy access to the panic button in case of an emergency." },
                    
                    { title: "Near Entrances" },
                    { text: "Place panic buttons near main entrances and exits for quick access when entering or leaving the property. This allows for immediate alert in case of a security threat." },
                    
                    { title: "Bathrooms" },
                    { text: "Install panic buttons in bathrooms for added safety. This is particularly important for individuals who may require assistance or are at risk of falling." },
                    
                    { title: "Work Areas" },
                    { text: "Place panic buttons in home offices, workshops, and other work areas where individuals may need to quickly alert others in case of an emergency." },
                    
                    { title: "Accessible Locations" },
                    { text: "Ensure that panic buttons are installed in accessible locations where they can be easily reached by individuals of all ages and abilities. Avoid placing them in hidden or hard-to-reach areas." },
                    
                    { title: "Portable Options" },
                    { text: "Consider portable panic buttons that can be carried by individuals, providing immediate access to the alarm regardless of their location within the property." },
                ]
            },

            {
                header: 'Common causes of false alarms',
                text: 'False alarms from panic buttons can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms from panic buttons:',
                section: [
                    { title: "Accidental Activation" },
                    { text: "Accidental activation can occur if the panic button is pressed unintentionally. To prevent this, ensure that the button is placed in a location where it is easily accessible but not likely to be accidentally pressed." },
                    
                    { title: "Testing Without Notification" },
                    { text: "Testing the panic button without notifying the monitoring service can result in false alarms. Always inform the monitoring service before conducting tests to prevent unnecessary responses." },
                    
                    { title: "Malfunctioning Button" },
                    { text: "A malfunctioning panic button can trigger false alarms. Regularly check and maintain the button to ensure it is functioning correctly and replace it if any issues are detected." },
                    
                    { title: "Low Battery" },
                    { text: "A low battery can cause false alarms. Regularly check the battery level and replace the battery as needed to ensure proper operation." },
                    
                    { title: "Interference" },
                    { text: "Wireless interference from other devices can cause false alarms. Ensure that the panic button is placed away from sources of interference and that the wireless communication is reliable." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXPANIC;
