import ex from './img/ex1.jpg'

const Existing=()=>{
    const sensor = {
        title: 'Existing Z-Wave Thermostat',
        model:'Existing Device',
        sku:[],
        group:'automation',
        modelViewImage:ex,
        image:[ex],
        shortDescription:"Select this option to integrate an existing device from a previous alarm system with the ProSeries Touchscreen panel.",
        price: 0,
        checkListKeyword:['Automation'],
        infoHeader:"Integration of Existing Device from Previous Alarm System",
        spec:[
            
        ],
        info:[{
            header: 'How does it work?',
            text: 'When you select this option, a specialized module will be used to connect your existing home automation devices to the new ProSeries Touchscreen panel.  This makes it possible to upgrade your system without needing to replace all your devices. It\'s important to note that this process does not involve installing new devices; rather, it allows us to reuse the devices you already have in place. By integrating this module, your existing devices will continue to function seamlessly with the upgraded ProSeries panel, providing you with a modernized system without the need for extensive rewiring or device replacement. However, some existing systems may block Z-Wave devices from being reused, and certain devices may not be compatible with the ProSeries panel',
            section: [
                { title: "" },
                { text: "" },]
        }
        ]
    }
    return sensor
}
export default Existing;