import bat2 from '../../../Batteries/bat2.jpg';
import h1 from './PRSIXEATimg/h1.jpg';
import h2 from './PRSIXEATimg/h2.jpg';
import h3 from './PRSIXEATimg/h3.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXHEAT = () => {
    const sensor = {
        title: 'Heat Detector',
        model: 'PROSIXHEAT',
        sku: ['PROSIXHEAT'],
        group: 'sensor',
        modelViewImage: h1,
        image: [h1,h3,h2,bat2],
        shortDescription: 'Detects significant temperature changes indicating a potential fire.',
        price: getPrice('PROSIXHEAT'),
        checkListKeyword: ['Fire'],
        infoHeader: "Heat Detector",
        spec: [
            { row1: 'Model:', row2: 'PROSIXHEAT' },
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Wireless Range:',row2:'300+ Feet (Open Air) from main controler'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'2'},
            {row1:'Sound Level:',row2:'85 dBA'},
            {row1:'Detection Principle:',row2:'Heat'},
            {row1:'Alarm Indicator Light:',row2:'Red'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Overall Diameter:',row2:'4.7 in, 119.4 mm'},
            {row1:'Product Width:',row2:'1.6 in, 40.6 mm'},
            {row1:'Approvals and Standards:',row2:'ETL Listed'},
        ],
        info: [
            {
                header: 'How does it work?',
                text: 'The PROSIXHEAT wireless heat detector identifies significant temperature increases that may indicate a fire. Here\'s how it typically operates:',
                section: [
                    { title: "Temperature Sensing" },
                    { text: "The heat detector is equipped with a temperature sensor that monitors the ambient temperature in the area where it is installed. It can detect rapid increases in temperature that are indicative of a fire." },
                    
                    { title: "Threshold Detection" },
                    { text: "The detector has a predefined temperature threshold. When the ambient temperature rises above this threshold, the detector triggers an alarm." },
                    
                    { title: "Wireless Transmission" },
                    { text: "Upon detecting a temperature rise above the threshold, the heat detector wirelessly transmits a signal to the security system's control panel. This signal is interpreted as a fire alert." },
                    
                    { title: "Alert or Alarm" },
                    { text: "Based on the programming and settings of the security system, the control panel can trigger various responses, such as sounding an alarm, sending notifications to a Securepol's CMS and mobile device." },
                    
                    { title: "Battery-Powered Operation" },
                    { text: "The PROSIXHEAT detector is powered by batteries, ensuring that it remains operational even during power outages. The long battery life of these sensors reduces the need for frequent battery replacements." },
                ]
            },
            {
                header: 'Where to place it?',
                text: 'Proper placement of the PROSIXHEAT wireless heat detector is crucial for effective operation and optimal fire detection coverage. Here are some guidelines on where to install it:',
                section: [
                    { title: "Ceilings" },
                    { text: "Install the heat detector on the ceiling, as heat rises and the highest point in the room is the best location to detect significant temperature increases. Avoid placing it in areas where it may be exposed to drafts or ventilation systems that could affect its accuracy." },
                    
                    { title: "Kitchens" },
                    { text: "Kitchens are prone to higher temperatures due to cooking activities. Placing a heat detector in the kitchen can help detect potential fires early, but ensure it is not too close to cooking appliances to avoid false alarms." },
                    
                    { title: "Garages" },
                    { text: "Garages often house vehicles and other equipment that can pose a fire risk. Install heat detectors in garages to enhance fire safety in these areas." },
                    
                    { title: "Attics" },
                    { text: "Attics can be susceptible to fires due to electrical wiring and stored items. Place heat detectors in attics to provide early warning of potential fires." },
                    
                    { title: "Avoid Interference" },
                    { text: "Install the detectors away from sources of interference that could affect their wireless communication with the security system's control panel or hub. Keep them clear of metal objects, electronic devices, or large appliances that may disrupt signals." },
                    
                    { title: "Height and Positioning" },
                    { text: "Place the detectors at a height where they are not easily accessible or visible to intruders. This helps prevent tampering or bypassing of the sensors. Additionally, ensure that the alignment is maintained when doors and windows are opened or closed." },
                ]
            },
            {
                header: 'When it may not detect?',
                text: 'The PROSIXHEAT wireless heat detector, like any sensor, may have limitations in certain scenarios. Here are some situations where the sensor may not detect properly:',
                section: [
                    { title: "Rapid but Localized Temperature Changes" },
                    { text: "If the temperature increase is rapid but localized (e.g., from a small appliance), the heat detector may not detect it as quickly. Ensure the detector is placed where it can monitor overall ambient temperature changes effectively." },
                    
                    { title: "Blocked or Obstructed Sensors" },
                    { text: "Ensure that the sensor is not blocked or obstructed by objects that could prevent it from detecting temperature changes accurately." },
                    
                    { title: "Interference or Obstructions" },
                    { text: "Sensors placed near sources of interference, such as electronic devices, metal objects, or other wireless equipment, may experience signal interference that could affect their performance." },
                    
                    { title: "Tampering or Bypassing" },
                    { text: "Intruders with knowledge of security systems may attempt to tamper with or bypass heat detectors. To prevent tampering, install the sensors securely and discreetly." },
                    
                    { title: "Battery Life and Maintenance" },
                    { text: "Regularly check and replace batteries in the sensors to ensure uninterrupted operation. Low battery levels can impact the sensor's performance and reliability, leading to missed detections or false alarms." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXHEAT;
