import React from 'react';
import css from './PriceDisplay.module.css';

const PriceDisplay = ({upfront=0, monthly=0, discount=0})=>{
    const prevUpfront =  upfront / (1 - discount / 100);
    const prevMonthly = monthly / (1 - discount / 100);
    return(
        <div className={css.priceList}>
                <div className={css.rowWrapper}>
                    <div className={css.prevPrice}>${prevUpfront.toFixed(2)}</div>
                    <div className={css.newPrice}>${upfront && upfront.toFixed(2)}</div>
                    <div className={css.label}>Upfront Payment</div>
                </div>
                <div className={css.rowWrapper}>
                    <div className={css.prevPrice}>${prevMonthly.toFixed(2)}</div>
                    <div className={css.newPrice}>${monthly && monthly.toFixed(2)}</div>
                    <div className={css.label}>Monthly Payment</div>
                </div>
        </div>
    )
}
export default PriceDisplay;