import React from 'react';
import css from './ProductTitle.module.css';

const ProductTitle = ({projectDeviceName})=>{
    return(
        <div className={css.productTitleBox}>
            <div className={css.productTitle}>
                <p>{projectDeviceName}</p>
            </div>
        </div>
    )
}
export default ProductTitle;