import w1 from './PROSIXFLOODimg/w1.jpg';
import w2 from './PROSIXFLOODimg/w2.jpg';
import w3 from './PROSIXFLOODimg/w3.jpg';
import w4 from './PROSIXFLOODimg/w4.jpg';
import w5 from './PROSIXFLOODimg/w5.jpg';
import w6 from './PROSIXFLOODimg/w6.jpg';
import bat from '../../../Batteries/Cr123a.jpg'
import { getPrice } from '../../../../../../Prices';
const PROSIXFLOOD = () => {
    const sensor = {
        title: 'Flood Detector',
        model: 'PROSIXFLOOD',
        sku: ['PROSIXFLOOD'],
        modelViewImage: w1,
        image: [w1,w2,w3,w4,w5,w6, bat],
        shortDescription: 'Detects the presence of water and potential flooding.',
        price: getPrice('PROSIXFLOOD'),
        group: 'sensor',
        checkListKeyword: ['Flood'],
        infoHeader: "Flood Detector",
        spec: [
            { row1: 'Model:', row2: 'PROSIXFLOOD' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Surface mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },
            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Detection Principle:', row2: 'Electrodes for water detection' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Sensor Cable Length:', row2: '6 ft, 182.88 cm' },
            { row1: 'Product Length:', row2: '3.1 in, 78.7 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Product Height:', row2: '1.4 in, 35.6 mm' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed' },
        ],
        info: [
            {
                header: 'Flood Detector: Early Warning for Water Leaks and Flooding',
                text: 'The PROSIXFLOOD flood detector is an essential device for detecting water leaks and potential flooding. Designed to provide early warning and prevent water damage, this flood detector ensures the safety and protection of your property and assets.',
                section: [
                    { title: "Early Detection" },
                    { text: "The PROSIXFLOOD utilizes advanced water sensing technology to detect the presence of water and provide early warning of potential flooding. Its high sensitivity ensures prompt detection even with small amounts of water, allowing for swift action to prevent damage." },
                    
                    { title: "Reliable Performance" },
                    { text: "Engineered for reliability, the PROSIXFLOOD flood detector delivers consistent and accurate detection without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The PROSIXFLOOD is a wireless flood detector that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROSIXFLOOD provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive instant alerts and notifications on your mobile device or control panel in the event of water detection. Stay informed and take prompt action to mitigate risks and ensure the safety of your property and assets." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXFLOOD operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXFLOOD flood detector meets industry standards and certifications for safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive safety plan for residential and commercial properties." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXFLOOD flood detector operates using advanced water sensing technology to provide early warning of potential water leaks and flooding.',
                section: [
                    { title: "Water Sensing" },
                    { text: "The PROSIXFLOOD is equipped with highly sensitive electrodes that detect the presence of water. When water comes into contact with the electrodes, it completes an electrical circuit, triggering the detection mechanism." },
                    
                    { title: "Detection Algorithm" },
                    { text: "Upon detecting water, the detector's built-in detection algorithm analyzes the water presence to determine if it is indicative of a leak or flooding. This analysis helps prevent false alarms caused by minor spills or condensation." },
                    
                    { title: "Alarm Activation" },
                    { text: "If the water presence is determined to be hazardous, the PROSIXFLOOD flood detector activates its alarm system. The alarm includes audible alerts and visual indicators to alert occupants of the potential danger." },
                    
                    { title: "Wireless Communication" },
                    { text: "The PROSIXFLOOD is a wireless flood detector, meaning it communicates wirelessly with a compatible security system or control panel. When the alarm is triggered, the detector sends an immediate signal to the control panel, which then initiates appropriate response actions." },
                    
                    { title: "Notification and Response" },
                    { text: "Upon receiving the alarm signal, the control panel notifies the designated individuals or monitoring service about the water detection event. This notification can be sent via mobile app notifications, text messages, email alerts, depending on the configured settings." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of flood detectors is crucial to their effectiveness in detecting water leaks and providing timely alerts. Here are some key guidelines for where to place flood detectors in your home or property:',
                section: [
                    { title: "Basements" },
                    { text: "Install flood detectors in basements, near sump pumps, and in areas prone to flooding. Early detection in these areas can prevent extensive water damage." },
                    
                    { title: "Bathrooms" },
                    { text: "Place flood detectors near toilets, sinks, bathtubs, and other plumbing fixtures that may leak or overflow. This ensures early detection of potential water leaks." },
                    
                    { title: "Kitchens" },
                    { text: "Install flood detectors under sinks, near dishwashers, and other appliances that use water. This helps detect leaks before they cause significant damage." },
                    
                    { title: "Laundry Rooms" },
                    { text: "Place flood detectors near washing machines, water heaters, and other appliances that may leak or overflow. This provides early warning of potential water damage." },
                    
                    { title: "Utility Rooms" },
                    { text: "Install flood detectors in utility rooms or areas with water pipes, tanks, or other water-related equipment. Early detection in these areas can prevent extensive damage." },
                    
                    { title: "Near Windows and Doors" },
                    { text: "Place flood detectors near windows and doors that may be prone to leaks or flooding during heavy rain or storms. This ensures early detection of water ingress." },
                    
                    { title: "Ceiling or Wall Mounting" },
                    { text: "Mount flood detectors on the floor or low on walls for optimal coverage. Ensure they are placed in areas where water is likely to accumulate in the event of a leak or flood." },
                ]
            },

            {
                header: 'Common causes of false alarms',
                text: 'False alarms from flood detectors can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms from flood detectors:',
                section: [
                    { title: "Condensation" },
                    { text: "Condensation from pipes or HVAC systems can cause false alarms if the detectors are placed too close to these sources. Ensure detectors are positioned away from potential condensation points." },
                    
                    { title: "Minor Spills" },
                    { text: "Minor spills from cleaning activities or other non-hazardous water sources can trigger false alarms. Place detectors in strategic locations to minimize false alarms from minor spills." },
                    
                    { title: "High Humidity" },
                    { text: "High humidity levels in certain areas, such as bathrooms or basements, can cause false alarms. Ensure proper ventilation and consider using humidity-resistant detectors if needed." },
                    
                    { title: "Insect Intrusion" },
                    { text: "Insects, particularly tiny insects like dust mites or gnats, can enter detectors and trigger false alarms by obstructing sensors or causing malfunctions." },
                    
                    { title: "Dust and Debris" },
                    { text: "Accumulated dust, lint, or airborne debris can enter detectors and interfere with their sensors, leading to false alarms. Regular cleaning and maintenance can help prevent this issue." },
                    
                    { title: "Malfunctioning Detectors" },
                    { text: "Over time, detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect water when needed." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXFLOOD;
