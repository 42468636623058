import y1 from './PROSIXMEDimg/y1.jpg';
import y2 from './PROSIXMEDimg/y2.jpg';
import y3 from './PROSIXMEDimg/y3.jpg';
import cr2450 from '../../../../Batteries/cr2450.jpg';
import { getPrice } from '../../../../../../../Prices';

const PROSIXMED = () => {
    const device = {
        title: 'Medical Alert Device',
        model: 'PROSIXMED',
        sku: ['PROSIXMED'],
        modelViewImage: y1,
        image: [y1,y2,y3,cr2450],
        shortDescription: 'Medical alert device for emergency assistance.',
        price: getPrice('PROSIXMED'),
        group: 'medical_alert',
        checkListKeyword: [],
        infoHeader: "Medical Alert Device",
        spec: [
            { row1: 'Model:', row2: 'PROSIXMED' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Portable or Wall mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'CR2450' },
            { row1: 'Number of Batteries:', row2: '2' },
            { row1: 'Dimensions:', row2: '3.1 x 1.6 x 1.4 inches' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'Medical Alert Device: Immediate Assistance in Emergencies',
                text: 'The PROSIXMED medical alert device provides immediate assistance in emergencies. Designed for ease of use and reliability, this device ensures that help is just a button press away.',
                section: [
                    { title: "Immediate Alert" },
                    { text: "The PROSIXMED allows users to send an immediate alert with the press of a button. This instant notification can be crucial in medical emergencies, providing quick response and assistance." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The device connects to your security system wirelessly, ensuring reliable communication and easy installation without the need for complex wiring." },
                    
                    { title: "Portable Design" },
                    { text: "The PROSIXMED is designed to be portable, allowing users to carry it with them or mount it on the wall for easy access. This flexibility ensures that the device is always within reach in an emergency." },
                    
                    { title: "Tamper-Resistant" },
                    { text: "The device features a tamper-resistant design, alerting the system if the device is tampered with or removed. This enhances security and ensures continuous protection." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXMED operates on long-lasting lithium batteries, providing continuous protection even during power outages. The battery indicator alerts you when battery replacement is needed." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXMED medical alert device meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXMED medical alert device operates using advanced wireless communication technology to provide immediate assistance in emergency situations.',
                section: [
                    { title: "Alert Activation" },
                    { text: "The device is activated when the user presses the emergency button. It sends an immediate alert to the security system's control panel, which then notifies the monitoring service or emergency contacts." },
                    
                    { title: "Wireless Communication" },
                    { text: "The device connects wirelessly to the security system, ensuring reliable communication and easy installation. This wireless connection allows for flexible placement and portability." },
                    
                    { title: "Tamper Detection" },
                    { text: "The PROSIXMED features tamper detection, alerting the system if the device is tampered with or removed. This ensures that any attempts to disable the device are detected and reported." },
                    
                    { title: "Battery Operation" },
                    { text: "The device operates on long-lasting lithium batteries, providing continuous protection even during power outages. The battery indicator alerts you when battery replacement is needed." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of the PROSIXMED medical alert device is crucial to its effectiveness. Here are some key guidelines for where to place the device:',
                section: [
                    { title: "Near Bedside" },
                    { text: "Place the device near the bedside for quick access during the night. This ensures that help is within reach in case of an emergency." },
                    
                    { title: "Common Areas" },
                    { text: "Install the device in common areas such as living rooms and kitchens where users spend significant time. This provides easy access to the device in case of an emergency." },
                    
                    { title: "Bathrooms" },
                    { text: "Consider placing a device in the bathroom, where slips and falls are common. This ensures that help is immediately available if needed." },
                    
                    { title: "Portable Option" },
                    { text: "Users can carry the device with them throughout the home or property for continuous protection. This portable option ensures that help is always within reach." },
                    
                    { title: "Accessible Locations" },
                    { text: "Ensure that the device is placed in accessible locations where it can be easily reached by individuals of all ages and abilities. Avoid placing it in hidden or hard-to-reach areas." },
                    
                    { title: "Secure Mounting" },
                    { text: "If mounting the device on the wall, ensure it is securely mounted to prevent tampering or accidental disconnection. Use the provided mounting hardware for a stable installation." },
                ]
            },

            {
                header: 'Common issues and troubleshooting',
                text: 'Here are common issues that may arise with the PROSIXMED medical alert device and how to troubleshoot them:',
                section: [
                    { title: "Unresponsive Device" },
                    { text: "If the device becomes unresponsive, check the battery levels and replace the batteries if necessary. Ensure that the device is within range of the security system's wireless signal." },
                    
                    { title: "Connectivity Issues" },
                    { text: "If the device loses wireless connectivity, check the network settings and ensure it is within range of the wireless signal. Verify that there are no network outages affecting the system." },
                    
                    { title: "False Alerts" },
                    { text: "False alerts can occur due to incorrect handling or sensor issues. Check the device and ensure that it is being used correctly. Adjust settings as needed to prevent false alerts." },
                    
                    { title: "Battery Issues" },
                    { text: "If the battery indicator shows low battery, replace the batteries promptly to ensure continuous operation. Check the battery connections and ensure they are secure." },
                    
                    { title: "Tamper Alerts" },
                    { text: "If the device triggers tamper alerts, check for any signs of tampering or physical damage. Ensure that all components are securely mounted and that there are no loose connections." },
                ]
            },
        ]
    }
    return device;
}
export default PROSIXMED;
