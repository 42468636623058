import q1 from './Wifiimg/q1.jpg';
import q3 from './Wifiimg/q3.jpg';
import { getPrice } from '../../../../../../Prices';
const PROWIFI = () => {
    const device = {
        title: 'Wi-Fi Communicator',
        model: 'PROWIFI',
        sku: ['PROWIFI'],
        modelViewImage: q1,
        image: [q1,q3],
        shortDescription: 'Provides reliable Wi-Fi communication for security systems.',
        isService: true,//this separate hardware from services 
        price: getPrice('PROWIFI'),
        monthly: getPrice('WIFI_MONI'),
        checkListKeyword: ['Wifi'],
        infoHeader: "Wi-Fi Communicator",
        spec: [
            { row1: 'Model:', row2: 'PROWIFI' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wi-Fi (Internet Communications)' },
            { row1: 'Network Type:', row2: '2.4GHz Wi-Fi' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Mounting:', row2: 'Build-in the panel' },
        ],
        info: [
            {
                header: 'Wi-Fi Communicator: Reliable Communication for Security Systems',
                text: 'The PROWIFI Wi-Fi communicator provides reliable and secure Wi-Fi communication for security systems. Designed to ensure uninterrupted communication, this device is essential for maintaining the integrity and responsiveness of your security setup.',
                section: [
                    { title: "Reliable Communication" },
                    { text: "The PROWIFI utilizes both 2.4GHz and 5GHz Wi-Fi networks to provide reliable and uninterrupted communication between the security system and monitoring services. This ensures that alerts and notifications are transmitted promptly and securely." },
                    
                    { title: "Nationwide Coverage" },
                    { text: "With nationwide coverage, the PROWIFI ensures that your security system remains connected regardless of your location. This wide coverage area enhances the reliability and effectiveness of your security system." },
                    
                    { title: "Easy Integration" },
                    { text: "The PROWIFI is designed for seamless integration with compatible security systems. This allows for easy installation and configuration, ensuring that your security setup remains robust and reliable." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROWIFI provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Power Supply" },
                    { text: "The PROWIFI operates on a 12V DC power supply, ensuring continuous operation and reliability. Its low power consumption makes it an efficient addition to your security system." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROWIFI Wi-Fi communicator meets industry standards and certifications for safety and performance, including ETL listing and FCC certification. This ensures compliance with local regulations and building codes." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROWIFI Wi-Fi communicator operates using advanced Wi-Fi technology to provide reliable and secure communication for security systems.',
                section: [
                    { title: "Wi-Fi Communication" },
                    { text: "The PROWIFI connects to your Wi-Fi network, allowing the security system to communicate with monitoring services and emergency contacts. This Wi-Fi communication ensures that alerts and notifications are transmitted securely and promptly." },
                    
                    { title: "Integration with Security Systems" },
                    { text: "The communicator integrates with the security system, receiving signals from sensors and control panels. When an alarm is triggered, the communicator transmits the alert to the monitoring service via the Wi-Fi network." },
                    
                    { title: "Tamper Detection" },
                    { text: "The PROWIFI features tamper detection, alerting the system if the device is tampered with or disconnected. This ensures that any attempts to disable the communicator are detected and reported." },
                    
                    { title: "Continuous Monitoring" },
                    { text: "The Wi-Fi communicator continuously monitors the status of the security system, ensuring that all signals are transmitted reliably. This continuous monitoring enhances the responsiveness and effectiveness of the security setup." },
                    
                    { title: "Backup Communication" },
                    { text: "In the event of a power outage or internet failure, the PROWIFI provides backup communication for the security system if it is paired with a compatible cellular communicator. This ensures that alerts and notifications are still transmitted, maintaining the integrity of the security setup." },
                ]
            },

            {
                header: 'Common causes of communication issues',
                text: 'Communication issues with Wi-Fi communicators can occur due to various reasons. Here are common causes of communication issues and how to address them:',
                section: [
                    { title: "Weak Wi-Fi Signal" },
                    { text: "Weak Wi-Fi signals can cause communication issues. Ensure that the communicator is placed in a location with strong Wi-Fi coverage, or consider using a Wi-Fi range extender if needed." },
                    
                    { title: "Interference" },
                    { text: "Interference from electronic devices, metal objects, or other sources can disrupt Wi-Fi communication. Place the communicator away from potential sources of interference to improve reliability." },
                    
                    { title: "Power Supply Issues" },
                    { text: "Power supply issues can cause the communicator to malfunction. Ensure that the device is connected to a reliable power source and check for any power supply problems." },
                    
                    { title: "Tampering" },
                    { text: "Tampering or physical damage to the communicator can cause communication issues. Regularly check the device for signs of tampering and ensure it is securely mounted." },
                    
                    { title: "Network Outages" },
                    { text: "Network outages or disruptions can affect Wi-Fi communication. Monitor the status of your Wi-Fi network and consider having a backup communication method in place." },
                    
                    { title: "Device Malfunction" },
                    { text: "A malfunctioning communicator can cause communication issues. Regularly test the device and perform maintenance to ensure it is functioning correctly." },
                ]
            },
        ]
    }
    return device;
}
export default PROWIFI;
