import React from 'react';
import Footer from "../../Footer/Footer";

export default function FooterSection({data}){
    return(
        <>
            {data && (
                <>
                {data.map((obj, i)=>(
                    <Footer key={i} obj={obj}/>
                ))} 
                </>
            )}
        </>
    )
}