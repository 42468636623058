import css from './RadioQuestion.module.css';
import React, { useEffect, useState } from 'react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const RadioQuestion = ({ 
    step, 
    setStep, 
    nextStep, 
    prevStep, 
    info, 
    setInfo, 
    infoKey='',
    header='', 
    paragraph='', 
    positiveCheckBox='Yes', 
    negativeCheckBox='No',
    backButtonDir=step-1,
    nextButtonDir=step+1,    
}) => {
    
    const [isChecked, setIsChecked] = useState(false);
    const [isYes, setIsYes] = useState(null);
    
    useEffect(() => {
        setIsChecked(false);
        setIsYes(null);
        if (info[infoKey] !== '') {
            setIsChecked(true);
            setIsYes(info[infoKey]);
        }
    }, [step, infoKey]);

    const handleYesAnsware = () => {
        setIsChecked(true);
        setIsYes(true);
        setInfo({ ...info, [infoKey]: true });
    };
    
    const handleNoAnsware = () => {
        setIsChecked(true);
        setIsYes(false);
        setInfo({ ...info, [infoKey]: false });
    };

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <div className={css.titleBox}>
                    <h2>{header}</h2>
                </div>
                

                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>{paragraph}</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.checkBoxWrapper}>
                            <div className={css.checkboxBox} onClick={handleYesAnsware}>
                                <div className={css.checkbox}>
                                    {isChecked && isYes ? (
                                        <MdOutlineCheckBox />
                                    ) : (
                                        <MdOutlineCheckBoxOutlineBlank />
                                    )}
                                </div>
                                <div className={css.label}>
                                    <p>{positiveCheckBox}</p>
                                </div>
                            </div>
                        </div>
                        <div className={css.checkBoxWrapper}>
                            <div className={css.checkboxBox} onClick={handleNoAnsware}>
                                <div className={css.checkbox}>
                                    {isChecked && !isYes ? (
                                        <MdOutlineCheckBox />
                                    ) : (
                                        <MdOutlineCheckBoxOutlineBlank />
                                    )}
                                </div>
                                <div className={css.label}>
                                    <p>{negativeCheckBox}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.buttonBoxWrapper}>
                    <button
                        className={css.buttonActive}
                        onClick={()=>setStep(backButtonDir)}
                    >
                        Back
                    </button>
                    <button
                        className={isChecked ? css.buttonActive : css.buttonInActive}
                        onClick={()=>setStep(nextButtonDir)}
                        disabled={!isChecked}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RadioQuestion;
