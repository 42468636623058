import React from 'react';
import BizInfoBlock from "../../containers/BizInfoBlock/BizInfoBlock";
import List from "../../List/List";

export default function BizInfoSection({ data }) {
    return (
        <>
            {data && (
                <>
                    {data.map((arr, rowIndex) => (
                        <BizInfoBlock key={rowIndex} i={rowIndex}>
                            {arr.map((column, columnIndex) => (
                                <List key={`${rowIndex}-${columnIndex}`} arr={column} i={`${rowIndex}-${columnIndex}`} />
                            ))}
                        </BizInfoBlock>
                    ))}
                </>
            )}
        </>
    );
}
