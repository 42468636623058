import React from 'react';
import css from './Send.module.css';
import {  SiMinutemailer } from "react-icons/si";

const Send=({view,setView,})=>{
    return(
        <div className={view !== 'send' ? `${css.box} ${css.inActive}` : `${css.box} ${css.active}`} onClick={() => setView('send')}>
            <div className={css.iconWrapper}>
            <SiMinutemailer className={css.icon}/>
            </div>
            <div className={css.label}>
                Send
            </div>
        </div>
    )
}
export default Send;