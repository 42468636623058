import React from 'react';
import css from "./Packages.module.css";
import CalcApp from "../../calcApp/calcApp"

export default function Packages(){
    return(
        <>      
            <div className={css.body}>
                <CalcApp/>
            </div>
        </>
    )
}
