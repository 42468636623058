import css from './OrderConfirmation.module.css';
import React, { useEffect, useState } from 'react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import {formatEmail, validateEmail} from '../../../AppCommonComponents/FormInput/Email';

const OrderConfirmation = ({ 
    customerInfo
 }) => {

   

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Submission Successful!</h2>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>Your application is now in the queue for processing.</p>
                        <p className={css.messageInstruction}>A confirmation email including a PDF copy of your application has been sent to: <br/><b>{customerInfo.email}</b></p>
                        <p className={css.messageInstruction}>Our team at Securepol will reach out to you shortly regarding the next steps to have your system installed. </p>
                        <p className={css.messageInstruction}>We appreciate your trust in us and are committed to providing you with the best service possible.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmation;
