import React from 'react';
import css from "./TextInputWithLabel.module.css"
const TextInputWithLabel =(props)=>{
    return(
        <div className={css.box}>
            <label htmlFor={props.id}>{props.label}{props.required && (<span>*</span>)}</label>
            <input {...props}></input>
        </div>
    )
}
export default TextInputWithLabel;