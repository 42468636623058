import css from './TransferView.module.css';
import React, { useState, useEffect} from 'react';
import emailjs from 'emailjs-com';
import Initial from './OrderSteps/Initial/Initial';
import Spinner from './OrderSteps/Spinner/Spinner';
import Keypad from './OrderSteps/Keypad/Keypad';
import { ConsoleLogger } from 'aws-amplify/utils';
import RotateLoader from 'react-spinners/RotateLoader';
import RadioQuestion from './OrderSteps/RadioQuestion/RadioQuestion'
import TextInputQuestion from './OrderSteps/TextInputQuestion/TextInputQuestion';
import TextAreaInputQuestion from './OrderSteps/TextAreaInputQuestion/TextAreaInputQuestion';
import customerInfo from './OrderSteps/CustomerInfo/CustomerInfo'
import CustomerInfo from './OrderSteps/CustomerInfo/CustomerInfo';
import PlainPage from './OrderSteps/PlainPage/PlainPage';
import SubmissionError from './OrderSteps/SubmissionError/SubmissionError'
const OrderView = ({

}) => {

    
    const [step, setStep]=useState(1)
    const [emailSent, setEmailSent] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [previewUrls, setPreviewUrls] = useState([]);

    const [requestID, setRequestID] = useState(generateShortId() )
    

    const [info, setInfo] = useState({
        isSecurepol:'',
        isMonitored:'',
        prevCompany:'',
        isInstallerCode:'',
        isPhoneLine:'',
        isInternet:'',
        isCellular:'',
        isApp:'',
        isCamera:'',
        cameraList:'',
        isAutomation:'',
        automationList:'',
        image1: '', 
        image2: '', 
        image3: '', 
        image4: '', 
        keypadImage:'',
        additionalInfo:'',
        monitoringDuration:'',
        phoneService:'',
        customerInfo:{
            fname:'',
            lname:'',
            phone:'',
            email:'',
            address:'',
            city:'',
            province:'',
            postal:''
        },
    })
    const createDetailString=()=>{

        return{
            isSecurepol:info.isSecurepol?'Yes':'No',
            isMonitored:info.isMonitored?'Yes':'No',
            isInstallerCode:info.isInstallerCode?'Yes':'No',
            isPhoneLine:info.isPhoneLine?'Yes':'No',
            isInternet:info.isInternet?'Yes':'No',
            isCellular:info.isCellular?'Yes':'No',
            isApp:info.isApp?'Yes':'No',
            isCamera:info.isCamera?'Yes':'No',
            isAutomation:info.isAutomation?'Yes':'No',
            phoneService:info.phoneService?'Yes':'No',

        }
        
    }
    function generateShortId() {
        // Get the current date and time
        const now = new Date();
    
        // Extract shortened components of the date and time
        const year = String(now.getFullYear()).slice(2); // Get last two digits of the year
        const month = String(now.getMonth() + 1); // Month (no leading zero)
        const day = String(now.getDate()); // Day (no leading zero)
        const hours = String(now.getHours()); // Hours (no leading zero)
        const minutes = String(now.getMinutes()); // Minutes (no leading zero)
    
        // Generate a random two-digit number
        const randomTwoDigits = String(Math.floor(Math.random() * 100)).padStart(2, '0');
    
        // Concatenate components to form the ID
        const id = year + month + day + hours + minutes + randomTwoDigits;
    
        return id;
    }
   
    const sendEmail = async () => {
        const serviceId = 'service_52qj8o7';
        const templateId = 'template_f9gxphk';
        const userId = 'Pgvr9-YrZHu7sbMO4';
        const ccEmail = 'tech@securepol.com';
    
        try {
            const response = await emailjs.send(
                serviceId,
                templateId,
                {
                    requestID: requestID,
                    ...info,
                    infoString:createDetailString(),
                    ccEmail: ccEmail,
                },
                userId
            );
            if (response.status === 200) {
                console.log('Email sent successfully:', response);
                return true;
            } else {
                console.error('Failed to send email:', response);
                return false;
            }
        } catch (error) {
            console.error('Email error:', error);  // Log the full error object
            return false;
        }
    };
    
    

    useEffect(() => {
        if (step === 23 && !emailSent) {
            (async () => {
                const emailSuccess = await sendEmail();
                if (emailSuccess) {
                    setEmailSent(true);
                    setTimeout(() => {
                        setStep(25)//success)
                    }, 4000);
                } else {
                    setEmailSent(false);
                    setTimeout(() => {
                        setStep(24)//failed
                    }, 4000);
                    
                }
            })();
        }
    }, [step, emailSent]);

    const nextStep=()=>setStep(step+1)
    const prevStep=()=>setStep(step-1)

    
    
      
    
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result.split('base64,')[1]);
          reader.onerror = (error) => reject(error);
        });
      };
    
      const renderSteep = () =>{
        switch(step){
            case 0:
                window.open('./transfer', '_self');
            break;
            case 1:
                return(
                    <Initial
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                );
            case 2:
            return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Initializing profile...'} 
                    spinnerTime={4000}
                />
                    
            );
            case 3:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isSecurepol'
                        header='Has your alarm system been monitored by Securepol before?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={1}
                        nextButtonDir={info.isSecurepol?15:4}
                    />
                );
            case 4:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isMonitored'
                        header='Is your alarm currently monitored or has it ever been monitored by another company?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={3}
                        nextButtonDir={info.isMonitored?5:14}
                    />
                );
                case 5:
                return(
                    <TextInputQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='prevCompany'
                        header='Please provide the name of the previous company.'
                        placeHolder='Previous monitoring company'
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                        
                    />
                );
            case 6:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isPhoneLine'
                        header='Is your alarm system using a landline for monitoring?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                    />
                );
                case 7:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isInternet'
                        header='Is your alarm system using the internet for monitoring?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                    />
                );
                case 8:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isCellular'
                        header='Is your alarm system using a cellular communicator for monitoring?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                    />
                );
                case 9:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isApp'
                        header='Is your alarm system using a mobile app for control?'
                        paragraph=''
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={info.isApp?10:14}
    
                    />
                );
               case 10:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isCamera'
                        header='Is your alarm application controlling any video cameras?'
                        paragraph='Like video doorbell, outdoor camera etc.?'
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={info.isCamera?11:12}
                    />
                );
                case 11:
                    return(
                        <TextAreaInputQuestion
                            step={step}
                            setStep={setStep}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            info={info}
                            setInfo={setInfo}
                            infoKey ='cameraList'
                            header='What cameras do you use?'
                            paragraph='List all your video devices'
                            placeHolder='Doorbell, outdoor, indoor, etc.'
                            positiveCheckBox='Yes'
                            negativeCheckBox='No'
                            backButtonDir={step-1}
                            nextButtonDir={step+1}
                            isAnswareRequired={true}
                        />
                    ); 
                    case 12:
                return(
                    <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='isAutomation'
                        header="Is your alarm system controlling any smart home devices?"
                        paragraph="Like door locks, thermostats, garage doors, lights, etc.?"
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={info.isCamera?11:10}
                        nextButtonDir={info.isAutomation?13:14}
                    />
                );
                case 13:
                    return(
                        <TextAreaInputQuestion
                            step={step}
                            setStep={setStep}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            info={info}
                            setInfo={setInfo}
                            infoKey ='automationList'
                            header="What smart devices do you use?"
                            paragraph="List all your automation devices."
                            placeHolder='Door lock, thermostat, etc.'
                            positiveCheckBox='Yes'
                            negativeCheckBox='No'
                            backButtonDir={step-1}
                            nextButtonDir={step+1}
                            isAnswareRequired={true}
                        />
                    ); 
            case 14:
                return(
                    <RadioQuestion
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    info={info}
                    setInfo={setInfo}
                    infoKey ='isInstallerCode'
                    header="Do you know your alarm system installer code?"
                    paragraph="The installer code allows changes to alarm system settings and is usually kept by the monitoring company. It's not the same as the master or user code."
                    positiveCheckBox='Yes'
                    negativeCheckBox='No'
                    backButtonDir={info.isMonitored?9:4}
                    nextButtonDir={step+1}
                />
                );
            case 15:
            return(
                <Keypad 
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    info={info}
                    setInfo={setInfo}
                    previewUrl={previewUrls}
                    setPreviewUrl={setPreviewUrls}
                    header='Alarm keypad and panel'
                    paragraph="Upload up to 4 images of your alarm keypads and other major components like the alarm panel. Keypads are usually located in mudrooms or near entry doors. If the keypad has a cover, please open it before taking the picture. The alarm panel and attached communicator, if not built into the main keypad, are usually located in the furnace room or electrical closet."

                    backButtonDir={info.isSecurepol?3:14}
                    nextButtonDir={step+1}
                />)
                case 16:
                    return(
                        <RadioQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='phoneService'
                        header="Do you have a landline phone service?"
                        paragraph="Please ensure that your phone service is a traditional landline and not a Voice over IP (VoIP) service. VoIP services do not work with alarm systems."
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step - 1}
                        nextButtonDir={step + 1}
                    />
                    );
                case 17:
                    
                return(
                    <TextAreaInputQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='additionalInfo'
                        header='Would you like to upgrade any part of your alarm system?'
                        paragraph='Please describe.'
                        placeHolder='Replace keypad, upgrade to cellular, add mobile app, etc.'
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                        
                    />
                ); 
                case 18:
                return(
                    <TextInputQuestion
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        infoKey ='monitoringDuration'
                        header='For how long would you like to use the monitoring service?'
                        placeHolder='Weeks, months, years, ongoing, etc.'
                        positiveCheckBox='Yes'
                        negativeCheckBox='No'
                        backButtonDir={step-1}
                        nextButtonDir={step+1}
                        
                    />
                );
            
                case 19:
                    return(
                        <Spinner 
                            step={step} 
                            setStep={setStep} 
                            message={'Preparing form...'} 
                            spinnerTime={4000}
                        />
                            
                    ); 
            case 20:
                return(
                    <CustomerInfo
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        header='Enter customer information'
                        backButtonDir={18}
                        nextButtonDir={21}
                        
                    />
                ); 
                case 21:
                return(
                    <Spinner 
                        step={step} 
                        setStep={setStep} 
                        message={'Finalizing...'} 
                        spinnerTime={4000}
                    />
                        
                ); 
                case 22:
                    return(
                        <PlainPage
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        info={info}
                        setInfo={setInfo}
                        header="Your application is ready to submit"
                        paragraph={`After receiving your application, we will review it and send our expert feedback to your email: ${info.customerInfo.email}`}
                        backButtonDir={20}
                        nextButtonDir={23}
                        backButtonLabel='Back'
                        nextButtonLabel="Submit"
                    />
                    );
    
                    case 23:
                        return(
                            <>
                            <div className={css.procceedBox}>
                              <div className={css.messageBox}>
                                <div className={css.spinnerContainer}>
                                  <RotateLoader  size={12} color="#274E65"/>
                                    <div className={css.message}>
                                      <p>Submiting applicaiton...</p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className={css.buttonBox}>
                            </div>
                          </>  
                        );
                    
                    case 24:
                        return(
                        <SubmissionError
                            step={step}
                            setStep={setStep}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            backButtonDir={23}
                        />
                        );
                    case 25:
                        return(
                            <PlainPage
                            step={step}
                            setStep={setStep}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            info={info}
                            setInfo={setInfo}
                            header = "Submission successful"
                            paragraph = "Your application is now in the queue for processing. Our team at Securepol will reach out to you shortly."
                            nextButtonDir={0}
                            nextButtonLabel="Close"
                            isBackButton={false}
                        />
                    );
    
        }
    }
    
    return( 
        <>
        <div className={css.sectionWrapperFull}>
            <div className={css.body}>
                {renderSteep()}
            </div>
        </div>
       </>
    )
}
export default OrderView;
