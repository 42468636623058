import l1 from './img/l1.jpg';
import l5 from './img/l5.jpg';
import { getPrice } from '../../../../Prices';
const SmartCode10ZW = () => {
    const device = {
        title: 'SmartCode 10 Traditional with Z-Wave Door Lock',
        model: 'SMART CODE 10',
        sku: ['SmartCode 10 ZW'],
        modelViewImage: l1,
        image: [l1,l5],
        shortDescription: 'Smart door lock with Z-Wave technology for enhanced security and convenience.',
        price:getPrice('WEIZER_CODE_10'),
        group: 'automation',
        checkListKeyword: ['Door Lock', 'Smart', 'Z-Wave'],
        infoHeader: "Smart Door Lock",
        spec: [
            { row1: 'Model:', row2: 'SmartCode 10' },
            { row1: 'Brand:', row2: 'Weiser' },
            { row1: 'Connectivity:', row2: 'Z-Wave' },
            { row1: 'Color:', row2: 'Satin Nickel / Venetian Bronze' },
            { row1: 'Mounting:', row2: 'Door mount' },
            { row1: 'Power Supply:', row2: '4 AA Batteries' },
            { row1: 'Compatibility:', row2: 'Works with most standard doors' },
            { row1: 'Keypad:', row2: 'Yes, with backlight' },
            { row1: 'Smartphone Control:', row2: 'Yes, via app' },
            { row1: 'Dimensions:', row2: '5.5 x 2.8 x 2.2 inches' },
            { row1: 'Weight:', row2: '3 lbs' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'BHMA Grade 2, UL Certified' },
        ],
        info: [
            {
                header: 'Enhanced Security and Convenience',
                text: 'The Weiser SmartCode 10 Traditional with Z-Wave Door Lock provides enhanced security and convenience for your home. With advanced features and smart technology, this door lock ensures peace of mind and ease of use.',
                section: [
                    { title: "Smart Control" },
                    { text: "Control your door lock remotely with your smartphone or Z-Wave controller. The SmartCode 10 offers seamless integration with smart home systems, providing remote access and control." },
                    
                    { title: "Z-Wave Technology" },
                    { text: "The built-in Z-Wave technology allows for integration with other Z-Wave devices in your smart home network. This enhances the functionality and automation of your door lock." },
                    
                    { title: "Keyless Entry" },
                    { text: "Enjoy the convenience of keyless entry with the backlit keypad. Create and manage multiple user codes for family members, guests, and service providers." },
                    
                    { title: "Auto-Lock Feature" },
                    { text: "The SmartCode 10 features an auto-lock function that automatically locks the door after a set period, ensuring your home is always secure." },
                    
                    { title: "Easy Installation" },
                    { text: "Designed for easy installation, the SmartCode 10 fits most standard doors and comes with detailed instructions. No hardwiring is required, and the lock is powered by 4 AA batteries." },
                    
                    { title: "Durable Design" },
                    { text: "Built to withstand the rigors of daily use, the SmartCode 10 features a durable construction that ensures long-lasting performance and reliability." },
                    
                    { title: "Smartphone Alerts" },
                    { text: "Receive real-time alerts on your smartphone for lock status, tamper alerts, and more. Stay informed and in control no matter where you are." },
                    
                    { title: "Voice Control" },
                    { text: "Compatible with popular voice assistants like Amazon Alexa and Google Assistant, allowing you to control your door lock with simple voice commands." },
                    
                    { title: "Customizable Settings" },
                    { text: "Customize the lock's settings to suit your specific needs. Set user codes, adjust auto-lock time, and manage notifications through the dedicated app." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The SmartCode 10 door lock meets industry standards and certifications for security and performance, including BHMA Grade 2 and UL certification." },
                ]
            },

            {
                header: 'Modern Security Features for Your Home',
                text: 'The Weiser SmartCode 10 Traditional with Z-Wave Door Lock offers modern security features to keep your home safe and secure. From keyless entry to real-time alerts, this door lock is designed to provide comprehensive protection.',
                section: [
                    { title: "Keyless Convenience" },
                    { text: "Eliminate the need for keys with the SmartCode 10's keyless entry feature. The backlit keypad allows you to enter your home easily, even in low-light conditions." },
                    
                    { title: "User Code Management" },
                    { text: "Create and manage up to 30 user codes for family members, guests, and service providers. Easily add or delete codes as needed for enhanced security and convenience." },
                    
                    { title: "Tamper Detection" },
                    { text: "The lock features tamper detection and alerts, notifying you if there is any unauthorized access or tampering with the lock." },
                    
                    { title: "Long Battery Life" },
                    { text: "The SmartCode 10 is powered by 4 AA batteries, providing long-lasting performance. Receive low battery alerts to ensure you never run out of power." },
                    
                    { title: "Sleek and Durable Design" },
                    { text: "Available in Satin Nickel and Venetian Bronze finishes, the SmartCode 10 complements any door decor. Its durable construction ensures reliable performance and longevity." },
                    
                    { title: "Smart Home Integration" },
                    { text: "Integrate the lock with your existing smart home system for enhanced automation and control. The Z-Wave technology allows for seamless integration with other smart devices." },
                ]
            },

            {
                header: 'Optimal Placement and Installation',
                text: 'Proper placement and installation of the Weiser SmartCode 10 Traditional with Z-Wave Door Lock are crucial for optimal performance. Follow these guidelines for best results:',
                section: [
                    { title: "Standard Door Compatibility" },
                    { text: "Ensure that the door lock is compatible with your door. The SmartCode 10 fits most standard doors with a thickness of 1-3/8 to 1-3/4 inches." },
                    
                    { title: "Easy Installation" },
                    { text: "The lock is designed for easy DIY installation. Follow the included instructions and use the provided hardware for a secure and stable installation." },
                    
                    { title: "Correct Alignment" },
                    { text: "Ensure that the lock and latch are properly aligned to prevent any issues with locking or unlocking. Test the lock several times to ensure smooth operation." },
                    
                    { title: "Battery Placement" },
                    { text: "Install the 4 AA batteries in the battery compartment located on the interior side of the lock. Ensure that the batteries are securely in place and the cover is properly closed." },
                    
                    { title: "Regular Maintenance" },
                    { text: "Perform regular maintenance checks on the lock. Replace batteries as needed, clean the keypad, and ensure that all components are functioning correctly." },
                    
                    { title: "Professional Assistance" },
                    { text: "If you encounter any issues during installation or operation, consider seeking professional assistance. This ensures that the lock is installed correctly and provides optimal security." },
                ]
            },
        ]
    }
    return device;
}
export default SmartCode10ZW;
