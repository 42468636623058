import ohc1 from './img/ohc1.jpg';
import ohc2 from './img/ohc2.jpg';
import ohc3 from './img/ohc3.jpg';
import Cr123aImage from '../../../Batteries/Cr123a.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXCTOHC = () => {
    const sensor = {
        title: 'Commercial Overhead Door Sensor',
        model: 'PROSIXCTOHC',
        sku: ['PROSIXCT'],
        group: 'sensor',
        modelViewImage: ohc3,
        image: [
            ohc3,
            ohc1,
            ohc2,
            Cr123aImage,
        ],
        shortDescription: 'Secures large commercial overhead doors in warehouses.',
        price: getPrice('PROSIXCTOHC'),
        checkListKeyword: ['Burglar'],
        infoHeader: "Commercial Overhead Door Sensor",
        spec: [
            { row1: 'Model:', row2: 'PROSIXCTOHC' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wired + Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Surface mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },
            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Product Length:', row2: '3.1 in, 78.7 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Product Height:', row2: '1.4 in, 27.9 mm' },
        ],
        info: [
            {
                header: 'How does it work?',
                text: 'The PROSIXCTOHC is designed to secure large overhead doors in commercial settings such as warehouses. It utilizes a robust aluminum contact sensor designed specifically for heavy-duty doors, with the PROSIXCT functioning as a wireless transmitter. Here’s how it operates:',
                section: [
                    { title: "Components" },
                    { text: "The system features a large aluminum wired contact sensor, installed on the commercial overhead door, and the PROSIXCT, which serves as a wireless transmitter, relaying door status to your security system." },

                    { title: "Magnetic Field & Contact" },
                    { text: "When the commercial overhead door is closed, the aluminum contact sensor detects its position, and the PROSIXCT transmits this status wirelessly to the security system as a 'closed' state." },

                    { title: "Opening Detection" },
                    { text: "Upon opening, the sensor detects the change, and the PROSIXCT instantly sends this information to the control panel, signaling an opening event." },

                    { title: "Wireless Transmission" },
                    { text: "The PROSIXCT acts as an intermediary, ensuring that the large, heavy-duty contact sensor's status is communicated reliably to the security system, even in expansive or industrial environments." },

                    { title: "Alert or Alarm" },
                    { text: "Depending on your system settings, the control panel can trigger an alarm, notify Securepol's CMS, or send alerts to your mobile device." },

                    { title: "Battery-Powered Operation" },
                    { text: "Powered by a long-lasting battery, the PROSIXCT ensures continuous operation, with regular checks recommended to maintain top performance." },
                ],
            },
        ],
    };
    return sensor;
};
export default PROSIXCTOHC;
