import React from 'react';
import css from './FeaturesChecklist.module.css';
import GraphicCheckBoxWithLabel from '../../MainView/GraphicCheckBoxWithLabel/GraphicCheckBoxWithLabel';

const FeaturesChecklist=({projectCheckList,basket})=>{
    return(
        <div className={css.productFeaturesBox}>
            <div className={css.productFeatures}>
                {projectCheckList.map((e,i)=>{
                    return(
                        <div key={i} className={css.featuresListBox}>
                            <div className={css.featuresListTitle}>
                                <h3>{e.title}</h3>
                            </div>
                            <div className={css.featuresListElements}>
                                {e.list.map((feature, i)=>{
                                    let keywordInBasket = false
                                    basket.map((e)=>{
                                        
                                        if(e.checkListKeyword){
                                            if(e.checkListKeyword.some(keyword=>keyword === feature.name)){
                                                keywordInBasket= true
                                            }
                                            
                                        }
                                        
                                    })
                                    return(
                                        <GraphicCheckBoxWithLabel title={feature.name} key={i} checked={keywordInBasket}/>
                                    )
                                    
                                    
                            })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FeaturesChecklist;