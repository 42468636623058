import React from 'react';
import css from './ListHeader.module.css';

const ListHeader = ()=>{
    return(
        <thead>
            <tr className={css.detailHeader}>
                <th className={css.detailHeaderNum}>#</th>
                <th className={css.detailHeaderLocation} colSpan={3}>Location</th>
                <th className={css.detailHeaderModel} colSpan={2}>Model</th>
            </tr>
        </thead>
    )
}
export default ListHeader;