import React from 'react';
import css from "./TextAreaWithLabel.module.css"
const TextAreaWithLabel =(props)=>{
    return(
        <div className={css.box}>
            <label htmlFor={props.id}>{props.label}{props.required && (<span>*</span>)}</label>
            <textarea {...props}></textarea>
        </div>
    )
}
export default TextAreaWithLabel;