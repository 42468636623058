import React from 'react';
import css from "./ListBody.module.css";
import { RiArrowDownSFill } from "react-icons/ri";

const ListBody =({basket, updateBasketItemsDetails, setModifiedModel, value, setComponentIndex, setTypeIndex, labelEnding,fixedLocation, setView})=>{
    //basket that comes here includs only items of scpecidied category of items used here
    //each item displays gets its own index numeber from the loop
    //setModifiedModel sends to ModelView index number of item that was pressed and its category like 'door sensor'
    const handleClick=(i,c)=>{
        setTypeIndex();
        setComponentIndex();
        setView('model');
        setModifiedModel({index:i,cat:c})
    }
    return(
        <tbody>
            {Array.from({ length: value }, (_, index) => (
                basket[index]&& (
                    <tr className={css.ds} key={index} value={index + 1}>
                    <td className={css.itemNum}>{index + 1}</td>
                    <td className={css.itemLocation} colSpan={3}>
                    
                    <input
                        className={css.locationInput}
                        readOnly={fixedLocation && fixedLocation !== undefined?true:false}
                        type='text'
                        placeholder='Edit'
                        onChange={(e) => {
                            const updatedBasket = [...basket]; // Clone the basket array
                            updatedBasket[index] = { ...updatedBasket[index], loc: e.target.value }; // Update loc property of the cloned object
                            updateBasketItemsDetails(updatedBasket); // Update the basket with the modified object
                        }}
                        
                        value={basket[index].loc || ''}
                    />
                    </td>
                    
                    <td onClick={()=>handleClick(index, basket[index].cat)} className={css.itemModel} >{basket[index].model}</td>
                    <td onClick={()=>handleClick(index, basket[index].cat)} className={css.itemOpen} ><RiArrowDownSFill /></td>
                </tr>
                )
                
            ))}
        </tbody>
    )
}
export default ListBody;