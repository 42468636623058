import { generateClient } from "aws-amplify/api";
import {createBasket} from '../../graphql/mutations'
const client = generateClient();

const UploadBasket = async ({ basket, valet }) => {
    let result = null;  // Initialize result to ensure it's accessible throughout the function

    const box={
        valet:valet,
        basket: basket
    }
    try {
        result = await client.graphql({
            query: createBasket,
            variables: { input: { items: JSON.stringify(box) } }
        });
        //console.log('Saved!');
    } catch (err) {
        console.error('Error storing basket:', err);
        return null;  // Return null or handle error as appropriate
    }

    if (result && result.data && result.data.createBasket) {
        return result.data.createBasket.id;
    } else {
        return null;  // Handle the case where result might not be structured as expected
    }
}
export default UploadBasket;