import SvgGrayWaveWithIcons from "../assets/svg/backgrounds/svgGrayWaveWithIcons";
import SvgProPanel from "../assets/svg/svgImages/svgProPanel";
import BlackFriday50off from "../assets/svg/sale/BlackFriday50off";
import camptz  from "../assets/images/web/camptz.png";
import doorbell  from "../assets/images/web/doorbell.png";
import thermostat  from "../assets/images/web/thermostat.png";
import lock  from "../assets/images/web/lock.png";
import garageOpener  from "../assets/images/web/garageOpener.jpg";
import garageWatch  from "../assets/images/web/garageWatch.png";
import switchesBackground  from "../assets/images/web/switchesBackground.jpg";
import Switch from "../assets/images/web/switch.png";
import Station from "../assets/images/web/securepolStationC.png";

export default function HomePageData(){
    const data={     
        cards:[
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Alarm Monitoring from<br/>$14.99 a month.`,
                    content: "Already have an alarm?<br/>Get 24/7 monitoring with no contract<br/>and free gurad service",
                    links: [{label:"Learn more", to:"/transfer"}],
                    svgBackground: SvgGrayWaveWithIcons(),
                    svgImage:'',
                },
            ],
            [
                {
                    settings: {bordered:false, darkBackground:false, height: ''},
                    content: '</br></br>',
                    svgBackground: BlackFriday50off(),
                }
            ],
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Alarm Systems`,
                    content: "Custom Security Packages.<br/>$0 Down, free installation.<br/>No Long-Term Contracts.",
                    links: [{label:"Build Your Package", to:"/packages"}],
                    //svgBackground: SvgGrayFullTopWhiteStripe(),
                    svgImage: SvgProPanel(),
                }
            ],
            [
                {
                    settings: { bordered:false, darkBackground:false, },
                    header: `AI-Powered Surveillance`,
                    content: "Person Detection, video-audio recording, no cloud fee",
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Doorbell Camera`,
                    content: "AI-Powerd Person Detection Notification.<br/>Head-to-Toe Visibility from 2K Camera<br/>Ultra Fast Connectivity to Smartphone",
                    links: [/*{label:"Learn more", to:"/packages"},*/{label:"Packages", to:"/packages?d=doorbell"}],
                    image: [{src: doorbell, alt:"Doorbell camera"}]
                },
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Security Cameras`,
                    content: "360 Panoramic View, Follow Person Mode</br>Instant Person Detection Alerts<br/>Audio & Video Recording",
                    links: [/*{label:"Learn more", to:"/packages"},*/{label:"Packages", to:"/packages?d=camera"}],
                    image: [{src: camptz, alt:"Outdoor ptz camera"}]
                },
                
            ],
            [
                {
                    settings: {bordered:false, darkBackground:false,},
                    header: `Smarten Up Your Place`,
                    content: "Transform your space into a haven of comfort,<br/> security, and convenience with integrated smart system.",
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Climate Control`,
                    content: "Smart Comfort, Smarter Savings.<br/>Remotely schedule you thermostat<br/>or let it auto-adjust with your alarm<br/> when you are away.",
                    links: [{label:"Package", to:"/packages?d=thermostat"}],
                    image: [{src: thermostat, alt:"Smart thermostat"}]
                },
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Smart Lock`,
                    content: "Keyless Convenience, Enhanced Security.<br/>Remotely open your door for guests<br/>and disarm your alarm automatically upon entry",
                    links: [/*{label:"Learn more", to:"/packages"},*/{label:"Package", to:"/packages?d=lock"}],
                    image: [{src: lock, alt:"Smart door lock"}]
                }
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Garage Opener`,
                    content: "Convenience in a click!<br/>Get instant alerts for an open garage.<br/>and close it remotely.",
                    links: [/*{label:"Learn more", to:"/packages"},*/{label:"Package", to:"packages?d=garageopener"}],
                    imgBackground: garageOpener,
                    image: [{src: garageWatch, alt:"garage opener"}]
                },
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Switch/Dimmer`,
                    content: "Light Up Your Home, Anytime, Anywhere.<br/> Control remotely, customize settings,<br/>and schedule lights for a lived-in look,<br/>even when you're away!",
                    links: [{label:"Package", to:"/packages?d=light"}],
                    imgBackground: switchesBackground,
                    image: [{src: Switch, alt:"Z-wave light switch"}]
                }
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Introducing AIECMS®<br/>AI-Enhanced Central Monitoring Station<br/>`,
                    content: "Experience the future of home and business security with AI-enhanced security alarm monitoring services.<br/><br/>Predictive Threat Analysis using trainded AI-models,<br/>where the system dynamically adjusts security protocols<br/>based on live data and threat levels.<br/><br/>Automated Emergency Services Coordination,<br/>ensuring rapid response time by integrating with local emergency services.<br/><br/>",
                    links: [{label:"Learn more", to:"/aiecms"}],
                    imgBackground:  Station,
                }
            ]
        ],
    }
    return data;
}
