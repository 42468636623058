import React from 'react';
import css from './CloseButton.module.css';
import { RiArrowGoBackLine } from "react-icons/ri";

const CloseButton=({setView})=>{
    return(
        <div className={css.closeButton} onClick={setView}>
            <RiArrowGoBackLine/>
        </div>
    )
}
export default CloseButton;