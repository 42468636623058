import o1 from './PROSIXCOMBOimg/o1.jpg';
import o2 from './PROSIXCOMBOimg/o2.jpg';
import o3 from './PROSIXCOMBOimg/o3.jpg';
import o4 from './PROSIXCOMBOimg/o4.jpg';
import o5 from './PROSIXCOMBOimg/o5.jpg';
import bat4 from '../../../Batteries/cr123ax4.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXCOMBO = () => {
    const sensor = {
        title: 'Smoke and Carbon Monoxide Detector',
        model: 'PROSIXCOMBO',
        sku: ['PROSIXCOMBO'],
        modelViewImage: o1,
        image: [o1,o5,o2,o3,o4,bat4],
        shortDescription: 'Detects both smoke and carbon monoxide gas in the air.',
        price: getPrice('PROSIXCOMBO'),
        group: 'sensor',
        checkListKeyword: ['Fire', 'CO'],
        infoHeader: "Smoke and Carbon Monoxide Detector",
        spec: [
            { row1: 'Model:', row2: 'PROSIXCOMBO' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Ceiling mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },
            { row1: 'Number of Batteries:', row2: '4' },
            { row1: 'Sound Level:', row2: '85 dBA' },
            { row1: 'Smoke Detection Principle:', row2: 'Photoelectric' },
            { row1: 'CO Detection Principle:', row2: 'Electrochemical' },
            { row1: 'Alarm Indicator Light:', row2: 'Red' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Overall Diameter:', row2: '4.7 in, 119.4 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed' },
        ],
        info: [
            {
                header: 'PROSIXCOMBO: Comprehensive Protection for Your Safety',
                text: 'The PROSIXCOMBO detector combines the functions of smoke and carbon monoxide detection in a single device, providing comprehensive protection against two major threats to safety: fire and carbon monoxide poisoning.',
                section: [
                    { title: "Dual Sensing Technology" },
                    { text: "The PROSIXCOMBO utilizes both photoelectric and electrochemical sensing technologies to detect smoke and carbon monoxide, ensuring early warning and accurate detection of both threats." },
                    
                    { title: "Reliable Performance" },
                    { text: "Engineered for reliability, the PROSIXCOMBO delivers consistent and accurate detection without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The PROSIXCOMBO is a wireless detector that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROSIXCOMBO provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive instant alerts and notifications on your mobile device or control panel in the event of smoke or carbon monoxide detection. Stay informed and take prompt action to mitigate risks and ensure the safety of occupants and assets." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXCOMBO operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXCOMBO detector meets industry standards and certifications for safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive safety plan for residential and commercial properties." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXCOMBO detector operates using a combination of photoelectric and electrochemical sensing technologies to provide early warning of both smoke and carbon monoxide.',
                section: [
                    { title: "Smoke Sensing" },
                    { text: "The PROSIXCOMBO is equipped with a highly sensitive photoelectric smoke sensor that detects the presence of smoke particles in the air. When smoke is present, it enters the detector's chamber, triggering the sensing mechanism." },
                    
                    { title: "Carbon Monoxide Sensing" },
                    { text: "The detector also features an electrochemical sensor that detects the presence of carbon monoxide gas. When carbon monoxide is present, it triggers the sensing mechanism." },
                    
                    { title: "Detection Algorithms" },
                    { text: "The built-in detection algorithms analyze the characteristics of smoke particles and carbon monoxide gas, such as density and composition, to differentiate between harmless environmental factors and potentially hazardous conditions." },
                    
                    { title: "Alarm Activation" },
                    { text: "If the smoke or carbon monoxide levels are determined to be hazardous, the PROSIXCOMBO detector activates its alarm system. The alarm includes audible alerts, such as sirens or beeps, and visual indicators, such as a flashing LED light, to alert occupants of the potential danger." },
                    
                    { title: "Wireless Communication" },
                    { text: "The PROSIXCOMBO is a wireless detector, meaning it communicates wirelessly with a compatible security system or control panel. When the alarm is triggered, the detector sends an immediate signal to the control panel, which then initiates appropriate response actions." },
                    
                    { title: "Notification and Response" },
                    { text: "Upon receiving the alarm signal, the control panel notifies the designated individuals or monitoring service about the smoke or carbon monoxide detection event. This notification can be sent via mobile app notifications, text messages, email alerts, depending on the configured settings." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of combined smoke and carbon monoxide detectors is crucial to their effectiveness in detecting harmful conditions early and providing timely alerts. Here are some key guidelines for where to place these detectors in your home or property:',
                section: [
                    { title: "Bedrooms" },
                    { text: "Install combined detectors inside each bedroom or in hallways adjacent to sleeping areas. Early detection in these areas ensures occupants are alerted while they sleep." },
                    
                    { title: "Living Areas" },
                    { text: "Place combined detectors in living rooms, family rooms, and other common areas where people spend significant time. This ensures comprehensive coverage and early detection in areas where smoke or carbon monoxide may accumulate." },
                    
                    { title: "Basements" },
                    { text: "Install combined detectors in basements and furnace rooms, as these areas often house fuel-burning appliances that can produce smoke or carbon monoxide." },
                    
                    { title: "Garages" },
                    { text: "If your garage is attached to your home, install a combined detector in the garage to detect any smoke or gas that may enter the home from vehicles or other sources." },
                    
                    { title: "Near Appliances" },
                    { text: "Place combined detectors near fuel-burning appliances, such as gas stoves, water heaters, and fireplaces, to detect any leaks or malfunctions that may produce smoke or carbon monoxide." },
                    
                    { title: "Ceiling or Wall Mounting" },
                    { text: "Mount combined detectors on the ceiling or high on walls for optimal coverage. Ensure they are at least 5 feet above the floor to prevent airflow obstruction." },
                ]
            },

            {
                header: 'Common causes of false alarms',
                text: 'False alarms from combined smoke and carbon monoxide detectors can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms:',
                section: [
                    { title: "Cooking Smoke" },
                    { text: "Smoke detectors located near kitchens or cooking areas may trigger false alarms due to smoke generated from cooking activities, especially when using high-heat cooking methods or when food is burned." },
                    
                    { title: "Steam and Humidity" },
                    { text: "High humidity levels, steam from showers or baths, or steam-producing appliances (e.g., kettles, humidifiers) can trigger false alarms in smoke detectors located in bathrooms or humid environments." },
                    
                    { title: "Dust and Debris" },
                    { text: "Accumulated dust, lint, or airborne debris can enter detectors and interfere with their sensors, leading to false alarms. This is common in areas with high dust levels or during home renovations." },
                    
                    { title: "Insect Intrusion" },
                    { text: "Insects, particularly tiny insects like dust mites or gnats, can enter detectors and trigger false alarms by obstructing sensors or causing malfunctions." },
                    
                    { title: "Chemical Fumes" },
                    { text: "Strong chemical odors or fumes from cleaning agents, paints, varnishes, or aerosol sprays can sometimes trigger detectors, especially if the detectors are sensitive to volatile organic compounds (VOCs)." },
                    
                    { title: "Malfunctioning Detectors" },
                    { text: "Over time, detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect smoke or carbon monoxide when needed." },
                    
                    { title: "Extreme Temperatures" },
                    { text: "Detectors located in areas with extreme temperatures (either too hot or too cold) may experience false alarms due to temperature fluctuations affecting sensor sensitivity." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXCOMBO;
