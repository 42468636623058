import css from './Initial.module.css';
import order from './image/readytosubmit.jpeg'
const Initial=({step, setStep, nextStep, prevStep})=>{
    return(
        <div className={css.procceedBox}>
            <div className={css.messageBox}>
                <h2>Ready to Place Your Order?</h2>
                <img className={css.orderImage} src={order} alt="order ilustration"/>
            </div>
            <div className={css.buttonBox}>
                <button className={css.button}onClick={nextStep}>Start</button>
            </div>
        </div>
    )
}
export default Initial;