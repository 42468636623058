import ex from './img/ex1.jpg'

const Existing=()=>{
    const sensor = {
        title: 'Existing Wired Flood Detector',
        model:'Existing Wired Sensor',
        sku:[],
        group:'wired',
        modelViewImage:ex,
        image:[ex],
        shortDescription:"Select this option to integrate an existing wired sensor from a previous alarm system with the ProSeries Touchscreen panel. An 8-zone Wired-to-Wireless conversion module will be added for every 8 zones.",
        price: 0,
        checkListKeyword:['Flood'],
        infoHeader:"Integration of Existing Wired Sensor from Previous Alarm System",
        spec:[
            
        ],
        info:[{
            header: 'How does it work?',
            text: 'When you select this option, the PROSIXC2W module will be used to connect your existing wired sensors to the new ProSeries Touchscreen panel. The PROSIXC2W is an 8-zone Wired-to-Wireless converter, which means it will allow up to eight of your existing wired sensors to communicate wirelessly with the ProSeries panel. This makes it possible to upgrade your alarm system without needing to replace all your wired sensors. For every set of eight wired sensors you have, an additional PROSIXC2W module will be added to your package to ensure full compatibility. It\'s important to note that this process does not involve installing new sensors; rather, it allows us to reuse the wired sensors you already have in place. By connecting the PROSIXC2W module, your current sensors will continue to function seamlessly with the upgraded ProSeries panel, providing you with a modernized system without the need for extensive rewiring or sensor replacement.',
            section: [
                { title: "" },
                { text: "" },]
        }
        ]
    }
    return sensor
}
export default Existing;