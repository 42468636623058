
import React from 'react';
import KitProPlus7 from './ProjectsData/alarms/kitProPlus7'
import proPanelImage from '../../assets/images/web/proPanel.png'

const Data=()=>
{
    const projects=
    [
        //First project Prseries alarm system
        {
            isDefault: true,
            name: "Alarm System Installation",
            deviceName:'Proseries 7" Plus Alarm Panel',
            deviceImage: proPanelImage,
            selectedComponentsSectionTitle: "Your Package Includes",
            //saleOptions: sale0to5,
            checker: KitProPlus7().checker,
            displayOrder: KitProPlus7().displayOrder,
            checkList: KitProPlus7().checkList,
            components: KitProPlus7().components,//get all proseries components
            garbage: KitProPlus7().garbage,
            resetBasket: KitProPlus7().resetBasket,
           // t:KitProPlus7().t,
            defaultPackage: KitProPlus7().defaultPackage,
        },
    ]
    return projects;
}
export default Data;