
import PROSIXCTImage from './PROSIXCTimg/PROSIXCT.jpg'
import ProsixctDoor1Image from './PROSIXCTimg/ProsixctDoor1.jpg'
import ProsixctDoor2Image from './PROSIXCTimg/ProsixctDoor2.jpg'
import ProsixctDoor3Image from './PROSIXCTimg/ProsixctDoor3.jpg'
import ProsixctSideImage from './PROSIXCTimg/ProsixctSide.jpg'
import ProsixctOpenImage from './PROSIXCTimg/ProsixctOpen.jpg'
import Prosixctwindow1Image from './PROSIXCTimg/Prosixctwindow1.jpg'
import Prosixctwindow2Image from './PROSIXCTimg/Prosixctwindow2.jpg'
import Proxict21Image from './PROSIXCTimg/Proxict21.jpg'
import Cr123aImage from '../../../Batteries/Cr123a.jpg'
import { getPrice } from '../../../../../../Prices';
const PROSIXCT =()=>{
    const sensor = {
        title: 'Door/Window Sensor',
        model:'PROSIXCT',
        sku:['PROSIXCT'],
        group:'sensor',
        modelViewImage:PROSIXCTImage,
        image:[PROSIXCTImage, ProsixctSideImage,ProsixctDoor1Image,Proxict21Image, ProsixctDoor2Image,ProsixctDoor3Image, Prosixctwindow1Image,Prosixctwindow2Image, ProsixctOpenImage, Cr123aImage,],
        shortDescription:'Detects the opening and closing of a door/window.',
        price: getPrice('PROSIXCT'),
        checkListKeyword:['Burglar'],
        infoHeader:"Door/Window Sensor",
        spec:[
            {row1:'Model:',row2:'PROSIXCT'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Wireless Range:',row2:'300+ Feet (Open Air) from main controler'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'1'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Product Length:',row2:'3.1 in, 78.7 mm'},
            {row1:'Product Width:',row2:'1.6 in, 40.6 mm'},
            {row1:'Product Height:',row2:'1.4 in, 27.9 mm'},
        ],
        info:[
            {
                header:'How does it work?',
                text:'The PROSIXCT wireless door and window contact sensor work based on magnetic principles. Here\'s a breakdown of how it typically operates:',
                section: [
                    {title: "Components"},
                    {text: "The PROSIXCT sensor consists of two main components: a magnet and a sensor. The magnet is typically installed on the moving part of the door or window (such as the door frame or window frame), while the sensor is installed on the stationary part (such as the door or window itself)."},

                    {title: "Magnetic Field"},
                    {text: "When the door or window is closed, the magnet and sensor are in close proximity, and their magnetic fields align. This alignment indicates that the door or window is securely closed, and the sensor detects this state as a \"closed\" position."},

                    {title: "Opening Detection"},
                    {text: "When the door or window is opened, the magnet moves away from the sensor, disrupting the magnetic field alignment. This change in magnetic field is detected by the sensor, which then interprets it as the door or window being opened."},

                    {title: "Wireless Transmission"},
                    {text: "Upon detecting the opening of the door or window, the PROSIXCT sensor wirelessly transmits a signal to the security system's control panel. This signal is interpreted by the system as a breach or unauthorized entry event."},
        
                    {title: "Alert or Alarm"},
                    {text: " Based on the programming and settings of the security system, the control panel can trigger various responses, such as sounding an alarm, sending notifications to a Securepol's CMS or mobile device."},
        
                    {title: "Battery-Powered Operation"},
                    {text: "The PROSIXCT sensor is typically powered by batteries, ensuring that it remains operational even during power outages. The long battery life of these sensors reduces the need for frequent battery replacements."},
                ]
            },
            {
                header:'Where to place it?',
                text:'The installation location of the PROSIXCT wireless door and window contact sensor is crucial for its effective operation and optimal security coverage. Here are some guidelines on where to install it:',
                section: [
                    {title: "Doors"},
                    {text: "Install the sensor on the door frame (the stationary part) and the magnet on the door itself (the moving part). Position them close together when the door is closed, ensuring that they align properly to detect openings and closings accurately. For added security, consider installing multiple sensors on different doors, especially for exterior entry points."},
                    
                    {title: "Windows"},
                    {text: "Similarly, for windows, mount the sensor on the window frame and the magnet on the window itself. Align them closely when the window is closed to detect any unauthorized openings. Ensure that the sensor is positioned securely and does not obstruct the window's operation."},
                    
                    {title: "Entry Points"},
                    {text: " Focus on installing the PROSIXCT sensors on all accessible entry points, including exterior doors, ground-level windows, sliding doors, and any other potential points of entry for intruders. Secure all vulnerable areas to maximize security coverage."},

                    {title: "Height and Positioning:"},
                    {text: "Place the sensors and magnets at a height where they are not easily accessible or visible to intruders. This helps prevent tampering or bypassing of the sensors. Additionally, ensure that the alignment is maintained when doors and windows are opened or closed."},
        
                    {title: "Avoid Interference"},
                    {text: "Install the sensors away from sources of interference that could affect their wireless communication with the security system's control panel or hub. Keep them clear of metal objects, electronic devices, or large appliances that may disrupt signals."},
        
                    {title: "Avoid Vibrations"},
                    {text: "Ensure that the sensor is mounted securely and stable to avoid false alarms triggered by vibrations or movements of the mounting surface."},
                ]
            },
            {
                header:'When it may not detect?',
                text:'The PROSIXCT wireless door and window contact sensor, like any sensor, may have limitations in certain scenarios. Here are some situations where the sensor may not detect properly:',
                section: [
                    {title: "Doors with large glass inserts:"},
                    {text: "If a door has a large glass insert or window that an intruder could break and enter through without opening the door itself, the contact sensor may not detect this type of unauthorized entry. In such cases, additional security measures such as glass break, shock detectors or motion sensors in the vicinity may be necessary to provide comprehensive security coverage."},
                    
                    {title: "Lower Parts of Windows"},
                    {text: "Placing the sensor on the lower parts of windows, especially in regions where ice can form during winter, may lead to potential issues. If the sensor or its components become wet or frozen, it could impact the sensor's functionality and reliability. To mitigate this, consider installing the sensor at higher positions on windows or using protective covers or weatherproofing solutions to shield the sensor from moisture and extreme weather conditions."},
                    
                    {title: "Interference or Obstructions"},
                    {text: "Sensors placed near sources of interference, such as electronic devices, metal objects, or other wireless equipment, may experience signal interference that could affect their performance."},

                    {title: "Tampering or Bypassing"},
                    {text: "Intruders with knowledge of security systems may attempt to tamper with or bypass contact sensors by manipulating the magnets or sensor components. To prevent tampering, install the sensors securely and discreetly."},
        
                    {title: "Battery Life and Maintenance"},
                    {text: "Regularly check and replace batteries in the sensors to ensure uninterrupted operation. Low battery levels can impact the sensor's performance and reliability, leading to missed detections or false alarms."},
                ]
            },
           
        ]
    }
    return sensor
}
export default PROSIXCT;