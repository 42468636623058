import c1 from './PROSIXCOimg/c1.jpg';
import c2 from './PROSIXCOimg/c2.jpg';
import c3 from './PROSIXCOimg/c3.jpg';
import c4 from './PROSIXCOimg/c4.jpg';
import c5 from './PROSIXCOimg/c5.jpg';
import bat2 from '../../../Batteries/bat2.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXCO = () => {
    const sensor = {
        title: 'Carbon Monoxide Detector',
        model: 'PROSIXCO',
        sku: ['PROSIXCO'],
        modelViewImage: c2,
        image: [c2, c1, c4, c3, c5, bat2],
        shortDescription: 'Detects the presence of carbon monoxide gas in the air.',
        price: getPrice('PROSIXCO'),
        group: 'sensor',
        checkListKeyword: ['CO'],
        infoHeader: "Carbon Monoxide Detector",
        spec: [
            { row1: 'Model:', row2: 'PROSIXCO' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Celling/Wall mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },
            { row1: 'Number of Batteries:', row2: '2' },
            { row1: 'Sound Level:', row2: '85 dBA' },
            { row1: 'Detection Principle:', row2: 'Electrochemical' },
            { row1: 'Alarm Indicator Light:', row2: 'Red' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Overall Diameter:', row2: '4.7 in, 119.4 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed' },
        ],
        info: [
            {
                header: 'Carbon Monoxide Detector: Essential for Safety',
                text: 'The PROSIXCO carbon monoxide detector is a crucial device for ensuring the safety of occupants by detecting the presence of carbon monoxide gas, which is colorless, odorless, and potentially lethal.',
                section: [
                    { title: "Early Detection" },
                    { text: "The PROSIXCO utilizes advanced electrochemical sensing technology to detect the presence of carbon monoxide gas in the air, providing early warning to prevent carbon monoxide poisoning." },
                    
                    { title: "Reliable Performance" },
                    { text: "Engineered for reliability, the PROSIXCO delivers consistent and accurate detection without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The PROSIXCO is a wireless carbon monoxide detector that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROSIXCO provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive instant alerts and notifications on your mobile device or control panel in the event of carbon monoxide detection. Stay informed and take prompt action to mitigate risks and ensure the safety of occupants and assets." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXCO operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXCO carbon monoxide detector meets industry standards and certifications for safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive safety plan for residential and commercial properties." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXCO carbon monoxide detector operates based on electrochemical sensing technology, providing early warning of dangerous carbon monoxide levels.',
                section: [
                    { title: "Carbon Monoxide Sensing" },
                    { text: "The PROSIXCO is equipped with an electrochemical sensor that detects the presence of carbon monoxide gas. When carbon monoxide is present, it triggers the sensing mechanism." },
                    
                    { title: "Detection Algorithm" },
                    { text: "Upon detecting carbon monoxide, the detector's built-in detection algorithm analyzes the gas concentration. This analysis helps determine whether the levels are hazardous and require an alarm." },
                    
                    { title: "Alarm Activation" },
                    { text: "If the carbon monoxide levels are determined to be dangerous, the PROSIXCO carbon monoxide detector activates its alarm system. The alarm includes audible alerts, such as sirens or beeps, and visual indicators, such as a flashing LED light, to alert occupants of the potential danger." },
                    
                    { title: "Wireless Communication" },
                    { text: "The PROSIXCO is a wireless carbon monoxide detector, meaning it communicates wirelessly with a compatible security system or control panel. When the alarm is triggered, the detector sends an immediate signal to the control panel, which then initiates appropriate response actions." },
                    
                    { title: "Notification and Response" },
                    { text: "Upon receiving the alarm signal, the control panel notifies the designated individuals or monitoring service about the carbon monoxide detection event. This notification can be sent via mobile app notifications, text messages, email alerts, depending on the configured settings." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of carbon monoxide detectors is crucial to their effectiveness in detecting harmful gas levels early and providing timely alerts. Here are some key guidelines for where to place carbon monoxide detectors in your home or property:',
                section: [
                    { title: "Bedrooms" },
                    { text: "Install carbon monoxide detectors inside each bedroom or in hallways adjacent to sleeping areas. Early detection in these areas ensures occupants are alerted while they sleep." },
                    
                    { title: "Living Areas" },
                    { text: "Place carbon monoxide detectors in living rooms, family rooms, and other common areas where people spend significant time. This ensures comprehensive coverage and early detection in areas where carbon monoxide may accumulate." },
                    
                    { title: "Basements" },
                    { text: "Install carbon monoxide detectors in basements and furnace rooms, as these areas often house fuel-burning appliances that can produce carbon monoxide." },
                    
                    { title: "Garages" },
                    { text: "If your garage is attached to your home, install a carbon monoxide detector in the garage to detect any gas that may enter the home from vehicles or other sources." },
                    
                    { title: "Near Appliances" },
                    { text: "Place carbon monoxide detectors near fuel-burning appliances, such as gas stoves, water heaters, and fireplaces, to detect any leaks or malfunctions that may produce carbon monoxide." },
                    
                    { title: "Ceiling or Wall Mounting" },
                    { text: "Mount carbon monoxide detectors on the ceiling or high on walls for optimal coverage. Ensure they are at least 5 feet above the floor to prevent airflow obstruction." },
                ]
            },

            {
                header: 'Common causes of false alarms',
                text: 'False alarms from carbon monoxide detectors can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms from carbon monoxide detectors:',
                section: [
                    { title: "Low Battery" },
                    { text: "A low battery can cause false alarms. Regularly check and replace batteries to ensure proper operation and avoid unnecessary alerts." },
                    
                    { title: "Malfunctioning Detectors" },
                    { text: "Over time, carbon monoxide detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect carbon monoxide when needed." },
                    
                    { title: "Extreme Temperatures" },
                    { text: "Detectors located in areas with extreme temperatures (either too hot or too cold) may experience false alarms due to temperature fluctuations affecting sensor sensitivity." },
                    
                    { title: "Household Chemicals" },
                    { text: "Strong chemical odors or fumes from cleaning agents, paints, varnishes, or aerosol sprays can sometimes trigger carbon monoxide detectors, especially if the detectors are sensitive to volatile organic compounds (VOCs)." },
                    
                    { title: "Insect Intrusion" },
                    { text: "Insects, particularly tiny insects like dust mites or gnats, can enter carbon monoxide detectors and trigger false alarms by obstructing sensors or causing malfunctions." },
                    
                    { title: "Dust and Debris" },
                    { text: "Accumulated dust, lint, or airborne debris can enter carbon monoxide detectors and interfere with their sensors, leading to false alarms. This is common in areas with high dust levels or during home renovations." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXCO;
