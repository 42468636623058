


import Gb1 from './PROSIXGBimg/Gb1.jpg'
import Gb2 from './PROSIXGBimg/Gb2.jpg'
import Gb3 from './PROSIXGBimg/Gb3.jpg'
import Gb4 from './PROSIXGBimg/Gb4.jpg'
import Gb5 from './PROSIXGBimg/Gb5.jpg'
import Gb6 from './PROSIXGBimg/Gb6.jpg'
import Gb7 from './PROSIXGBimg/Gb7.jpg'
import btr from '../../../Batteries/Cr123a.jpg'
import { getPrice } from '../../../../../../Prices';
const PROSIXGB =()=>{
    const sensor = {
        title: 'Glassbreak Detector',
        model:'PROSIXGB',
        sku:['PROSIXGB'],
        group:'sensor',
        modelViewImage:Gb1,
        image:[Gb1,Gb2,Gb3,Gb4,Gb5,Gb6,Gb7,btr],
        shortDescription:'Provides reliable detection of glass breakage.',
        price: getPrice('PROSIXGB'),
        checkListKeyword:['Burglar'],
        infoHeader:"Glassbreak Detector",
        spec:[
            {row1:'Model:',row2:'PROSIXGB'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Wireless Range:',row2:'300+ Feet (Open Air) from main controler'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'1'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Product Width:',row2:'29 mm, 1.14 in'},
            {row1:'Product Height:',row2:'3.78 in, 96 mm'},
        ],

        info:[
            {
            header: 'How does it work?',
            text: 'The PROSIXGB ProSeries Wireless Glassbreak Detector works using advanced acoustic detection technology to identify the sound of breaking glass. Here\'s a breakdown of how it typically operates:',
            section: [
            {title: "Sound Detection"},
            {text: "The detector is equipped with a sensitive microphone designed to pick up the specific sound frequency associated with breaking glass. It listens continuously for these sounds within its coverage area."},
                {title: "Dual-Stage Detection"},
                {text: "The PROSIXGB utilizes a dual-stage detection process to accurately identify genuine glass break events:  1. It detects the initial high-pitched sound of glass cracking. 2. It then listens for the subsequent low-frequency sound of glass shattering."},

                {title: "Advanced Algorithms"},
                {text: "The device uses advanced algorithms to analyze the detected sounds and differentiate between actual glass breakage and other similar noises that could potentially cause false alarms (e.g., jingling keys, clinking dishes)."},

                {title: "Wireless Transmission"},
                {text: "Once a genuine glass break event is identified, the detector sends a wireless signal to the central control panel of the security system. The control panel then processes the signal and triggers an alarm or alert, notifying the user of the potential security breach."},

                {title: "Tamper Protection"},
                {text: "The detector includes tamper-resistant features to alert the user if someone tries to disable or tamper with the device."},

                {title: "Battery-Powered Operation"},
                {text: "The detector is powered by a long-life battery, ensuring that it remains operational for extended periods without the need for frequent battery replacements."}
                ]
        },
        {
            header: 'Where to place it?',
            text: 'The installation location of the PROSIXGB ProSeries Wireless Glassbreak Detector is crucial for its effective operation and optimal security coverage. Here are some guidelines on where to install it:',
            section: [
                {title: "Optimal Placement"},
                {text: "For maximum effectiveness, the detector should be placed within the room where glass windows or doors are present. It should be mounted on walls or ceilings facing the glass surfaces."},
                        
                {title: "Coverage Area"},
                {text: "The coverage range typically extends up to 25 feet in all directions, making it suitable for monitoring multiple windows or glass doors within the specified range."},

                {title: "Height and Positioning"},
                {text: "Place the detector at a height where it is not easily accessible or visible to intruders. This helps prevent tampering or bypassing of the sensors. Additionally, ensure that the alignment is maintained when doors and windows are opened or closed."},
            
                {title: "Avoid Interference"},
                {text: "Install the detector away from sources of interference that could affect its wireless communication with the security system's control panel or hub. Keep it clear of metal objects, electronic devices, or large appliances that may disrupt signals."},
            
                {title: "Avoid Vibrations"},
                {text: "Ensure that the detector is mounted securely and stable to avoid false alarms triggered by vibrations or movements of the mounting surface."}
            ]
        },
        {
            header: 'When it may not detect?',
            text: 'The PROSIXGB ProSeries Wireless Glassbreak Detector, like any sensor, may have limitations in certain scenarios. Here are some situations where the detector may not detect properly:',
            section: [
                {title: "Insufficient Sound Levels"},
                {text: "If the breaking glass sound is muffled or not loud enough, the detector may not pick up the sound accurately. This can happen if the glass is thick or if there are heavy curtains or other sound-dampening materials around the windows."},

                {title: "Obstructions"},
                {text: "Objects placed between the detector and the glass surface can obstruct sound waves, potentially preventing the detector from accurately sensing the breaking glass."},

                {title: "Extreme Distances"},
                {text: "If the detector is placed too far from the glass surface, it may not detect the sound of breaking glass effectively. Adhering to the specified coverage range is crucial for optimal performance."},

                {title: "False Alarms"},
                {text: "While advanced algorithms help minimize false alarms, certain noises that resemble breaking glass (e.g., dropping metal objects, loud music) might still trigger the detector."},

                {title: "Battery Life and Maintenance"},
                {text: "Regularly check and replace batteries in the detector to ensure uninterrupted operation. Low battery levels can impact the detector's performance and reliability, leading to missed detections or false alarms."}
            ]
        }

           
        ]
    }
    return sensor
}
export default PROSIXGB;