import React, { useState, useEffect } from 'react';
import css from './EditView.module.css';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import PriceDisplay from '../AppCommonComponents/Price/PriceDisplay/PriceDisplay';
import SelectQty from './SelectQty/SelectQty';
import SvgWhiteSquaresBottom from '../../assets/svg/backgrounds/svgWhiteSquaresBottolm';
import SvgBackground from '../../webComponents/Hooks/SvgBackground';

const EditView = ({
  project, monthly, upfront, setValue, checkWithBasket, view, setView, basket, setBasket, setModifiedModel,
  isVisible, setIsVisible, setComponentIndex, setTypeIndex, setModelIndex, discount, resetBasket
}) => {

  const [shake, setShake] = useState(false);


  const handleBasketReset = () => {
    resetBasket();
  
    const element = document.querySelector(`.${css.body}`);
    if (element) {
      // Remove the class if it's already there to restart the animation
      element.classList.remove(css.shake);
  
      // Use requestAnimationFrame to ensure the browser has removed the class before re-adding it
      requestAnimationFrame(() => {
        element.classList.add(css.shake);
      });
    }
  };

 

  return (
    <div className={css.sectionWrapper}>
     
      <div className={css.body} style={SvgBackground(SvgWhiteSquaresBottom())}>
        {project.components.map((component, i) => (
          <div key={'com' + i}>
            <div className={css.category}>
                <div className={css.categoryTitle}><h2>{component.editViewSectionTitle}</h2></div>
                <div className={css.resetBtnBox}> {i===0&&<button className={css.resetBtn} onClick={handleBasketReset}>Reset</button>}</div>
            </div>
            <div className={css.itemsWrapper}>
              {component.type.map((type, j) => (
                <SelectQty
                  key={'type' + j}
                  project={project}
                  checkWithBasket={checkWithBasket}
                  setValue={setValue}
                  basket={basket}
                  setBasket={setBasket}
                  setModifiedModel={setModifiedModel}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  equipmentType={type}
                  setView={setView}
                  setComponentIndex={() => setComponentIndex(i)}
                  setTypeIndex={() => setTypeIndex(j)}
                  setModelIndex={setModelIndex}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={css.footer}>
        <div className={css.priceWrapper}>
          <PriceDisplay upfront={upfront} monthly={monthly} discount={discount} />
        </div>
      </div>
      <div className={css.header}>
        <NavigationElementsWrapper>
          <AppMenu setView={setView} view={view} />
        </NavigationElementsWrapper>
      </div>
    </div>
  );
};

export default EditView;
