import React from 'react';
import {Path, Svg,G} from '@react-pdf/renderer';
const PDFbackgroundline= () => (
    <Svg  style={{ position:'absolute', width: 1000, height: 800 }}>
      <G id="Layer_1">
      <Path d="M-21.111,-33.257 L675.394,-33.257 L675.394,497.598 L-21.111,497.598 z" fill="#fff"/>
      <Path d="M-174.909,-16.989 L580.732,-275.347 C589.187,-278.238 597.213,-277.158 598.657,-272.934 L707.699,45.991 C709.143,50.215 703.46,55.983 695.004,58.874 L-60.636,317.232 C-69.092,320.123 -77.117,319.043 -78.562,314.819 L-187.604,-4.106 C-189.048,-8.33 -183.364,-14.098 -174.909,-16.989 z" fill="#f7f8f9" fill-opacity="0.009"/>
      <Path d="M-214.833,-63.161 L472.431,-469.866 C480.122,-474.417 488.199,-474.992 490.472,-471.151 L662.126,-181.084 C664.399,-177.243 660.008,-170.439 652.318,-165.888 L-34.947,240.817 C-42.637,245.368 -50.715,245.943 -52.988,242.102 L-224.642,-47.965 C-226.915,-51.807 -222.524,-58.61 -214.833,-63.161 z" fill="#fff"/>
      
      </G>
    </Svg>
  )
  export default  PDFbackgroundline;