import React from 'react';
import { SlMenu}from "react-icons/sl"
import { TfiClose}from "react-icons/tfi"
import css from "./BurgerMenu.module.css"
export default function BurgerMenu({burgerMenuOpen = false, onClick=f=>f}){
    return (
        <div>
          {!burgerMenuOpen ? (
            <SlMenu className={css.style} onClick={onClick} />
          ) : (
            <TfiClose className={css.style} onClick={onClick} />
          )}
        </div>
    );
}