const prices = {
    PROLTE: 121, //121
    PROA7PLUS: 250, // without wifi
  
    PROLTECN:200, // (NOT USED)

    PROWIFI:65, //Wifi modul
    PROLTE_PROWIFI:190, // Wifi and Cell Modules LTE $121


    WIFI_MONI:17.99, //Wifi Monitoring Monthly
    WIFI_CELL_MONI:21.99,// Wifi and Cell Monitoring monthly
    TOTAL_CONNECT:3, // Monthly

    CELL_MONI:21.99, // Monthly Cellular monitoring (NOT USED)
    PROTAKEOVER:87, //69............Take over module 86.25
    PROSIXC2W:200, //8Z..........Wired-To-Wireless

    PROSIXCT: 28, //22...........Door/Window 28
    PROSIXSHOCK: 65, //52........Shock  65
    PROSIXPIR: 58, //46..........Motion 58
    PROSIXGB: 90, //74...........Glass  90
    PROSIXSMOKEV: 92, //73.......Smoke  92
    PROSIXCO: 140, //112.........CO     140
    PROSIXCOMBO: 204, //204.........Smoke/CO Combo 250
    PROSIXHEAT: 92, //69...........Heat   92
    PROSIXFLOOD: 58, // 49.......Flood  58
    PROSIXFOB: 33, //25............Keyfob 33
    PROSIXPANIC: 54, //43........Pnic   54
    PROSIXMED: 54, //43............Medical 54
    PROSIXCTOHR: 93, //30........OH Door, ProsixCt with wired door contacct 22, 7, i45: 93
    PROSIXCTOHC: 215, //60.......OH Door, ProsixCt with wired aluminum door contact 22, 50, i100: 215
    PROSIXTEMP:70, //49............Low Temp 
    PROSIXLCDKP: 219, //100......keypad 125,i50
    PROWLTOUCH_WALL: 309, //207.......Sub keypad 259,50 309
    PROWLTOUCH_DESK: 309, //207.......Sub keypad 259,50 309
    PROSIXSIREN: 150,//83.......Indoor Siren  104
    PROSIXSIRENO: 160, //123 ....Outdoor Siren 154
   

    EZVIZ_DOORBELL:250,
    EZVIZ_360_128GB:250,
    EZVIZ_360_256GB:280,

    GD00Z8GC:300, //Garage opener
    GE45603ZW:75, //60.............Light plug  75
    WEIZER_CODE_10:300, //lock
    T6PRO:303 //193...........Thermostat 193, i50: 303
};


export const getPrice = (key) => {
    if (prices[key]) {
        return prices[key];
    } else {
        throw new Error(`Price for key "${key}" not found`);
    }
};

export default prices;
