import React from 'react';
import css from "./FormCard.module.css"
const FormCard = ({children, bordered=true})=>{
    return(
        <div className={css.cardWrapper}>
            <div className={bordered?css.borderedCard:css.noBorderedCard}>
                <div className={css.contentWrapper}>
                    {children}
                </div>
            </div>
        </div>
    )
}
export default FormCard;