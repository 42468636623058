import React from 'react';
import { IoCallOutline } from "react-icons/io5";
import css from "./Phone.module.css"
export default function PhoneIcon(){
    return (
        <div>
             <a href="tel:+14037031273" >
                <IoCallOutline className={css.style} />
            </a>
        </div>
    );
}