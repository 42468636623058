import React, { useEffect } from 'react';
import css from "./AIECMS.module.css";
import AIECMSPageData from "../../Db/AIECMSPageData";
import LegalData from "../../Db/LegalData";
import BizInfoData from "../../Db/BizInfoData";
import FooterData from "../../Db/FooterData";
import CardSection from "../../webComponents/common/PageSections/CardSection/CardSection";
import LegalSection from "../../webComponents/common/PageSections/LegalSection/LegalSection";
import BizInfoSection from "../../webComponents/common/PageSections/BizInfoSection/BizInfoSection";
import FooterSection from "../../webComponents/common/PageSections/FooterSection/FooterSection"

export default function AIECMS(){
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return(
        <>      
            <div style={{height:47}}>{/* div under nav bar */}
                
            </div>
            <div className={css.sectionWrapper}>
                <CardSection data={AIECMSPageData().cards}/>
                <LegalSection data={LegalData().legal}/> 
                <BizInfoSection data={BizInfoData().infoLinks}/>
                <FooterSection data={FooterData().footer}/>
            </div>
        </>
    )
}

