import React, { useEffect, useState, useRef } from 'react';
import css from './AppCard.module.css';
import MainView from '../../../MainView/MainView';
import EditView from '../../../EditView/EditView';
import ModelView from '../../../ModelView/ModelView';
import InfoView from '../../../InfoView/InfoView';
import PaymentView from '../../../PaymentView/PaymentView';
import SendView from '../../../SendView/SendView';
import OrderView from '../../../OrderView/OrderView';
import TransferView from '../../../TransferView/TransferView';
import emailjs from 'emailjs-com';
import ReactGA from 'react-ga4';

const AppCard = ({
    setView,
    view,
    preBasket,
    project,
    inputUpfront,
    setInputUpfront,
    contractDuration,
    setContractDuration,
    sliderUpfront,
    setSliderUpfront,
    upfrontFromDb,
    setUpfrontFromDB,
    customerType,
    setCustomerType,
    discount,
    latitude, 
    longitude,
    url,
    showInstructions,
    setShowInstructions,
    showPaymentInstructions,
    setShowPaymentInstructions
}) => {

    const emailSentRef = useRef(false); // Ref to track email sent status

    const [typeIndex, setTypeIndex] = useState(0);
    const [modelIndex, setModelIndex] = useState(0);
    const [componentIndex, setComponentIndex] = useState(0);
    const [basket, setBasket] = useState([]);
    const [valet, setValet] = useState({});
    const [isVisible, setIsVisible] = useState([]);
    const [modifiedModel, setModifiedModel] = useState({});
    const [value, setValue] = useState();
    const [hardwareTotal, setHardwareTotal] = useState(0);
    const [monthlyTotal, setMonthlyTotal] = useState(0);
    const [tax] = useState(0.05);
    const [intrest] = useState(0.0125);
    const [contractDurationMemo, setContractDurationMemo] = useState(36);
    const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
    const [monitoringAddress, setMonitoringAddress] = useState({
        address: '',
        unit: '',
        city: '',
        province: 'AB',
        postal: '',
    });
    const [customerInfo, setCustomerInfo] = useState({
        business: '',
        fname: '',
        lname: '',
        phone: '',
        email: '',
        address: '',
        unit: '',
        city: '',
        province: '',
        postal: '',
        country: ''
    });
    const [callList, setCallList] = useState([]);

    useEffect(() => {
        if (emailSentRef.current) return; // Prevent re-sending if already sent
            sendEmail(latitude, longitude);
            emailSentRef.current = true; // Mark email as sent using ref
       
    }, []); // Empty dependency array to ensure this runs only once on mount

    const sendEmail = async (latitude, longitude) => {
        const serviceId = 'service_52qj8o7';
        const templateId = 'template_ogu0qsm';
        const userId = 'Pgvr9-YrZHu7sbMO4';
        const ccEmail = 'tech@securepol.com';

        try {
            const response = await emailjs.send(
                serviceId,
                templateId,
                {
                    url:url,
                    latitude: latitude !== null ? latitude : 'Location not available',
                    longitude: longitude !== null ? longitude : 'Location not available',
                    ccEmail: ccEmail,
                },
                userId
            );
            console.log('Email sent successfully:', response.status, response.text);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    //update basket at initial
    useEffect(()=>{
        setBasket(project.checker.runChecker(basket))
        basket.forEach((item)=>{
            if(Object.keys(item).length===1)
            {
                updateBasket()
            }
        })
    })

    useEffect(()=>{
        if(localStorage.getItem('basket') && preBasket.length ===0){
            getBasketFromLocalStorage();
        }
        updateBasket();  
    },[])

    useEffect(()=>{
        setBasket(preBasket)
    },[preBasket])

    useEffect(()=>{
        setValet({
            contract:contractDuration,
            upfront:sliderUpfront,
            customerType:customerType,
            monthly:monthlyTotal.toFixed(2)
        })
    },[monthlyTotal, inputUpfront, customerType])

    
    useEffect(()=>{
        saveBasketInLocalStorage()
    },[valet])

    function saveBasketInLocalStorage(){
        //combine basket with valet (payment plan)
        const box={
            valet:valet,
            basket: basket
        }
        // Convert the array of objects to a JSON string
        const basketDataString = JSON.stringify(box);
        localStorage.setItem('basket', basketDataString);
    }

    function getBasketFromLocalStorage(){
        const savedBasketDataString = localStorage.getItem('basket');
        setBasket(JSON.parse(savedBasketDataString));
    }

    //when basket change recalculate monthly and hardware totals
    useEffect(()=>{
        setHardwareTotal(getHardwareTotal());
        setMonthlyTotal(contractDuration>1?(totalWithIntrest()/contractDuration)+getMonthlyTotal():getMonthlyTotal());
        if(localStorageLoaded)
        {
            //this runs checker from project file
            //checker is a function that scan basket and applays logick like add automation fee when automation devices are added to basket
            if(basket.length !== 0)
                {   
                    saveBasketInLocalStorage();
                }
        }
        if(contractDuration === 1)
        {
            setSliderUpfront(getHardwareTotal())
        }
        
    },[basket])

    //calculate total with intrest based on user selection
    const totalWithIntrest=()=>(((hardwareTotal-sliderUpfront)*(intrest*contractDuration))+hardwareTotal)-sliderUpfront;


    useEffect(()=>{
        setMonthlyTotal(contractDuration>1?(totalWithIntrest()/contractDuration)+getMonthlyTotal():getMonthlyTotal())
        //in case when user had sellected items and set upfront payment (example $1000)
        //then he starts deleteing items and hardwareTotal falls bellow ($1000)
        //this reset upfront to $0
        if((hardwareTotal<sliderUpfront) && !upfrontFromDb){
           setSliderUpfront(0);
           setInputUpfront(0)
        }
        setUpfrontFromDB(false)
    },[contractDuration, sliderUpfront, hardwareTotal])


   


    
    function resetBasket(){
        setBasket(project.resetBasket)
        setIsVisible([])
    }
    const updateBasket = ()=>{
        project.components.map((component)=>{
            component.type.map((type)=>{
                setValue(checkWithBasket(type).length);
                if(basket.length!==0){
                    addDefaultModel(type);
                    addDefaultLocation(type);
                    defaultUnknownModel(type);
                    addDefaultPrice(type);
                    addCheckListKeyword(type);
                    addRadioName(type);
                    addDefaultMonthlyPrice(type);
                    addDisplayNoQty(type);
                    addGroup(type);
                    addIsService(type)
                }
                setLocalStorageLoaded(true);
            })
        })
    }
    
    function getHardwareTotal() {
        let total = 0;
    
        basket.forEach((item) => {
            total += item.price;
        });
    
        // add 20% fee to hardware if order is over 500
        if (total > 500) {
            total = total + ((total - 500) * (total / 6000));
        }
    
        return Math.round(total);
    }
    
    function getMonthlyTotal() {
        let total = 0;
        basket.forEach((item) => {
            if (item.monthly) {
                total += item.monthly;
            }
        });
        return total;
    }
    
    //Checks basket for item of current category(type.title) and retruns array of found items
    //used for setup qty of currently selected items
    //olso is passed to itemList to provide details about selected item
    const checkWithBasket =(type)=>{
        let items=[];
        basket.map((item)=>{
            if(item.cat === type.title)
            {
                items.push(item);
            }
        })
        return items;
    }

    //Adds default model to basket itmes if they dont have model provided.
    const addDefaultModel = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.model && e.cat === type.title) {
                    return { ...e, model: type.models[0].model};
                }
                return e;
            });
        });
    };
    //Adds default item price to items in the basket if they dont have price provided.
    const addDefaultPrice = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.price && e.cat === type.title) {
                    return { ...e, price: type.models[0].price };
                }
                return e;
            });
        });
    };

    const addGroup= (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.group && e.cat === type.title) {
                    return { ...e, group: type.models[0].group };
                }
                return e;
            });
        });
    };
    //Adds default item monthly price to items in the basket if they dont have price provided.
    const addDefaultMonthlyPrice = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.monthly && (type.models[0].monthly && e.cat === type.title)) {
                    return { ...e, monthly: type.models[0].monthly };
                }
                return e;
            });
        });
    };

    //Adds default item monthly price to items in the basket if they dont have price provided.
    const addIsService = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.isService && (type.models[0].isService && e.cat === type.title)) {
                    return { ...e, isService: type.models[0].isService };
                }
                return e;
            });
        });
    };
    
    //Replace unrecognized model with default model
    const defaultUnknownModel = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!type.models.some(m => m.model === e.model) && e.cat === type.title) {
                    return { ...e, model: type.models[0].model };
                }
                return e;
            });
        });
    };
    
    //Adds default location to basket items if they dont have loc provided
    const addDefaultLocation = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.loc ) {
                    let location ="";
                    if(type.fixedLocation && e.cat === type.title )
                    {
                        location = type.fixedLocation;
                    }
                    return { ...e, loc: location };
                }
                return e;
            });
        });
    };
    
    //Adds checklistKeyword to the basket from defalut (index 0)item 
    const addCheckListKeyword = (type) => {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.checkListKeyword && e.cat === type.title) {
                    return { ...e, checkListKeyword: type.models[0].checkListKeyword };
                }
                return e;
            });
        });
    };
    function addRadioName(type)
    {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.radioName && (type.radioName && e.cat === type.title)) {
                    return { ...e, radioName: type.radioName};
                }
                return e;
            });
        });
    }
    function addDisplayNoQty(type)
    {
        setBasket(prevBasket => {
            return prevBasket.map(e => {
                if (!e.displayNoQty && (type.displayNoQty && e.cat === type.title)) {
                    return { ...e, displayNoQty: type.displayNoQty};
                }
                return e;
            });
        });
    }

    const renderView = () => {
        // Track the current view being rendered
        ReactGA.event({
            category: 'View',
            action: `Viewed ${view} page`,
            label: view
        });

        switch (view) {
            case 'main':
                return <MainView 
                project={project} 
                basket={basket} 
                setView={setView}
                monthly={monthlyTotal}
                upfront={sliderUpfront}
                contractDuration={contractDuration}
                discount={discount}
                view={view}
                showInstructions={showInstructions}
                setShowInstructions={setShowInstructions}
                />;
            case 'edit':
                return <EditView  
                project={project} 
                checkWithBasket={checkWithBasket} 
                value={value} setValue={setValue} 
                basket={basket} setBasket={setBasket} 
                setModifiedModel={setModifiedModel}  
                isVisible={isVisible} 
                setIsVisible={setIsVisible} 
                setView={setView} 
                setComponentIndex={setComponentIndex} 
                setTypeIndex={setTypeIndex}
                monthly={monthlyTotal}
                upfront={sliderUpfront}
                setModelIndex={setModelIndex}
                discount={discount}
                view={view}
                resetBasket={resetBasket}
                />;
            case 'model':
                return <ModelView 
                basket={basket} 
                setBasket={setBasket}  
                modifiedModel={modifiedModel} 
                models={project.components[componentIndex].type[typeIndex].models} 
                setModelIndex={setModelIndex} 
                setView={setView}
                monthly={monthlyTotal}
                upfront={sliderUpfront}
                discount={discount}
                view={view}
                />;
            case 'info':
                return <InfoView 
                model={project.components[componentIndex].type[typeIndex].models[modelIndex]} 
                setView={setView}
                view={view}
                />;
            case 'payment':
                return <PaymentView 
                model={project.components[componentIndex].type[typeIndex].models[modelIndex]} 
                setView={setView}
                monthlyTotal={monthlyTotal}
                setMonthlyTotal={setMonthlyTotal}
                hardwareTotal={hardwareTotal}
                tax={tax}
                intrest={intrest}
                contractDuration={contractDuration}
                setContractDuration={setContractDuration}
                contractDurationMemo={contractDurationMemo}
                setContractDurationMemo={setContractDurationMemo}
                sliderUpfront={sliderUpfront}
                setSliderUpfront={setSliderUpfront}
                inputUpfront={inputUpfront}
                setInputUpfront={setInputUpfront}
                customerType={customerType}
                setCustomerType={setCustomerType}
                view={view}
                showPaymentInstructions={showPaymentInstructions}
                setShowPaymentInstructions={setShowPaymentInstructions}
                />;
            case 'send':
                return <SendView 
                project={project} 
                setView={setView}
                view={view}
                basket={basket}
                valet={valet}
                monthly={monthlyTotal}
                upfront={sliderUpfront}
                tax={tax}
                contractDuration={contractDuration}
                />;
            case 'order':
                return <OrderView 
                project={project} 
                setView={setView}
                view={view}
                basket={basket}
                valet={valet}
                monthly={monthlyTotal}
                upfront={sliderUpfront}
                tax={tax}
                contractDuration={contractDuration}
                monitoringAddress={monitoringAddress}
                setMonitoringAddress={setMonitoringAddress}
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
                customerType={customerType}
                callList={callList}
                setCallList={setCallList}
                />;
            case 'transfer':
                return <TransferView 
                />;
            default:
                return <div>No view selected</div>; 
        }
    };

    return (
        <>
        <div className={css.appBackground}>
                <div className={css.card}>
            {renderView()} 
            </div>
            </div>       
        </>
    );
};

export default AppCard;

