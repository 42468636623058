import a1 from './TotalConnect2img/a1.jpg'
import a2 from './TotalConnect2img/a2.jpg'
import a3 from './TotalConnect2img/a3.jpg'
import a4 from './TotalConnect2img/a4.jpg'
import a5 from './TotalConnect2img/a5.jpg'
import { getPrice } from '../../../../../../Prices';

const TOTALCONNECT2 = () => {
    const device = {
        title: 'Total Connect 2.0 Security System',
        model: 'TOTAL CONNECT 2.0',
        sku: ['TOTALCONNECT2'],
        isService: true,
        modelViewImage: a1,
        image: [a1,a2,a3,a4,a5],
        shortDescription: 'Comprehensive security system with remote access and control.',
        price: 0,
        monthly:getPrice('TOTAL_CONNECT'),
        checkListKeyword: ['App'],
        infoHeader: "Comprehensive Security and Smart Home Control",
        spec: [
            { row1: 'Name:', row2: 'TOTAL CONNECT 2.0' },
            { row1: 'Brand:', row2: 'Honeywell' },
        ],
        info: [
            {
                header: 'Ultimate Security and Convenience',
                text: 'The Total Connect 2.0 security system provides unparalleled security and convenience with its comprehensive features and remote access capabilities. Protect your home and loved ones with advanced technology and smart home integration.',
                section: [
                    { title: "Remote Access" },
                    { text: "Control your security system from anywhere using your smartphone, tablet, or computer. With Total Connect 2.0, you can arm/disarm the system, receive real-time alerts, and view live video feeds remotely." },
                    
                    { title: "Smart Home Integration" },
                    { text: "Integrate Total Connect 2.0 with your smart home devices, including lights, locks, thermostats, and cameras. Enjoy seamless automation and control all your devices from a single app." },
                    
                    { title: "Real-Time Alerts" },
                    { text: "Stay informed with real-time alerts for security events, such as intrusions, fire, or environmental hazards. Customize notifications to receive alerts via email, text, or push notifications." },
                    
                    { title: "User-Friendly Interface" },
                    { text: "The intuitive interface of Total Connect 2.0 makes it easy to manage your security system and smart home devices. Access a wide range of features and settings with just a few taps." },
                    
                    { title: "Video Surveillance" },
                    { text: "Monitor your property with high-definition video surveillance. View live video feeds, record clips, and store footage securely in the cloud for easy access and review." },
                    
                    {title: "Geo-Services"},
                    {text: "Enhance your security with Geo-Services, which uses your smartphone's location to alert you if you leave behind an unsecured alarm based on your proximity to the premises."
                      },
                      
                    
                    { title: "Voice Control" },
                    { text: "Compatible with popular voice assistants like Amazon Alexa and Google Assistant, Total Connect 2.0 allows you to control your security system and smart home devices using simple voice commands." },
                    
                    { title: "Customizable Automation" },
                    { text: "Create custom automation rules to enhance your security and convenience. Set schedules, trigger actions based on events, and automate your daily routines with ease." },
                    
                    { title: "Secure Cloud Storage" },
                    { text: "Store your video footage and system data securely in the cloud. Access your information anytime, anywhere, with the peace of mind that it is protected by advanced encryption and security measures." },
                ]
            },

            {
                header: 'Enhance Your Lifestyle with Total Connect 2.0',
                text: 'Total Connect 2.0 not only provides top-notch security but also enhances your lifestyle with its smart home capabilities and user-friendly features. Experience the convenience and peace of mind that comes with a truly connected home.',
                section: [
                    { title: "Seamless Integration" },
                    { text: "Effortlessly integrate your security system with other smart home devices for a unified experience. Control lights, locks, thermostats, and more from the Total Connect 2.0 app." },
                    
                    { title: "Energy Management" },
                    { text: "Save on energy costs by integrating your security system with smart thermostats and lighting. Automatically adjust settings based on your schedule and preferences." },
                    
                    { title: "Enhanced Safety" },
                    { text: "Ensure the safety of your home and loved ones with advanced features such as smoke and carbon monoxide detection, water leak sensors, and more. Receive instant alerts and take action immediately." },
                    
                    { title: "Convenient Automation" },
                    { text: "Automate your daily routines with customizable automation rules. Set schedules for your smart devices, trigger actions based on events, and simplify your life with Total Connect 2.0." },
                    
                    { title: "Peace of Mind" },
                    { text: "With real-time alerts, remote access, and comprehensive monitoring, Total Connect 2.0 provides peace of mind knowing that your home is secure and protected, no matter where you are." },
                ]
            },
        ]
    }
    return device;
}
export default TOTALCONNECT2;
