import n1 from './img/n1.jpg';
import n2 from './img/n2.jpg';
import { getPrice } from '../../../../Prices';

const T6ProZW = () => {
    const device = {
        title: 'T6 Pro Z-Wave Thermostat',
        model: 'T6 Pro',
        sku: ['T6ProZW'],
        modelViewImage: n1,
        image: [n1,n2],
        shortDescription: 'Smart thermostat with Z-Wave technology for efficient home climate control.',
        price:getPrice('T6PRO'),
        group: 'automation',
        checkListKeyword: [],
        infoHeader: "Smart Thermostat",
        spec: [
            { row1: 'Model:', row2: 'T6 Pro' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Z-Wave' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Wall mount' },
            { row1: 'Power Supply:', row2: 'Hardwired' },
            { row1: 'Display:', row2: 'Touchscreen' },
            { row1: 'Compatibility:', row2: 'Works with most HVAC systems' },
            { row1: 'Smartphone Control:', row2: 'Yes, via app' },
            { row1: 'Dimensions:', row2: '4.09 x 4.09 x 1.06 inches' },
            { row1: 'Weight:', row2: '1.2 lbs' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'FCC, UL Certified' },
        ],
        info: [
            {
                header: 'Efficient Home Climate Control',
                text: 'The T6 Pro Z-Wave Thermostat provides efficient home climate control with advanced features and smart technology. Manage your home’s temperature from anywhere with ease.',
                section: [
                    { title: "Smart Control" },
                    { text: "Control your thermostat remotely with your smartphone or Z-Wave controller. The T6 Pro offers seamless integration with smart home systems, providing remote access and control." },
                    
                    { title: "Touchscreen Display" },
                    { text: "The T6 Pro features a user-friendly touchscreen display, making it easy to adjust settings, set schedules, and view temperature information at a glance." },
                    
                    { title: "Z-Wave Technology" },
                    { text: "The built-in Z-Wave technology allows for integration with other Z-Wave devices in your smart home network. This enhances the functionality and automation of your home climate control." },
                    
                    { title: "Energy Efficiency" },
                    { text: "The thermostat helps improve your home’s energy efficiency by allowing you to create custom schedules and temperature settings that fit your lifestyle. Save on energy costs while maintaining comfort." },
                    
                    { title: "Easy Installation" },
                    { text: "Designed for easy installation, the T6 Pro works with most HVAC systems and comes with detailed instructions. The wall mount design ensures a clean and integrated look." },
                    
                    { title: "Voice Control" },
                    { text: "Compatible with popular voice assistants like Amazon Alexa and Google Assistant, allowing you to control your thermostat with simple voice commands." },
                    
                    { title: "Real-Time Alerts" },
                    { text: "Receive real-time alerts on your smartphone for temperature changes, system status, and more. Stay informed and in control no matter where you are." },
                    
                    { title: "Customizable Settings" },
                    { text: "Customize the thermostat’s settings to suit your specific needs. Set temperature schedules, adjust settings remotely, and manage notifications through the dedicated app." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The T6 Pro thermostat meets industry standards and certifications for safety and performance, including FCC and UL certification." },
                ]
            },

            {
                header: 'Smart Features for Modern Living',
                text: 'The T6 Pro Z-Wave Thermostat offers smart features to enhance your home’s climate control. From remote access to energy savings, this thermostat is designed to provide comfort and convenience.',
                section: [
                    { title: "Remote Access" },
                    { text: "Manage your home’s temperature from anywhere using the dedicated app. Adjust settings, create schedules, and receive alerts for complete control over your home’s climate." },
                    
                    { title: "Energy Savings" },
                    { text: "The T6 Pro helps you save on energy costs by allowing you to create custom temperature schedules that fit your daily routine. Reduce energy consumption without sacrificing comfort." },
                    
                    { title: "Geo-Fencing" },
                    { text: "Utilize geo-fencing technology to automatically adjust your home’s temperature based on your location. The thermostat can set the perfect temperature as you arrive home and save energy while you’re away." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive smart alerts for temperature changes, system maintenance reminders, and more. Stay informed and proactive about your home’s climate control." },
                    
                    { title: "Sleek and Modern Design" },
                    { text: "The T6 Pro features a sleek and modern design that complements any home decor. The intuitive touchscreen display provides easy access to all settings and information." },
                    
                    { title: "Compatibility" },
                    { text: "The thermostat is compatible with most HVAC systems, including conventional systems and heat pumps. Ensure that your home’s climate control is efficient and reliable." },
                ]
            },

        ]
    }
    return device;
}
export default T6ProZW;
