export default function SvgTrianglesLinesBottom(){
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="640" height="480" viewBox="0, 0, 640, 480">
    <defs>
      <linearGradient id="Gradient_1" gradientUnits="userSpaceOnUse" x1="303.124" y1="-56" x2="303.124" y2="537">
        <stop offset="0" stop-color="#FFFFFF"/>
        <stop offset="0.295" stop-color="#F2F7FF"/>
        <stop offset="1" stop-color="#87A7C6"/>
      </linearGradient>
      <linearGradient id="Gradient_2" gradientUnits="userSpaceOnUse" x1="-127.5" y1="300.25" x2="721" y2="300.25">
        <stop offset="0" stop-color="#FFFFFF"/>
        <stop offset="1" stop-color="#3D607A"/>
      </linearGradient>
      <linearGradient id="Gradient_3" gradientUnits="userSpaceOnUse" x1="-643.235" y1="-464.203" x2="254.089" y2="-464.203" gradientTransform="matrix(-0.992, 0.127, -0.127, -0.992, 0, 0)">
        <stop offset="0" stop-color="#FFFFFF"/>
        <stop offset="1" stop-color="#3D607A"/>
      </linearGradient>
      <linearGradient id="Gradient_4" gradientUnits="userSpaceOnUse" x1="-827.126" y1="-524.585" x2="173.706" y2="-524.585" gradientTransform="matrix(-0.916, 0.402, -0.402, -0.916, 0, 0)">
        <stop offset="0" stop-color="#FFFFFF"/>
        <stop offset="1" stop-color="#3D607A"/>
      </linearGradient>
      <linearGradient id="Gradient_5" gradientUnits="userSpaceOnUse" x1="71.611" y1="103.064" x2="767.345" y2="103.064" gradientTransform="matrix(0.704, 0.711, -0.711, 0.704, 0, 0)">
        <stop offset="0" stop-color="#FFFFFF"/>
        <stop offset="1" stop-color="#3D607A"/>
      </linearGradient>
    </defs>
    <g id="Layer_1">
      <path d="M267.458,581.79 C267.953,582.078 270.829,581.45 270.112,583.49 C266.419,593.993 250.642,591.728 244.721,590.969 C212.058,586.783 175.005,576.729 152.29,533.016 C134.56,498.896 132.958,454.472 107.288,411.303 C83.851,371.89 57.545,366.467 29.136,344.75 C13.88,333.088 -0.285,308.71 -16.767,302.436 C-38.289,294.244 -57.088,317.523 -76.533,325.16 C-91.634,331.091 -107.596,328.115 -122.952,323.443" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M270.809,581.545 C271.312,581.841 274.232,581.24 273.512,583.288 C269.805,593.831 253.77,591.375 247.753,590.545 C214.559,585.966 176.881,575.425 153.627,531.124 C135.476,496.544 133.664,451.758 107.41,407.972 C83.44,367.995 56.697,362.24 27.752,340.042 C12.207,328.121 -2.282,303.397 -19.05,296.893 C-40.943,288.401 -59.941,311.653 -79.66,319.134 C-94.973,324.944 -111.2,321.769 -126.816,316.892" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M271.991,582.094 C272.5,582.398 275.429,581.871 274.723,583.88 C271.087,594.222 254.945,591.429 248.888,590.471 C215.476,585.19 177.505,573.911 153.746,529.571 C135.201,494.961 132.995,450.628 106.225,406.73 C81.784,366.651 54.848,360.352 25.556,337.742 C9.825,325.6 -4.953,300.821 -21.867,294.008 C-43.951,285.112 -62.852,307.677 -82.612,314.628 C-97.959,320.027 -114.299,316.519 -130.042,311.341" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M272.048,582.148 C272.547,582.442 275.429,581.871 274.723,583.88 C271.087,594.222 255.237,591.701 249.29,590.845 C216.48,586.124 179.225,575.511 156.139,531.796 C138.118,497.674 136.216,453.624 110.166,410.396 C86.383,370.929 59.945,365.093 31.289,343.075 C15.9,331.25 1.523,306.844 -15.062,300.338 C-36.715,291.843 -55.429,314.582 -74.893,321.808 C-90.01,327.421 -106.05,324.192 -121.493,319.293" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M272.152,581.476 C272.669,581.786 275.64,581.272 274.928,583.296 C271.265,593.715 254.883,590.79 248.737,589.784 C214.83,584.234 176.284,572.599 152.073,527.674 C133.176,492.607 130.829,447.828 103.565,403.329 C78.673,362.701 51.333,356.167 21.563,333.147 C5.576,320.784 -9.476,295.668 -26.651,288.679 C-49.076,279.554 -68.194,302.215 -88.222,309.106 C-103.776,314.458 -120.361,310.809 -136.344,305.479" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M272.228,581.546 C272.731,581.843 275.64,581.272 274.928,583.296 C271.265,593.715 255.269,591.149 249.266,590.277 C216.154,585.466 178.552,574.709 155.228,530.608 C137.022,496.184 135.076,451.779 108.762,408.163 C84.738,368.343 58.054,362.419 29.123,340.179 C13.586,328.235 -0.937,303.611 -17.677,297.027 C-39.534,288.43 -58.405,311.32 -78.043,318.574 C-93.295,324.207 -109.484,320.927 -125.071,315.965" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M272.816,586.627 C273.319,586.922 276.239,586.322 275.518,588.37 C271.812,598.913 255.777,596.456 249.76,595.626 C216.566,591.047 178.888,580.507 155.634,536.205 C137.483,501.625 135.67,456.84 109.417,413.054 C85.447,373.077 58.704,367.322 29.759,345.123 C14.214,333.202 -0.275,308.479 -17.043,301.975 C-38.936,293.482 -57.934,316.734 -77.653,324.216 C-92.967,330.026 -109.193,326.851 -124.81,321.973" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M274.03,582.915 C274.543,583.229 277.469,582.821 276.79,584.754 C273.291,594.705 257.093,591.396 251.016,590.243 C217.495,583.881 179.327,571.508 154.934,527.421 C135.893,493.008 133.076,449.71 105.674,405.944 C80.656,365.985 53.604,358.868 23.969,335.763 C8.055,323.355 -7.08,298.663 -24.107,291.408 C-46.337,281.935 -64.949,303.246 -84.636,309.305 C-99.926,314.011 -116.333,309.998 -132.164,304.379" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M270.341,581.371 C270.849,581.665 273.801,581.011 273.062,583.111 C269.261,593.923 253.075,591.645 247.001,590.884 C213.492,586.683 175.485,576.464 152.232,531.583 C134.082,496.552 132.492,450.872 106.206,406.561 C82.206,366.105 55.221,360.612 26.098,338.37 C10.459,326.426 -4.045,301.401 -20.949,295.002 C-43.022,286.647 -62.339,310.648 -82.299,318.564 C-97.801,324.712 -114.176,321.703 -129.927,316.948" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M273.78,581.108 C274.296,581.411 277.292,580.784 276.551,582.892 C272.735,593.746 256.285,591.272 250.112,590.438 C216.059,585.835 177.412,575.117 153.606,529.633 C135.024,494.13 133.219,448.079 106.335,403.135 C81.789,362.1 54.357,356.268 24.684,333.533 C8.749,321.323 -6.087,295.945 -23.284,289.31 C-45.739,280.647 -65.259,304.62 -85.501,312.377 C-101.221,318.402 -117.866,315.189 -133.884,310.223" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M274.992,581.669 C275.514,581.98 278.52,581.428 277.793,583.496 C274.05,594.144 257.491,591.324 251.277,590.359 C217.001,585.034 178.055,573.558 153.731,528.036 C134.745,492.503 132.535,446.919 105.12,401.861 C80.091,360.723 52.461,354.332 22.434,331.175 C6.308,318.738 -8.826,293.303 -26.172,286.352 C-48.821,277.275 -68.241,300.541 -88.525,307.753 C-104.278,313.354 -121.04,309.799 -137.186,304.525" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M275.051,581.724 C275.562,582.025 278.52,581.428 277.793,583.497 C274.049,594.144 257.79,591.602 251.689,590.741 C218.03,585.992 179.817,575.198 156.182,530.316 C137.734,495.284 135.835,449.989 109.16,405.618 C84.805,365.107 57.685,359.191 28.309,336.64 C12.534,324.53 -2.188,299.477 -19.197,292.84 C-41.405,284.174 -60.633,307.618 -80.614,315.112 C-96.131,320.932 -112.585,317.663 -128.425,312.675" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M275.159,581.033 C275.688,581.35 278.737,580.812 278.004,582.896 C274.233,593.622 257.428,590.667 251.122,589.652 C216.339,584.054 176.803,572.213 152.016,526.09 C132.669,490.089 130.316,444.047 102.395,398.372 C76.904,356.671 48.86,350.039 18.342,326.462 C1.954,313.8 -13.46,288.019 -31.074,280.887 C-54.072,271.575 -73.716,294.941 -94.274,302.092 C-110.24,307.646 -127.253,303.947 -143.645,298.517" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M275.236,581.105 C275.752,581.408 278.737,580.812 278.004,582.896 C274.233,593.622 257.823,591.035 251.666,590.157 C217.696,585.316 179.127,574.375 155.249,529.097 C136.611,493.755 134.668,448.095 107.722,403.327 C83.12,362.453 55.748,356.447 26.091,333.669 C10.164,321.436 -4.709,296.16 -21.876,289.443 C-44.292,280.672 -63.683,304.273 -83.842,311.796 C-99.498,317.638 -116.104,314.317 -132.091,309.264" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M275.833,586.328 C276.349,586.63 279.345,586.003 278.604,588.111 C274.788,598.966 258.338,596.491 252.165,595.657 C218.112,591.055 179.465,580.336 155.659,534.852 C137.077,499.349 135.272,453.298 108.388,408.354 C83.842,367.32 56.41,361.487 26.737,338.752 C10.802,326.542 -4.035,301.164 -21.231,294.529 C-43.685,285.866 -63.206,309.84 -83.448,317.597 C-99.168,323.621 -115.813,320.408 -131.831,315.443" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M277.083,582.507 C277.61,582.828 280.612,582.399 279.912,584.389 C276.311,594.633 259.695,591.283 253.461,590.117 C219.074,583.683 179.926,571.081 154.951,525.821 C135.457,490.493 132.619,445.975 104.556,401.055 C78.935,360.042 51.186,352.81 20.808,329.145 C4.494,316.436 -11.005,291.092 -28.466,283.685 C-51.265,274.015 -70.388,295.991 -90.595,302.285 C-106.289,307.174 -123.118,303.1 -139.355,297.373" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M352.228,590.762 C352.723,591.05 355.599,590.422 354.882,592.462 C351.19,602.965 335.413,600.7 329.491,599.941 C296.828,595.754 259.775,585.7 237.06,541.988 C219.33,507.868 217.728,463.444 192.058,420.275 C168.621,380.862 142.315,375.439 113.906,353.722 C98.65,342.059 84.486,317.681 68.003,311.407 C46.482,303.216 27.682,326.495 8.238,334.132 C-6.863,340.062 -22.826,337.086 -38.182,332.415" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M267.647,590.538 C268.151,590.833 271.07,590.233 270.35,592.281 C266.643,602.824 250.609,600.367 244.591,599.537 C211.397,594.958 173.719,584.418 150.465,540.116 C132.314,505.537 130.502,460.751 104.248,416.965 C80.279,376.988 53.536,371.233 24.59,349.034 C9.045,337.113 -5.443,312.39 -22.211,305.886 C-44.105,297.394 -63.103,320.646 -82.822,328.127 C-98.135,333.937 -114.361,330.762 -129.978,325.885" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M356.761,591.066 C357.27,591.37 360.2,590.842 359.493,592.851 C355.857,603.194 339.715,600.401 333.658,599.443 C300.247,594.161 262.276,582.883 238.517,538.542 C219.972,503.933 217.765,459.6 190.995,415.702 C166.554,375.622 139.618,369.324 110.326,346.714 C94.596,334.571 79.817,309.792 62.903,302.98 C40.819,294.084 21.918,316.649 2.158,323.6 C-13.188,328.999 -29.529,325.49 -45.272,320.312" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M356.819,591.12 C357.317,591.414 360.199,590.842 359.493,592.851 C355.857,603.194 340.008,600.673 334.06,599.817 C301.25,595.095 263.996,584.483 240.909,540.768 C222.888,506.646 220.986,462.596 194.936,419.368 C171.153,379.901 144.715,374.065 116.059,352.047 C100.67,340.222 86.293,315.816 69.709,309.31 C48.055,300.815 29.341,323.554 9.877,330.78 C-5.24,336.392 -21.28,333.163 -36.723,328.264" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M268.991,590.469 C269.508,590.779 272.478,590.265 271.767,592.289 C268.103,602.708 251.722,599.783 245.575,598.777 C211.668,593.227 173.122,581.592 148.912,536.667 C130.014,501.6 127.667,456.821 100.403,412.322 C75.511,371.694 48.171,365.16 18.402,342.139 C2.414,329.777 -12.638,304.661 -29.813,297.672 C-52.238,288.547 -71.355,311.208 -91.384,318.099 C-106.938,323.45 -123.523,319.802 -139.506,314.472" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M269.066,590.539 C269.569,590.836 272.478,590.265 271.767,592.289 C268.103,602.708 252.107,600.142 246.105,599.27 C212.992,594.459 175.39,583.702 152.066,539.601 C133.86,505.177 131.914,460.771 105.6,417.156 C81.576,377.336 54.892,371.412 25.961,349.171 C10.424,337.228 -4.099,312.604 -20.839,306.02 C-42.696,297.422 -61.567,320.313 -81.205,327.567 C-96.457,333.2 -112.646,329.92 -128.233,324.957" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M357.586,595.599 C358.09,595.894 361.009,595.293 360.289,597.342 C356.582,607.884 340.547,605.428 334.53,604.598 C301.336,600.019 263.658,589.478 240.404,545.177 C222.253,510.597 220.441,465.811 194.187,422.025 C170.217,382.049 143.475,376.293 114.529,354.095 C98.984,342.174 84.495,317.451 67.727,310.946 C45.834,302.454 26.836,325.706 7.117,333.187 C-8.196,338.998 -24.422,335.822 -40.039,330.945" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M358.8,591.887 C359.314,592.201 362.239,591.793 361.56,593.726 C358.061,603.676 341.863,600.368 335.786,599.215 C302.265,592.853 264.097,580.48 239.704,536.393 C220.663,501.98 217.846,458.682 190.444,414.916 C165.426,374.957 138.374,367.839 108.739,344.735 C92.825,332.327 77.69,307.635 60.664,300.379 C38.433,290.906 19.821,312.217 0.134,318.277 C-15.156,322.983 -31.562,318.97 -47.394,313.351" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M355.112,590.343 C355.619,590.637 358.571,589.983 357.832,592.083 C354.031,602.895 337.846,600.617 331.771,599.855 C298.262,595.655 260.255,585.435 237.002,540.555 C218.852,505.524 217.262,459.844 190.976,415.533 C166.976,375.076 139.991,369.584 110.869,347.342 C95.229,335.398 80.725,310.373 63.821,303.974 C41.749,295.619 22.431,319.62 2.471,327.536 C-13.031,333.684 -29.406,330.674 -45.156,325.92" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M270.618,590.101 C271.135,590.403 274.13,589.776 273.389,591.885 C269.573,602.739 253.124,600.265 246.951,599.43 C212.897,594.828 174.25,584.11 150.444,538.626 C131.863,503.123 130.057,457.072 103.173,412.127 C78.628,371.093 51.195,365.261 21.522,342.525 C5.587,330.316 -9.249,304.937 -26.446,298.303 C-48.9,289.64 -68.421,313.613 -88.663,321.37 C-104.382,327.394 -121.027,324.181 -137.045,319.216" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M443.017,623.475 C443.922,623.68 447.377,621.735 447.993,624.788 C451.164,640.506 427.738,643.396 419.018,644.604 C370.913,651.27 312.182,651.6 246.725,600.606 C195.633,560.802 158.589,500.839 89.503,451.707 C26.428,406.848 -13.924,409.42 -69.941,390.573 C-100.023,380.451 -138.582,352.579 -166.121,350.27 C-202.078,347.254 -209.615,386.115 -230.307,403.894 C-246.376,417.701 -270.615,419.691 -295.351,419.139" fill-opacity="0" stroke="#FFFFFF" stroke-width="1" stroke-opacity="0.092" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.807" display="none"/>
      <path d="M359.821,590.696 C360.332,590.997 363.29,590.4 362.563,592.468 C358.82,603.116 342.56,600.574 336.459,599.713 C302.8,594.963 264.588,584.169 240.952,539.288 C222.504,504.255 220.606,458.961 193.93,414.59 C169.575,374.079 142.455,368.163 113.08,345.612 C97.304,333.501 82.582,308.449 65.573,301.812 C43.366,293.145 24.137,316.59 4.156,324.084 C-11.361,329.904 -27.815,326.635 -43.654,321.647" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M271.997,590.025 C272.527,590.342 275.575,589.805 274.843,591.888 C271.071,602.615 254.266,599.66 247.961,598.645 C213.177,593.047 173.641,581.206 148.854,535.083 C129.507,499.081 127.154,453.04 99.234,407.365 C73.742,365.664 45.698,359.032 15.181,335.454 C-1.208,322.793 -16.622,297.012 -34.236,289.88 C-57.234,280.568 -76.877,303.934 -97.436,311.085 C-113.402,316.638 -130.415,312.94 -146.807,307.51" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M272.074,590.097 C272.59,590.401 275.575,589.805 274.843,591.888 C271.071,602.615 254.662,600.028 248.504,599.15 C214.534,594.309 175.966,583.368 152.087,538.09 C133.449,502.748 131.506,457.088 104.56,412.32 C79.958,371.446 52.587,365.44 22.929,342.662 C7.002,330.429 -7.87,305.153 -25.038,298.435 C-47.454,289.665 -66.844,313.266 -87.004,320.789 C-102.659,326.631 -119.266,323.31 -135.253,318.257" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M360.603,595.299 C361.119,595.602 364.115,594.975 363.374,597.083 C359.558,607.937 343.109,605.463 336.935,604.628 C302.882,600.026 264.235,589.308 240.429,543.824 C221.847,508.321 220.042,462.27 193.158,417.326 C168.612,376.291 141.18,370.459 111.507,347.724 C95.572,335.514 80.736,310.136 63.539,303.501 C41.085,294.838 21.564,318.811 1.322,326.569 C-14.397,332.593 -31.043,329.38 -47.06,324.414" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M361.853,591.478 C362.38,591.8 365.382,591.371 364.682,593.361 C361.081,603.605 344.465,600.255 338.231,599.088 C303.844,592.654 264.697,580.053 239.721,534.792 C220.227,499.464 217.389,454.946 189.326,410.026 C163.705,369.014 135.956,361.781 105.578,338.116 C89.264,325.408 73.765,300.063 56.304,292.657 C33.505,282.986 14.382,304.962 -5.825,311.257 C-21.518,316.146 -38.348,312.072 -54.585,306.344" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M185.033,763.758 C185.404,764.166 192.63,763.596 186.679,766.146 C156.043,779.273 128.975,775.103 118.615,773.654 C61.47,765.663 8.327,749.818 58.46,692.105 C97.591,647.058 192.189,590.142 235.118,532.887 C274.313,480.612 232.807,471.554 222.92,441.498 C217.611,425.358 242.529,393.051 222.857,383.7 C197.172,371.489 107.697,399.727 51.367,407.918 C7.62,414.279 -18.259,409.185 -39.17,401.972" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M192.38,763.716 C192.752,764.134 200.006,763.603 194.032,766.163 C163.278,779.339 136.108,774.904 125.71,773.356 C68.351,764.821 15.011,748.303 65.345,689.794 C104.633,644.125 199.6,586.73 242.704,528.638 C282.058,475.6 240.397,466.082 230.479,435.368 C225.153,418.873 250.172,386.099 230.428,376.43 C204.649,363.805 114.83,391.993 58.285,399.962 C14.372,406.152 -11.604,400.782 -32.592,393.286" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M193.572,764.514 C193.937,764.943 201.052,764.505 195.191,767.016 C165.022,779.943 138.376,775.068 128.178,773.354 C71.924,763.903 19.615,746.418 69.008,687.819 C107.562,642.079 200.731,585.232 243.032,526.955 C281.653,473.747 240.797,463.519 231.083,432.251 C225.867,415.458 250.419,382.59 231.059,372.514 C205.78,359.359 117.674,386.675 62.211,393.964 C19.138,399.625 -6.336,393.82 -26.918,385.929" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M193.571,764.586 C193.936,765.002 201.052,764.505 195.191,767.016 C165.022,779.943 138.371,775.439 128.171,773.865 C71.906,765.178 19.586,748.602 68.967,690.856 C107.512,645.783 200.676,589.321 242.965,531.959 C281.575,479.587 240.71,469.992 230.985,439.531 C225.763,423.172 250.309,390.813 230.943,381.156 C205.657,368.548 117.547,396.101 62.08,403.766 C19.003,409.718 -6.477,404.295 -27.064,396.785" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M195.263,763.736 C195.63,764.174 202.798,763.757 196.893,766.287 C166.499,779.31 139.657,774.247 129.384,772.464 C72.715,762.63 20.022,744.643 69.787,685.259 C108.632,638.907 202.497,581.478 245.118,522.393 C284.032,468.448 242.875,457.885 233.093,426.054 C227.84,408.959 252.578,375.638 233.076,365.316 C207.612,351.84 118.851,379.262 62.977,386.452 C19.584,392.036 -6.077,386.032 -26.811,377.927" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M195.262,763.832 C195.629,764.252 202.798,763.757 196.893,766.287 C166.499,779.31 139.65,774.737 129.375,773.137 C72.692,764.311 19.983,747.523 69.734,689.264 C108.566,643.79 202.425,586.871 245.03,528.992 C283.928,476.149 242.76,466.42 232.964,435.654 C227.703,419.131 252.432,386.481 232.922,376.712 C207.449,363.956 118.684,391.692 62.803,399.377 C19.406,405.346 -6.263,399.844 -27.003,392.241" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M185.265,770.374 C185.637,770.792 192.891,770.26 186.916,772.82 C156.162,785.997 128.993,781.562 118.595,780.014 C61.235,771.479 7.895,754.961 58.229,696.452 C97.517,650.783 192.485,593.388 235.588,535.296 C274.942,482.258 233.281,472.74 223.364,442.025 C218.038,425.531 243.056,392.757 223.313,383.088 C197.534,370.463 107.715,398.65 51.17,406.62 C7.256,412.81 -18.719,407.44 -39.707,399.943" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M195.908,765.727 C196.258,766.171 203.102,765.885 197.463,768.301 C168.437,780.738 142.813,775.2 133.005,773.235 C78.907,762.394 28.609,743.494 76.156,685.167 C113.269,639.639 202.918,584.065 243.644,525.906 C280.827,472.806 241.539,461.522 232.218,429.593 C227.212,412.446 250.85,379.66 232.236,369.01 C207.933,355.104 123.173,380.841 69.824,386.996 C28.392,391.776 3.895,385.32 -15.895,376.859" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M191.814,763.455 C192.196,763.873 199.636,763.275 193.509,765.9 C161.969,779.414 134.101,775.193 123.435,773.729 C64.601,765.653 9.887,749.517 61.497,690.269 C101.782,644.023 199.171,585.503 243.365,526.737 C283.715,473.083 240.982,463.882 230.801,433.098 C225.334,416.565 250.986,383.405 230.733,373.86 C204.288,361.396 112.172,390.514 54.178,399.02 C9.138,405.626 -17.505,400.457 -39.035,393.106" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M199.379,763.398 C199.762,763.826 207.23,763.266 201.08,765.902 C169.418,779.468 141.445,774.975 130.74,773.41 C71.685,764.776 16.768,747.952 68.586,687.887 C109.032,641.003 206.801,581.991 251.175,522.368 C291.688,467.931 248.796,458.259 238.583,426.799 C233.099,409.904 258.855,376.265 238.527,366.394 C211.985,353.506 119.515,382.573 61.3,390.853 C16.09,397.284 -10.653,391.832 -32.263,384.19" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M200.606,764.213 C200.982,764.652 208.307,764.189 202.273,766.774 C171.213,780.083 143.78,775.139 133.281,773.403 C75.364,763.828 21.509,746.01 72.358,685.855 C112.048,638.901 207.965,580.454 251.513,520.642 C291.272,466.033 249.207,455.631 239.205,423.603 C233.833,406.403 259.109,372.668 239.176,362.38 C213.15,348.947 122.442,377.118 65.342,384.698 C20.996,390.585 -5.231,384.686 -26.422,376.639" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M200.605,764.287 C200.981,764.713 208.307,764.189 202.273,766.774 C171.213,780.083 143.775,775.52 133.274,773.926 C75.346,765.135 21.479,748.249 72.316,688.968 C111.997,642.697 207.909,584.645 251.444,525.771 C291.191,472.019 249.118,462.265 239.105,431.065 C233.727,414.309 258.996,381.096 239.057,371.237 C213.023,358.365 122.313,386.779 65.207,394.744 C20.858,400.93 -5.375,395.422 -26.571,387.765" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M202.347,763.412 C202.725,763.86 210.104,763.419 204.026,766.023 C172.734,779.431 145.099,774.295 134.522,772.488 C76.178,762.521 21.927,744.19 73.16,683.23 C113.149,635.647 209.783,576.603 253.66,515.962 C293.72,460.597 251.346,449.853 241.273,417.248 C235.864,399.738 261.331,365.538 241.252,354.999 C215.035,341.237 123.653,369.518 66.129,376.997 C21.455,382.806 -4.965,376.703 -26.312,368.438" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M202.345,763.511 C202.724,763.941 210.104,763.419 204.026,766.023 C172.735,779.431 145.092,774.797 134.513,773.178 C76.155,764.244 21.887,747.143 73.104,687.335 C113.082,640.652 209.709,582.13 253.57,522.726 C293.614,468.49 251.229,458.601 241.141,427.087 C235.724,410.164 261.182,376.652 241.095,366.678 C214.868,353.655 123.482,382.258 65.951,390.245 C21.272,396.448 -5.155,390.86 -26.509,383.109" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M202.458,763.198 C202.842,763.627 210.31,763.067 204.159,765.702 C172.497,779.268 144.525,774.776 133.819,773.211 C74.765,764.576 19.848,747.752 71.665,687.688 C112.111,640.804 209.881,581.792 254.254,522.168 C294.767,467.732 251.875,458.06 241.663,426.6 C236.178,409.705 261.934,376.066 241.606,366.195 C215.065,353.307 122.595,382.374 64.38,390.654 C19.169,397.085 -7.574,391.632 -29.183,383.991" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M172.274,793.896 C172.607,794.383 180.14,794.147 173.682,796.721 C140.434,809.974 112.985,803.653 102.462,801.402 C44.413,788.986 -8.54,767.817 50.297,704.698 C96.223,655.43 200.891,595.772 252.232,532.762 C299.106,475.233 257.343,462.494 250.805,427.557 C247.294,408.795 276.982,373.281 257.806,361.459 C232.769,346.023 136.899,373.18 77.715,379.315 C31.751,384.079 5.644,376.769 -15.073,367.321" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-102.801,319.406 C-103.671,319.263 -111.267,320.939 -107.302,318.468 C-86.89,305.748 -51.504,302.772 -38.123,301.556 C35.689,294.846 113.59,293.016 120.06,332.816 C125.11,363.882 80.111,411.745 94.274,449.932 C107.205,484.798 164.397,481.63 208.72,495.494 C232.523,502.939 239.724,524.619 272.396,525.768 C315.056,527.268 385.784,495.411 440.862,480.377 C483.636,468.702 518.686,466.437 550.415,466.229" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M-111.114,320.829 C-111.998,320.681 -119.666,322.343 -115.686,319.862 C-95.195,307.093 -59.401,304.236 -45.868,303.064 C28.787,296.599 107.653,295.084 114.77,335.34 C120.325,366.762 75.434,414.946 90.319,453.605 C103.908,488.901 161.783,485.944 206.866,500.148 C231.077,507.775 238.678,529.719 271.782,531.021 C315.005,532.721 386.18,500.824 441.744,485.865 C484.897,474.248 520.36,472.109 552.49,472.035" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-113.348,320.638 C-114.235,320.483 -121.849,322.069 -117.944,319.636 C-97.843,307.109 -62.162,304.583 -48.674,303.536 C25.732,297.762 104.489,296.95 112.775,337.074 C119.243,368.393 75.796,415.946 91.798,454.55 C106.407,489.795 164.148,487.363 209.609,501.896 C234.024,509.7 242.258,531.605 275.373,533.193 C318.611,535.266 388.796,504.152 443.879,489.757 C486.657,478.577 522.028,476.761 554.13,476.971" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-113.427,320.6 C-114.3,320.452 -121.849,322.069 -117.944,319.636 C-97.843,307.109 -62.565,304.387 -49.227,303.267 C24.348,297.09 102.118,295.799 109.479,335.474 C115.224,366.443 71.358,413.793 86.367,451.915 C100.069,486.72 157.124,483.956 201.709,498.063 C225.653,505.639 233.335,527.275 265.996,528.643 C308.64,530.428 378.567,499.189 433.242,484.595 C475.703,473.262 510.661,471.246 542.349,471.255" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-114.416,321.368 C-115.316,321.209 -123.012,322.794 -119.078,320.343 C-98.828,307.723 -62.716,305.257 -49.066,304.232 C26.233,298.58 105.98,297.957 114.706,338.564 C121.517,370.259 77.917,418.25 94.444,457.338 C109.533,493.025 167.984,490.712 214.142,505.526 C238.93,513.482 247.452,535.66 281,537.349 C324.802,539.556 395.615,508.261 451.275,493.839 C494.5,482.639 530.305,480.892 562.814,481.185" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-114.52,321.318 C-115.401,321.168 -123.012,322.794 -119.078,320.342 C-98.828,307.723 -63.248,304.999 -49.796,303.879 C24.409,297.695 102.854,296.441 110.359,336.455 C116.218,367.688 72.066,415.411 87.283,453.863 C101.176,488.97 158.722,486.218 203.725,500.472 C227.893,508.126 235.686,529.95 268.634,531.349 C311.654,533.176 382.126,501.716 437.248,487.033 C480.057,475.63 515.316,473.619 547.28,473.648" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-110.345,315.984 C-111.229,315.835 -118.897,317.497 -114.916,315.017 C-94.426,302.248 -58.632,299.391 -45.098,298.219 C29.556,291.754 108.422,290.238 115.539,330.495 C121.094,361.917 76.203,410.1 91.088,448.76 C104.678,484.056 162.552,481.099 207.635,495.302 C231.847,502.93 239.447,524.874 272.551,526.176 C315.775,527.875 386.949,495.978 442.514,481.02 C485.666,469.403 521.129,467.263 553.259,467.189" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-117.341,320.448 C-118.228,320.282 -125.7,321.737 -121.943,319.396 C-102.604,307.344 -67.355,305.36 -54.034,304.519 C19.447,299.884 97.474,300.197 107.562,339.827 C115.436,370.76 74.591,416.975 92.256,455.216 C108.383,490.131 165.502,488.552 211.243,503.505 C235.807,511.535 244.993,533.223 277.891,535.254 C320.845,537.906 388.959,508.258 442.885,494.86 C484.764,484.454 519.741,483.166 551.569,483.826" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-110.183,320.858 C-111.077,320.712 -118.884,322.444 -114.802,319.9 C-93.788,306.805 -57.436,303.702 -43.69,302.435 C32.138,295.449 112.145,293.47 118.624,334.358 C123.681,366.272 77.272,415.507 91.658,454.728 C104.793,490.536 163.538,487.209 208.998,501.399 C233.411,509.019 240.716,531.286 274.264,532.426 C318.067,533.914 390.831,501.091 447.455,485.575 C491.43,473.526 527.433,471.154 560.018,470.9" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.044"/>
      <path d="M-118.727,322.33 C-119.634,322.179 -127.515,323.896 -123.417,321.343 C-102.322,308.197 -65.552,305.217 -51.649,303.995 C25.043,297.259 106.039,295.603 113.181,336.957 C118.755,369.237 72.455,418.802 87.581,458.506 C101.391,494.756 160.836,491.645 207.075,506.183 C231.907,513.99 239.621,536.528 273.611,537.824 C317.992,539.516 391.215,506.652 448.338,491.213 C492.701,479.222 529.129,476.98 562.124,476.863" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-121.02,322.137 C-121.931,321.979 -129.756,323.618 -125.736,321.113 C-105.042,308.217 -68.389,305.576 -54.533,304.484 C21.9,298.457 102.782,297.524 111.125,338.741 C117.638,370.914 72.823,419.83 89.096,459.475 C103.953,495.671 163.259,493.101 209.884,507.976 C234.924,515.964 243.29,538.461 277.29,540.051 C321.684,542.127 393.888,510.069 450.514,495.209 C494.491,483.668 530.823,481.758 563.787,481.934" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-121.101,322.098 C-121.997,321.947 -129.756,323.618 -125.736,321.113 C-105.042,308.217 -68.802,305.375 -55.1,304.208 C20.482,297.769 100.353,296.345 107.747,337.102 C113.519,368.915 68.275,417.623 83.53,456.774 C97.457,492.519 156.06,489.608 201.788,504.047 C226.345,511.801 234.144,534.024 267.679,535.387 C311.464,537.168 383.404,504.982 439.612,489.919 C483.264,478.221 519.172,476.105 551.714,476.075" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-122.12,322.889 C-123.043,322.726 -130.954,324.364 -126.903,321.841 C-106.055,308.848 -68.961,306.27 -54.939,305.2 C22.411,299.297 104.309,298.558 113.102,340.27 C119.966,372.829 74.992,422.194 91.802,462.337 C107.15,498.987 167.184,496.536 214.524,511.7 C239.947,519.844 248.607,542.621 283.051,544.315 C328.024,546.527 400.874,514.283 458.092,499.394 C502.529,487.832 539.305,485.992 572.688,486.253" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-122.227,322.837 C-123.13,322.684 -130.954,324.364 -126.904,321.84 C-106.055,308.848 -69.506,306.006 -55.687,304.837 C20.542,298.39 101.105,297.003 108.647,338.108 C114.534,370.193 68.995,419.284 84.462,458.775 C98.584,494.83 157.692,491.93 203.847,506.52 C228.634,514.354 236.547,536.769 270.377,538.165 C314.548,539.988 387.048,507.574 443.716,492.419 C487.725,480.649 523.942,478.538 556.767,478.527" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-117.917,317.351 C-118.824,317.2 -126.705,318.917 -122.607,316.363 C-101.512,303.217 -64.742,300.237 -50.839,299.016 C25.853,292.28 106.849,290.623 113.991,331.978 C119.565,364.257 73.265,413.823 88.391,453.526 C102.201,489.776 161.646,486.666 207.885,501.203 C232.717,509.01 240.431,531.548 274.421,532.844 C318.802,534.537 392.025,501.673 449.149,486.233 C493.511,474.243 529.939,472 562.934,471.884" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M-125.12,321.947 C-126.03,321.777 -133.709,323.281 -129.842,320.871 C-109.932,308.464 -73.725,306.381 -60.042,305.501 C15.438,300.646 95.565,300.869 105.76,341.576 C113.718,373.35 71.582,420.888 89.564,460.159 C105.981,496.014 164.645,494.32 211.555,509.627 C236.748,517.848 246.091,540.12 279.867,542.166 C323.967,544.837 394.039,514.288 449.473,500.453 C492.523,489.71 528.446,488.342 561.13,488.981" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.044"/>
      <path d="M489.664,643.301 C404.479,622.881 209.798,482.314 54.833,329.335" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linejoin="round" opacity="0.044"/>
      <path d="M835.355,432.218 C725.529,494.552 299.205,485.122 -116.868,411.156" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linejoin="round" opacity="0.044"/>
      <g opacity="0.113">
        <path d="M-76.087,-56 L682.334,-56 L682.334,537 L-76.087,537 z" fill="url(#Gradient_1)"/>
        <path d="M-76.087,-56 L682.334,-56 L682.334,537 L-76.087,537 z" fill-opacity="0" stroke="#93B5C8" stroke-width="1" stroke-linejoin="round"/>
      </g>
      <g opacity="0.113">
        <path d="M721,450 C252.386,450 -127.5,315.909 -127.5,150.5" fill="url(#Gradient_2)"/>
        <path d="M721,450 C252.386,450 -127.5,315.909 -127.5,150.5" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round"/>
      </g>
      <path d="M517,472.5 C516.448,472.5 516,450.338 516,423" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round" opacity="0.113"/>
      <path d="M453,377 C453,335.855 481.206,302.5 516,302.5" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round" opacity="0.113"/>
      <g opacity="0.113">
        <path d="M-212.439,340.477 C279.1,277.334 695.092,362.535 716.704,530.779" fill="url(#Gradient_3)"/>
        <path d="M-212.439,340.477 C279.1,277.334 695.092,362.535 716.704,530.779" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round"/>
      </g>
      <g opacity="0.113">
        <path d="M18.535,474.441 C524.674,252.292 964.742,140.011 1001.453,223.654" fill="url(#Gradient_4)"/>
        <path d="M18.535,474.441 C524.674,252.292 964.742,140.011 1001.453,223.654" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round"/>
      </g>
      <g opacity="0.113">
        <path d="M365.664,717.81 C95.299,444.78 -33.411,133.865 78.182,23.361" fill="url(#Gradient_5)"/>
        <path d="M365.664,717.81 C95.299,444.78 -33.411,133.865 78.182,23.361" fill-opacity="0" stroke="#3D607A" stroke-width="1" stroke-opacity="0.111" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
`;
}