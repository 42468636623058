import css from './Keypad.module.css';
import React, { useEffect, useState } from 'react';
import keypads from './img/keypads.jpg';
import imageCompression from 'browser-image-compression';
import { MdClose } from "react-icons/md";
const KeypadImage = ({ 
    setStep, 
    previewUrl, 
    setPreviewUrl, 
    info,
    setInfo,
    backButtonDir,
    nextButtonDir,
    header,
    paragraph,
}) => {

    const [isFormValid, setIsFormValid] = useState(false);

    const handleFileChange = async (event) => {
        const files = event.target.files;

        // Limit to 4 images
        const totalImages = previewUrl.length + files.length;
        if (totalImages > 4) {
            alert('You can only upload up to 4 images.');
            return;
        }

        const newPreviewUrls = [];
        const newImages = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file) {
                const options = {
                    maxSizeMB: 0.05, // Maximum size in MB (adjust as needed)
                    maxWidthOrHeight: 800, // Max width or height in pixels
                    useWebWorker: true, // Use web workers for better performance
                };

                try {
                    const compressedFile = await imageCompression(file, options);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64Image = reader.result.split('base64,')[1];
                        newPreviewUrls.push(reader.result);
                        newImages.push(base64Image);

                        if (newPreviewUrls.length === files.length) {
                            const updatedPreviewUrls = [...previewUrl, ...newPreviewUrls];
                            setPreviewUrl(updatedPreviewUrls);

                            // Add images to info
                            const updatedInfo = { ...info };
                            const imageKeys = ['image1', 'image2', 'image3', 'image4'];

                            updatedPreviewUrls.forEach((_, index) => {
                                updatedInfo[imageKeys[index]] = updatedPreviewUrls[index] || '';
                            });

                            setInfo(updatedInfo);
                            setIsFormValid(true);
                        }
                    };
                    reader.readAsDataURL(compressedFile);
                } catch (error) {
                    console.error('Image compression error:', error);
                }
            }
        }
    };

    const handleDeleteImage = (index) => {
        const updatedPreviewUrls = previewUrl.filter((_, i) => i !== index);
        setPreviewUrl(updatedPreviewUrls);

        // Update info object
        const updatedInfo = { ...info };
        const imageKeys = ['image1', 'image2', 'image3', 'image4'];
        updatedPreviewUrls.forEach((url, i) => {
            updatedInfo[imageKeys[i]] = url.split('base64,')[1];
        });
        // Clear any leftover images
        for (let i = updatedPreviewUrls.length; i < 4; i++) {
            updatedInfo[imageKeys[i]] = '';
        }

        setInfo(updatedInfo);
        setIsFormValid(updatedPreviewUrls.length > 0);
    };

    useEffect(() => {
        if (info.image1 || info.image2 || info.image3 || info.image4) {
            setIsFormValid(true);
        }
    }, [info]);

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>{header}</h2>
                <img className={css.keypadsExampleImage} src={keypads} alt="Keypads Example" />
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>{paragraph}</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <label htmlFor="keypadImage" className={css.buttonActive} style={{ width: '140px' }}>Upload Images</label>
                        <input
                            id="keypadImage"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            multiple
                            disabled={previewUrl.length >= 4} // Disable input if 4 images are already uploaded
                        />
                        <p className={css.counter}>{`Images uploaded: ${previewUrl.length}/4`}</p> {/* Counter */}
                        {previewUrl.length > 0 && (
                            <div className={css.imageBox}>
                                {previewUrl.map((url, index) => (
                                    <div key={index} className={css.imageContainer}>
                                        <div className={css.deleteWrapper}>
                                            <button
                                                className={css.deleteButton}
                                                onClick={() => handleDeleteImage(index)}
                                            >
                                                <MdClose className={css.x}/>
                                            </button>
                                        </div>
                                        <img
                                            src={url}
                                            alt={`Image Preview ${index + 1}`}
                                            className={css.imagePreview}
                                        />
                                        
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <button
                    className={css.buttonActive}
                    onClick={() => setStep(backButtonDir)}
                >
                    Back
                </button>
                <button
                    className={isFormValid ? css.buttonActive : css.buttonInActive}
                    onClick={() => setStep(nextButtonDir)}
                    disabled={!isFormValid}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default KeypadImage;
