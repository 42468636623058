import q2 from './WifiCellimg/q2.jpg';
import q3 from './WifiCellimg/q3.jpg';
import q4 from './WifiCellimg/q4.jpg';
import { getPrice } from '../../../../../../Prices';
const wifiCell =()=>{
    const device = {
        title: 'Wifi & Cellular Monitoring',
        model:'PROLTE & PROWIFI',
        sku:['PROLTE'],
        modelViewImage:q2,
        image:[q2,q3,q4],
        shortDescription: 'Reliable cellular and Wi-Fi communication for security systems.',
        isService: true,//this seperate hardware from services 
        price: getPrice('PROLTE_PROWIFI'),
        monthly: getPrice('WIFI_CELL_MONI'),
        //add group like group:'cloud', to triger checker function 
        checkListKeyword:['Wifi','Cellular'],
        shortDescription: 'Reliable cellular and Wi-Fi communication for security systems.',
        checkListKeyword: ['Cellular', 'Wifi'],
        infoHeader: "Cellular and Wi-Fi Communicators",
        spec: [
            { row1: 'Model:', row2: 'PROLTE / PROWIFI' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Cellular / Wi-Fi' },
            { row1: 'Mounting:', row2: 'Build-in the panel' },
            { row1: 'Power Supply:', row2: '12V DC' },
            { row1: 'Network Type:', row2: 'LTE / 2.4GHz Wi-Fi' },
            { row1: 'Coverage:', row2: 'Nationwide' },
            { row1: 'Dimensions:', row2: '5.5 x 3.5 x 1.2 inches' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'Reliable Communication for Your Security System',
                text: 'The PROLTE and PROWIFI communicators provide reliable and secure communication for your security system. Whether you need cellular, Wi-Fi, or both, these devices ensure your system stays connected.',
                section: [
                    { title: "Dual Connectivity Options" },
                    { text: "With both cellular (PROLTE) and Wi-Fi (PROWIFI) options, you can choose the best communication method for your security system, or use both for enhanced reliability and redundancy." },
                    
                    { title: "Nationwide Coverage" },
                    { text: "The PROLTE provides nationwide LTE coverage, ensuring that your security system remains connected even in areas with poor Wi-Fi signal." },
                    
                    { title: "High-Speed Wi-Fi" },
                    { text: "The PROWIFI supports both 2.4GHz and 5GHz Wi-Fi networks, providing high-speed and reliable communication for your security system." },
                    
                    { title: "Easy Integration" },
                    { text: "Both communicators are designed for seamless integration with compatible security systems. This allows for easy installation and configuration, ensuring that your security setup remains robust and reliable." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With tamper-resistant designs, both the PROLTE and PROWIFI provide added security against tampering or interference. Their durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Power Supply" },
                    { text: "Both devices operate on a 12V DC power supply, ensuring continuous operation and reliability. Their low power consumption makes them efficient additions to your security system." },
                    
                    { title: "Compliance and Certification" },
                    { text: "Both the PROLTE and PROWIFI communicators meet industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How do they work?',
                text: 'The PROLTE and PROWIFI communicators operate using advanced cellular and Wi-Fi technology to provide reliable and secure communication for security systems.',
                section: [
                    { title: "Cellular Communication (PROLTE)" },
                    { text: "The PROLTE connects to the cellular network, allowing the security system to communicate with monitoring services and emergency contacts. This cellular communication ensures that alerts and notifications are transmitted securely and promptly." },
                    
                    { title: "Wi-Fi Communication (PROWIFI)" },
                    { text: "The PROWIFI connects to your Wi-Fi network, allowing the security system to communicate with monitoring services and emergency contacts. This Wi-Fi communication provides high-speed and reliable connectivity." },
                    
                    { title: "Dual Communication Path" },
                    { text: "Using both the PROLTE and PROWIFI together provides a dual communication path, enhancing reliability and ensuring that your security system remains connected even if one communication method fails." },
                    
                    { title: "Tamper Detection" },
                    { text: "Both devices feature tamper detection, alerting the system if they are tampered with or disconnected. This ensures that any attempts to disable the communicators are detected and reported." },
                    
                    { title: "Continuous Monitoring" },
                    { text: "The communicators continuously monitor the status of the security system, ensuring that all signals are transmitted reliably. This continuous monitoring enhances the responsiveness and effectiveness of the security setup." },
                    
                    { title: "Backup Communication" },
                    { text: "In the event of a power outage or network failure, the PROLTE provides backup communication for the security system. This ensures that alerts and notifications are still transmitted, maintaining the integrity of the security setup." },
                ]
            },
        ]
    }
    return device;
}
export default wifiCell;