import React from 'react';
import css from "./FlexCardWrapper.module.css";

export default function FlexCardWrapper({i, children}){
    
    return(
        <div key={i} className={css.sectionWrapper}>
            {children}
        </div>  
    )
}