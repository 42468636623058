import React from "react";
import css from './ContactFormBizInfo.module.css';
import SocialLinks from "../SocialLinks/SocialLinks"
import { FaPhone } from "react-icons/fa6";
import { MdAlternateEmail } from "react-icons/md";
import FooterData from "../../../Db/FooterData";

const ContactFormBizInfo = ()=>{
    return(
        <div className={css.wrapper}>
            <div className={css.container}>
                <div className={css.box}>
                    <div>
                        <ul>
                            <li><h3>Customer Service</h3></li>
                            <li><a href="tel:+4037031273"><span><FaPhone /></span>(403) 703-1273</a></li>
                            <li><a href="tel:+18448804736"><span><FaPhone /></span>1(844) 880-4736</a></li>
                            <li><a href="mailto:info@securepol.com"><span><MdAlternateEmail /></span>info@securepol.com</a></li>
                        </ul>
                    </div>
                </div>
                <div className={css.box}>
                    <div>
                        <ul>
                            <li><h3>Securepol Incorporated</h3></li>
                            <li>#300 400 5th Ave SW</li>
                            <li>Calgary, AB T2P 0L6</li>
                            <li>Lic#: BL184235</li>
                            <li>CPS#: 00427</li>
                            <li>info@securepol.com</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={css.socialContainer}>
                <div className={css.box}>
                    {FooterData().footer.map((link,i)=>(
                        <SocialLinks key={i} obj={link}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ContactFormBizInfo;