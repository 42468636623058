import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  useLocation,
} from "react-router-dom";
import Navigation from "./webComponents/common/nav/Navigation/Navigation";
import { Helmet } from 'react-helmet';

// Import pages directly without lazy loading
import Home from '../src/pages/Home/Home';
import Packages from '../src/pages/Packages/Packages';
import Transfer from '../src/pages/Transfer/Transfer';
import Contact from '../src/pages/Contact/Contact';
import AIECMS from './pages/AIECMS/AIECMS';

// Initialize GA4 with your Measurement ID, only in production
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-2L0E1Q72XC', {
    gaOptions: {
      siteSpeedSampleRate: 100
    },
  });
}

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // Send a pageview to GA4 whenever the route changes
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return null;
};

export default function App() {
  return (
    <Router>
      <TrackPageView />
      <Helmet>
        <title>Alarm Systems 40% OFF | Build, Price, Order Online | We Install</title>
        <meta name="description" content="AI-enhanced alarm monitoring and smart security installations to protect your home and business." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://www.securepol.com",
              "potentialAction": [
                {
                  "@type": "SearchAction",
                  "target": "https://www.securepol.com/packages",
                  "query-input": "required name=packages"
                },
                {
                  "@type": "SearchAction",
                  "target": "https://www.securepol.com/transfer",
                  "query-input": "required name=transfer"
                },
                {
                  "@type": "SearchAction",
                  "target": "https://www.securepol.com/contact",
                  "query-input": "required name=contact"
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aiecms" element={<AIECMS />} />
        <Route path="*" element={<Home />} /> {/* Redirect all unknown routes to Home */}
      </Routes>
    </Router>
  );
}
