import React from 'react';
const SvgBackground=(svg)=>{
    const background = {
        backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    
    };
    return background;
}
export default SvgBackground