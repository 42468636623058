import SvgGrayWaveWithIcons from "../assets/svg/backgrounds/svgGrayWaveWithIcons";
import SvgProPanel from "../assets/svg/svgImages/svgProPanel";
import Station from "../assets/images/web/securepolStationC.png";

export default function TransferData(){
    const data={     
        cards:[
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Monitoring for Existing System`,
                    content: "Our monthly alarm monitoring rates start as low as:<br/><br/><div style=\"display: flex; justify-content:center; text-align: left;\"><div style=\" text-align: left;\">$14.99 for phone-based*<br/> $17.99 for internet-based*<br/>$21.99 for cellular-based*</div></div><br/><br/><span style=\"font-size: 1em;\">*The final cost varies based on the features and communication options.</span></br></br>Tell Us About Your System, and We’ll See How We Can Help.",
                    links: [{label:"Get Started", to:"/packages?v=transfer"}],
                    svgBackground: SvgGrayWaveWithIcons(),
                    svgImage:'',
                },
            ],
            
            [
                {
                    settings: {bordered:true, darkBackground:false,},
                    header: `Upgrade to Next-Gen`,
                    content: "We Got All the Cool Stuff.<br/>Touchscreens, Cameras, Smart Home Solution<br/>You Choose, We Install.<br/>",
                    links: [{label:"Build Your Package", to:"/packages"}],
                    //svgBackground: SvgGrayFullTopWhiteStripe(),
                    svgImage: SvgProPanel(),
                }
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Introducing AIECMS®<br/>AI-Enhanced Central Monitoring Station<br/>`,
                    content: "Experience the future of home and business security with AI-enhanced security alarm monitoring services.<br/><br/>Predictive Threat Analysis using trainded AI-models,<br/>where the system dynamically adjusts security protocols<br/>based on live data and threat levels.<br/><br/>Automated Emergency Services Coordination,<br/>ensuring rapid response time by integrating with local emergency services.<br/><br/>",
                    links: [{label:"Learn more", to:"/aiecms"}],
                    imgBackground:  Station,
                }
            ]
           
        ],
    }
    return data;
}
