import React from 'react';
import css from './DeviceList.module.css';
import { LuDot } from "react-icons/lu";
import { BsDot } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";



const DeviceList = ({basket, project}) => {
    
    //count items with same category to display qty
    function countOccurrences(arr) {
        return arr.reduce((acc, val) => {
            acc[val.cat] = (acc[val.cat] || 0) + 1;
            return acc;
        }, {});
    }
    const occurrences = countOccurrences(basket);
   
    const sortedData = project.displayOrder.map(item => ({
        cat: item,
        count: occurrences[item] || 0,
    }));

    // Sorting the sortedData array based on the displayOrder
    sortedData.sort((a, b) => project.displayOrder.indexOf(a.cat) - project.displayOrder.indexOf(b.cat));
    // Rendering the sorted list items
    const listItems = sortedData.map((item, index) => {
        let noQty=false;
        let obj = basket.find((obj)=> obj.cat === item.cat)
        if(obj && obj.displayNoQty!== undefined && obj.displayNoQty)
        {
            noQty = true
        }

        if(item.count)
            {
            return(
                <div key={index}>
                    {noQty ?
                    (
                        <div className={css.noQty} key={index}>  <span className={css.dotSpan}>-</span> {item.cat}</div>
                    
                    ):(
                        <div className={css.qty} key={index}>  {item.count} {item.cat}{item.count > 1 ? 's' : ''}</div>
                    
                    )}
                </div>
                )    
            }
        }
    )

    return (
        <div className={css.equipmentList}>
            <div>
                {listItems}
            </div>
        </div>
    
    );
}
export default DeviceList;