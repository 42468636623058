/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBasket = /* GraphQL */ `
  query GetBasket($id: ID!) {
    getBasket(id: $id) {
      id
      items
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBaskets = /* GraphQL */ `
  query ListBaskets(
    $filter: ModelBasketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBaskets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        items
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
