import css from './PackageConfirmation.module.css';
import React, { useState } from 'react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const PackageConfirmation = ({ project, step, setStep, nextStep, prevStep, basket, contractDuration = 1, upfront = 0, monthly = 0 }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const TableHeader = ({ label1, label2 }) => (
        <div className={css.tableRow}>
            <p className={css.tableHeader}>{label1}</p>
            {label2 && <p className={css.tableHeader}>{label2}</p>}
        </div>
    );

    const TableRow = ({ item, noQty }) => (
        item.count > 0 && (
            <div className={css.tableRow}>
                <p className={css.tableCell}>{noQty ? "-" : item.count}</p>
                <p className={css.tableCell}>{item.cat}{item.count > 1 ? 's' : ''}</p>
            </div>
        )
    );

    const countOccurrences = (arr) => {
        return arr.reduce((acc, val) => {
            if (!('isService' in val)) {
                acc[val.cat] = (acc[val.cat] || 0) + 1;
            }
            return acc;
        }, {});
    };

    const occurrences = countOccurrences(basket);

    const sortedData = project.displayOrder.map(item => ({
        cat: item,
        count: occurrences[item] || 0,
    }));

    sortedData.sort((a, b) => project.displayOrder.indexOf(a.cat) - project.displayOrder.indexOf(b.cat));

    const DeviceListTable = ({ sortedData, basket }) => (
        <div className={css.table}>
            <TableHeader label1={'Qty'} label2={'Description'} />
            {sortedData.map((item, index) => {
                const obj = basket.find(obj => (obj.cat === item.cat) && (!obj.isService));
                const details = basket.filter(obj => (obj.cat === item.cat) && (!obj.isService));
                const noQty = obj && obj.displayNoQty !== undefined && obj.displayNoQty;
                return (
                    <div key={index}>
                        <TableRow item={item} noQty={noQty} />
                        <div className={css.itemDetailsBox}>
                            {obj && (
                                <div className={css.itemDetailsHeaderBox}>
                                    <div className={css.itemDetailsHeaderLocation}>
                                        <p className={css.pMarg}>Location</p>
                                    </div>
                                    <div className={css.itemDetailsHeaderModel}>
                                        <p className={css.pMarg}>Model</p>
                                    </div>
                                </div>
                            )}
                            <div className={css.itemDetailsWrapper}>
                                {details.map((obj, i) => (
                                    <div key={i} className={css.itemDetailsRow}>
                                        <div className={css.itemDetailsOrderLabel}>
                                            <p className={css.pMarg}>{i + 1 + '. ' + obj.loc}</p>
                                        </div>
                                        <div className={css.itemDetailsModelLabel}>
                                            <p className={css.pMarg}>{obj.model}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Here's Your Package in Glance!</h2>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.item}>
                            <DeviceListTable sortedData={sortedData} basket={basket} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={css.buttonBox}>
                <div className={css.checkboxBox} onClick={handleCheckboxChange}>
                    <div className={css.checkbox}>
                        {isChecked ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}
                    </div>
                    <div className={css.term}>
                        <p>I confirm and accept the selected equipment.</p>
                    </div>
                </div>
                <button
                    className={css.buttonActive}
                    onClick={() => setStep(1)}
                >
                    Back
                </button>
                <button
                    className={isChecked ? css.buttonActive : css.buttonInActive}
                    onClick={nextStep}
                    disabled={!isChecked}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PackageConfirmation;
