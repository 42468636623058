import React from 'react';
import css from "./RasterImageContainer.module.css";

export default function RasterImageContainer({image=[], settings}){

    return(
        <div>
            {image && (
                <div>
                    {image.map((img, i)=>(
                        <div key={i} className={css.imageContainer}>
                            <img className={css.image} src={img.src} alt={img.alt} />
                        </div>
                    ))}
                </div>
             )}
        </div> 
    )
}