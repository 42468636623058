import j1 from './PROSIXKEYFOBimg/j1.jpg';
import j2 from './PROSIXKEYFOBimg/j2.jpg';
import j3 from './PROSIXKEYFOBimg/j3.jpg';
import cr2450 from '../../../../Batteries/cr2450.jpg';
import { getPrice } from '../../../../../../../Prices';

const PROSIXFOB = () => {
    const device = {
        title: 'Key Fob',
        model: 'PROSIXFOB',
        sku: ['PROSIXFOB'],
        modelViewImage: j1,
        image: [j1,j2,j3,cr2450],
        shortDescription: 'Wireless key fob for easy control of your security system.',
        price: getPrice('PROSIXFOB'),
        group: 'key_fob',
        checkListKeyword: [],
        infoHeader: "Key Fob",
        spec: [
            { row1: 'Model:', row2: 'PROSIXFOB' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'Black' },
            { row1: 'Mounting:', row2: 'Portable' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR2032, 3V' },
            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Dimensions:', row2: '2.4 x 1.3 x 0.5 inches' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'Key Fob: Convenient Remote Control for Your Security System',
                text: 'The PROSIXFOB key fob provides convenient remote control for your security system. Designed for ease of use and portability, this key fob allows you to arm and disarm your system, trigger panic alerts, and control other functions with the press of a button.',
                section: [
                    { title: "Easy-to-Use Buttons" },
                    { text: "The PROSIXFOB features clearly labeled buttons for arming, disarming, and triggering panic alerts. The intuitive design allows for quick and easy operation, even in stressful situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The key fob connects to your security system wirelessly, ensuring reliable communication and easy setup. This wireless connection allows you to control your system from up to 300 feet away." },
                    
                    { title: "Portable Design" },
                    { text: "Designed to be portable, the PROSIXFOB can be attached to your keychain or carried in your pocket or bag. This ensures that you always have control of your security system within reach." },
                    
                    { title: "Panic Alert" },
                    { text: "In addition to arming and disarming your system, the key fob features a panic button that sends an immediate alert to your security system and monitoring service. This provides an extra layer of security and peace of mind." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXFOB operates on a long-lasting lithium battery, ensuring continuous operation. The battery indicator alerts you when battery replacement is needed." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXFOB key fob meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXFOB key fob operates using advanced wireless communication technology to provide convenient remote control of your security system.',
                section: [
                    { title: "Button Functions" },
                    { text: "The key fob features buttons for arming, disarming, and triggering panic alerts. Each button is clearly labeled and easy to press, allowing for quick operation." },
                    
                    { title: "Wireless Communication" },
                    { text: "The key fob connects wirelessly to the security system, ensuring reliable communication and easy setup. This wireless connection allows for remote control from up to 300 feet away." },
                    
                    { title: "Panic Alert" },
                    { text: "The panic button sends an immediate alert to the security system and monitoring service when pressed. This provides an extra layer of security in emergency situations." },
                    
                    { title: "Battery Operation" },
                    { text: "The key fob operates on a long-lasting lithium battery. The battery indicator alerts you when battery replacement is needed, ensuring continuous operation." },
                    
                    { title: "Portable and Convenient" },
                    { text: "Designed to be portable, the key fob can be easily carried with you, ensuring that you always have control of your security system within reach." },
                ]
            },

            {
                header: 'Where to keep it?',
                text: 'The placement of the PROSIXFOB key fob is crucial to its effectiveness. Here are some key guidelines for where to keep the key fob:',
                section: [
                    { title: "With Your Keys" },
                    { text: "Attach the key fob to your keychain for easy access. This ensures that you always have control of your security system when you need it." },
                    
                    { title: "In Your Pocket" },
                    { text: "Keep the key fob in your pocket for quick access. This is especially useful when you are on the go and need to arm or disarm your system quickly." },
                    
                    { title: "In Your Bag" },
                    { text: "Carry the key fob in your bag for convenient access while you are out. Ensure that it is stored in an easily reachable pocket or compartment." },
                    
                    { title: "Near Entry Points" },
                    { text: "Consider keeping an additional key fob near main entry points for quick access when entering or leaving the property. This allows for immediate arming or disarming of the system." },
                    
                    { title: "Accessible Locations" },
                    { text: "Ensure that the key fob is kept in accessible locations where it can be easily reached in an emergency. Avoid placing it in hidden or hard-to-reach areas." },
                ]
            },

            {
                header: 'Common issues and troubleshooting',
                text: 'Here are common issues that may arise with the PROSIXFOB key fob and how to troubleshoot them:',
                section: [
                    { title: "Unresponsive Buttons" },
                    { text: "If the buttons on the key fob become unresponsive, check the battery levels and replace the battery if necessary. Ensure that the key fob is within range of the security system's wireless signal." },
                    
                    { title: "Connectivity Issues" },
                    { text: "If the key fob loses wireless connectivity, check the network settings and ensure it is within range of the wireless signal. Verify that there are no network outages affecting the system." },
                    
                    { title: "False Alerts" },
                    { text: "False alerts can occur due to accidental button presses. Ensure that the key fob is stored securely to prevent accidental activation." },
                    
                    { title: "Battery Issues" },
                    { text: "If the battery indicator shows low battery, replace the battery promptly to ensure continuous operation. Check the battery connections and ensure they are secure." },
                ]
            },
        ]
    }
    return device;
}
export default PROSIXFOB;