import React from 'react';
import FlexCardWrapper from "../../containers/FlexCardWrapper/FlexCardWrapper";
import FlexCard from "../../containers/FlexCard/FlexCard";
import HeaderText from "../../Card/CardHeader/HeaderText";
import ContentText from "../../Card/CardContent/ContentText";
import BlueLink from "../../BlueLink/BlueLink";
import SvgImageContainer from "../../containers/ImageContainer/SvgImageContainer/SvgImageContainer";
import RasterImageContainer from "../../containers/ImageContainer/RasterImageContainer/RasterImageContainer";

export default function CardSection({ data }) {
    return (
        <>
            {data && (
                <>
                    {data.map((row, rowIndex) => (
                        <FlexCardWrapper key={rowIndex} i={rowIndex}>
                            {row.map((column, columnIndex) => (
                                <FlexCard key={`${rowIndex}-${columnIndex}`} column={column} i={`${rowIndex}-${columnIndex}`}>
                                    <HeaderText settings={column.settings} header={column.header} />
                                    <ContentText settings={column.settings} content={column.content} />
                                    <BlueLink settings={column.settings} links={column.links} />
                                    <SvgImageContainer settings={column.settings} svg={column.svgImage} />
                                    <RasterImageContainer settings={column.settings} image={column.image} />
                                </FlexCard>
                            ))}
                        </FlexCardWrapper>
                    ))}
                </>
            )}
        </>
    );
}
