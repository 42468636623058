import k1 from './PROSIXLCDKPimg/k1.jpg';
import k2 from './PROSIXLCDKPimg/k2.jpg';
import k3 from './PROSIXLCDKPimg/k3.jpg';
import k4 from './PROSIXLCDKPimg/k4.jpg';
import k5 from './PROSIXLCDKPimg/k5.jpg'
import k6 from './PROSIXLCDKPimg/k6.jpg'
import k7 from './PROSIXLCDKPimg/k7.jpg'
import { getPrice } from '../../../../../../Prices';
const PROSIXLCDKP = () => {
    const device = {
        title: 'LCD Keypad',
        model: 'PROSIXLCDKP',
        sku: ['PROSIXLCDKP'],
        modelViewImage: k1,
        image: [k1,k2,k3,k4,k5,k6,k7],
        shortDescription: 'User-friendly LCD keypad for easy control of your security system.',
        price: getPrice('PROSIXLCDKP'),
        group: 'keypad',
        checkListKeyword: [],
        infoHeader: "LCD Keypad",
        spec: [
            { row1: 'Model:', row2: 'PROSIXLCDKP' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Wall mount or Desk mount' },
            { row1: 'Power Supply:', row2: 'AC Power with Battery Backup' },
            { row1: 'Screen Type:', row2: 'LCD' },
            { row1: 'Dimensions:', row2: '5.8 x 3.5 x 1.0 inches' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'LCD Keypad: User-Friendly Control for Your Security System',
                text: 'The PROSIXLCDKP LCD keypad provides a user-friendly interface for controlling your security system. With its clear display and straightforward design, it simplifies the management of your security setup.',
                section: [
                    { title: "Easy-to-Use Interface" },
                    { text: "The PROSIXLCDKP features a clear LCD display and a straightforward interface, providing easy control of your security system. Users can quickly access system status, arm/disarm the system, and navigate through menus." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The keypad connects to your security system wirelessly, ensuring reliable communication and easy installation without the need for complex wiring." },
                    
                    { title: "Customizable" },
                    { text: "The PROSIXLCDKP allows for customization of the display and settings, enabling users to personalize the interface with preferred options. This enhances the user experience and simplifies system management." },
                    
                    { title: "Real-Time Alerts" },
                    { text: "Receive real-time alerts and notifications on the LCD keypad, keeping you informed about system status and any security events. The keypad provides audible and visual alerts to ensure that important information is noticed." },
                    
                    { title: "Automation Integration" },
                    { text: "Integrate the PROSIXLCDKP with your home automation system to control smart devices such as lights, locks, and thermostats. This enhances the convenience and functionality of your security setup." },
                    
                    { title: "Power Supply" },
                    { text: "The PROSIXLCDKP operates on AC power with a battery backup, ensuring continuous operation even during power outages. The battery backup provides peace of mind and reliable performance." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXLCDKP LCD keypad meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXLCDKP LCD keypad operates using advanced communication technology to provide easy control and monitoring of your security system.',
                section: [
                    { title: "LCD Interface" },
                    { text: "The clear LCD display provides a user-friendly interface for interacting with the security system. Users can easily access system status, arm/disarm the system, and navigate through menus and settings." },
                    
                    { title: "Wireless Communication" },
                    { text: "The keypad connects to the security system wirelessly, allowing for reliable and secure communication. This wireless connection simplifies installation and ensures that the keypad can be placed in convenient locations." },
                    
                    { title: "Real-Time Monitoring" },
                    { text: "The PROSIXLCDKP provides real-time monitoring of the security system, displaying alerts and notifications directly on the screen. This ensures that users are immediately informed of any security events." },
                    
                    { title: "Automation Control" },
                    { text: "The keypad can be integrated with home automation systems, allowing users to control smart devices directly from the LCD interface. This includes adjusting thermostats, controlling lights, and locking/unlocking doors." },
                    
                    { title: "Customizable Display" },
                    { text: "Users can customize the display to suit their preferences, adding shortcuts to frequently used functions and adjusting the interface for optimal usability." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of the PROSIXLCDKP LCD keypad is crucial to its effectiveness. Here are some key guidelines for where to place the keypad:',
                section: [
                    { title: "Near Entry Points" },
                    { text: "Place the keypad near main entry points such as front and back doors for easy access when entering or leaving the property. This allows for quick arming and disarming of the system." },
                    
                    { title: "Central Location" },
                    { text: "Install the keypad in a central location within the home for convenient access by all occupants. This ensures that the keypad is easily reachable from various areas of the property." },
                    
                    { title: "Bedrooms" },
                    { text: "Consider placing a keypad in the master bedroom for quick access during the night. This allows users to easily monitor and control the security system from their bedroom." },
                    
                    { title: "Near Automation Devices" },
                    { text: "If the keypad is used to control home automation devices, place it near these devices for easy access and control. This enhances the convenience of managing smart home features." },
                    
                    { title: "Away from Direct Sunlight" },
                    { text: "Avoid placing the keypad in direct sunlight, which can affect the visibility of the display. Choose a location with consistent lighting for optimal screen readability." },
                    
                    { title: "Secure Mounting" },
                    { text: "Ensure that the keypad is securely mounted to prevent tampering or accidental disconnection. Use the provided mounting hardware for a stable installation." },
                ]
            },

            {
                header: 'Common issues and troubleshooting',
                text: 'Here are common issues that may arise with the PROSIXLCDKP LCD keypad and how to troubleshoot them:',
                section: [
                    { title: "Unresponsive Screen" },
                    { text: "If the LCD screen becomes unresponsive, try rebooting the device by disconnecting and reconnecting the power supply. Ensure that the screen is clean and free of debris." },
                    
                    { title: "Connectivity Issues" },
                    { text: "If the keypad loses wireless connectivity, check the network settings and router configuration. Ensure that the keypad is within range of the wireless signal and that there are no network outages." },
                    
                    { title: "False Alerts" },
                    { text: "False alerts can occur due to incorrect settings or sensor issues. Check the system settings and ensure that all sensors are functioning correctly. Adjust sensitivity settings as needed." },
                    
                    { title: "Battery Backup Issues" },
                    { text: "If the battery backup is not functioning, check the battery connections and replace the battery if necessary. Ensure that the AC power supply is stable and providing consistent power." },
                    
                    { title: "Screen Visibility" },
                    { text: "If the screen is difficult to read, adjust the brightness settings on the keypad. Ensure that the keypad is not placed in direct sunlight or areas with extreme lighting conditions." },
                ]
            },
        ]
    }
    return device;
}
export default PROSIXLCDKP;
