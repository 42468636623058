import React from 'react';
import css from "./Footer.module.css";
import SocialLinks from "../SocialLinks/SocialLinks";

export default function Footer({obj}){
  
    return(
        <div>
            {obj &&(
                <div>
                    <div className={css.container}>
                        <div className={css.wrapper}>
                            <h3>{obj.title}</h3>
                            <p>{obj.address1} {obj.address2}</p>
                            <p>Lic#: {obj.lic}</p>
                            <p>Main: {obj.tel1}</p>
                            <p>Toll Free: {obj.tel2}</p>
                            <p>{obj.email}</p>
                        </div>
                        <SocialLinks obj={obj}/>
                    </div>
                    <div className={css.copyright}>
                        <p>{obj.copyright}</p>
                    </div>
                </div>
            )}
        </div>
    )
}