import css from './MonthlyConfirmation.module.css';
import React, { useState } from 'react';
import { BsCreditCard } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const MonthlyConfirmation = ({ project, step, setStep, nextStep, prevStep, basket, contractDuration = 1, upfront = 0, monthly = 0 }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Monthly Payment Requirement</h2>
                
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                            <BsCreditCard/>
                        </div>
                        <div className={css.item}>
                            <p>${monthly.toFixed(2)} Monthly Payment</p>
                        </div>
                    </div>
                </div>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p >This payment is required as a scheduled, automatic charge to a credit card (Visa, MasterCard, Amex), or Visa debit.</p>
                    </div>
                    
                    <div className={css.description}>
                        <p > Cash, checks, or wire transfers are not accepted for monthly payment.</p>
                    </div>
                    <div className={css.description}>
                        <p >The initial payment will be processed on the first day of service.</p>
                    </div>
                    
                    <div className={css.descriptionTax}>
                        <p >5% GST not included.</p>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.checkboxBox} >
                    <div className={css.checkbox} onClick={handleCheckboxChange}>
                    {isChecked?<MdOutlineCheckBox/>:<MdOutlineCheckBoxOutlineBlank/>}
                    </div>
                    <div className={css.term}>
                    <p>I acknowledge and accept these conditions</p>
                    </div>
                </div>
                <button 
                    className={css.buttonActive} 
                    onClick={()=>setStep(upfront>0?8:7)} 
                    >
                        Back
                    </button>
                <button 
                    className={isChecked ? css.buttonActive : css.buttonInActive} 
                    onClick={()=>setStep(contractDuration>1?10:11)} 
                    disabled={!isChecked}
                    >
                        Next
                    </button>
            </div>
        </div>
    );
};

export default MonthlyConfirmation;






