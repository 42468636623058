import React from 'react';
import css from "./SocialLinks.module.css";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYelp } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { FaGoogle } from "react-icons/fa";

export default function SocialLinks({ obj }) {
    return (
        <div className={css.socialLinks}>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].fb} target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </a>
            </div>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].x} target="_blank" rel="noopener noreferrer">
                    <FaXTwitter />
                </a>
            </div>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].yelp} target="_blank" rel="noopener noreferrer">
                    <FaYelp />
                </a>
            </div>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].instagram} target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
            </div>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].youTube} target="_blank" rel="noopener noreferrer">
                    <TfiYoutube />
                </a>
            </div>
            <div className={css.iconBox}>
                <a href={obj.socialLinks[0].google} target="_blank" rel="noopener noreferrer">
                    <FaGoogle />
                </a>
            </div>
        </div>
    );
}
