import React from 'react';
import css from "./HeaderText.module.css";

export default function HeaderText({header='',settings, fontSize}){

    return(
        <div className={css.headerWrpper}>
            {header && (
            <div className={`${css.header} ${settings.darkBackground ? css.brightFont: ''}`}>
                <h1 style={{fontSize: `clamp(.7rem, 3vw, ${fontSize}rem)`}} dangerouslySetInnerHTML={{ __html: header }}></h1>
            </div>
        )}
        </div>
    )
}