import ProPanelPlusImage from '../../../../../../assets/images/proseries/panel/ProPanelPlus.jpg'
import ProPanelWall2Image from '../../../../../../assets/images/proseries/panel/ProPanelWall2.jpg'
import ProPanelWall3Image from '../../../../../../assets/images/proseries/panel/ProPanelWall3.jpg'
import ProPanelPlusSQRImage from '../../../../../../assets/images/proseries/panel/ProPanelPlusSQR.jpg'
import Prosixctpanel3 from '../../../../../../assets/images/proseries/panel/Prosixctpanel3.jpg'
import proplus54 from '../../../../../../assets/images/proseries/panel/proplus54.jpg'
import propanel21 from '../../../../../../assets/images/proseries/panel/propanel21.jpg'
import propanel32 from '../../../../../../assets/images/proseries/panel/propanel32.jpg'
import propanel35 from '../../../../../../assets/images/proseries/panel/propanel35.jpg'
import propanel36 from '../../../../../../assets/images/proseries/panel/propanel36.jpg'
import propanel84 from '../../../../../../assets/images/proseries/panel/propanel84.jpg'
import { getPrice } from '../../../../../Prices';
const PROA7PLUS =()=>{
    const panel = {
        title: '7" Touchscreen Alarm Panel',
        model:'PROA7PLUS',
        sku:['PROA7PLUS'],
        modelViewImage:ProPanelPlusSQRImage,
        image: [ProPanelPlusImage,propanel32,propanel21,propanel35, propanel36,propanel84, ProPanelWall2Image,ProPanelWall3Image,Prosixctpanel3, proplus54],
        shortDescription:'Honeywell ProSeries 7" All-In-One Control Touchscreen Panel, (Monitoring via Wi-Fi internet)',
        price: getPrice('PROA7PLUS'),
        checkListKeyword:[],
        infoHeader:"ProSeries 7 inch All-in-One Panel",
        spec:[
            {row1:'Monitoring:',row2:'Via Wifi or Wifi and Cellular Network'},
            {row1:'Model:',row2:'PROA7PLUS'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Display Size:',row2:'7 inch diagonal, 178 m diagonal'},
            {row1:'Build-in Camera:',row2:'Yes'},
            {row1:'Build-in Z-Wave',row2:'Yes'},
            {row1:'Bluetooth Disarming ',row2:'Yes'},
            {row1:'Amazon Alexa:',row2:'Yes'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Maximum Zones:',row2:'250'},
            {row1:'Maximum Secondary Wi-Fi TOuchscreen Keypads:',row2:'8'},
            {row1:'User Codes:',row2:'96'},
            {row1:'Maximum Z-Wave Devices:',row2:'78'},
            {row1:'-Locks:',row2:'4'},
            {row1:'-Thermostats:',row2:'6'},
            {row1:'-Garage Openers:',row2:'6'},
            {row1:'-Light Switches:',row2:'60'},
            {row1:'Maximum Smart Scenes:',row2:'100'},
            {row1:'Partitions:',row2:'4'},
            {row1:'Power Supply:',row2:'9V DC'},
            {row1:'Battery Type:',row2:'Lithium 7,2V'},
            {row1:'Number of Batteries:',row2:'1'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Product Width:',row2:'7.9 in, 200.6 mm'},
            {row1:'Product Height:',row2:'5.77 in, 146.6 mm'},
        ],
        info:[
            {
                header:'7-Inch Touchscreen Panel: Elevate Your Space with Style',
                text:'Introducing our exquisite 7-inch touchscreen panel, a sleek and elegant addition that brings a touch of prestige to your home or business. Designed with both functionality and aesthetics in mind, this flat panel is a perfect blend of modern design and cutting-edge technology. The Resideo Alarm Panel is a state-of-the-art security system control panel designed to provide robust protection for residential and commercial properties. With its advanced features and intuitive interface, the Resideo Alarm Panel offers comprehensive security solutions to keep your property safe and secure.',
                section: [
                    {title: "Stunning Visuals"},
                    {text: "The vibrant 7-inch touchscreen display delivers stunning visuals, allowing you to interact with your security system and smart home devices with clarity and precision. The high-resolution screen ensures that every detail is crisp and clear, enhancing your overall user experience."},

                    {title: "Intuitive Interface"},
                    {text: "Navigate effortlessly through menus and controls thanks to the intuitive touch interface. Whether you're arming your security system, adjusting smart thermostats, or viewing camera feeds, the touchscreen panel provides a seamless and user-friendly experience."},

                    {title: "Modern Elegance"},
                    {text: "With its slim profile and clean lines, our 7-inch touchscreen panel exudes modern elegance. It seamlessly integrates into any décor, adding a sophisticated touch to your living space, office, or commercial environment."},

                    {title: "Enhanced Functionality"},
                    {text: "Beyond its aesthetic appeal, the touchscreen panel offers enhanced functionality to streamline your daily routines. Control lighting, manage access control, receive notifications, and more, all from one central hub that complements your lifestyle."},
        
                    {title: "Versatile Applications"},
                    {text: "Whether used as a standalone security panel or as part of a comprehensive smart home system, the 7-inch touchscreen panel adapts to your needs. Its versatility allows you to customize settings, create automation routines, and monitor your property with ease."},
        
                    {title: "Remote Accessibility"},
                    {text: "Stay connected and in control from anywhere with remote access capabilities. Use your smartphone or tablet to interact with the touchscreen panel, ensuring peace of mind and convenience whether you're at home or on the go."},

                    {title: "Elevate Your Space"},
                    {text: "Elevate the look and feel of your space with our 7-inch touchscreen panel. Its combination of sleek design, advanced technology, and user-friendly interface not only enhances security and convenience but also adds a touch of prestige to your place."},
                ]
            },
            {
                header:'Seamless Connectivity with Securepol AIECMS',
                text:'The panel\'s integration with Securepol AIECMS enables real-time communication and data exchange, allowing for swift and precise response to security events and system updates.',
                section: [
                    {title: "Dual Connectivity Options"},
                    {text: "Enjoy flexibility and reliability with two pathways for connectivity. Connect the panel to your network via WiFi for fast and convenient communication within your premises. Alternatively, utilize the cellular module, which connects to cellphone towers, ensuring uninterrupted communication even in areas with limited or no WiFi coverage."},

                    {title: "WiFi Connectivity"},
                    {text: "With WiFi connectivity, the panel leverages your existing network infrastructure, providing seamless communication with your security system, smart devices, and the Securepol AIECMS platform."},

                    {title: "Cellular Module"},
                    {text: "The cellular module serves as a backup or primary communication channel, offering reliable connectivity independent of WiFi availability. It connects directly to cellphone towers, guaranteeing connectivity in remote locations or during network outages."},

                    {title: "Enhanced Security and Monitoring"},
                    {text: "By leveraging Securepol AIECMS through our touchscreen panel, you benefit from enhanced security features, centralized monitoring, and intelligent event management. Receive instant alerts, access comprehensive reports, and take proactive measures to safeguard your property."},
        
                ]
            },
            {
                header:'Total Connect Application: Empowering Smart Security and Home Automation',
                text:'Introducing Total Connect, your gateway to seamless control and management of your security system and home automation devices. Paired with the PROA7PLUS control panel, Total Connect offers a comprehensive suite of features to enhance your security, streamline user management, and elevate your home automation experience.',
                section: [
                    {title: "Arming and Disarming"},
                    {text: "With Total Connect, you can arm and disarm your security system remotely from anywhere using your smartphone or tablet. Whether you're at home, at work, or on the go, enjoy peace of mind knowing that your property is secure at all times."},

                    {title: "User Management"},
                    {text: "Total Connect simplifies user management by allowing you to add or remove user codes easily. Grant access to family members, trusted individuals, or service providers, and manage their permissions effortlessly through the intuitive interface."},

                    {title: "Control User Entry"},
                    {text: "Monitor and control user entry with Total Connect's detailed access logs and notifications. Receive alerts when a user enters or exits the premises, ensuring accountability and security."},

                    {title: "Home Automation Control"},
                    {text: "Take control of your smart home devices directly from the Total Connect app. Adjust thermostats, turn lights on or off, and even lock or unlock doors remotely, creating a customized and energy-efficient home environment."},
                    
                    {title: "Customized Notifications"},
                    {text: "Stay informed with real-time notifications tailored to your preferences. Receive alerts for alarms, system status changes, user activities, and home automation events, keeping you connected and informed at all times."},

                    {title: "Integration with PROA7PLUS:"},
                    {text: "Total Connect seamlessly integrates with the PROA7PLUS control panel, harnessing its advanced features and reliability. Benefit from a unified platform that combines robust security, user-friendly controls, and smart automation for a holistic and connected living experience."},

                    {title: "Secure and Reliable"},
                    {text: "Rest assured that your data and communications are secure with Total Connect's encrypted connections and industry-leading security protocols. Enjoy reliable performance and peace of mind knowing that your security and automation systems are in good hands."},

                    {title: ""},
                    {text: "Empower your lifestyle with Total Connect and the PROA7PLUS control panel, where smart security, user management, home automation, and geofencing converge to deliver a seamless and intelligent living experience."},
                ]
            },
        ]
    }
    return panel
}
export default PROA7PLUS;