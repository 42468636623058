export const formatPhone = (value) => {
    // Remove all non-numeric characters
    value = value.replace(/\D/g, '');
  
    // Format the phone number as (111) 222-3333
    if (value.length <= 3) {
      return `(${value}`;
    } else if (value.length <= 6) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  };
  
  export const validatePhone = (phoneNumber) =>{
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      return phoneRegex.test(phoneNumber);
  };
  