import React from 'react';
import css from "./LegalBlock.module.css";

export default function LegalBlock({children}){
  
    return(
        <div className={css.cardWrapper}>
            <div className={css.card} >
                <div className={css.contentWrapper}>
                    {children}
                </div>
            </div>
        </div>
    )
}