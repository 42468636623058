import React from 'react';
import css from "./FlexCard.module.css";

export default function FlexCard({column, children}){
    function svgBackground(svg){
        const background = {
            backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        
        };
        return background;
    }
    function regBackground(img){
        const background = {
            backgroundImage: `url("${img}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        
        };
        return background;
    }
    return(
        <div className={css.cardWrapper}>
            <div className={column.settings.bordered ? css.card:css.banerWrapper} style={column.svgBackground ? svgBackground(column.svgBackground):regBackground(column.imgBackground)}>
                <div className={`${css.contentWrapper} ${column.darkBackground?css.brightFont:''}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}