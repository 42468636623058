import React from 'react';
import css from './Cart.module.css';
import { BsCart3 } from "react-icons/bs";

const Cart = ({ view, setView }) => {
    return (
        <div className={view !== 'main' ? `${css.box} ${css.inActive}` : `${css.box} ${css.active}`} onClick={() => setView('main')}>
            <div className={css.iconWrapper}>
                <BsCart3 className={css.icon} />
            </div>
            <div className={css.label}>
                Cart
            </div>
        </div>
    );
}

export default Cart;