import React from 'react';
import { Link } from 'react-router-dom';
import css from "./BlueLink.module.css";
import { GoChevronRight } from "react-icons/go";

export default function BlueLink({links=[], settings, fontSize}){

    return(
        <div>
            {links && (
                <div className={css.linksWrapper}>
                    {links.map((link,i)=>(
                        <Link key={i} className={css.linkto} to={link.to}>
                            <div className={`${css.link} ${settings.darkBackground ? css.brightFont:''}`} style={{fontSize: `clamp(1rem, 3vw, ${fontSize}rem)`}}>
                                <span>
                                    {typeof link.label === 'string' ? 
                                        <span dangerouslySetInnerHTML={{ __html: link.label }}></span> :
                                        link.to
                                    }
                                    {link.label && (
                                        <span  className={css.arrow}>
                                        <GoChevronRight  viewBox="0 0 32 9" />
                                    </span> 
                                    )}
                                </span>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}