import React, {useRef, useState} from "react";
import css from "./Contact.module.css";

import FormCard from "../../webComponents/common/Form/FormCard/FormCard"
import LegalData from "../../Db/LegalData";
import BizInfoData from "../../Db/BizInfoData";
import FooterData from "../../Db/FooterData";
import LegalSection from "../../webComponents/common/PageSections/LegalSection/LegalSection";
import BizInfoSection from "../../webComponents/common/PageSections/BizInfoSection/BizInfoSection";
import FooterSection from "../../webComponents/common/PageSections/FooterSection/FooterSection"

import ContactFormWithEmailSender from '../../webComponents/common/Form/ContactFormWithEmailSender/ContactFormWithEmailSender'
import ContactFormBizInfo from '../../webComponents/common/ContactFormBizInfo/ContactFormBizInfo'






const Contact=()=>{
    return(
        <>      
            <div style={{height:47}}>{/* div under nav bar */}
            </div>
            <div className={css.sectionWrapper}>
                <div className={css.contactWrapper}>
                    <div className={css.contact}>
                        <FormCard bordered={true}>
                            <ContactFormWithEmailSender/>
                        </FormCard>
                        <FormCard bordered={false}>
                            <ContactFormBizInfo/>
                        </FormCard>
                    </div>
                </div>
                <div className={css.footerWrapper}>
                    <LegalSection data={LegalData().legal}/> 
                    <BizInfoSection data={BizInfoData().infoLinks}/>
                    <FooterSection data={FooterData().footer}/>
                </div>
            </div>
        </>
    )
}
export default  Contact;