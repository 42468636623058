import React from 'react';
import FlexCardWrapper from "../../containers/FlexCardWrapper/FlexCardWrapper";
import LegalBlock from "../../containers/LegalBlock/LegalBlock";
import LegalText from "../../Legal/LegalText";

export default function LegalSection({ data }) {
    return (
        <>
            {data && (
                <>
                    {data.map((row, rowIndex) => (
                        <FlexCardWrapper key={rowIndex} i={rowIndex}>
                            {row.map((column, columnIndex) => (
                                <LegalBlock key={`${rowIndex}-${columnIndex}`}>
                                    <LegalText i={`${rowIndex}-${columnIndex}`} terms={column.terms} />
                                </LegalBlock>
                            ))}
                        </FlexCardWrapper>
                    ))}
                </>
            )}
        </>
    );
}
