export default function LegalData(){
    const data={     
        
        legal:[
            [
                {
                    settings: {bordered:false, darkBackground:false,},
                    terms: "*The images of the products displayed on our website are for illustrative purposes only. While every effort is made to ensure the accuracy of the colors, design, and style of the products in these photographs, variations may occur due to the inherent differences in digital photography, individual monitor settings, and display technologies. The products you receive may exhibit slight variations in appearance from those seen online. This discrepancy can be attributed to various factors including but not limited to lighting conditions at the time of photography and slight changes in product design or materials.",
                },
            ],
            [
                {
                    settings: {bordered:false, darkBackground:false,},
                    terms: "Furthermore, we periodically update our product lines and designs, which may lead to minor modifications in the products that are not immediately reflected in the images on our website. Such variations, while minor, contribute to the uniqueness of each item. By proceeding with your purchase, you acknowledge and accept that any minor differences between the product delivered and the images displayed on our website do not constitute a defect in the product nor misrepresentation on our part."
                },
            ],
            [
                {
                    settings: {bordered:false, darkBackground:false,},
                    terms: "We are committed to delivering products of the highest quality and functionality. Our focus is on customer satisfaction, and we endeavor to provide clear and accurate product representations on our website. However, we recommend that customers read the product descriptions thoroughly and reach out to our customer service team for any clarifications before making a purchase. Your understanding and acknowledgment of this disclaimer form part of the terms and conditions of sale.",
                },
            ],
        ]
    }
    return data;
}