import i1 from './PROSIXSIRENOimg/i1.jpg';
import i2 from './PROSIXSIRENOimg/i2.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXSIRENO = () => {
    const device = {
        title: 'Additional Outdoor Siren',
        model: 'PROSIXSIRENO',
        sku: ['PROSIXSIRENO'],
        modelViewImage: i1,
        image: [i1,i2],
        shortDescription: 'Loud outdoor siren for effective alerting in emergency situations.',
        price: getPrice('PROSIXSIRENO'),
        group: 'siren',
        checkListKeyword: [],
        infoHeader: "Additional Outdoor Siren",
        spec: [
            { row1: 'Model:', row2: 'PROSIXSIRENO' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Wall mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'AA' },
            { row1: 'Number of Batteries:', row2: '4' },
            { row1: 'Sound Level:', row2: '110 dB' },
            { row1: 'Dimensions:', row2: '7.0 x 4.5 x 2.0 inches' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'Outdoor Siren: Loud and Effective Alerting',
                text: 'The PROSIXSIRENO outdoor siren provides loud and effective alerting for emergency situations. Designed for outdoor use, this siren ensures that alerts are heard clearly, deterring intruders and alerting occupants and neighbors.',
                section: [
                    { title: "Loud and Clear Alerts" },
                    { text: "The PROSIXSIRENO emits a loud 110 dB siren sound, ensuring that alerts are heard clearly even from a distance. This loud sound helps deter intruders and alerts occupants and neighbors to emergency situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The siren connects to your security system wirelessly, ensuring reliable communication and easy installation without the need for complex wiring." },
                    
                    { title: "Weather-Resistant Design" },
                    { text: "The PROSIXSIRENO is designed for outdoor use, with a weather-resistant housing that protects it from the elements. This ensures reliable performance in various weather conditions." },
                    
                    { title: "Tamper-Resistant" },
                    { text: "The siren features a tamper-resistant design, alerting the system if the device is tampered with or removed. This enhances security and ensures continuous protection." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXSIRENO operates on long-lasting lithium batteries, providing continuous protection even during power outages. The battery indicator alerts you when battery replacement is needed." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXSIRENO outdoor siren meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXSIRENO outdoor siren operates using advanced wireless communication technology to provide loud and effective alerting in emergency situations.',
                section: [
                    { title: "Siren Activation" },
                    { text: "The siren is activated when the security system detects an emergency situation, such as a break-in or fire. It emits a loud 110 dB sound to alert occupants and deter intruders." },
                    
                    { title: "Wireless Communication" },
                    { text: "The siren connects wirelessly to the security system, ensuring reliable communication and easy installation. This wireless connection allows for flexible placement in strategic outdoor locations." },
                    
                    { title: "Tamper Detection" },
                    { text: "The PROSIXSIRENO features tamper detection, alerting the system if the siren is tampered with or removed. This ensures that any attempts to disable the siren are detected and reported." },
                    
                    { title: "Battery Operation" },
                    { text: "The siren operates on long-lasting lithium batteries, providing continuous protection even during power outages. The battery indicator alerts you when battery replacement is needed." },
                    
                    { title: "Weather Resistance" },
                    { text: "The weather-resistant housing of the siren ensures reliable performance in various weather conditions, protecting it from rain, snow, and other elements." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of the PROSIXSIRENO outdoor siren is crucial to its effectiveness. Here are some key guidelines for where to place the siren:',
                section: [
                    { title: "Entry Points" },
                    { text: "Place the siren near main entry points such as front and back doors, garage doors, and windows. This ensures that the loud sound is heard clearly when an intruder attempts to enter." },
                    
                    { title: "High Locations" },
                    { text: "Install the siren in a high location to maximize sound coverage and make it harder for intruders to tamper with it. A higher placement also helps the sound travel further." },
                    
                    { title: "Visible Locations" },
                    { text: "Place the siren in visible locations to act as a deterrent for potential intruders. The presence of a visible siren can discourage break-ins and enhance security." },
                    
                    { title: "Weather Protection" },
                    { text: "Ensure that the siren is placed in a location that provides some protection from direct exposure to the elements, while still allowing it to function effectively in various weather conditions." },
                    
                    { title: "Secure Mounting" },
                    { text: "Ensure that the siren is securely mounted to prevent tampering or accidental disconnection. Use the provided mounting hardware for a stable installation." },
                ]
            },

            {
                header: 'Common issues and troubleshooting',
                text: 'Here are common issues that may arise with the PROSIXSIRENO outdoor siren and how to troubleshoot them:',
                section: [
                    { title: "Unresponsive Siren" },
                    { text: "If the siren becomes unresponsive, check the battery levels and replace the batteries if necessary. Ensure that the siren is within range of the security system's wireless signal." },
                    
                    { title: "Connectivity Issues" },
                    { text: "If the siren loses wireless connectivity, check the network settings and router configuration. Ensure that the siren is within range of the wireless signal and that there are no network outages." },
                    
                  
                    { title: "Battery Issues" },
                    { text: "If the battery indicator shows low battery, replace the batteries promptly to ensure continuous operation. Check the battery connections and ensure they are secure." },
                    
                    { title: "Weather Damage" },
                    { text: "Inspect the siren regularly for signs of weather damage. Ensure that the weather-resistant housing is intact and that the siren is functioning correctly in various weather conditions." },
                ]
            },
        ]
    }
    return device;
}
export default PROSIXSIRENO;
