import s1 from './PROSIXSMOKEVimg/s1.jpg'
import s2 from './PROSIXSMOKEVimg/s2.jpg'
import s3 from './PROSIXSMOKEVimg/s3.jpg'
import s4 from './PROSIXSMOKEVimg/s4.jpg'
import s5 from './PROSIXSMOKEVimg/s5.jpg'
import bat2 from '../../../Batteries/bat2.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXCT =()=>{
    const sensor = {
        title:'Smoke Detector',
        model:'PROSIXSMOKEV',
        sku:['PROSIXSMOKEV'],
        modelViewImage:s1,
        image:[s1,s4,s2,s3,s5, bat2,],
        shortDescription:'Detect the presence of smoke particles in the air.',
        price: getPrice('PROSIXSMOKEV'),
        checkListKeyword:['Fire'],
        infoHeader:"Door/Window Sensor",
        spec:[
            {row1:'Model:',row2:'PROSIXSMOKEV'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Wireless Range:',row2:'300+ Feet (Open Air) from main controler'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'2'},
            {row1:'Sound Level:',row2:'85 dBA'},
            {row1:'Detection Principle:',row2:'Photoelectric'},
            {row1:'Alarm Indicator Light:',row2:'Red'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Overall Diameter:',row2:'4.7 in, 119.4 mm'},
            {row1:'Product Width:',row2:'1.6 in, 40.6 mm'},
            {row1:'Approvals and Standards:',row2:'ETL Listed'},
        ],
        info:[
            {
                header:'Smoke Detector: Early Warning for Fire Safety',
                text:'The PROSIXSMOKEV smoke detector is an essential component of any comprehensive fire safety system. Designed with cutting-edge technology and built-in intelligence, this smoke detector offers early detection and rapid response to potential fire threats, ensuring the safety and protection of your property and occupants.',
                section: [
                    {title: "Early Detection"},
                    {text: " The PROSIXSMOKEV utilizes advanced sensing technology to detect the presence of smoke particles in the air, providing early warning of a potential fire incident. Its high sensitivity ensures prompt detection even in the early stages of a fire, allowing for swift evacuation and intervention."},

                    {title: "Reliable Performance"},
                    {text: "Engineered for reliability, the PROSIXSMOKEV smoke detector delivers consistent and accurate detection without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations."},

                    {title: "Wireless Connectivity"},
                    {text: "The PROSIXSMOKEV is a wireless smoke detector that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property."},

                    {title: "Tamper-Resistant Design"},
                    {text: "With a tamper-resistant design, the PROSIXSMOKEV smoke detector provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access."},
        
                    {title: "Smart Alerts"},
                    {text: " Receive instant alerts and notifications on your mobile device or control panel in the event of smoke detection. Stay informed and take prompt action to mitigate risks and ensure the safety of occupants and assets."},
        
                    {title: "Battery-Powered"},
                    {text: "The PROSIXSMOKEV operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality."},

                    {title: "Compliance and Certification:"},
                    {text: "The PROSIXSMOKEV smoke detector meets industry standards and certifications for fire safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive fire safety plan for residential and commercial properties."},

                    {title: "Easy Integration"},
                    {text: "Integrate the PROSIXSMOKEV smoke detector with your existing security and automation systems for centralized monitoring and control. Enhance your overall security infrastructure with seamless integration and intelligent alerting capabilities."},
                ]
            },

            {
                header:'How does it work?',
                text:'The PROSIXSMOKEV smoke detector operates based on a combination of advanced sensing technology and intelligent detection algorithms. Here\'s how it works in detail:',
                section: [
                    {title: "Smoke Sensing"},
                    {text: "The PROSIXSMOKEV is equipped with a highly sensitive smoke sensor that detects the presence of smoke particles in the air. When smoke is present, it enters the detector's chamber, triggering the sensing mechanism."},

                    {title: "Detection Algorithm"},
                    {text: "Upon detecting smoke, the detector's built-in detection algorithm analyzes the characteristics of the smoke particles, such as density and composition. This analysis helps differentiate between harmless environmental factors (like steam or dust) and potentially hazardous smoke associated with fires."},

                    {title: "Alarm Activation"},
                    {text: " If the smoke is determined to be indicative of a fire hazard, the PROSIXSMOKEV smoke detector activates its alarm system. The alarm includes audible alerts, such as sirens or beeps, and visual indicators, such as flashing LED light, to alert occupants of the potential danger. "},

                    {title: "Wireless Communication"},
                    {text: "The PROSIXSMOKEV is a wireless smoke detector, meaning it communicates wirelessly with a compatible security system or control panel. When the alarm is triggered, the detector sends an immediate signal to the control panel, which then initiates appropriate response actions."},
        
                    {title: "Notification and Response"},
                    {text: "Upon receiving the alarm signal, the control panel notifies the designated individuals or monitoring service about the smoke detection event. This notification can be sent via mobile app notifications, text messages, email alerts, or automated calls, depending on the configured settings."},
        
                    {title: "Linked Smoke Detectors for Enhanced Safety"},
                    {text: "The PROSIXSMOKEV smoke detectors offer a critical safety feature that sets them apart—they can be linked and programmed to activate simultaneously in the event of a fire, providing comprehensive coverage and early warning throughout your property."},

                    {title: "Interconnected Functionality"},
                    {text: "By linking multiple PROSIXSMOKEV smoke detectors together, they create an interconnected network of detectors that can communicate with each other."},

                    {title: "Comprehensive Coverage"},
                    {text: "The ability of linked smoke detectors to activate in unison ensures comprehensive coverage, especially in larger properties or multi-story buildings where a single smoke detector may not provide sufficient warning throughout the entire premises."},

                    {title: "Early Warning System:"},
                    {text: "With linked smoke detectors, you establish an early warning system that maximizes safety by providing occupants with more time to evacuate safely and alert emergency services promptly."},

                    {title: "Scalability:"},
                    {text: "The linked smoke detector system is scalable, accommodating additional detectors as needed to expand coverage and enhance safety measures based on the size and layout of your property."},

                    {title: "Peace of Mind:"},
                    {text: "By choosing PROSIXSMOKEV smoke detectors with linking capabilities, you gain peace of mind knowing that your fire detection system is not only advanced but also synchronized and optimized for rapid response in case of emergencies."},

                    {title: "Scalability:"},
                    {text: "The linked smoke detector system is scalable, accommodating additional detectors as needed to expand coverage and enhance safety measures based on the size and layout of your property."},

                ]
            },

            {
                header:'Fire Monitoring?',
                text:'With over 20 years of experience in the field, Securepol is a trusted name in installing live safety devices, especially fire detection systems. Our commitment to safety is evident in the meticulous protocols we\'ve developed to handle fire signals received by our monitoring facility:',

                section: [
                    {title: "Prioritization of Fire Signals"},
                    {text: "At Securepol, we prioritize fire signals above all other alerts. This ensures that any indication of a fire hazard receives immediate attention and action from our team."},

                    {title: "False Alarm Reduction:"},
                    {text: "Our systems are equipped with advanced technology to minimize false alarms. This helps prevent unnecessary panic and ensures that genuine fire threats are promptly addressed."},

                    {title: "Instant Alerting"},
                    {text: "Upon receiving a fire signal, our monitoring facility generates instant alerts. These alerts are relayed to designated individuals or emergency services, enabling quick response and mitigation measures."},

                    {title: "Fire Department Dispatch"},
                    {text: " In cases where immediate intervention is required, our protocols include automatic dispatch of fire department personnel. This ensures that trained professionals are on-site without delay, further enhancing safety."},
        
                    {title: "Professional Installation and Maintenance:"},
                    {text: "Our team of experienced technicians ensures that all safety devices, including fire detection systems, are installed correctly and undergo regular maintenance. This proactive approach ensures optimal functionality and reliability of your safety infrastructure."},
        
                    {title: "Compliance and Standards"},
                    {text: "Securepol adheres to stringent industry standards and regulations. Our systems are designed and maintained to meet or exceed safety requirements, providing you with peace of mind and regulatory compliance."},

                    {title: "Continuous Improvement"},
                    {text: "We continuously evaluate and enhance our protocols and technologies to stay ahead of evolving safety needs. This commitment to innovation ensures that your safety systems remain effective and responsive over time."},
                ]
            },
            
            {
                header:'My place is already equipt with standalone somke detectors should I still add monitored one?',
                text:'Yes, monitored smoke detectors are a superior choice compared to standalone ones for several reasons, primarily due to their ability to provide round-the-clock monitoring and swift response in case of emergencies. Unlike standalone smoke detectors that rely solely on audible alarms, monitored smoke detectors are connected to a professional monitoring service. We highly recommend including fire protection in your alarm package due to the serious threat it poses to your safety and that of your neighborhood. Smoke detectors play a crucial role in detecting fires at their earliest stages, as fires often begin with smoke that can accumulate for hours before developing into a full-blown blaze. By detecting smoke early, smoke detectors provide vital time for evacuation and intervention, significantly reducing the risk of property damage and personal harm. It\'s important to note that once a fire ignites, it can engulf a house within as little as 15 minutes. This rapid progression underscores the critical importance of early detection and swift response to fire threats. Including fire protection features in your alarm package adds an extra layer of security and peace of mind, ensuring that you and your loved ones are well-prepared to handle emergencies and protect your property.By investing in fire protection alongside your alarm system, you\'re taking proactive measures to safeguard against one of the most devastating threats to your home and community. Don\'t wait until it\'s too late—prioritize fire safety today for a safer tomorrow.',
                section:[
                    {title: ""},
                    {text: ""},
                ]
            },
            {
                header:'Wher to place it?',
                text:'The placement of smoke detectors is crucial to their effectiveness in detecting fires early and providing timely alerts. Here are some key guidelines for where to place smoke detectors in your home or property:',
                section: [
                    {title: "Bedrooms"},
                    {text: "Install smoke detectors inside each bedroom. If a fire starts while occupants are asleep, early detection in the bedroom can provide crucial time for evacuation."},
                    
                    {title: "Hallways"},
                    {text: "Place smoke detectors in hallways outside of bedrooms. Hallways serve as primary escape routes during a fire, so having detectors in these areas ensures early detection along the evacuation path."},
                    
                    {title: "Living Areas"},
                    {text: "Install smoke detectors in living rooms, family rooms, and other common areas where people spend significant time. This ensures comprehensive coverage and early detection in areas where fires may originate."},

                    {title: "Kitchen"},
                    {text: "While it's important to have a smoke detector in the kitchen, avoid placing it too close to cooking appliances to prevent false alarms from cooking-related smoke. Instead, position the detector near the kitchen entrance or in an adjacent hallway."},
        
                    {title: "Basement"},
                    {text: "Place smoke detectors in basements, furnace rooms and crawl spaces, as these areas are susceptible to fire hazards but may not be regularly occupied."},
        
                    {title: "Ceiling Mounting"},
                    {text: "Mount smoke detectors on the ceiling for optimal coverage. Ensure they are at least 4 inches away from walls and corners to prevent airflow obstruction."},

                    {title: "Additional Considerations"},
                    {text: "Avoid placing smoke detectors near windows, doors, or air vents, as drafts or airflow can affect their performance. Additionally, do not install smoke detectors in areas with high humidity or steam (e.g., bathrooms), as this can also trigger false alarms."},

                ]
            },

            {
                header:'Common cause of false alarm',
                text:'False alarms from smoke detectors can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms from smoke detectors:',
                section: [
                    {title: "Cooking Smoke"},
                    {text: "Smoke detectors located near kitchens or cooking areas may trigger false alarms due to smoke generated from cooking activities, especially when using high-heat cooking methods or when food is burned."},
                    
                    {title: "Steam and Humidity"},
                    {text: "High humidity levels, steam from showers or baths, or steam-producing appliances (e.g., kettles, humidifiers) can trigger false alarms in smoke detectors located in bathrooms or humid environments."},
                    
                    {title: "Dust and Debris"},
                    {text: "Accumulated dust, lint, or airborne debris can enter smoke detectors and interfere with their sensors, leading to false alarms. This is common in areas with high dust levels or during home renovations."},

                    {title: "Insect Intrusion"},
                    {text: "Insects, particularly tiny insects like dust mites or gnats, can enter smoke detectors and trigger false alarms by obstructing sensors or causing malfunctions."},
        
                    {title: "Chemical Fumes"},
                    {text: "Strong chemical odors or fumes from cleaning agents, paints, varnishes, or aerosol sprays can sometimes trigger smoke detectors, especially if the detectors are sensitive to volatile organic compounds (VOCs)."},
        
                    {title: "Cigarette Smoke"},
                    {text: "Smoke detectors may detect cigarette smoke, especially if the detectors are located in close proximity to smoking areas or if there is inadequate ventilation to dissipate the smoke."},

                    {title: "Malfunctioning Detectors"},
                    {text: " Over time, smoke detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect smoke when needed."},

                    {title: "Extreme Temperatures"},
                    {text: "Smoke detectors located in areas with extreme temperatures (either too hot or too cold) may experience false alarms due to temperature fluctuations affecting sensor sensitivity."},
        
                    {title: "Cigarette Smoke"},
                    {text: "Smoke detectors may detect cigarette smoke, especially if the detectors are located in close proximity to smoking areas or if there is inadequate ventilation to dissipate the smoke."},

                    {title: "Malfunctioning Detectors"},
                    {text: " Over time, smoke detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect smoke when needed."},

                ]
            },
           
        ]
    }
    return sensor
}
export default PROSIXCT;