export const formatAddress = (value) => {
    const streetTypeAbbreviations = [];
  const directionalSuffixes = ['SW', 'NW', 'NE', 'SE', 'N', 'S', 'E', 'W'];
  
    let address = value.toLowerCase().split(' ');
  
    // Capitalize the first letter of each word and fully capitalize specific abbreviations
    address = address.map((word, index) => {
      if (index === 0 && !isNaN(word)) {
        return word.toUpperCase(); // House number should remain uppercase
      } else if (streetTypeAbbreviations.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else if (directionalSuffixes.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });
  
    return address.join(' ');
  };

  export const validateAddress=(address)=>{
    // Regular expression for validating addresses
    const regex = /^[0-9]+\s[a-zA-Z0-9\s,'-]{2,}$/;
  
    // Test the address against the regular expression
    return regex.test(address);
  }
  