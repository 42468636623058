import b1 from './img/b1.jpg';
import b2 from './img/b2.jpg';
import b3 from './img/b3.jpg';
import { getPrice } from '../../../../Prices';

const GD00Z8GC = () => {
    const device = {
        title: 'GoControl GD00Z-8-GC Garage Door Opener',
        model: 'GD00Z8GC',
        sku: ['GD00Z8GC'],
        modelViewImage: b1,
        image: [b1, b2,b3],
        shortDescription: 'Smart garage door opener with Z-Wave technology for remote control and monitoring.',
        price:getPrice('GD00Z8GC'),
        group: 'automation',
        checkListKeyword: ['automation'],
        infoHeader: "Smart Garage Door Opener",
        spec: [
            { row1: 'Model:', row2: 'GD00Z-8-GC' },
            { row1: 'Brand:', row2: 'GoControl' },
            { row1: 'Connectivity:', row2: 'Z-Wave' },
            { row1: 'Color:', row2: 'Black' },
            { row1: 'Mounting:', row2: 'Ceiling or Wall mount' },
            { row1: 'Power Supply:', row2: 'AC 120V' },
            { row1: 'Compatibility:', row2: 'Works with most garage doors' },
            { row1: 'Smartphone Control:', row2: 'Yes, via TC2.0 alarm app' },
            { row1: 'Dimensions:', row2: '6.5 x 1.5 x 1.5 inches' },
            { row1: 'Weight:', row2: '1.0 lbs' },
        ],
        info: [
            {
                header: 'Smart Garage Door Control',
                text: 'The GoControl GD00Z-8-GC Garage Door Opener provides advanced control and security for your garage. With Z-Wave technology and smartphone integration, you can manage your garage door from anywhere.',
                section: [
                    { title: "Smart Control" },
                    { text: "Control your garage door opener from your smartphone or Z-Wave controller. The GD00Z-8-GC offers seamless integration with smart home systems, providing remote access and control." },
                    
                    { title: "Z-Wave Technology" },
                    { text: "The built-in Z-Wave technology allows for integration with other Z-Wave devices in your smart home network. This enhances the functionality and automation of your garage door opener." },
                    
                    { title: "Remote Control Included" },
                    { text: "The garage door opener comes with a remote control for convenient access. You can also control the opener using your smartphone via the dedicated app." },
                    
                    { title: "Enhanced Security" },
                    { text: "The GD00Z-8-GC features tamper detection and alerts, ensuring the security of your garage. Receive notifications if the opener is tampered with or if there is unauthorized access." },
                    
                    { title: "Easy Installation" },
                    { text: "Designed for easy installation, the GD00Z-8-GC fits most garage doors and comes with detailed instructions. The ceiling or wall mount design ensures a clean and space-saving setup." },
                    
                    { title: "Voice Control" },
                    { text: "Compatible with popular voice assistants like Amazon Alexa and Google Assistant, allowing you to control your garage door with simple voice commands." },
                    
                    { title: "Durable Design" },
                    { text: "Built to withstand the rigors of daily use, the GD00Z-8-GC features a durable construction that ensures long-lasting performance and reliability." },
                    
                    { title: "Energy Efficient" },
                    { text: "The opener is designed to be energy-efficient, consuming minimal power during operation and standby mode, helping you save on energy costs." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The GD00Z-8-GC smart garage door opener meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'Innovative Features for Modern Living',
                text: 'The GoControl GD00Z-8-GC Smart Garage Door Opener combines innovative features and modern technology to enhance your daily life. Experience the convenience and security of smart garage door control.',
                section: [
                    { title: "Real-Time Notifications" },
                    { text: "Receive real-time notifications on your smartphone for garage door status, tamper alerts, and more. Stay informed and in control no matter where you are." },
                    
                    { title: "Customizable Automation" },
                    { text: "Create custom automation rules for your garage door opener. Set schedules, trigger actions based on events, and integrate with other smart home devices for a seamless experience." },
                    
                    
                    { title: "Quiet Operation" },
                    { text: "The GD00Z-8-GC operates quietly, reducing noise and ensuring that it does not disturb your household. The smooth operation is ideal for homes with attached garages." },
                    
                    { title: "Battery Backup" },
                    { text: "In the event of a power outage, the battery backup ensures that you can still operate your garage door. This provides peace of mind and reliable access at all times." },
                ]
            },
        ]
    }
    return device;
}
export default GD00Z8GC;
