import React from 'react';
import css from './Order.module.css';
import { TbTruckDelivery } from "react-icons/tb";

const Order=({view, setView})=>{
    return(
        <div className={view !== 'order' ? `${css.box} ${css.inActive}` : `${css.box} ${css.active}`} onClick={() => setView('order')}>
            <div className={css.iconWrapper}>
                <TbTruckDelivery className={css.icon}/> 
            </div>
            <div className={css.label}>
                Order
            </div>
        </div>
    )
}
export default Order;