import React from 'react';
import css from "./BizInfoBlock.module.css";

export default function BizInfoBlock({children}){
  
    return(
        <div className={css.container}>
            <div className={css.cardWrapper}>
                {children}
            </div>
        </div>
    )
}