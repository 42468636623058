import React from 'react';
import css from "./SvgImageContainer.module.css";

export default function SvgImageContainer({svg, settings={}, maxWidth}){
    let bordered= false;
    settings.bordered == false ?bordered = false: bordered = true;
    function svgImage(svg){
        const image = {
            backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginBottom: 0,
        };
        return image;
    }
    return(
        <div className={css.contentWrapper}>
            {svg && (
                <div className={css.svgImageContainer} style={maxWidth ? { maxWidth } : {}}>
                    <div className={true?css.svgImage:css.svgBaner} style={svgImage(svg)}></div>
                </div>
            )}
        </div> 
    )
}