

import React, { useState, useEffect } from 'react';
import ProjectsData from '../../Db/AppData/AppData';
import { useLocation } from 'react-router-dom';
import css from './LoadingView.module.css';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import { RotateLoader } from 'react-spinners';
import { generateClient } from 'aws-amplify/api';
import { getBasket } from '../../graphql/queries';
import AppCard from '../AppCommonComponents/AppStructure/AppCard/AppCard'

const client = generateClient();
const LoadingView = () => {
    const settings = { projectId: 0 };
    const [projectId, setProjectId] = useState(settings.projectId);
    const [project, setProject] = useState(ProjectsData()[0]);
    const [view, setView] = useState('main');
    const [preBasket, setPreBasket] = useState([]);
    const [sliderUpfront, setSliderUpfront] = useState(0);
    const [inputUpfront, setInputUpfront] = useState(0);
    const [contractDuration, setContractDuration]=useState(1);
    const [upfrontFromDb, setUpfrontFromDB] = useState(false)
    const [dataLoaded, setDataLoaded]=useState(false)
    const [customerType, setCustomerType] = useState('homeowner');
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [spinnerSpeed, setSpinnerSpeed] = useState(1); // Control spinner speed directly
    const [message, setMessage] = useState('');
    const [loading, setLoading]=useState(true);
    const [discount, setDiscount] = useState(40);
    const [url, setUrl] = useState(null)
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [showInstructions, setShowInstructions] = useState(true); 
    const[showPaymentInstructions,setShowPaymentInstructions]= useState(true); 
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        const local = params.get('d');
        const urlView = params.get('v');
        setUrl(urlView?urlView:'calculator');
        setView(urlView?urlView:'main')
        const stages = [
            { msg: 'Welcome to the Securepol Self-Estimate App!', action: () => Promise.resolve() },
            { msg: 'Establishing secure connections...', action: () => Promise.resolve() },
            { msg: 'Syncing with the cloud, just a moment...', action: () => fetchBasket(id, local) },
            { msg: 'Scanning for local deals and promotions...', action: collectUserLocation },
            { msg: `Great news! I\'ve found a ${discount}% discount. Applying now...`, action: () => Promise.resolve() },
            { msg: 'Almost there, just wrapping things up...', action: () => Promise.resolve(collectUserLocation()) },
        ];
    
        const isReturningUser = localStorage.getItem('returningUser') === 'true';
        //console.log(url)
        const stagesToSkip = isReturningUser? [0, 1, 3, 4] : [];
        

        const nextStage = (index) => {
            if (index < stages.length) {
                if (stagesToSkip.includes(index)) {
                    nextStage(index + 1);
                    return;
                }
                setMessage(stages[index].msg);
                setSpinnerSpeed(1); // Ensure spinner runs when moving to the next stage
                stages[index].action().then(() => {  
                    setTimeout(() => nextStage(index + 1), 3000);
                });
            } else {
                if(setPreBasket.length!==0)
                    {
                        setLoading(false);
                    }
                
            }
        };
        setTimeout(() => nextStage(0), 1000);
    
        
    }, [setView, location]);
    
    function cleanUpBasket(basket) {
        // Assuming project.garbage is a global variable or accessible in this scope
        const garbage = project.garbage;
        
        // Check if project.garbage is defined and is an array
        if (Array.isArray(garbage)) {
            // Create a new basket excluding the items categorized as garbage
            let cleanBasket = basket.filter(obj => !garbage.includes(obj.cat));
            
            // Update the basket state with the cleaned basket
            return cleanBasket
        }
    }


    useEffect(() => {
              const storedLatitude = localStorage.getItem('latitude');
              const storedLongitude = localStorage.getItem('longitude');
          
              if (storedLatitude && storedLongitude) {
                  setLatitude(storedLatitude);
                  setLongitude(storedLongitude);
              } else {
                  // Re-attempt to retrieve the location if it's not stored
                  //collectUserLocation();
              }
          }, []);

    function collectUserLocation() {
        setSpinnerSpeed(-1); // Stop the spinner while waiting for location permission
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        //console.log(`Location collected: Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
                        const lat = position.coords.latitude;
                        const long = position.coords.longitude;
                        setLatitude(lat);
                        setLongitude(long);
                        localStorage.setItem('latitude', lat);
                        localStorage.setItem('longitude', long);
                        setSpinnerSpeed(1)
                        localStorage.setItem('returningUser', true);
                        resolve();  // Resolve the promise once location is collected
                    },
                    (error) => {
                        //console.error(`Error collecting location: ${error.message}`);
                        setSpinnerSpeed(1)
                        localStorage.setItem('returningUser', true);
                        resolve();  // Resolve the promise even on error to continue the process
                    }
                );
            } else {
                //console.error("Geolocation is not supported by this browser.");
                setSpinnerSpeed(1)
                resolve();  // Resolve the promise if geolocation isn't supported
            }
        });
    }
    
    //fetches data form db, local storage if none it fetches default from file 
    //if user select one of the website (doorbell, lock etc) packages it also taks default package from file including specified item.
    const fetchBasket = async (id, local) => {
        try {
            const result = await client.graphql({
                query: getBasket,
                variables: { id }
            });
            const box = JSON.parse(result.data.getBasket.items);
            setPreBasket(box.basket);
            setUpfrontFromDB(true)
            setContractDuration(box.valet.contract)
            setInputUpfront(box.valet.upfront)
            setSliderUpfront(box.valet.upfront)
            setCustomerType(box.valet.customerType)
            //console.log('valet upfront:' +box.valet.upfront);
        } catch (err) {
            //setDataLoaded(true)
            if(local!==null)
            {
                setPreBasket(project.defaultPackage(local))
                
            }
            else
            {   
                //data from local storage 
                const data = JSON.parse(localStorage.getItem('basket'))
                
                if(localStorage.getItem('basket'))
                {
                    setPreBasket(cleanUpBasket(data.basket));
                    setUpfrontFromDB(true)
                    setContractDuration(data.valet.contract)
                    setInputUpfront(data.valet.upfront)
                    setSliderUpfront(data.valet.upfront)
                    setCustomerType(data.valet.customerType)
                    
                }
                else 
                {
                    //default package from file
                    setPreBasket(project.defaultPackage(local))

                }
            }
           // console.error('Fetch error:', err);
        }

        
    };

    return (
        <div>
            <div className={css.appBackground}>
                <div className={css.card}>
                    {loading ? (
                    <div className={css.sectionWrapper}>
                        <div className={css.body}>
                        {isLoading ? (
                            <div className={css.messageBody}>
                                <div className={css.loader}>
                                    <RotateLoader size={12} color="#274E65" speedMultiplier={spinnerSpeed} />
                                </div>
                                <div className={css.message}>
                                    {message && <p>{message}</p>}
                                </div>
                            </div>
                        ) : null}
                            <div className={css.header}>
                                <NavigationElementsWrapper />
                            </div>
                        </div>
                    </div>
                    ) : (
                        
                        <div>
                            <AppCard
                                setView={setView}
                                view={view}
                                setPreBasket={setPreBasket}
                                preBasket={preBasket}
                                project={project}
                                inputUpfront={inputUpfront}
                                setInputUpfront={setInputUpfront}
                                contractDuration={contractDuration}
                                setContractDuration={setContractDuration}
                                sliderUpfront={sliderUpfront}
                                setSliderUpfront={setSliderUpfront}
                                upfrontFromDb={upfrontFromDb}
                                setUpfrontFromDB={setUpfrontFromDB}
                                dataLoaded={dataLoaded}
                                setDataLoaded={setDataLoaded}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                discount={discount}
                                latitude={latitude}
                                longitude={longitude}
                                url={url}
                                showInstructions={showInstructions}
                                setShowInstructions={setShowInstructions}
                                showPaymentInstructions={showPaymentInstructions}
                                setShowPaymentInstructions={setShowPaymentInstructions}
                            />
                        </div>
                    
                    )}
                </div>
            </div>
        </div>
    );
    
};

export default LoadingView;