
import bat from '../../../Batteries/Cr123a.jpg'
import s1 from './PROSIXSHOCKimg/s1.jpg'
import s2 from './PROSIXSHOCKimg/s2.jpg'
import s3 from './PROSIXSHOCKimg/s3.jpg'
import s4 from './PROSIXSHOCKimg/s4.jpg'
import s5 from './PROSIXSHOCKimg/s5.jpg'
import s6 from './PROSIXSHOCKimg/s6.jpg'
import s7 from './PROSIXSHOCKimg/s7.jpg'
import s8 from './PROSIXSHOCKimg/s8.jpg'
import s9 from './PROSIXSHOCKimg/s9.jpg'
import s10 from './PROSIXSHOCKimg/s10.jpg'
import { getPrice } from '../../../../../../Prices';
const PROSIXSHOCK =()=>{
    const sensor = {
        title: 'Door/Window & Shock Sensor',
        model:'PROSIXSHOCK',
        sku:['PROSIXSHOCK'],
        modelViewImage:s1,
        image:[s1,s10,s2,s3,s4,s5,s6,s7,s8,s9,bat],
        price: getPrice('PROSIXSHOCK'),
        checkListKeyword:['Burglar'],
        shortDescription:'Two-in-One: Detects openings, closings, and vibrations associated with a sharp hit, providing ultimate protection for glass doors or large windows.',
        infoHeader:"Door/Window & Shock Sensor",
        spec:[
            {row1:'Model:',row2:'PROSIXSHOCK'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Surface mount'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'1'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Product Length:',row2:'3.1 in, 78.7 mm'},
            {row1:'Product Width:',row2:'1.6 in, 40.6 mm'},
            {row1:'Product Height:',row2:'1.4 in, 27.9 mm'},
        ],
        info:[
            {
                header:'How does it work?',
                text:'The PROSIXSHOCK wireless shock sensor is a versatile security device designed to provide dual functionality for enhanced protection of doors, windows, and other vulnerable surfaces. Unlike traditional sensors that focus solely on detecting openings, the PROSIXSHOCK offers a unique "two-in-one" design, serving as both a door/window contact sensor and a vibration sensor.',
                section: [
                    {title: "Door/Window Contact Sensor"},
                    {text: "When installed on doors or windows, the PROSIXSHOCK functions as a reliable contact sensor, detecting openings and closings with precision. It monitors the status of doors and windows, triggering alerts or alarms when unauthorized entry is attempted."},

                    {title: "Components"},
                    {text: "The PROSIXCT sensor consists of two main components: a magnet and a sensor. The magnet is typically installed on the moving part of the door or window (such as the door frame or window frame), while the sensor is installed on the stationary part (such as the door or window itself)."},

                    {title: "Magnetic Field"},
                    {text: "When the door or window is closed, the magnet and sensor are in close proximity, and their magnetic fields align. This alignment indicates that the door or window is securely closed, and the sensor detects this state as a \"closed\" position."},

                    {title: "Opening Detection"},
                    {text: "When the door or window is opened, the magnet moves away from the sensor, disrupting the magnetic field alignment. This change in magnetic field is detected by the sensor, which then interprets it as the door or window being opened."},

                    {title: "Vibration Sensor"},
                    {text: "In addition to its role as a contact sensor, the PROSIXSHOCK is equipped with vibration sensing capabilities. It detects vibrations or shocks caused by attempts to force open or tamper with secured surfaces, such as doors, windows, or cabinets."},

                    {title: "Wireless Transmission"},
                    {text: "Upon detecting the opening of the door or window, the sensor wirelessly transmits a signal to the security system's control panel. This signal is interpreted by the system as a breach or unauthorized entry event."},
        
                    {title: "Alert or Alarm"},
                    {text: " Based on the programming and settings of the security system, the control panel can trigger various responses, such as sounding an alarm, sending notifications to a Securepol's CMS and mobile device."},
        
                    {title: "Battery-Powered Operation"},
                    {text: "The PROSIXSHOCK sensor is typically powered by batteries, ensuring that it remains operational even during power outages. The long battery life of these sensors reduces the need for frequent battery replacements."},
                ]
            },
            {
                header:'Where to place it?',
                text:'The PROSIXSHOCK wireless shock sensor is particularly well-suited for use in areas where there are doors with glass inserts or windows that could be broken to gain entry without opening the door. Here are some ideal locations to use the shock sensor:',
                section: [
                    {title: "Doors with Glass Inserts"},
                    {text: "Install the PROSIXSHOCK sensor on doors that feature glass inserts. These types of doors are vulnerable to forced entry through breaking the glass, bypassing the traditional door locks. The shock sensor will detect vibrations and impacts caused by attempts to break the glass, triggering an alarm or alerting you to the intrusion attempt."},
                    
                    {title: "Ideal for Tempered Glass Security"},
                    {text: "The PROSIXSHOCK wireless shock sensor is the ideal solution for securing areas with tempered glass, where traditional glass break detectors may not be as efficient. Tempered glass, known for its strength and safety features, poses unique challenges for intrusion detection systems. Unlike standard glass break detectors that rely on sound or frequency analysis, the PROSIXSHOCK sensor directly detects vibrations and impacts on the glass surface, ensuring reliable detection even with tempered glass."},
        
                    {title: "Glass Sliding Doors"},
                    {text: "Secure glass sliding doors with the PROSIXSHOCK sensor to monitor for any attempts to force open or shatter the glass panels. Sliding doors are often targeted by intruders due to their accessibility, making them a critical area to protect with shock sensors."},
                    
                    {title: "Large Windows"},
                    {text: "Use the PROSIXSHOCK sensor on large windows that could be broken or tampered with to gain unauthorized access. Intruders may target these windows as potential entry points, and the shock sensor provides an additional layer of security by detecting any attempts to breach the glass."},

                    {title: "Glass Showcases or Cabinets"},
                    {text: " In commercial settings such as retail stores or museums, glass showcases or cabinets containing valuable items can benefit from the PROSIXSHOCK sensor. It helps protect against theft by detecting any impacts or vibrations caused by attempts to break the glass and access the contents."},
        
                    {title: "Glass Doors in Offices or Facilities"},
                    {text: "Offices, conference rooms, or facilities with glass doors can use the PROSIXSHOCK sensor to enhance security. It ensures that unauthorized entry attempts, such as forced entry or break-ins, are promptly detected and addressed."},
        
                    {title: "Customizable Sensitivity"},
                    {text: "Tailor the sensor's sensitivity settings to match the specific characteristics of tempered glass, ensuring optimal performance in detecting varying levels of impact or vibration."},
                ]
            },
            {
                header:'When it may not detect?',
                text:'The PROSIXCT wireless door and window contact sensor, like any sensor, may have limitations in certain scenarios. Here are some situations where the sensor may not detect properly:',
                section: [
                    {title: "Lower Parts of Windows"},
                    {text: "Placing the sensor on the lower parts of windows, especially in regions where ice can form during winter, may lead to potential issues. If the sensor or its components become wet or frozen, it could impact the sensor's functionality and reliability. To mitigate this, consider installing the sensor at higher positions on windows or using protective covers or weatherproofing solutions to shield the sensor from moisture and extreme weather conditions."},
                    
                    {title: "Interference or Obstructions"},
                    {text: "Sensors placed near sources of interference, such as electronic devices, metal objects, or other wireless equipment, may experience signal interference that could affect their performance. Similarly, obstructions that block the sensor's line of sight with the magnet (e.g., thick curtains, furniture) may lead to inaccurate detections or failures to detect openings and closings."},

                    {title: "Tampering or Bypassing"},
                    {text: "Intruders with knowledge of security systems may attempt to tamper with or bypass contact sensors by manipulating the magnets or sensor components. To prevent tampering, install the sensors securely and discreetly."},
        
                    {title: "Battery Life and Maintenance"},
                    {text: "Regularly check and replace batteries in the sensors to ensure uninterrupted operation. Low battery levels can impact the sensor's performance and reliability, leading to missed detections or false alarms."},
        
                    {title: "Tampering or Damage"},
                    {text: "Physical damage to the sensor or tampering with its components can also lead to reduced sensitivity or failure to detect motion."},
                ]
            },
           
        ]
    }
    return sensor
}
export default PROSIXSHOCK;