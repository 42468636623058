import React from 'react';
import css from './ModelView.module.css';
import CloseButton from '../AppCommonComponents/CloseButton/CloseButton';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import PriceDisplay from '../AppCommonComponents/Price/PriceDisplay/PriceDisplay';
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { GoChevronRight } from "react-icons/go";
import SvgTrianglesLinesBottom from '../../assets/svg/backgrounds/svgTrianglesLinesBottom'
import SvgBackground from '../../webComponents/Hooks/SvgBackground'



const ModelView =({models, monthly, upfront,setModelIndex, basket, setBasket, modifiedModel , setView,discount})=>{
    
    const handelClick=(i)=>{
        setModelIndex(i);
        setView('info');
    }
    const updateModel=(model, price, checkListKeyword, group)=>{
        let items = basket.filter((e) => e.cat === modifiedModel.cat);
        let remainingItems = basket.filter((e) => e.cat !== modifiedModel.cat);
        items.map((e,i)=>{
            
            if(i === modifiedModel.index)
            {
                e.model = model;
                e.price = price;
                e.checkListKeyword=checkListKeyword;
                e.group = group;

            }
        })
        setBasket([...remainingItems, ...items])
    }
    const getElement=()=>{
        let items = basket.filter((e) => e.cat === modifiedModel.cat);
        return items[modifiedModel.index];
    } 
    return(
        <div className={css.sectionWrapper} style={SvgBackground(SvgTrianglesLinesBottom())}>
            <div className={css.header}>
                <NavigationElementsWrapper>   
                    <div className={css.spacer}></div>
                    <CloseButton setView={()=>setView('edit')}/>
                </NavigationElementsWrapper>
                </div>
                <div className={css.body}>
                    {models.map((model,i)=>(
                        <div key={i} className={css.itemsWrapper}>
                            <div className={`${getElement().model === model.model? css.modelCardActive:css.modelCardInActive}`}>
                                <div className={css.modelElements}>
                                    <div className={css.image}onClick={()=>handelClick(i)}>
                                        <div className={css.imageWrapper}>
                                            <div className={css.imageBox}>
                                                <img  src={model.modelViewImage} alt={'image of '+model.title}/>
                                            </div>
                                                <div className={css.imageDots} >
                                            <MdOutlineRadioButtonChecked />
                                            <MdOutlineRadioButtonUnchecked />
                                            <MdOutlineRadioButtonUnchecked />
                                            <MdOutlineRadioButtonUnchecked />
                                            <MdOutlineRadioButtonUnchecked />
                                            <MdOutlineRadioButtonUnchecked />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className={css.text}>
                                        <div className={css.title}>
                                            <h3>{model.title}</h3>
                                        </div>
                                        <div className={css.description}>
                                            <p>{model.shortDescription}</p>
                                        </div>
                                        <div className={css.more} onClick={()=>handelClick(i)}>
                                            <div><span>Learn more <GoChevronRight  viewBox="0 0 32 9" /></span></div>
                                        </div>
                                    </div>
                                    <div onClick={()=>updateModel(model.model, model.price, model.checkListKeyword,model.group)} className={css.selectionDot}>
                                    {
                                    getElement().model === model.model? 
                                        <MdOutlineRadioButtonChecked/>:<MdOutlineRadioButtonUnchecked />
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={css.footer}>
                <div className={css.priceWrapper}>
                    <PriceDisplay upfront={upfront} monthly={monthly} discount={discount}/>
                </div>
            </div>
        </div>
        
    )
}
export default ModelView;