export default function SvgWhiteSquaresBottom(){
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="640" height="480" viewBox="0, 0, 640, 480">
  <defs>
    <linearGradient id="Gradient_1" gradientUnits="userSpaceOnUse" x1="9.041" y1="449.5" x2="63.959" y2="449.5">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_2" gradientUnits="userSpaceOnUse" x1="53.953" y1="423.779" x2="108.871" y2="423.779">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_3" gradientUnits="userSpaceOnUse" x1="9.041" y1="395.961" x2="63.959" y2="395.961">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_4" gradientUnits="userSpaceOnUse" x1="53.953" y1="371.3" x2="108.871" y2="371.3">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_5" gradientUnits="userSpaceOnUse" x1="53.953" y1="475.224" x2="108.871" y2="475.224">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_6" gradientUnits="userSpaceOnUse" x1="-36.691" y1="371.3" x2="18.227" y2="371.3">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_7" gradientUnits="userSpaceOnUse" x1="9.041" y1="343.978" x2="63.959" y2="343.978">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_8" gradientUnits="userSpaceOnUse" x1="-36.691" y1="423.779" x2="18.227" y2="423.779">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_9" gradientUnits="userSpaceOnUse" x1="-36.691" y1="475.224" x2="18.227" y2="475.224">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_10" gradientUnits="userSpaceOnUse" x1="9.041" y1="500.757" x2="63.959" y2="500.757">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_11" gradientUnits="userSpaceOnUse" x1="98.107" y1="449.5" x2="153.026" y2="449.5">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_12" gradientUnits="userSpaceOnUse" x1="164.217" y1="370.387" x2="219.135" y2="370.387" gradientTransform="matrix(0.99, 0.139, -0.139, 0.99, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_13" gradientUnits="userSpaceOnUse" x1="310.689" y1="335.679" x2="365.607" y2="335.679" gradientTransform="matrix(0.935, 0.355, -0.355, 0.935, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_14" gradientUnits="userSpaceOnUse" x1="294.37" y1="419.607" x2="349.289" y2="419.607" gradientTransform="matrix(0.954, 0.301, -0.301, 0.954, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_15" gradientUnits="userSpaceOnUse" x1="398.831" y1="343.383" x2="453.749" y2="343.383" gradientTransform="matrix(0.923, 0.385, -0.385, 0.923, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_16" gradientUnits="userSpaceOnUse" x1="140.532" y1="462.851" x2="195.45" y2="462.851" gradientTransform="matrix(0.971, -0.24, 0.24, 0.971, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
    <linearGradient id="Gradient_17" gradientUnits="userSpaceOnUse" x1="282.669" y1="276.338" x2="337.588" y2="276.338" gradientTransform="matrix(0.954, 0.299, -0.299, 0.954, 0, 0)">
      <stop offset="0.5" stop-color="#3C617C"/>
      <stop offset="0.696" stop-color="#FFFFFF"/>
      <stop offset="1" stop-color="#638096"/>
    </linearGradient>
  </defs>
  <g id="Layer_1">
    <g opacity="0.05">
      <path d="M22.77,473.28 L9.041,449.5 L22.77,425.72 L50.23,425.72 L63.959,449.5 L50.23,473.28 z" fill="url(#Gradient_1)"/>
      <path d="M22.77,473.28 L9.041,449.5 L22.77,425.72 L50.23,425.72 L63.959,449.5 L50.23,473.28 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M67.683,447.559 L53.953,423.779 L67.683,399.999 L95.142,399.999 L108.871,423.779 L95.142,447.559 z" fill="url(#Gradient_2)"/>
      <path d="M67.683,447.559 L53.953,423.779 L67.683,399.999 L95.142,399.999 L108.871,423.779 L95.142,447.559 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M22.77,419.741 L9.041,395.961 L22.77,372.181 L50.23,372.181 L63.959,395.961 L50.23,419.741 z" fill="url(#Gradient_3)"/>
      <path d="M22.77,419.741 L9.041,395.961 L22.77,372.181 L50.23,372.181 L63.959,395.961 L50.23,419.741 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M67.683,395.08 L53.953,371.3 L67.683,347.519 L95.142,347.519 L108.871,371.3 L95.142,395.08 z" fill="url(#Gradient_4)"/>
      <path d="M67.683,395.08 L53.953,371.3 L67.683,347.519 L95.142,347.519 L108.871,371.3 L95.142,395.08 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M67.683,499.004 L53.953,475.224 L67.683,451.444 L95.142,451.444 L108.871,475.224 L95.142,499.004 z" fill="url(#Gradient_5)"/>
      <path d="M67.683,499.004 L53.953,475.224 L67.683,451.444 L95.142,451.444 L108.871,475.224 L95.142,499.004 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M-22.962,395.08 L-36.691,371.3 L-22.962,347.519 L4.497,347.519 L18.227,371.3 L4.497,395.08 z" fill="url(#Gradient_6)"/>
      <path d="M-22.962,395.08 L-36.691,371.3 L-22.962,347.519 L4.497,347.519 L18.227,371.3 L4.497,395.08 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M22.77,367.758 L9.041,343.978 L22.77,320.198 L50.23,320.198 L63.959,343.978 L50.23,367.758 z" fill="url(#Gradient_7)"/>
      <path d="M22.77,367.758 L9.041,343.978 L22.77,320.198 L50.23,320.198 L63.959,343.978 L50.23,367.758 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M-22.962,447.559 L-36.691,423.779 L-22.962,399.999 L4.497,399.999 L18.227,423.779 L4.497,447.559 z" fill="url(#Gradient_8)"/>
      <path d="M-22.962,447.559 L-36.691,423.779 L-22.962,399.999 L4.497,399.999 L18.227,423.779 L4.497,447.559 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M-22.962,499.004 L-36.691,475.224 L-22.962,451.444 L4.497,451.444 L18.227,475.224 L4.497,499.004 z" fill="url(#Gradient_9)"/>
      <path d="M-22.962,499.004 L-36.691,475.224 L-22.962,451.444 L4.497,451.444 L18.227,475.224 L4.497,499.004 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M22.77,524.537 L9.041,500.757 L22.77,476.977 L50.23,476.977 L63.959,500.757 L50.23,524.537 z" fill="url(#Gradient_10)"/>
      <path d="M22.77,524.537 L9.041,500.757 L22.77,476.977 L50.23,476.977 L63.959,500.757 L50.23,524.537 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M111.837,473.28 L98.107,449.5 L111.837,425.72 L139.296,425.72 L153.026,449.5 L139.296,473.28 z" fill="url(#Gradient_11)"/>
      <path d="M111.837,473.28 L98.107,449.5 L111.837,425.72 L139.296,425.72 L153.026,449.5 L139.296,473.28 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M121.392,415.086 L111.104,389.627 L128.007,367.988 L155.199,371.807 L165.488,397.266 L148.584,418.905 z" fill="url(#Gradient_12)"/>
      <path d="M121.392,415.086 L111.104,389.627 L128.007,367.988 L155.199,371.807 L165.488,397.266 L148.584,418.905 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M175.687,451.212 L171.293,424.107 L192.57,406.749 L218.241,416.497 L222.634,443.602 L201.357,460.96 z" fill="url(#Gradient_13)"/>
      <path d="M175.687,451.212 L171.293,424.107 L192.57,406.749 L218.241,416.497 L222.634,443.602 L201.357,460.96 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M160.23,515.601 L154.301,488.789 L174.556,470.249 L200.74,478.52 L206.668,505.332 L186.413,523.872 z" fill="url(#Gradient_14)"/>
      <path d="M160.23,515.601 L154.301,488.789 L174.556,470.249 L200.74,478.52 L206.668,505.332 L186.413,523.872 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M239.478,497.66 L235.958,470.427 L257.782,453.763 L283.126,464.33 L286.646,491.563 L264.822,508.228 z" fill="url(#Gradient_15)"/>
      <path d="M239.478,497.66 L235.958,470.427 L257.782,453.763 L283.126,464.33 L286.646,491.563 L264.822,508.228 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M266.665,435.312 L247.624,415.527 L255.238,389.144 L281.893,382.547 L300.934,402.332 L293.32,428.714 z" fill="url(#Gradient_16)"/>
      <path d="M266.665,435.312 L247.624,415.527 L255.238,389.144 L281.893,382.547 L300.934,402.332 L293.32,428.714 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <g opacity="0.05">
      <path d="M193.066,375.032 L187.077,348.234 L207.291,329.648 L233.493,337.861 L239.481,364.66 L219.268,383.245 z" fill="url(#Gradient_17)"/>
      <path d="M193.066,375.032 L187.077,348.234 L207.291,329.648 L233.493,337.861 L239.481,364.66 L219.268,383.245 z" fill-opacity="0" stroke="#E1E1E1" stroke-width="1" stroke-linejoin="round"/>
    </g>
    <path d="M-28.974,446.22 C-29.626,446.351 -30.971,447.879 -32.637,446.87 C-41.21,441.679 -27.769,435.198 -22.821,432.735 C4.472,419.149 41.069,405.505 103.392,409.648 C152.037,412.882 200.348,426.944 264.16,429.551 C322.42,431.93 346.581,421.672 389.521,415.877 C412.581,412.765 448.384,414.389 466.577,408.92 C490.332,401.778 478.753,385.402 484.241,373.941 C488.504,365.039 502.83,358.71 518.534,353.224" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-31.058,447.838 C-31.724,447.971 -33.128,449.509 -34.8,448.497 C-43.406,443.285 -29.582,436.75 -24.491,434.266 C3.591,420.568 41.142,406.799 104.467,410.891 C153.895,414.085 202.738,428.172 267.613,430.716 C326.845,433.039 351.664,422.691 395.491,416.811 C419.027,413.652 455.424,415.243 474.061,409.719 C498.396,402.507 486.985,386.049 492.829,374.514 C497.369,365.555 512.091,359.171 528.195,353.633" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-32.468,448.197 C-33.146,448.33 -34.635,449.849 -36.275,448.856 C-44.718,443.743 -30.461,437.27 -25.207,434.812 C3.773,421.248 42.313,407.59 106.033,411.463 C155.769,414.486 204.405,428.242 269.762,430.586 C329.432,432.727 354.963,422.472 399.47,416.57 C423.371,413.4 460.029,414.876 479.097,409.388 C503.993,402.223 493.244,386.038 499.681,374.66 C504.68,365.824 519.837,359.498 536.348,354.003" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-32.565,448.206 C-33.226,448.336 -34.635,449.849 -36.275,448.856 C-44.718,443.743 -30.954,437.314 -25.885,434.871 C2.079,421.397 39.411,407.846 101.996,411.819 C150.847,414.92 198.971,428.721 263.111,431.173 C321.671,433.412 346.362,423.23 389.795,417.423 C413.119,414.303 449.101,415.839 467.612,410.4 C491.782,403.299 480.717,387.143 486.655,375.809 C491.266,367.007 505.916,360.726 521.922,355.275" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-31.924,448.477 C-32.614,448.611 -34.146,450.145 -35.799,449.144 C-44.305,443.993 -29.74,437.455 -24.371,434.971 C5.24,421.269 44.561,407.467 109.218,411.328 C159.686,414.342 208.895,428.181 275.235,430.499 C335.803,432.616 361.868,422.254 407.144,416.269 C431.458,413.055 468.667,414.517 488.104,408.969 C513.482,401.724 502.782,385.408 509.471,373.927 C514.666,365.011 530.142,358.62 546.983,353.066" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-32.051,448.489 C-32.719,448.62 -34.146,450.145 -35.799,449.144 C-44.305,443.993 -30.39,437.512 -25.265,435.05 C3.006,421.466 40.733,407.804 103.895,411.797 C153.196,414.914 201.728,428.813 266.464,431.273 C325.569,433.519 350.525,423.254 394.386,417.394 C417.941,414.247 454.257,415.788 472.96,410.304 C497.38,403.144 486.263,386.864 492.294,375.441 C496.978,366.57 511.786,360.238 527.959,354.743" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-37.86,447.066 C-38.526,447.198 -39.929,448.737 -41.601,447.725 C-50.208,442.513 -36.383,435.977 -31.293,433.494 C-3.211,419.796 34.34,406.027 97.665,410.119 C147.094,413.313 195.936,427.4 260.812,429.944 C320.043,432.267 344.862,421.919 388.689,416.038 C412.225,412.88 448.622,414.47 467.26,408.947 C491.595,401.735 480.183,385.277 486.028,373.741 C490.567,364.783 505.289,358.399 521.394,352.861" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-34.766,448.859 C-35.459,448.99 -37.072,450.468 -38.65,449.512 C-46.773,444.593 -31.928,438.267 -26.453,435.864 C3.751,422.609 43.592,409.226 107.486,412.724 C157.36,415.454 205.324,428.585 270.982,430.596 C330.928,432.432 357.41,422.396 402.681,416.501 C426.993,413.335 463.809,414.619 483.426,409.227 C509.038,402.187 499.418,386.552 506.751,375.505 C512.447,366.926 528.185,360.738 545.227,355.349" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-30.545,447.682 C-31.213,447.816 -32.583,449.388 -34.297,448.35 C-43.124,443.005 -29.383,436.341 -24.325,433.809 C3.575,419.84 41.015,405.813 104.954,410.098 C154.862,413.443 204.498,427.93 269.955,430.634 C329.717,433.103 354.426,422.556 398.423,416.609 C422.05,413.415 458.778,415.099 477.399,409.478 C501.712,402.138 489.729,385.284 495.281,373.493 C499.594,364.336 514.244,357.828 530.311,352.188" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-32.672,449.346 C-33.354,449.482 -34.783,451.065 -36.505,450.023 C-45.365,444.657 -31.231,437.937 -26.027,435.384 C2.68,421.3 41.098,407.145 106.065,411.378 C156.775,414.682 206.957,429.193 273.504,431.834 C334.262,434.246 359.645,423.607 404.551,417.572 C428.667,414.33 466.002,415.98 485.079,410.303 C509.987,402.891 498.175,385.952 504.093,374.085 C508.688,364.869 523.744,358.306 540.222,352.613" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-34.116,449.715 C-34.811,449.851 -36.327,451.414 -38.016,450.391 C-46.708,445.128 -32.13,438.473 -26.758,435.945 C2.872,421.999 42.304,407.96 107.675,411.967 C158.699,415.096 208.667,429.266 275.706,431.702 C336.913,433.926 363.027,423.383 408.63,417.325 C433.119,414.072 470.723,415.604 490.241,409.964 C515.725,402.6 504.594,385.944 511.12,374.239 C516.188,365.149 531.689,358.645 548.585,352.997" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-34.215,449.724 C-34.892,449.858 -36.327,451.414 -38.016,450.391 C-46.708,445.128 -32.635,438.518 -27.453,436.006 C1.135,422.152 39.329,408.222 103.537,412.332 C153.655,415.54 203.097,429.757 268.89,432.303 C328.958,434.627 354.212,424.16 398.714,418.199 C422.613,414.998 459.523,416.591 478.47,411.002 C503.209,403.703 491.754,387.076 497.769,375.416 C502.44,366.361 517.422,359.903 533.799,354.3" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-33.556,450.004 C-34.263,450.141 -35.824,451.718 -37.525,450.688 C-46.282,445.386 -31.389,438.663 -25.9,436.109 C4.376,422.022 44.609,407.833 110.94,411.829 C162.716,414.948 213.271,429.205 281.318,431.614 C343.446,433.814 370.107,423.161 416.498,417.019 C441.411,413.72 479.579,415.238 499.474,409.536 C525.452,402.09 514.371,385.298 521.155,373.488 C526.423,364.315 542.251,357.745 559.485,352.036" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-33.686,450.015 C-34.37,450.151 -35.824,451.718 -37.525,450.688 C-46.282,445.386 -32.056,438.722 -26.816,436.19 C2.086,422.223 40.685,408.179 105.485,412.31 C156.064,415.534 205.926,429.852 272.329,432.407 C332.956,434.738 358.481,424.186 403.422,418.171 C427.557,414.942 464.809,416.54 483.953,410.904 C508.949,403.545 497.44,386.791 503.549,375.04 C508.294,365.914 523.438,359.404 539.987,353.755" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-39.652,448.549 C-40.334,448.685 -41.764,450.268 -43.485,449.226 C-52.346,443.86 -38.212,437.141 -33.008,434.587 C-4.3,420.503 34.117,406.349 99.085,410.581 C149.795,413.885 199.977,428.396 266.524,431.037 C327.282,433.449 352.665,422.81 397.571,416.775 C421.687,413.533 459.022,415.183 478.099,409.506 C503.006,402.094 491.195,385.155 497.112,373.288 C501.708,364.072 516.764,357.509 533.242,351.817" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-36.468,450.395 C-37.178,450.53 -38.822,452.05 -40.446,451.066 C-48.809,446.002 -33.628,439.497 -28.029,437.027 C2.858,423.399 43.626,409.643 109.174,413.265 C160.337,416.092 209.612,429.619 276.958,431.712 C338.445,433.622 365.536,423.305 411.923,417.256 C436.834,414.007 474.598,415.341 494.679,409.8 C520.898,402.565 510.929,386.475 518.376,375.111 C524.16,366.285 540.259,359.924 557.7,354.385" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-36.315,441.774 C-36.967,441.905 -38.313,443.434 -39.978,442.425 C-48.552,437.233 -35.11,430.752 -30.163,428.289 C-2.869,414.704 33.728,401.059 96.05,405.202 C144.696,408.436 193.007,422.499 256.819,425.105 C315.079,427.485 339.24,417.227 382.179,411.432 C405.239,408.319 441.043,409.944 459.236,404.474 C482.991,397.333 471.411,380.957 476.9,369.495 C481.162,360.594 495.489,354.264 511.192,348.778" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-38.399,443.393 C-39.065,443.525 -40.469,445.064 -42.141,444.052 C-50.748,438.84 -36.923,432.304 -31.832,429.821 C-3.75,416.123 33.8,402.354 97.126,406.446 C146.554,409.64 195.396,423.726 260.272,426.271 C319.504,428.594 344.323,418.246 388.149,412.365 C411.686,409.207 448.083,410.797 466.72,405.274 C491.055,398.061 479.643,381.603 485.488,370.068 C490.027,361.11 504.75,354.726 520.854,349.188" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-39.809,443.752 C-40.488,443.884 -41.976,445.403 -43.617,444.41 C-52.06,439.298 -37.802,432.825 -32.549,430.366 C-3.568,416.802 34.972,403.145 98.692,407.018 C148.428,410.041 197.064,423.796 262.42,426.141 C322.091,428.282 347.622,418.027 392.128,412.124 C416.029,408.954 452.688,410.43 471.756,404.942 C496.652,397.777 485.903,381.593 492.34,370.215 C497.339,361.379 512.495,355.053 529.007,349.558" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-39.906,443.76 C-40.567,443.891 -41.976,445.403 -43.617,444.41 C-52.06,439.298 -38.296,432.868 -33.226,430.426 C-5.262,416.951 32.069,403.401 94.655,407.374 C143.506,410.475 191.629,424.275 255.769,426.727 C314.329,428.966 339.021,418.785 382.453,412.977 C405.778,409.858 441.76,411.394 460.271,405.955 C484.441,398.854 473.375,382.697 479.313,371.363 C483.925,362.561 498.575,356.28 514.58,350.83" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-39.265,444.032 C-39.956,444.166 -41.488,445.699 -43.14,444.699 C-51.646,439.548 -37.081,433.009 -31.713,430.526 C-2.101,416.824 37.22,403.021 101.877,406.882 C152.345,409.896 201.553,423.735 267.893,426.054 C328.462,428.171 354.526,417.809 399.802,411.824 C424.117,408.609 461.326,410.072 480.762,404.523 C506.141,397.279 495.441,380.962 502.13,369.482 C507.325,360.565 522.801,354.174 539.642,348.62" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-39.393,444.043 C-40.06,444.175 -41.488,445.699 -43.14,444.699 C-51.646,439.548 -37.732,433.067 -32.607,430.604 C-4.335,417.021 33.392,403.359 96.554,407.352 C145.855,410.468 194.387,424.367 259.123,426.827 C318.227,429.073 343.184,418.809 387.045,412.948 C410.599,409.801 446.915,411.342 465.618,405.859 C490.039,398.698 478.922,382.419 484.952,370.996 C489.636,362.125 504.444,355.793 520.618,350.297" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-45.201,442.62 C-45.867,442.753 -47.271,444.292 -48.943,443.279 C-57.549,438.068 -43.725,431.532 -38.634,429.049 C-10.552,415.351 26.999,401.582 90.324,405.673 C139.752,408.867 188.595,422.954 253.47,425.498 C312.702,427.822 337.521,417.474 381.348,411.593 C404.884,408.435 441.281,410.025 459.918,404.501 C484.253,397.289 472.842,380.831 478.686,369.296 C483.226,360.337 497.948,353.953 514.052,348.416" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-42.107,444.414 C-42.8,444.544 -44.413,446.022 -45.991,445.067 C-54.114,440.148 -39.27,433.821 -33.794,431.418 C-3.59,418.164 36.25,404.78 100.145,408.278 C150.018,411.009 197.983,424.139 263.641,426.15 C323.586,427.986 350.069,417.95 395.34,412.056 C419.652,408.89 456.468,410.173 476.084,404.782 C501.697,397.741 492.077,382.107 499.41,371.06 C505.105,362.48 520.844,356.292 537.886,350.903" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-37.887,443.236 C-38.554,443.371 -39.924,444.943 -41.639,443.904 C-50.465,438.56 -36.724,431.896 -31.666,429.363 C-3.767,415.394 33.674,401.368 97.613,405.653 C147.521,408.997 197.157,423.484 262.614,426.188 C322.376,428.658 347.085,418.111 391.081,412.164 C414.709,408.97 451.436,410.653 470.058,405.032 C494.371,397.692 482.388,380.839 487.94,369.047 C492.252,359.89 506.902,353.383 522.97,347.743" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-40.013,444.901 C-40.695,445.037 -42.125,446.62 -43.846,445.577 C-52.707,440.212 -38.573,433.492 -33.369,430.939 C-4.661,416.854 33.756,402.7 98.724,406.933 C149.434,410.236 199.616,424.748 266.163,427.389 C326.921,429.8 352.304,419.162 397.21,413.126 C421.326,409.885 458.661,411.534 477.738,405.857 C502.645,398.445 490.834,381.507 496.751,369.64 C501.347,360.424 516.403,353.86 532.881,348.168" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-41.457,445.27 C-42.152,445.406 -43.668,446.968 -45.357,445.946 C-54.049,440.682 -39.471,434.027 -34.1,431.499 C-4.47,417.553 34.963,403.514 100.333,407.522 C151.358,410.65 201.326,424.82 268.365,427.256 C329.572,429.48 355.686,418.937 401.288,412.88 C425.778,409.627 463.382,411.158 482.899,405.518 C508.383,398.155 497.253,381.498 503.779,369.793 C508.847,360.703 524.348,354.2 541.244,348.551" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-41.556,445.279 C-42.233,445.413 -43.668,446.968 -45.357,445.946 C-54.049,440.682 -39.977,434.072 -34.794,431.561 C-6.206,417.706 31.988,403.776 96.196,407.887 C146.314,411.095 195.756,425.312 261.548,427.857 C321.617,430.182 346.87,419.714 391.372,413.754 C415.272,410.553 452.181,412.146 471.129,406.556 C495.868,399.258 484.413,382.63 490.428,370.97 C495.099,361.915 510.081,355.458 526.458,349.855" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-40.897,445.558 C-41.604,445.696 -43.165,447.273 -44.866,446.243 C-53.623,440.94 -38.73,434.217 -33.241,431.664 C-2.965,417.576 37.267,403.388 103.599,407.384 C155.375,410.503 205.93,424.759 273.977,427.169 C336.105,429.368 362.765,418.715 409.156,412.573 C434.07,409.275 472.237,410.793 492.133,405.09 C518.111,397.645 507.03,380.853 513.813,369.042 C519.082,359.87 534.91,353.3 552.144,347.59" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-41.028,445.57 C-41.711,445.705 -43.165,447.273 -44.866,446.243 C-53.623,440.94 -39.397,434.276 -34.157,431.744 C-5.255,417.778 33.344,403.734 98.144,407.865 C148.723,411.089 198.584,425.407 264.988,427.961 C325.615,430.293 351.14,419.74 396.081,413.726 C420.216,410.496 457.468,412.095 476.612,406.459 C501.607,399.1 490.099,382.345 496.208,370.594 C500.953,361.468 516.097,354.958 532.646,349.309" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-46.993,444.104 C-47.676,444.24 -49.105,445.823 -50.826,444.78 C-59.687,439.415 -45.553,432.695 -40.349,430.142 C-11.641,416.057 26.776,401.903 91.743,406.136 C142.454,409.439 192.635,423.951 259.182,426.592 C319.94,429.003 345.324,418.365 390.229,412.329 C414.345,409.088 451.681,410.737 470.757,405.06 C495.665,397.648 483.853,380.71 489.771,368.843 C494.367,359.627 509.422,353.063 525.901,347.371" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-43.809,445.95 C-44.519,446.084 -46.163,447.604 -47.788,446.62 C-56.15,441.556 -40.969,435.052 -35.37,432.581 C-4.483,418.954 36.285,405.198 101.832,408.819 C152.996,411.646 202.271,425.174 269.616,427.266 C331.103,429.177 358.195,418.86 404.582,412.81 C429.493,409.561 467.257,410.895 487.337,405.354 C513.557,398.12 503.588,382.029 511.035,370.665 C516.819,361.839 532.918,355.478 550.359,349.939" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-91.439,307.246 C-92.131,307.28 -96.581,310.784 -95.127,307.205 C-87.642,288.783 -64.28,277.758 -55.495,273.489 C-7.036,249.943 46.967,230.849 73.208,273.064 C93.689,306.015 87.982,368.359 118.776,407.118 C146.891,442.506 185.544,426.488 224.387,431.965 C245.247,434.907 262.136,457.095 285.829,451.189 C316.765,443.476 349.352,393.035 380.052,364.473 C403.894,342.291 427.407,332.119 449.695,324.931" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-96.533,310.63 C-97.238,310.661 -101.747,314.165 -100.287,310.572 C-92.774,292.079 -69.058,281.096 -60.141,276.842 C-10.954,253.379 43.902,234.418 70.847,276.992 C91.879,310.222 86.423,372.894 117.983,412.012 C146.798,447.728 186.048,431.791 225.612,437.474 C246.859,440.526 264.175,462.916 288.255,457.082 C319.698,449.465 352.579,398.883 383.663,370.295 C407.804,348.094 431.677,337.97 454.321,330.841" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-98.214,310.911 C-98.925,310.935 -103.437,314.344 -102.005,310.82 C-94.635,292.678 -70.819,282.081 -61.866,277.975 C-12.479,255.324 42.683,237.158 70.383,279.33 C92.004,312.247 87.224,373.912 119.542,412.724 C149.049,448.159 188.491,432.829 228.501,438.789 C249.988,441.99 267.73,464.199 291.974,458.676 C323.63,451.464 356.239,401.958 387.289,374.094 C411.404,352.454 435.388,342.705 458.167,335.894" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-98.291,310.886 C-98.988,310.915 -103.437,314.344 -102.005,310.82 C-94.634,292.678 -71.21,281.955 -62.403,277.802 C-13.821,254.891 40.384,236.417 67.185,278.299 C88.104,310.99 82.918,372.524 114.273,411.026 C142.9,446.178 181.676,430.633 220.836,436.319 C241.866,439.373 259.072,461.409 282.875,455.744 C313.955,448.347 346.314,398.76 376.969,370.769 C400.777,349.03 424.36,339.151 446.737,332.211" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-98.571,311.945 C-99.293,311.968 -103.864,315.394 -102.421,311.843 C-94.995,293.566 -70.843,282.942 -61.763,278.824 C-11.679,256.11 44.285,237.936 72.557,280.54 C94.626,313.794 89.976,375.972 122.929,415.2 C153.015,451.015 193.023,435.658 233.678,441.775 C255.511,445.06 273.604,467.505 298.21,461.998 C330.336,454.809 363.29,404.967 394.734,376.947 C419.153,355.187 443.48,345.418 466.592,338.608" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-98.672,311.913 C-99.375,311.941 -103.863,315.394 -102.421,311.843 C-94.995,293.566 -71.358,282.776 -62.471,278.596 C-13.449,255.54 41.252,236.959 68.34,279.181 C89.484,312.138 84.298,374.143 115.981,412.961 C144.906,448.403 184.036,432.763 223.571,438.518 C244.802,441.609 262.188,463.826 286.212,458.132 C317.579,450.699 350.203,400.75 381.125,372.562 C405.14,350.671 428.938,340.732 451.521,333.752" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-98.629,305.149 C-99.333,305.18 -103.842,308.684 -102.383,305.091 C-94.869,286.598 -71.154,275.615 -62.237,271.361 C-13.049,247.898 41.806,228.937 68.752,271.511 C89.784,304.741 84.327,367.413 115.888,406.531 C144.703,442.247 183.953,426.311 223.517,431.993 C244.764,435.045 262.079,457.435 286.16,451.601 C317.602,443.984 350.484,393.402 381.568,364.815 C405.709,342.613 429.582,332.489 452.225,325.36" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-101.137,311.578 C-101.853,311.591 -106.337,314.824 -104.959,311.434 C-97.868,293.979 -74.062,284.072 -65.115,280.229 C-15.76,259.029 39.499,242.257 68.201,283.491 C90.604,315.677 86.933,375.304 120.228,413.354 C150.627,448.094 190.094,433.834 230.531,440.195 C252.246,443.611 270.541,465.372 294.873,460.383 C326.644,453.869 358.59,406.424 389.367,379.906 C413.268,359.313 437.261,350.228 460.092,343.97" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-95.86,310.457 C-96.571,310.493 -101.139,314.105 -99.643,310.42 C-91.937,291.454 -67.962,280.079 -58.946,275.675 C-9.215,251.383 46.195,231.664 73.033,275.069 C93.982,308.949 88.027,373.106 119.54,412.949 C148.312,449.326 187.976,432.793 227.798,438.378 C249.184,441.377 266.467,464.187 290.773,458.078 C322.509,450.102 356.009,398.156 387.538,368.725 C412.023,345.869 436.151,335.372 459.018,327.946" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M-101.09,313.946 C-101.813,313.979 -106.443,317.59 -104.94,313.892 C-97.205,294.852 -72.867,283.52 -63.716,279.131 C-13.238,254.924 43.045,235.342 70.606,279.113 C92.119,313.28 86.42,377.775 118.719,417.986 C148.209,454.699 188.484,438.249 229.046,444.045 C250.829,447.157 268.548,470.174 293.251,464.139 C325.506,456.26 359.308,404.169 391.231,374.712 C416.023,351.836 440.52,341.387 463.752,334.022" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-102.815,314.237 C-103.544,314.263 -108.176,317.777 -106.702,314.148 C-99.114,295.471 -74.674,284.536 -65.487,280.299 C-14.805,256.926 41.791,238.162 70.126,281.52 C92.243,315.363 87.239,378.821 120.316,418.716 C150.516,455.14 190.987,439.313 232.006,445.394 C254.034,448.66 272.191,471.489 297.062,465.774 C329.535,458.312 363.056,407.329 394.943,378.616 C419.707,356.318 444.318,346.254 467.687,339.216" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-102.893,314.212 C-103.608,314.242 -108.176,317.776 -106.702,314.148 C-99.114,295.471 -75.075,284.407 -66.037,280.121 C-16.18,256.483 39.434,237.403 66.848,280.464 C88.246,314.075 82.826,377.399 114.916,416.976 C144.214,453.109 184.003,437.063 224.15,442.863 C245.71,445.978 263.317,468.63 287.736,462.769 C319.62,455.118 352.884,404.051 384.366,375.208 C408.815,352.808 433.015,342.612 455.973,335.441" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-103.182,315.302 C-103.922,315.326 -108.615,318.857 -107.13,315.202 C-99.485,296.385 -74.7,285.422 -65.383,281.173 C-13.987,257.735 43.432,238.96 72.354,282.762 C94.929,316.952 90.059,380.938 123.786,421.26 C154.578,458.074 195.63,442.219 237.31,448.46 C259.694,451.811 278.21,474.883 303.451,469.185 C336.407,461.745 370.282,410.416 402.572,381.542 C427.649,359.119 452.612,349.035 476.323,341.998" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-103.285,315.268 C-104.007,315.299 -108.615,318.857 -107.13,315.202 C-99.485,296.385 -75.229,285.252 -66.109,280.939 C-15.801,257.15 40.324,237.959 68.032,281.37 C89.659,315.254 84.239,379.063 116.664,418.965 C146.268,455.396 186.419,439.251 226.951,445.122 C248.717,448.275 266.509,471.113 291.153,465.223 C323.332,457.532 356.869,406.094 388.624,377.048 C413.286,354.491 437.706,344.232 460.876,337.021" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-103.23,308.309 C-103.953,308.342 -108.582,311.953 -107.08,308.254 C-99.344,289.215 -75.007,277.882 -65.856,273.494 C-15.378,249.287 40.905,229.704 68.466,273.476 C89.979,307.642 84.28,372.138 116.58,412.349 C146.069,449.062 186.344,432.612 226.906,438.408 C248.689,441.52 266.408,464.537 291.112,458.502 C323.367,450.623 357.168,398.532 389.091,369.075 C413.883,346.199 438.38,335.75 461.612,328.385" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-114.529,291.356 C-115.28,291.346 -120.298,294.88 -118.515,291.06 C-109.337,271.398 -83.423,260.915 -73.672,256.838 C-19.881,234.352 39.645,217.118 65.54,265.126 C85.753,302.599 75.915,370.474 106.939,415.009 C135.263,455.669 178.007,440.758 219.692,449.398 C242.078,454.037 259.039,479.482 285.009,474.625 C318.919,468.285 357.127,415.271 392.007,386.084 C419.096,363.417 445.122,353.879 469.648,347.524" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M508.727,363.81 C509.488,363.458 513.019,359.417 512.871,362.058 C512.106,375.656 490.517,392.877 482.45,399.419 C437.949,435.505 385.405,471.842 338.601,461.552 C302.068,453.52 282.7,416.94 232.228,410.715 C186.147,405.032 149.509,432.317 103.78,448.026 C79.222,456.463 51.155,452.544 27.082,467.145 C-4.351,486.21 -19.979,529.259 -42.534,559.527 C-60.051,583.033 -82.161,599.862 -104.133,614.483" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M513.03,359.523 C513.806,359.167 517.404,355.097 517.255,357.749 C516.487,371.4 494.485,388.767 486.264,395.364 C440.914,431.756 387.361,468.43 339.62,458.285 C302.356,450.366 282.571,413.728 231.094,407.678 C184.094,402.153 146.755,429.681 100.133,445.626 C75.096,454.189 46.47,450.365 21.932,465.114 C-10.106,484.371 -26.006,527.638 -48.981,558.103 C-66.824,581.763 -89.356,598.74 -111.751,613.5" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M514.795,358.562 C515.581,358.207 519.221,354.187 519.075,356.789 C518.321,370.18 496.048,387.385 487.726,393.92 C441.817,429.967 387.592,466.357 339.174,456.793 C301.381,449.327 281.255,413.561 229.055,408.041 C181.397,403.002 143.593,430.293 96.357,446.301 C70.99,454.899 41.962,451.379 17.112,466.037 C-15.332,485.177 -31.372,527.729 -54.608,557.786 C-72.653,581.129 -95.465,597.956 -118.142,612.608" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M514.891,358.539 C515.659,358.188 519.221,354.187 519.075,356.788 C518.322,370.18 496.538,387.266 488.398,393.756 C443.497,429.556 390.472,465.653 343.178,455.814 C306.263,448.133 286.646,412.243 235.653,406.428 C189.097,401.119 152.126,428.207 105.955,443.955 C81.16,452.413 52.803,448.729 28.506,463.252 C-3.219,482.215 -18.944,524.691 -41.685,554.627 C-59.346,577.875 -81.656,594.58 -103.83,609.109" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M514.766,357.83 C515.565,357.47 519.265,353.412 519.117,356.033 C518.358,369.524 495.719,386.907 487.26,393.508 C440.597,429.925 385.479,466.706 336.24,457.182 C297.807,449.748 277.324,413.767 224.242,408.326 C175.779,403.359 137.353,430.937 89.33,447.171 C63.541,455.889 34.022,452.411 8.762,467.233 C-24.219,486.587 -40.506,529.487 -64.117,559.818 C-82.454,583.373 -105.642,600.376 -128.692,615.187" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M514.893,357.799 C515.669,357.445 519.265,353.412 519.117,356.033 C518.358,369.524 496.365,386.749 488.147,393.292 C442.813,429.383 389.276,465.777 341.521,455.891 C304.245,448.174 284.433,412.029 232.943,406.199 C185.932,400.877 148.605,428.186 101.986,444.077 C76.951,452.611 48.317,448.916 23.786,463.561 C-8.245,482.682 -24.119,525.481 -47.077,555.652 C-64.907,579.083 -87.432,595.924 -109.82,610.574" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M517.639,361.49 C518.415,361.134 522.013,357.064 521.864,359.716 C521.096,373.366 499.094,390.734 490.873,397.331 C445.523,433.723 391.97,470.397 344.229,460.252 C306.965,452.333 287.18,415.695 235.703,409.644 C188.703,404.12 151.364,431.647 104.742,447.593 C79.705,456.156 51.079,452.332 26.541,467.08 C-5.497,486.338 -21.397,529.604 -44.372,560.07 C-62.215,583.73 -84.747,600.707 -107.142,615.467" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M517.79,356.795 C518.586,356.443 522.268,352.532 522.127,355.034 C521.402,367.918 498.855,384.745 490.43,391.134 C443.958,426.378 389.049,462.059 339.896,453.485 C301.529,446.792 281.005,412.666 228.027,408.029 C179.658,403.796 141.383,430.518 93.505,446.515 C67.794,455.105 38.331,452.094 13.162,466.505 C-19.699,485.322 -35.847,526.436 -59.334,555.63 C-77.573,578.303 -100.669,594.773 -123.633,609.151" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M512.348,359.941 C513.128,359.579 516.749,355.422 516.596,358.142 C515.809,372.141 493.678,389.846 485.409,396.572 C439.792,433.676 385.933,471.028 337.968,460.38 C300.529,452.069 280.689,414.385 228.964,407.918 C181.739,402.014 144.183,430.065 97.313,446.187 C72.143,454.845 43.379,450.778 18.704,465.784 C-13.515,485.377 -29.544,529.682 -52.668,560.819 C-70.626,585 -93.289,602.302 -115.811,617.331" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" opacity="0.241"/>
    <path d="M516.76,355.532 C517.555,355.166 521.244,350.98 521.09,353.71 C520.299,367.763 497.746,385.619 489.319,392.402 C442.832,429.819 387.938,467.517 339.013,457.017 C300.825,448.822 280.558,411.078 227.802,404.79 C179.636,399.048 141.361,427.347 93.576,443.711 C67.915,452.499 38.578,448.53 13.426,463.687 C-19.414,483.477 -35.72,528.005 -59.274,559.346 C-77.567,583.686 -100.664,601.139 -123.619,616.31" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M518.569,354.545 C519.374,354.18 523.106,350.045 522.956,352.723 C522.18,366.509 499.349,384.199 490.817,390.917 C443.758,427.979 388.176,465.383 338.556,455.481 C299.825,447.751 279.209,410.904 225.713,405.162 C176.871,399.919 138.12,427.974 89.706,444.403 C63.707,453.225 33.957,449.568 8.486,464.632 C-24.77,484.301 -41.219,528.093 -65.041,559.013 C-83.541,583.025 -106.925,600.324 -130.168,615.384" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M518.667,354.52 C519.455,354.16 523.106,350.045 522.956,352.723 C522.18,366.509 499.85,384.076 491.507,390.749 C445.48,427.557 391.127,464.662 342.66,454.477 C304.83,446.528 284.735,409.553 232.476,403.509 C184.763,397.99 146.866,425.836 99.543,441.998 C74.129,450.677 45.068,446.852 20.163,461.777 C-12.354,481.265 -28.482,524.979 -51.796,555.775 C-69.902,579.691 -92.771,596.864 -115.5,611.798" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M518.54,353.79 C519.359,353.421 523.151,349.247 522.999,351.945 C522.218,365.834 499.012,383.705 490.34,390.493 C442.508,427.933 386.01,465.739 335.55,455.878 C296.163,448.181 275.18,411.112 220.78,405.45 C171.114,400.28 131.725,428.63 82.505,445.29 C56.072,454.237 25.819,450.621 -0.072,465.854 C-33.878,485.743 -50.581,529.893 -74.787,561.094 C-93.586,585.325 -117.354,602.804 -140.982,618.028" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M518.67,353.758 C519.465,353.395 523.151,349.247 522.999,351.945 C522.218,365.834 499.673,383.544 491.249,390.271 C444.779,427.378 389.902,464.788 340.962,454.555 C302.762,446.567 282.466,409.33 229.698,403.27 C181.52,397.737 143.257,425.811 95.476,442.119 C69.816,450.877 40.471,447.04 15.326,462.09 C-17.506,481.74 -33.785,525.787 -57.322,556.825 C-75.602,580.928 -98.691,598.242 -121.639,613.299" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M521.483,357.562 C522.278,357.197 525.967,353.01 525.813,355.74 C525.023,369.794 502.469,387.65 494.042,394.433 C447.555,431.849 392.661,469.547 343.737,459.048 C305.548,450.852 285.281,413.108 232.525,406.82 C184.359,401.079 146.084,429.378 98.3,445.741 C72.638,454.529 43.301,450.56 18.149,465.717 C-14.69,485.507 -30.997,530.035 -54.551,561.376 C-72.843,585.716 -95.941,603.169 -118.895,618.34" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M521.639,352.728 C522.455,352.367 526.229,348.344 526.084,350.921 C525.338,364.184 502.226,381.483 493.59,388.052 C445.953,424.286 389.67,460.959 339.297,452.076 C299.979,445.141 278.953,409.982 224.659,405.148 C175.089,400.735 135.856,428.203 86.784,444.619 C60.43,453.434 30.236,450.301 4.438,465.109 C-29.246,484.445 -45.806,526.757 -69.883,556.788 C-88.583,580.111 -112.256,597.042 -135.795,611.818" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="Shape" opacity="0.241"/>
    <path d="M-109.82,386.453 C-26.311,341.163 444.381,425.668 941.5,575.201" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linejoin="round" opacity="0.635"/>
    <path d="M812.403,297.689 C725.418,424.864 214.26,583.38 -329.299,651.745" fill-opacity="0" stroke="#E5EEF2" stroke-width="1" stroke-linejoin="round" opacity="0.383"/>
  </g>
</svg>
`;
}