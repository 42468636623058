import React, { useEffect } from 'react';
import css from './Spinner.module.css';
import RotateLoader from 'react-spinners/RotateLoader';

const Spinner = ({ step, setStep, message, spinnerTime=3000}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setStep(step + 1);
    }, spinnerTime);

    return () => clearTimeout(timer);
  }, [step, setStep]);

  return (
    <>
      <div className={css.procceedBox}>
        <div className={css.messageBox}>
          <div className={css.spinnerContainer}>
            <RotateLoader  size={12} color="#274E65"/>
              <div className={css.message}>
                <p>{message}</p>
              </div>
          </div>
        </div>
      </div>
      <div className={css.buttonBox}>
      </div>
    </>
  );
};

export default Spinner;
