import React from 'react';
import css from './NavigationElementsWrapper.module.css';

const NavigationElementsWrapper = ({ children})=>{
   return(
    <div className={css.elementsWrapper}>
        {children}
    </div>  
   )
}
export default NavigationElementsWrapper;