export default function SvgGrayWaveWithIcons(){
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="100" height="30" viewBox="0, 0, 100, 30">
    <defs>
      <filter id="Shadow_1">
        <feGaussianBlur in="SourceAlpha" stdDeviation="0"/>
        <feOffset dx="-0.1" dy="0.4" result="offsetblur"/>
        <feFlood flood-color="#000000"/>
        <feComposite in2="offsetblur" operator="in"/>
      </filter>
      <filter id="Shadow_2">
        <feGaussianBlur in="SourceAlpha" stdDeviation="0"/>
        <feOffset dx="-0.1" dy="0.4" result="offsetblur"/>
        <feFlood flood-color="#000000"/>
        <feComposite in2="offsetblur" operator="in"/>
      </filter>
      <filter id="Shadow_3">
        <feGaussianBlur in="SourceAlpha" stdDeviation="0"/>
        <feOffset dx="-0.1" dy="0.4" result="offsetblur"/>
        <feFlood flood-color="#000000"/>
        <feComposite in2="offsetblur" operator="in"/>
      </filter>
    </defs>
    <g id="Layer_1">
      <g id="Shape">
        <path d="M109.765,31.098 C18.054,30.522 -56.065,22.813 -56.017,15.237" fill="#F8F8F8"/>
        <path d="M109.765,31.098 C18.054,30.522 -56.065,22.813 -56.017,15.237" fill-opacity="0" stroke="#F8F8F8" stroke-width="16.5" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M74.062,18.801 L76.171,18.854 C77.084,18.877 77.657,19.608 77.68,20.368 L77.739,22.297 C77.765,23.112 77.152,23.926 76.159,23.901 L74.049,23.848 C73.137,23.825 72.563,23.094 72.54,22.334 L72.481,20.405 C72.456,19.59 73.068,18.776 74.062,18.801 z" filter="url(#Shadow_1)" fill="rgba(0,0,0,0.033)"/>
        <path d="M74.024,20.301 L76.134,20.354 C76.159,20.355 76.18,20.382 76.181,20.415 L76.24,22.343 C76.241,22.376 76.222,22.402 76.196,22.402 L74.087,22.348 C74.062,22.348 74.04,22.321 74.039,22.288 L73.98,20.359 C73.979,20.326 73.999,20.3 74.024,20.301 z" fill="#FFFFFF"/>
        <path d="M74.024,20.301 L76.134,20.354 C76.159,20.355 76.18,20.382 76.181,20.415 L76.24,22.343 C76.241,22.376 76.222,22.402 76.196,22.402 L74.087,22.348 C74.062,22.348 74.04,22.321 74.039,22.288 L73.98,20.359 C73.979,20.326 73.999,20.3 74.024,20.301 z" fill-opacity="0" stroke="#FFFFFF" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M67.458,18.541 L69.568,18.594 C70.48,18.618 71.053,19.348 71.076,20.108 L71.136,22.037 C71.161,22.852 70.548,23.666 69.555,23.641 L67.445,23.588 C66.533,23.565 65.96,22.834 65.936,22.074 L65.877,20.145 C65.852,19.33 66.465,18.516 67.458,18.541 z" filter="url(#Shadow_2)" fill="rgba(0,0,0,0.033)"/>
        <path d="M67.42,20.041 L69.53,20.094 C69.555,20.095 69.576,20.122 69.577,20.155 L69.637,22.083 C69.638,22.116 69.618,22.142 69.593,22.142 L67.483,22.088 C67.458,22.088 67.437,22.061 67.436,22.028 L67.377,20.099 C67.376,20.066 67.395,20.04 67.42,20.041 z" fill="#FFFFFF"/>
        <path d="M67.42,20.041 L69.53,20.094 C69.555,20.095 69.576,20.122 69.577,20.155 L69.637,22.083 C69.638,22.116 69.618,22.142 69.593,22.142 L67.483,22.088 C67.458,22.088 67.437,22.061 67.436,22.028 L67.377,20.099 C67.376,20.066 67.395,20.04 67.42,20.041 z" fill-opacity="0" stroke="#FFFFFF" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M80.48,18.931 L82.59,18.984 C83.503,19.007 84.076,19.738 84.099,20.498 L84.158,22.427 C84.183,23.242 83.571,24.056 82.578,24.031 L80.468,23.978 C79.555,23.955 78.982,23.224 78.959,22.464 L78.9,20.535 C78.875,19.72 79.487,18.906 80.48,18.931 z" filter="url(#Shadow_3)" fill="rgba(0,0,0,0.033)"/>
        <path d="M80.443,20.431 L82.552,20.484 C82.578,20.484 82.599,20.511 82.6,20.544 L82.659,22.473 C82.66,22.506 82.641,22.532 82.615,22.531 L80.506,22.478 C80.481,22.477 80.459,22.45 80.458,22.418 L80.399,20.489 C80.398,20.456 80.418,20.43 80.443,20.431 z" fill="#FFFFFF"/>
        <path d="M80.443,20.431 L82.552,20.484 C82.578,20.484 82.599,20.511 82.6,20.544 L82.659,22.473 C82.66,22.506 82.641,22.532 82.615,22.531 L80.506,22.478 C80.481,22.477 80.459,22.45 80.458,22.418 L80.399,20.489 C80.398,20.456 80.418,20.43 80.443,20.431 z" fill-opacity="0" stroke="#FFFFFF" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <path d="M94.729,11.617 L96.524,11.617 L96.523,9.824 L98.32,9.824 L98.32,8.026 L96.523,8.026 L96.524,6.233 L94.729,6.233 L94.731,8.026 L92.934,8.026 L92.934,9.824 L94.731,9.824 z" fill="#F8F8F8" display="none"/>
      <g display="none">
        <path d="M94.557,14.737 L95.282,14.737 L95.282,15.367 L94.557,15.367 z" fill="#F8F8F8"/>
        <path d="M96.1,17.456 C96.031,17.456 95.976,17.389 95.976,17.305 L95.976,15.565 L97.071,15.565 L97.532,17.939 L97.531,18.093 L97.531,19.143 C97.531,19.224 97.476,19.289 97.41,19.289 L96.345,19.289 C96.278,19.289 96.224,19.224 96.224,19.143 L96.224,17.456 L96.109,17.456" fill="#F8F8F8"/>
        <path d="M95.154,17.459 C95.223,17.459 95.278,17.392 95.278,17.309 L95.278,15.567 L94.184,15.567 L93.722,17.942 L93.724,18.096 L93.724,19.146 C93.724,19.228 93.778,19.293 93.845,19.293 L94.909,19.293 C94.977,19.293 95.031,19.228 95.031,19.146 L95.031,17.459 L95.145,17.459" fill="#F8F8F8"/>
        <path d="M95.439,15.567 L95.814,15.567 L95.814,17.184 L95.439,17.184 z" fill="#F8F8F8"/>
        <path d="M95.976,14.737 L96.701,14.737 L96.701,15.367 L95.976,15.367 z" fill="#F8F8F8"/>
      </g>
      <g>
        <path d="M79.999,23.104 L80.738,21.818 L81.456,20.519 L82.247,21.859 L83.059,23.186 L81.529,23.133 z" fill="#F8F8F8"/>
        <path d="M79.999,23.104 L80.738,21.818 L81.456,20.519 L82.247,21.859 L83.059,23.186 L81.529,23.133 z" fill-opacity="0" stroke="#FFFFFF" stroke-width="0.1" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.418,22.993 C71.57,23.075 75.311,21.401 74.298,20.05" fill="#F8F8F8"/>
        <path d="M75.418,22.993 C71.57,23.075 75.311,21.401 74.298,20.05" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M74.298,20.05 C75.16,20.319 75.854,21.041 75.923,21.499" fill="#F8F8F8"/>
        <path d="M74.298,20.05 C75.16,20.319 75.854,21.041 75.923,21.499" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.923,21.499 C76.38,21.388 76.318,20.682 76.382,20.797 C77.159,22.198 76.96,22.997 75.417,22.987" fill="#F8F8F8"/>
        <path d="M75.923,21.499 C76.38,21.388 76.318,20.682 76.382,20.797 C77.159,22.198 76.96,22.997 75.417,22.987" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M74.997,21.045 L75.407,22.402" fill="#F8F8F8"/>
        <path d="M74.997,21.045 L75.407,22.402" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.781,22.122 L74.711,20.483" fill="#F8F8F8"/>
        <path d="M75.781,22.122 L74.711,20.483" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.535,22.58 C75.504,22.235 75.714,22.158 75.849,21.839" fill="#F8F8F8"/>
        <path d="M75.535,22.58 C75.504,22.235 75.714,22.158 75.849,21.839" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M76.258,22.408 C76.114,21.994 75.763,21.715 75.545,21.32" fill="#F8F8F8"/>
        <path d="M76.258,22.408 C76.114,21.994 75.763,21.715 75.545,21.32" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.139,22.678 C73.783,22.722 74.429,21.834 75.34,21.597" fill="#FFFFFF"/>
        <path d="M75.139,22.678 C73.783,22.722 74.429,21.834 75.34,21.597" fill-opacity="0" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.139,22.678 C76.271,22.655 74.837,21.821 75.34,21.597" fill="#FFFFFF"/>
        <path d="M75.139,22.678 C76.271,22.655 74.837,21.821 75.34,21.597" fill-opacity="0" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M75.17,22.592 L75.1,21.733" fill="#F8F8F8"/>
        <path d="M75.17,22.592 L75.1,21.733" fill-opacity="0" stroke="#FFFFFF" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <g>
        <path d="M74.568,20.97 C74.594,20.977 74.763,20.731 74.788,20.587" fill="#F8F8F8"/>
        <path d="M74.568,20.97 C74.594,20.977 74.763,20.731 74.788,20.587" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path d="M68.613,19.793 C68.155,20.019 68.119,20.148 67.246,20.132" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.613,19.793 C69.031,20.149 69.479,20.194 69.998,20.181" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M67.246,20.132 C67.318,21.512 67.504,22.618 68.613,22.785" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M69.998,20.181 C70.051,21.352 69.967,22.492 68.726,22.777" fill-opacity="0" stroke="#F8F8F8" stroke-width="0.22" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.852,21.422 C68.879,21.407 68.899,21.384 68.922,21.364" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.507,20.509 L68.507,20.509" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.224,20.492 L68.224,20.492" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.287,20.473 C68.303,20.457 68.32,20.445 68.337,20.431" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.632,20.304 L68.306,21.008" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M69.467,20.603 C69.318,23.089 68.066,22.476 67.701,20.517" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.974,20.784 C68.983,20.784 68.988,20.773 68.995,20.768" fill-opacity="0" stroke="#F8F8F8" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M69.718,20.586 L69.778,20.537 L69.91,20.396 L69.954,20.332 L69.954,20.332 C69.992,20.272 69.983,20.222 69.936,20.22 C69.889,20.219 69.82,20.267 69.783,20.327 L69.747,20.392 L69.729,20.497 L69.684,20.558 C69.684,20.558 69.684,20.558 69.684,20.558 C69.667,20.576 69.661,20.596 69.67,20.604 C69.68,20.612 69.701,20.604 69.718,20.586 L69.718,20.586" fill="#F8F8F8"/>
      <path d="M69.673,20.485 L69.705,20.534 L69.801,20.609 L69.822,20.669 L69.822,20.669 C69.868,20.697 69.961,20.657 70.031,20.579 C70.1,20.501 70.12,20.414 70.074,20.386 C70.074,20.386 70.074,20.386 70.074,20.386 L70,20.385 L69.906,20.342 L69.864,20.302 C69.864,20.302 69.864,20.302 69.864,20.302 C69.841,20.274 69.779,20.292 69.726,20.342 C69.673,20.393 69.649,20.457 69.673,20.485 L69.673,20.485" fill="#F8F8F8"/>
      <path d="M67.979,20.86 L67.997,20.744 L67.998,20.655 L67.998,20.655 C67.958,20.606 67.853,20.636 67.763,20.721 C67.672,20.807 67.631,20.916 67.67,20.965 L67.755,20.981 L67.867,20.969 C67.882,20.985 67.919,20.974 67.95,20.943 C67.981,20.913 67.994,20.876 67.979,20.86 L67.979,20.86" fill="#F8F8F8"/>
    </g>
  </svg>`;
};