import css from './CallListMessage.module.css';
import React, { useState } from 'react';
import { BsCreditCard } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { RiPassValidLine } from "react-icons/ri";
import { GiRingingAlarm } from "react-icons/gi";
import { IoCreateOutline } from "react-icons/io5";



const MonthlyConfirmation = ({ project, step, setStep, nextStep, prevStep, basket, contractDuration = 1, upfront = 0, monthly = 0 }) => {
    
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <div className={css.headerWrapper}><RiPassValidLine  className={css.headerIcon}/><div className={css.header}><h2>Call List</h2></div></div>
           
                
                
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                       
                            
                        </div>
                        <div className={css.item}>
                        </div>
                    </div>
                </div>
                <div className={css.descriptionBox}>
                <div className={css.description}>
                    <p>The next step is to create a call list.</p>
                    </div>

                    <div className={css.description}>
                        <p>A call list is a prioritized list of individuals we will notify in case of an alarm before dispatching authorities.</p>
                    </div>

                    <div className={css.description}>
                        <p>This procedure is crucial in monitoring operations to minimize false alarms.</p>
                    </div>

                    <div className={css.description}>
                        <p>The call list should include individuals directly associated with the alarm, such as those who work or reside on the premises, or those who live nearby and can attend the property in case of an emergency.</p>
                    </div>

                    <div className={css.description}>
                        <p>The list may include property owners, family members, employees, neighbors, and friends.</p>
                    </div>

                    <div className={css.description}>
                        <p>Each individual must provide their name, phone number, and a verbal passcode.</p>
                    </div>

                    <div className={css.description}>
                        <p>The verbal passcode, a word or number of your choice, is essential for the monitoring station dispatchers to confirm authorized individuals during alarm events or to manage your account.</p>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.checkboxBox} >
                    <div className={css.checkbox}>
                    </div>
                    <div className={css.term}>
                    <p></p>
                    </div>
                </div>
                <button 
                    className={css.buttonActive} 
                    onClick={()=>{setStep(step-2)}} 
                    >
                        Back
                    </button>
                <button 
                    className={css.buttonActive } 
                    onClick={nextStep} 
                    >
                        Next
                    </button>
            </div>
        </div>
    );
};

export default MonthlyConfirmation;






