import React from 'react';
import css from "./List.module.css";

export default function List({arr,i}){
    return(
        <>
        {arr && (
            <div key={i} className={css.wrapper}>{arr.map((obj,i)=>{
                    return(
                        <div key={i} className={css.ulList}>
                        <h3>{obj.title}</h3>
                        <ul className={obj.settings.bullets == false ? css.noBullets:''}>
                            {obj.list.map((l,i)=>(
                                <li key={'lix'+i}>{l.linkTo?<a href={l.linkTo} target="_blank">{l.text}</a>:l.text}</li>
                            ))}
                        </ul>
                    </div>
                    )
            })}
            </div>
        )}
        </>
    )
}