import ai1 from '../pages/AIECMS/img/ai1.jpg'
import ai2 from '../pages/AIECMS/img/ai2.jpg'
import ai9 from '../pages/AIECMS/img/ai9.jpg'
import ai5 from '../pages/AIECMS/img/ai5.jpg'
import ai6 from '../pages/AIECMS/img/ai6.jpg'
import ai7 from '../pages/AIECMS/img/ai7.jpg'
import ai15 from '../pages/AIECMS/img/ai15.jpg'
export default function HomePageData(){
    const data={     
        cards:[
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Introducing AIECMS®<br/>AI-Enhanced Central Monitoring Station`,
                    content: "The Next Generation of AI-Driven Security Monitoring<br/>Built on cutting-edge AI algorithms, this system represents a leap forward in automated protection.<br/><br/>Engineered by Securepol, AIECMS® revolutionizes security by harnessing the power of AI to turn raw alarm event data into actionable insights. <br/>",
                    imgBackground:  ai15,
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Intelligent Alert Management`,
                    content: "Advanced machine learning filters out false alarms with over 90% accuracy, allowing only critical alerts to pass through, significantly reducing unnecessary interventions.<br/><br/><br/>",
                    imgBackground:  ai9,
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Proactive Intruder Detection:`,
                    content: "Utilizing deep learning models, AIECMS® continuously analyzes environmental patterns, identifying potential security breaches before they escalate.<br/><br/><br/>",
                    imgBackground:  ai2,
                },
                
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `24/7 AI-Assisted Remote Support`,
                    content: "Integrating real-time data processing with AI-driven decision-making, AIECMS® provides continuous operational support, enhancing the reliability and responsiveness of security operations.<br/><br/><br/>",
                    imgBackground:  ai1,
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Event-Based Decision Making`,
                    content: "By processing and evaluating incoming data, the AI predicts potential scenarios, providing dispatchers with instant insights into what might be unfolding. <br/>This predictive capability allows for more informed and rapid decision-making, optimizing response times and effectiveness.<br/><br/>Through Contextual Analysis, the system not only identifies the type of event but also considers historical data and environmental factors, ensuring that responses are tailored to the specific situation.<br/><br/>AIECMS® represents a shift from traditional monitoring to intelligent, context-aware security management, setting a new standard in proactive threat detection and response.",
                    imgBackground:  ai5,
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `AIECMS® Transforming Alarm Signal Interpretation with AI`,
                    content: "Traditional alarm monitoring places the responsibility of signal interpretation on dispatchers, which can result in mistakes or delays in critical situations. AIECMS® revolutionizes this process by instantly analyzing and interpreting alarm signals with unparalleled accuracy.",
                    imgBackground:  ai6,
                },
            ],
            [
                {
                    settings: {bordered:true, darkBackground:true,},
                    header: `Optimized Dispatcher Support`,
                    content: "AIECMS® provides dispatchers with clear, actionable insights, minimizing the cognitive load and enabling them to focus on executing the most appropriate response. The AI’s continuous learning capability further refines its accuracy over time, improving decision-making with each use.<br/><br/>Securepol's AIECMS® represents a major advancement in alarm monitoring, shifting the critical task of signal interpretation from humans to an AI system designed for precision and efficiency.",
                    imgBackground:  ai7,
                },
            ],
            
        ],
    }
    return data;
}
