import React, { useState, useEffect } from 'react';
import css from './InfoView.module.css';
import CloseButton from '../AppCommonComponents/CloseButton/CloseButton';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';

const InfoView =({setView, model, upfront})=>{
    const [showNavOnMobile, setShowNavOnMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowNavOnMobile(window.innerWidth >= 768); // Adjust the breakpoint
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window size
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    
    const images=[];
    model.image.map((i)=>{
         images.push({
            original: i,
            thumbnail: i,
          })
             
    })
    
    return(
        <div className={css.sectionWrapper}>
            <div className={css.header}>
                <NavigationElementsWrapper>   
                <div className={css.spacer}></div>
                    <CloseButton setView={()=>setView('model')}/>
                    </NavigationElementsWrapper>
            </div>
            <div className={css.body}>
                <div  className={css.itemsWrapper}>
                
                    <div className={css.imageGallery}>
                        <ImageGallery   items={images} style={css.imageGalleryX}showPlayButton={false} showFullscreenButton={false} showThumbnails={true} showNav={showNavOnMobile} height={100} width={100} fontSize={10}/>
                    </div>
                    <div className={css.infoBox}>
                        <h1 className={css.infoTitle}>{model.infoHeader}</h1>
                        <table className={css.infoTable}>
                            <tbody>
                                {model.spec.map((obj,i)=>(
                                    <tr key={i}>
                                        <>
                                            <td className={css.row1}>{obj.row1}</td>
                                            <td className={css.td2}>{obj.row2}</td>
                                        </>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                    <div className={css.infoBox}>
                        {model.info.map((obj,i)=>(
                            <div key={'head'+i} className={css.sectionBox}>
                                <h2>{obj.header}</h2>
                                <p>{obj.text}</p>
                                {obj.section.map((part,i)=>(
                                    <div key={'par'+i}>
                                        <h3 className={css.td3}>{part.title}</h3>
                                        <p className={css.td4}>{part.text}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>   
                </div>
            </div>
        </div>
    )
}
export default InfoView;