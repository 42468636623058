export default function FooterData(){
    const data={     
        footer:[
            {
                title:'Securepol Inc.',
                address1:'#300 400 5th Ave SW',
                lic:'BL184235',
                address2:'Calgary, AB T2P 0L6',
                tel1:'(403) 703-1273',
                tel2:'1(844) 880-4736',
                email:'info@securepol.com',
                copyright:'Copyright© 2001-2024 Securepol Inc. All rights reserved. Securepol® and the Securepol logo® are registered trademarks of Securepol Incorporated.',
                socialLinks:[{
                    fb:'https://www.facebook.com/Securepol-Inc-Security-Alarm-Monitoring-for-Home-Business-612307615464793/?ref=nf',
                    x: 'https://twitter.com/securepol?lang=en',
                    yelp:'https://www.yelp.ca/biz/securepol-calgary-5',
                    instagram:'https://www.instagram.com/securepol/',
                    youTube:'https://www.youtube.com/watch?v=P4E5b66EYME',
                    google:'https://g.co/kgs/7R5JCZ'
    
                }],
            },
        ],
    }
    return data;
}
