import css from './PlanConfirmation.module.css';
import React, { useState } from 'react';
import { ImCalendar } from "react-icons/im";
import { LiaFileContractSolid } from "react-icons/lia";
import { BsCreditCard } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const PlanConfirmation = ({ project, step, setStep, nextStep, prevStep, basket, contractDuration = 1, upfront = 0, monthly = 0 }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Your Payment Plan</h2>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                            <LiaFileContractSolid/>
                        </div>
                        <div className={css.item}>
                            <p>{contractDuration > 1? contractDuration + '-Month Contract': 'No Contract'}</p>
                        </div>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                            <BsCreditCard/>
                        </div>
                        <div className={css.item}>
                            <p> ${upfront} Upfront Payment</p>
                        </div>
                    </div>
                </div>
                
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                            <ImCalendar />
                        </div>
                        <div className={css.item}>
                            <p>${monthly.toFixed(2)} Monthly Payment</p>
                        </div>
                    </div>
                </div>

                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.icon}>
                            
                        </div>
                        <div className={css.description}>
                            <p>5% GST not included</p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className={css.buttonBox}>
                <div className={css.checkboxBox} >
                    <div className={css.checkbox} onClick={handleCheckboxChange}>
                    {isChecked?<MdOutlineCheckBox/>:<MdOutlineCheckBoxOutlineBlank/>}
                    </div>
                    <div className={css.term}>
                    <p>I acknowledge and accept these conditions</p>
                    </div>
                </div>
                <button 
                    className={css.buttonActive} 
                    onClick={()=>setStep(4)} 
                    >
                        Back
                    </button>
                <button 
                    className={isChecked ? css.buttonActive : css.buttonInActive} 
                    onClick={()=>setStep(contractDuration>1?7:8)} 
                    disabled={!isChecked}
                    >
                        Next
                    </button>
            </div>
        </div>
    );
};

export default PlanConfirmation;






