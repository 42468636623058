import React from 'react';
import css from "./LegalText.module.css";

export default function LegalText({terms="",i}){

    return(
        <>
        {terms && (
            <div key={i} className={css.termsWrapper}>
                <div className={css.terms}>
                    <p dangerouslySetInnerHTML={{ __html: terms }}></p>
                </div>
            </div>
        )}
        </>
    )
}