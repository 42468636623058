import React, {useEffect, useState}from 'react';
import css from './MainView.module.css';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import FeaturesCheckList from './FeaturesChecklist/FeaturesChecklist';
import SectionTitleWithEditButton from '../AppCommonComponents/SectionTitleWithEditButton/SectionTitleWithEditButton';
import PriceDisplay from '../AppCommonComponents/Price/PriceDisplay/PriceDisplay';
import DeviceList from './DeviceList/DeviceList';
import ProductImage from './ProductImage/ProductImage';
import ProductTitle from './ProductTitle/ProductTitle';
import SvgBlueWaveWhiteBackground from '../../assets/svg/backgrounds/svgBlueWaveWhiteBackground';
import SvgBackground from '../../webComponents/Hooks/SvgBackground';
import { GiInfo } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";


const MainView =({
    project, 
    monthly, 
    upfront, 
    view, 
    setView, 
    basket, 
    contractDuration,
    discount,
    showInstructions,
    setShowInstructions})=>{
    const alarmPackageIndex=0;
    // State to show/hide instructions
   
    const handleClick=()=>{
        setShowInstructions(!showInstructions)
    }
    
    return(
        <div className={css.sectionWrapper} >
            <div className={`${css.instructionsOverlay} ${showInstructions ? css.show : ''}`}>
            <div className={css.instructionsModal}>
                <button className={css.closeBtn} onClick={handleClick}><IoMdClose /></button>
                <h2 className={css.modalTitle}>How to Use the App</h2>
                <p className={css.modalSubTitle}>Click the icons at the bottom of the page to navigate:</p>
                <ul className={css.modalList}>
                    <li className={css.modalLine}><b className={css.modalBold}>Cart:</b> View your selected package.</li>
                    <li className={css.modalLine}><b className={css.modalBold}>Edit:</b> Make changes to your package.</li>
                    <li className={css.modalLine}><b className={css.modalBold}>Plan:</b> Adjust your payment plan.</li>
                    <li className={css.modalLine}><b className={css.modalBold}>Send:</b> Email the PDF estimate to yourself.</li>
                    <li className={css.modalLine}><b className={css.modalBold}>Order:</b> Place your order for installation.</li>
                    <li className={css.modalLine}><b className={css.modalBold}>Exit:</b> Return to the main website.</li>
                </ul>
            </div>
            </div>
            
            <div className={css.body} style={SvgBackground(SvgBlueWaveWhiteBackground())}>
                <div className={css.bodyElementsBox}>
                    <div className={css.titleBox}>
                        <div className={css.title} >
                            <ProductTitle projectDeviceName={project.deviceName}/>
                        </div>
                        
                        <div className={css.icon} onClick={handleClick}>
                            <GiInfo/>
                        </div>
                    </div>
                    
                    <div className={css.productElementsBox}>
                        <ProductImage projectDeviceImage={project.deviceImage} projectDeviceName={project.deviceName}/>
                        <FeaturesCheckList projectCheckList={project.checkList} basket={basket}/>
                    </div>
                </div>
                <SectionTitleWithEditButton title={project.selectedComponentsSectionTitle} setView={()=>setView('edit')}/>
                <DeviceList basket={basket} project={project}/>
            </div>
            <div className={css.footer} >
                <SectionTitleWithEditButton title={contractDuration === 1?"Contract: Month-to-Month":"Contract: "+contractDuration+"-Month "}  setView={()=>setView('payment')}/>
                <div className={css.priceWrapper}>
                    <PriceDisplay upfront={upfront} monthly={monthly} discount={discount} />
                </div>      
            </div>
            <div className={css.header}>
                <NavigationElementsWrapper>
                    <AppMenu 
                    setView={setView}
                     view={view}/>
                </NavigationElementsWrapper>
            </div>
        </div>
    )
}
export default MainView;