import css from './SendView.module.css';
import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import {pdf} from '@react-pdf/renderer';
import emailjs from 'emailjs-com';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import SvgBackground from '../../webComponents/Hooks/SvgBackground';
import SvgBlueWave from '../../assets/svg/backgrounds/svgBlueWave';
import TextInputWithLabel from '../../webComponents/common/Form/FormElements/TextInputWithLabel/TextInputWithLabel';
import SubmitButton from '../../webComponents/common/Form/FormElements/SubmitButton/SubmitButton';
import PDFDocumentEstimate from './PDFDocumentEstimate/PDFDocumentEstimate';
import { MdOutlineRadioButtonChecked,  MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { BsSendCheck } from "react-icons/bs";
import UploadBasket from "../../Db/uploadToDB/UploadBasket";



const PDFGenerator = ({view, setView, basket, valet, project,upfront, monthly, tax, contractDuration}) => {
  const [isLocationSame, setIsLocationSame]= useState(true);
  const [confirmationEmail, setConfirmationEmail] = useState('')
  const [formData, setFormData] = useState({ 
      name: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      province:'',
      postal: '',
      country:'',

      locName: '',
      locPhone: '',
      locAddress: '',
      locCity: '',
      locProvince:'AB',
      locPostal: '',
      basketIDLink:'',
    
  });
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading
  const [pdfID, setPdfID] = useState(generateShortId() )

  function generateShortId() {
    return Math.floor(100000 + Math.random() * 900000);
}

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    // Manually validate form fields and return true if valid, false otherwise
    if (!formData.email || !formData.email.trim()) {
      alert('Please enter an email address.');
      return false;
    }
    // Add more validation logic for other fields if needed
    return true; // Form is valid
  };
const sendEmail = async () => {
    if (!validateForm()) {
        // Exit function if form is not valid
        return;
    }
    setLoading(true);

    // Ensure basket and valet is saved in the DB and basketID is returned
    // id is used to make a specific link to the app
    const basketID = await UploadBasket({ basket, valet });
 
    //email.js credentials
    const serviceId = 'service_52qj8o7';
    const templateId = 'template_2f4z1no';
    const userId = 'Pgvr9-YrZHu7sbMO4';
    const ccEmail = 'tech@securepol.com';

      // Replace formData.name with 'Valued Customer' if it is empty
      const modifiedFormData = {
        ...formData,
        name: formData.name.trim() === '' ? 'Valued Customer' : formData.name
    };
    
    //stores customer eamil entered in form to display in 'email sent confirmation' text
    setConfirmationEmail(formData.email)
    try {
        const blob = await pdf(<PDFDocumentEstimate pdfID={pdfID} basketID={basketID} formData={formData} basket={basket} project={project} upfront={upfront} monthly={monthly} tax={tax} contractDuration={contractDuration} />).toBlob();
        const base64data = await convertBlobToBase64(blob);
        if (!base64data) {
            //console.error('Failed to convert PDF blob to base64.');
            setLoading(false);
            return;
        }
        const response = await emailjs.send(
            serviceId,
            templateId,
            {
              pdfFile: base64data,
              basketlink: "https://www.securepol.com/packages?id=" + basketID, 
              ...modifiedFormData,
              ccEmail: ccEmail,
              pdfID:pdfID,
            },
            userId
        );
        console.log('Email sent:', response);
        setEmailSent(true);
        setFormData({
            name: '',
            phone: '',
            email: '',
            address: '',
            city: '',
            province: '',
            postal: '',
            country:'',

            locName: '',
            locPhone: '',
            locAddress: '',
            locCity: '',
            locProvince: '',
            locPostal: '',
        });
    } catch (error) {
        console.error('Email error:', error);
    } finally {
        setLoading(false); // Stop loading regardless of the outcome
    }
};

  

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result.split('base64,')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
      <div className={css.sectionWrapper} style={SvgBackground(SvgBlueWave())}>
        <div className={css.header}>
          <NavigationElementsWrapper>
            <AppMenu 
              setView={setView} 
              view={view}/>
          </NavigationElementsWrapper>
        </div>
        {!emailSent ? (
          <div className={css.body}>
            <h3>Email the Estimate as a PDF</h3>
            <div className={css.formBox}>
              <form className={css.form}>
                <TextInputWithLabel 
                  label='Name'
                  className={css.input}
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <TextInputWithLabel
                  label='Phone'
                  className={css.input}
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleInputChange}/>
                <TextInputWithLabel
                  label='Email'
                  required
                  className={css.input}
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}/>
                <TextInputWithLabel
                  label='Address'
                  className={css.input}
                  type="text"
                  name='address'
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}/>
                <TextInputWithLabel
                  label='City'
                  className={css.input}
                  type="text"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}/>
                <TextInputWithLabel
                  label= {isLocationSame?'Province':'Province/State'}
                  className={css.input}
                  type="text"
                  name="province"
                  placeholder="Province"
                  onChange={handleInputChange}/>
                <TextInputWithLabel
                  label= {isLocationSame?'Postal Code':'Postal/Zip Code'}
                  className={css.input}
                  type="text"
                  name="postal"
                  placeholder="Postal Code"
                  value={formData.postal}
                  onChange={handleInputChange}/>
                  {!isLocationSame && (
                <TextInputWithLabel
                  label='Country'
                  className={css.input}
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleInputChange}/>
                  )}
                <div className={css.locationCheckBox} onClick={()=>setIsLocationSame(!isLocationSame)}>
                  {isLocationSame ? <MdOutlineRadioButtonChecked className={css.checkIcon}/> : <MdOutlineRadioButtonUnchecked className={css.checkIcon}/>}
                  <div className={css.checkLabel}>
                    Service at the same address
                  </div>
                </div>
                <div className={css.serviceAddressTitle}>
                  <h3>{!isLocationSame && 'Enter Service Address'}</h3>
                </div>
                  
                {!isLocationSame && (
                  <div>
                    <TextInputWithLabel 
                      label='Name'
                      className={css.input}
                      type="text"
                      name="locName"
                      placeholder="Name"
                      value={formData.locName}
                      onChange={handleInputChange}
                    />
                    <TextInputWithLabel
                      label='Phone'
                      className={css.input}
                      type="tel"
                      name="locPhone"
                      placeholder="Phone"
                      value={formData.locPhone}
                      onChange={handleInputChange}/>
                    <TextInputWithLabel
                      label='Address'
                      className={css.input}
                      type="text"
                      name="locAddress"
                      placeholder="Address"
                      value={formData.locAddress}
                      onChange={handleInputChange}/>
                    <TextInputWithLabel
                      label='City'
                      className={css.input}
                      type="text"
                      name="locCity"
                      placeholder="City"
                      value={formData.locCity}
                      onChange={handleInputChange}/>
                    <TextInputWithLabel
                      readOnly 
                      label='Province'
                      className={css.input}
                      type="text"
                      name="locProvince"
                      placeholder="Province"
                      value='AB'
                      onChange={handleInputChange}/>
                    <TextInputWithLabel
                      label='Postal Code'
                      className={css.input}
                      type="text"
                      name="locPostal"
                      placeholder="Postal Code"
                      value={formData.locPostal}
                      onChange={handleInputChange}/>
                  </div>

                )}
                <div className={css.buttonBox}>
                  <SubmitButton 
                    type="button" 
                    onClick={sendEmail}
                    text="Send Email"
                  />
                </div>
              </form>
              {loading ? <BeatLoader color='#d74a49' /> : null}
            </div>
          </div>
        ) : (
          <div>
            <div className={css.body}>
              <div className={css.alertBox}>
                <div>
                  <div className={css.sentAlertIcon}>
                    <BsSendCheck />
                  </div>
                  <h2 className={css.sentAlert}>Email sent successfully!</h2>
                  <p className={css.sentAlert}>to {confirmationEmail}</p>
                  <div className={css.sentAlert}>
                  <p className={css.sentText}>Thank you for using our estimating tool to create your security system quote.<br/><br/>
                  An email with an attached PDF of your estimate has been sent successfully. You can modify this estimate at any time by clicking the link provided in your email. <br/><br/>If you wish to proceed with the installation of your security system, you can easily do so directly through our app.<br/><br/> We appreciate your choice to secure your premises with Securepol and look forward to assisting you with your security needs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
    
};

export default PDFGenerator;
