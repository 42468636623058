export default function BizInfoData(){
    const data={     
        infoLinks:[
            [
                [
                    {
                    settings: {bordered:false, darkBackground:false,},
                    title: "Services we provide",
                    list:[
                            {text: '24/7 Security Alarm Monitoring.', linkTo: ""},
                            {text: 'Bespoke Security System Architecture', linkTo: ""},
                            {text: 'Complimentary Security System Assessment', linkTo: ""},
                            {text: 'Provision and Installation of Security Infrastructure', linkTo: ""},
                            {text: 'Expert Wiring Services for Alarm Systems', linkTo: ""},
                            {text: 'No-Cost Security Hardware and Setup', linkTo: ""},
                            {text: 'SO-Compliant Security Alarm Solutions', linkTo: ""},
                            {text: 'Tailored Security Oversight Services', linkTo: ""},
                            {text: 'Intruder Alert Systems without Binding Agreements', linkTo: ""},
                            {text: 'Flexible Monitoring Services Independent of Contracts', linkTo: ""},
                            {text: 'Integration of Hardwired and Wireless Residential Security Solutions', linkTo: ""},
                            {text: 'Rapid Response Coordination with Authorities and Security Personnel', linkTo: ""},
                            {text: 'Issuance of Verified Home Monitoring Accreditations', linkTo: ""},
                            {text: 'Round-the-Clock Technical Support and Customer Service', linkTo: ""},
                            {text: 'Permanent Live Operator Availability in Calgary', linkTo: ""},
                        ],
                    },
                ],
                
                [
                    {
                    settings: {bordered:false, darkBackground:false,},
                    title: "Alarm Monitoring",
                    list:[
                            {text: 'Uninterrupted Round-the-Clock Security Surveillance', linkTo: ""},
                            {text: 'Immediate Response Coordination with Security Personnel and Law Enforcement', linkTo: ""},
                            {text: 'Multi-channel Monitoring through Telephone, Internet, and Cellular Connections', linkTo: ""},
                            {text: 'Interactive Security Management via Mobile Devices', linkTo: ""},
                            {text: 'Cellular-Based Alert Systems', linkTo: ""},
                            {text: 'Redundant Communication Systems to Ensure Uninterrupted Service', linkTo: ""},
                            {text: 'Emergency Power Solutions for Security Systems', linkTo: ""},
                            {text: 'Detailed Reports on Security System Activation and Shutdown', linkTo: ""},
                            {text: 'Supervised Control of System Armament and Disarmament', linkTo: ""},
                            {text: 'Constant Availability of Security Technical Assistance via Telephone, Remotely, or On-Site', linkTo: ""},
                        
                        ],
                    },
                ],
                [
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Security Features",
                        list:[
                                {text: 'Intrusion Defense Systems', linkTo: ""},
                                {text: 'Hybrid Solutions for Home Alarms, Both Wireless and Wired', linkTo: ""},
                                {text: 'Sensory Equipment for Doors and Windows', linkTo: ""},
                                {text: 'Surveillance for Glass Breakage Incidents', linkTo: ""},
                                {text: 'Motion and Vibration Analysis Technologies', linkTo: ""},
                                {text: 'Automated Garage Entry Surveillance', linkTo: ""},
                                {text: 'Individual Emergency Response Devices', linkTo: ""},
                                {text: 'Comprehensive Monitoring for Smoke, Thermal Variations, and Combustible Gases', linkTo: ""},
                                {text: 'Carbon Monoxide Detection and Surveillance', linkTo: ""},
                                {text: 'Environmental Alert Systems for Cold Temperatures and Water Intrusion', linkTo: ""},
                                {text: 'Remote Management and Alert Systems via Mobile Devices', linkTo: ""},
                                {text: 'Resilient Communication Backup for Alarm Systems', linkTo: ""},
                              ],
                    },
                ],
                [
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Proudly Serving",
                        list:[
                            
                              {text: 'Airdrie', linkTo: "https://www.airdrie.ca/"},
                              {text: 'Banff', linkTo: "https://banff.ca/"},
                              {text: 'Beiseker', linkTo: "https://beiseker.com/"},
                              {text: 'Black Diamond', linkTo: "https://www.diamondvalley.town/"},
                              {text: 'Bragg Creek', linkTo: "https://visitbraggcreek.com/"},
                              {text: 'Calgary', linkTo: "https://www.calgary.ca/home.html"},
                              {text: 'Canmore', linkTo: "https://www.canmore.ca/"},
                              {text: 'Carstairs', linkTo: "https://carstairs.ca/"},
                              {text: 'Chestermere', linkTo: "https://thecityofchestermere.ca/"},
                              {text: 'Claresholm', linkTo: "https://www.claresholm.ca/"},
                              {text: 'Cochrane', linkTo: "https://www.cochrane.ca/"},
                              {text: 'Crossfield', linkTo: "https://www.crossfieldalberta.com/"},
                              {text: 'Diamond Valley', linkTo: "https://www.diamondvalley.town/"},
                              {text: 'High River', linkTo: "https://highriver.ca/"},
                              {text: 'Indus', linkTo: "https://indus.rockyview.ab.ca/"},
                              {text: 'Irricana', linkTo: "https://townofirricana.ca/"},
                              {text: 'Lacombe', linkTo: "https://www.lacombe.ca/"},
                              {text: 'Langdon', linkTo: "https://www.rockyview.ca/langdon"},
                              {text: 'Longview', linkTo: "http://village.longview.ab.ca/"},
                              {text: 'Okotoks', linkTo: "https://www.okotoks.ca/"},
                              {text: 'Olds', linkTo: "https://www.olds.ca/"},
                              {text: 'Read Dear', linkTo: "https://www.reddeer.ca/"},
                              {text: 'Strathmore', linkTo: "https://strathmore.ca/en/index.aspx"},
                              {text: 'Sundre', linkTo: "https://sundre.com/"},
                              {text: 'Sylvan Lake', linkTo: "https://www.sylvanlake.ca/en/index.aspx"},
                              {text: 'Vulcan', linkTo: "https://townofvulcan.ca/"},
                              {text: 'And all sorunding areas', linkTo: ""},
    
                            ],
        
                    },
                ],
                [
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Our Listings",
                        list:[
                                {text: 'Facebook', linkTo: "https://www.facebook.com/Securepol-Inc-Security-Alarm-Monitoring-for-Home-Business-612307615464793/?ref=nf"},
                                {text: 'Google', linkTo: "https://g.co/kgs/7R5JCZ"},
                                
                                {text: 'HomStars', linkTo: "https://homestars.com/companies/2875497-securepol-incorporated"},
                                {text: 'BBB', linkTo: "https://www.bbb.org/ca/ab/calgary/profile/burglar-alarm-systems/securepol-inc-0017-87491"},
                                {text: 'Instagram', linkTo: "https://www.instagram.com/securepol/"},
                                
                                {text: 'Yelp', linkTo: "https://www.yelp.ca/biz/securepol-calgary-5"},
                                {text: 'YouTube', linkTo: "https://www.youtube.com/watch?v=P4E5b66EYME"},
                                {text: 'X', linkTo: "https://twitter.com/securepol?lang=en"},
                            ],
        
                    },
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Accepted Payments",
                        list:[
                                {text: 'Visa', linkTo: ""},
                                {text: 'Visa Debit', linkTo: ""},
                                {text: 'MasterCard', linkTo: ""},
                                {text: 'Amex', linkTo: ""},
                                {text: 'Checks', linkTo: ""},
                                {text: 'Wire-Transfer', linkTo: ""},
                                {text: 'Apple Pay', linkTo: ""},
                                {text: 'Google Pay', linkTo: ""},
                                {text: 'Cash', linkTo: ""},
                            ],
        
                    },
                ],
                [
                    {
                        settings: {bordered:false, darkBackground:false, bullets: false},
                        title: "Business Location",
                        list:[
                                {text: '#300 400 5th Ave SW', linkTo: ""},
                                {text: 'Calgary,AB T2P 0L6', linkTo: ""},
                                {text: 'Tel. (403) 703-1273', linkTo: ""},
                                {text: 'info@securepol.com', linkTo: ""},
                            ],
        
                    },
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Office Hours",
                        list:[
                                {text: 'Monday: Open 24 Hours', linkTo: ""},
                                {text: 'Tusday: Open 24 Hours', linkTo: ""},
                                {text: 'Wednesday: Open 24 Hours', linkTo: ""},
                                {text: 'Thursday: Open 24 Hours', linkTo: ""},
                                {text: 'Friday: Open 24 Hours', linkTo: ""},
                                {text: 'Sutarday: Open 24 Hours', linkTo: ""},
                                {text: 'Sunday: Open 24 Hours', linkTo: ""},
                            ],
        
                    },
                    {
                        settings: {bordered:false, darkBackground:false,},
                        title: "Nearby Places",
                        list:[
                                {text: 'The Westin Calgary', linkTo: "https://www.google.ca/maps/place/The+Westin+Calgary/@51.0487548,-114.0702049,18.01z/data=!3m1!5s0x53716ffb98a9793d:0xd97bfae25bf7ae72!4m17!1m7!3m6!1s0x537170f58efb939d:0x613b769819ed1b06!2sTim+Hortons!8m2!3d51.0487893!4d-114.0712755!16s%2Fg%2F1tfkf7yc!3m8!1s0x53716ffb75306947:0xa500607bf00d6799!5m2!4m1!1i2!8m2!3d51.0496678!4d-114.0696241!16s%2Fg%2F1v_z8l0y?entry=ttu"},
                                {text: 'Tim Hortons', linkTo: "https://www.google.ca/maps/place/Tim+Hortons/@51.0485992,-114.0708282,18z/data=!4m6!3m5!1s0x537170f58efb939d:0x613b769819ed1b06!8m2!3d51.0487893!4d-114.0712755!16s%2Fg%2F1tfkf7yc?entry=ttu"},
                                {text: 'Phil & Sebastian', linkTo: "https://www.google.ca/maps/place/Phil+%26+Sebastian+Coffee+Roasters+-+Calgary+Place/@51.0489483,-114.069872,20.7z/data=!4m6!3m5!1s0x53716fb948772935:0x2ac5ab8baf777c6e!8m2!3d51.0489006!4d-114.0695877!16s%2Fg%2F11ffw57m1k?entry=ttu"},
                                {text: 'Kabuku Downtown', linkTo: "https://www.google.ca/maps/place/Kabuku+Downtown/@51.0489483,-114.069872,20.7z/data=!4m6!3m5!1s0x53716ffb71276d49:0xcbe99c02977d2074!8m2!3d51.0491583!4d-114.069741!16s%2Fg%2F1tpbbncs?entry=ttu"},
                                {text: 'TD Canada Trust ATM', linkTo: "https://www.google.ca/maps/place/TD+Canada+Trust+ATM/@51.0489483,-114.069872,20.7z/data=!4m6!3m5!1s0x53716fa57e12f92f:0x3ec3a05b859c0be7!8m2!3d51.0488466!4d-114.0698602!16s%2Fg%2F11f62cr1cv?entry=ttu"},
                                {text: 'J. Fournier Hair Design', linkTo: "https://www.google.ca/maps/place/J.+FOURNIER+HAIR+DESIGN/@51.0489384,-114.0705087,20.7z/data=!3m1!5s0x5374560914ec8b67:0x83defb05b504612a!4m6!3m5!1s0x53716ffb648c3b2f:0xfc7e601eda9ccd7f!8m2!3d51.048846!4d-114.0704035!16s%2Fg%2F1tmxf6v4?entry=ttu"},
                                {text: 'Van Express', linkTo: "https://www.google.ca/maps/place/Van+Express/@51.0485385,-114.073537,20.7z/data=!3m1!5s0x53716fe4b3746f37:0x809738b63db34257!4m6!3m5!1s0x53716fe4b30eb093:0x39d5e145d7c1660!8m2!3d51.048415!4d-114.073417!16s%2Fg%2F11fy4pp78l?entry=ttu"},
                                {text: 'Barclay Centre', linkTo: "https://www.google.ca/maps/place/Barclay+Centre/@51.0479461,-114.0715736,18.4z/data=!4m6!3m5!1s0x53716ffca5208175:0x5051a630addcb7e3!8m2!3d51.0473456!4d-114.0709023!16s%2Fg%2F1td4pch9?entry=ttu"},
                                {text: 'Deville Coffee', linkTo: "https://www.google.ca/maps/place/Deville+Coffee/@51.0480305,-114.0706989,18.4z/data=!4m6!3m5!1s0x53716f43e55a0c79:0x44c31b8c4af521fe!8m2!3d51.048191!4d-114.0693512!16s%2Fg%2F11gwmhg6t9?entry=ttu"},
                                {text: 'RBC Royal Bank', linkTo: "https://www.google.ca/maps/place/RBC+Royal+Bank/@51.048412,-114.0681558,19.41z/data=!4m6!3m5!1s0x53716ffc776170ed:0x86388dee89628a59!8m2!3d51.0481691!4d-114.0673014!16s%2Fg%2F1jdmb45tn?entry=ttu"},
                                
                            ],
        
                    },
                ],
    
            ]
            
        ]
    }
    return data;
}
