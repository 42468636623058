import css from './TextAreaInputQuestion.module.css';
import React, { useEffect, useState } from 'react';

const TextAreaInputQuestion = ({ 
    step, 
    setStep, 
    info, 
    setInfo, 
    infoKey='',
    header='', 
    paragraph='', 
    backButtonDir=step-1,
    nextButtonDir=step+1,    
    inputValue='',
    placeHolder='',
    isAnswareRequired=false,
}) => {
    
    const [isChecked, setIsChecked] = useState(isAnswareRequired);

    const handleInput = (e) => {
        setInfo({...info, [infoKey]: e.target.value});
    };

    useEffect(() => {
        setIsChecked(info[infoKey] === '');
    }, [info, infoKey]);
    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <div className={css.titleBox}>
                    <h2>{header}</h2>
                </div>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>{paragraph}</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.checkBoxWrapper}>
                            <div className={css.checkboxBox}>
                                <div className={css.checkbox}>
                                <textarea 
                                    className={css.input}
                                    value={info[infoKey] || inputValue} 
                                    onChange={handleInput} 
                                    placeholder={placeHolder}
                                    rows="5"
/>
                                </div>
                                <div className={css.label}>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.buttonBoxWrapper}>
                    <button
                        className={css.buttonActive}
                        onClick={() => setStep(backButtonDir)}
                    >
                        Back
                    </button>
                    <button
                        className={isChecked ?css.buttonInActive:css.buttonActive}
                        onClick={() => setStep(nextButtonDir)}
                        disabled={isChecked}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TextAreaInputQuestion;
