import SemiBolodBase64 from "../base64/semiboldBase64";
export default function BlackFriday50off(){

    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="400" height="60" viewBox="0, 0, 400, 60">
  <g id="Layer_1">
    <g>
      <path d="M-8.794,-6.116 L408.794,-6.116 L408.794,66.116 L-8.794,66.116 z" fill="#222"/>
      <path d="M408.794,-26.116 C419.84,-26.116 428.794,-17.161 428.794,-6.116 L428.794,66.116 C428.794,77.161 419.84,86.116 408.794,86.116 L-8.794,86.116 C-19.84,86.116 -28.794,77.161 -28.794,66.116 L-28.794,-6.116 C-28.794,-17.161 -19.84,-26.116 -8.794,-26.116 L408.794,-26.116 z M408.794,-6.116 L-8.794,-6.116 L-8.794,66.116 L408.794,66.116 L408.794,-6.116 z" fill="#FFFFFF"/>
    </g>
    <text transform="matrix(0.999, -0.05, 0.05, 0.999, 200, 30)">
      <tspan x="-110.449" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF">Huge</tspan>
      <tspan x="-52.949" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF"> </tspan>
      <tspan x="-45.775" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF">Sale:</tspan>
      <tspan x="9.199" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF"> </tspan>
      <tspan x="16.373" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF">40%</tspan>
      <tspan x="62.407" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF"> </tspan>
      <tspan x="69.581" y="4" font-family="Helvetica, Arial, sans-serif" font-size="23" fill="#FFFFFF">Off!</tspan>
    </text>
  </g>
</svg>
`;
}