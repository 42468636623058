import React from 'react';
import css from './ItemList.module.css';
import ListHeader from './ListHeader/ListHeader';
import ListBody from './ListBody/ListBody';

const ItemList = ({basket, setModifiedModel, updateBasketItemsDetails, models, setComponentIndex, setTypeIndex, value, labelEnding, fixedLocation, setView}) =>{
    return(
        <div className={css.itemDetailsWrapper}>
            <div className={css.itemDetails}>
                <table className={css.itemTable}>
                    <ListHeader/>
                    <ListBody 
                        basket={basket} 
                        setModifiedModel={setModifiedModel}  
                        updateBasketItemsDetails={updateBasketItemsDetails} 
                        value={value} 
                        models={models} 
                        setComponentIndex={setComponentIndex} 
                        setTypeIndex={setTypeIndex} 
                        labelEnding={labelEnding} 
                        fixedLocation={fixedLocation} 
                        setView={setView}
                    />
                </table>
            </div>
        </div>
    )
}
export default ItemList;