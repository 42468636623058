import css from './Initial.module.css';
import order from './image/readytosubmit.jpeg'
const Initial=({step, setStep, nextStep, prevStep})=>{
    return(
        <div className={css.proceedBox}>
            <div className={css.messageBox}> 
                <h2>Existing Alarm Evaluation</h2>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>Click "Next" to provide information about your existing system.</p>
                        <p className={css.messageInstruction}></p>
                    </div>
                </div>
                <img className={css.orderImage} src={order} alt="order ilustration"/>
            </div>
            
            <div className={css.buttonBox}>
                <div className={css.checkboxBox}>
                    <div className={css.checkbox}>
                    </div>
                    <div className={css.term}>
                        <label></label>
                    </div>
                </div>
                <button
                    className={css.buttonActive}
                    onClick={()=>setStep(0)}
                >
                    Back
                </button>
                <button
                    className={css.buttonActive}
                    onClick={nextStep}
                >
                    Next
                </button>
            </div>
        </div> 
    )
}
export default Initial;