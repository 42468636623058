import u1 from './PROWLTOUCHimg/u1.jpg';
import u2 from './PROWLTOUCHimg/u2.jpg';
import u3 from './PROWLTOUCHimg/u3.jpg';
import u4 from './PROWLTOUCHimg/u4.jpg';
import { getPrice } from '../../../../../../Prices';

const PROWLTOUCH = () => {
    const device = {
        title: 'Touchscreen Keypad w/Wall Mount',
        model: 'PROWLTOUCH_WALL',
        sku: ['PROWLTOUCH'],
        modelViewImage: u1,
        image: [u1,u2,u3,u4],
        shortDescription: 'User-friendly touchscreen keypad for easy control of your security system.',
        price: getPrice('PROWLTOUCH_WALL'),
        group: 'keypad',
        checkListKeyword: [''],
        infoHeader: "Touchscreen Keypad",
        spec: [
            { row1: 'Model:', row2: 'PROWLTOUCH' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wi-Fi' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Wall mount or Desk mount' },
            { row1: 'Power Supply:', row2: 'AC Power with Battery Backup' },
            { row1: 'Screen Size:', row2: '7 inches' },
            { row1: 'Resolution:', row2: '1024 x 600' },
            { row1: 'Touch Technology:', row2: 'Capacitive' },
            { row1: 'Dimensions:', row2: '7.7 x 5.1 x 0.8 inches' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed, FCC Certified' },
        ],
        info: [
            {
                header: 'Touchscreen Keypad: User-Friendly Control for Your Security System',
                text: 'The PROWLTOUCH touchscreen keypad provides a user-friendly interface for controlling your security system. With its intuitive design and advanced features, it simplifies the management of your security setup.',
                section: [
                    { title: "Easy-to-Use Interface" },
                    { text: "The PROWLTOUCH features a capacitive touchscreen with a high-resolution display, providing an easy-to-use interface for controlling your security system. The intuitive design allows for quick access to system status, arming/disarming, and other functions." },
                    
                    { title: "Wi-Fi Connectivity" },
                    { text: "The keypad connects to your security system via Wi-Fi, ensuring reliable communication and easy installation without the need for complex wiring." },
                    
                    { title: "Customizable" },
                    { text: "The PROWLTOUCH allows for customization of the display, enabling users to personalize the interface with preferred settings and shortcuts. This enhances the user experience and simplifies system management." },
                    
                    { title: "Real-Time Alerts" },
                    { text: "Receive real-time alerts and notifications on the touchscreen keypad, keeping you informed about system status and any security events. The keypad provides audible and visual alerts to ensure that important information is noticed." },
                    
                    { title: "Automation Integration" },
                    { text: "Integrate the PROWLTOUCH with your home automation system to control smart devices such as lights, locks, and thermostats. This enhances the convenience and functionality of your security setup." },
                    
                    { title: "Power Supply" },
                    { text: "The PROWLTOUCH operates on AC power with a battery backup, ensuring continuous operation even during power outages. The battery backup provides peace of mind and reliable performance." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROWLTOUCH touchscreen keypad meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROWLTOUCH touchscreen keypad operates using advanced touchscreen technology and Wi-Fi communication to provide easy control and monitoring of your security system.',
                section: [
                    { title: "Touchscreen Interface" },
                    { text: "The capacitive touchscreen provides a responsive and intuitive interface for interacting with the security system. Users can easily access system status, arm/disarm the system, and navigate through menus and settings." },
                    
                    { title: "Wi-Fi Communication" },
                    { text: "The keypad connects to the security system via Wi-Fi, allowing for reliable and secure communication. This wireless connection simplifies installation and ensures that the keypad can be placed in convenient locations." },
                    
                    { title: "Real-Time Monitoring" },
                    { text: "The PROWLTOUCH provides real-time monitoring of the security system, displaying alerts and notifications directly on the screen. This ensures that users are immediately informed of any security events." },
                    
                    { title: "Automation Control" },
                    { text: "The keypad can be integrated with home automation systems, allowing users to control smart devices directly from the touchscreen. This includes adjusting thermostats, controlling lights, and locking/unlocking doors." },
                    
                    { title: "Customizable Display" },
                    { text: "Users can customize the display to suit their preferences, adding shortcuts to frequently used functions and adjusting the interface for optimal usability." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of the PROWLTOUCH touchscreen keypad is crucial to its effectiveness. Here are some key guidelines for where to place the keypad:',
                section: [
                    { title: "Near Entry Points" },
                    { text: "Place the keypad near main entry points such as front and back doors for easy access when entering or leaving the property. This allows for quick arming and disarming of the system." },
                    
                    { title: "Central Location" },
                    { text: "Install the keypad in a central location within the home for convenient access by all occupants. This ensures that the keypad is easily reachable from various areas of the property." },
                    
                    { title: "Bedrooms" },
                    { text: "Consider placing a keypad in the master bedroom for quick access during the night. This allows users to easily monitor and control the security system from their bedroom." },
                    
                    { title: "Near Automation Devices" },
                    { text: "If the keypad is used to control home automation devices, place it near these devices for easy access and control. This enhances the convenience of managing smart home features." },
                    
                    { title: "Away from Direct Sunlight" },
                    { text: "Avoid placing the keypad in direct sunlight, which can affect the visibility of the display. Choose a location with consistent lighting for optimal screen readability." },
                    
                    { title: "Secure Mounting" },
                    { text: "Ensure that the keypad is securely mounted to prevent tampering or accidental disconnection. Use the provided mounting hardware for a stable installation." },
                ]
            },

            {
                header: 'Common issues and troubleshooting',
                text: 'Here are common issues that may arise with the PROWLTOUCH touchscreen keypad and how to troubleshoot them:',
                section: [
                    { title: "Unresponsive Screen" },
                    { text: "If the touchscreen becomes unresponsive, try rebooting the device by disconnecting and reconnecting the power supply. Ensure that the screen is clean and free of debris." },
                    
                    { title: "Connectivity Issues" },
                    { text: "If the keypad loses Wi-Fi connectivity, check the Wi-Fi network and router settings. Ensure that the keypad is within range of the Wi-Fi signal and that there are no network outages." },
                    
                    { title: "False Alerts" },
                    { text: "False alerts can occur due to incorrect settings or sensor issues. Check the system settings and ensure that all sensors are functioning correctly. Adjust sensitivity settings as needed." },
                    
                    { title: "Battery Backup Issues" },
                    { text: "If the battery backup is not functioning, check the battery connections and replace the battery if necessary. Ensure that the AC power supply is stable and providing consistent power." },
                    
                    { title: "App Connectivity" },
                    { text: "If you experience issues with the mobile app connectivity, ensure that the app is updated to the latest version. Check your device's internet connection and try reinstalling the app if necessary." },
                    
                    { title: "Screen Visibility" },
                    { text: "If the screen is difficult to read, adjust the brightness settings on the keypad. Ensure that the keypad is not placed in direct sunlight or areas with extreme lighting conditions." },
                ]
            },
        ]
    }
    return device;
}
export default PROWLTOUCH;
