import css from './Select.module.css';
import React, { useEffect, useState } from 'react';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";


const Select = ({ totalQty, editable = true, radioName='', radioSelected=false, itemsInBasket, updateQty, gridAreaNameSelect}) => {

  const [isChecked, setIsChecked] = useState(false);
  const [selected, setSelected]=useState(false);
 useEffect(()=>{
  setSelected(radioSelected)
 })

  const handleCheckboxChange = () => {
    if (editable) {
      const newValue = !isChecked ? 1 : 0; // Toggle between 1 and 0
      setIsChecked(!isChecked);
      updateQty(newValue);
    }
  };

  const handleRadioSelected=()=>{
    updateQty(1);
  }
 
  useEffect(() => {
    setIsChecked(itemsInBasket);
  }, [itemsInBasket]);

  return (
    <div>
      <div className={css.itemSelect} style={{ gridArea: gridAreaNameSelect }}>
        {totalQty === 1 ? 
          radioName !=='' ? (
            <div className={css.radioBox} onClick={handleRadioSelected}>
                {selected? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />}
            </div>
          ):(
            <div className={css.checkBox} onClick={handleCheckboxChange}>
              {isChecked? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}
            </div>
          
        ):(
          <select
            className={css.selectVal}
            value={itemsInBasket}
            onChange={(e) => updateQty(Number(e.target.value))}
            disabled={!editable}
          >
            {editable && <option value={0}>0</option>}
            {Array.from({ length: totalQty }, (_, index) => (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default Select;
