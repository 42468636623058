import t1 from './PROSIXTEMPimg/t1.jpg';
import t2 from './PROSIXTEMPimg/t2.jpg';
import t3 from './PROSIXTEMPimg/t3.jpg';
import bat from '../../../Batteries/Cr123a.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXTEMP = () => {
    const sensor = {
        title: 'Temperature Detector',
        model: 'PROSIXTEMP',
        sku: ['PROSIXTEMP'],
        modelViewImage: t1,
        image: [t1,t2,t3,bat],
        shortDescription: 'Monitors temperature changes to detect extreme temperatures.',
        price: getPrice('PROSIXTEMP'),
        group: 'sensor',
        checkListKeyword: ['Freez', ],
        infoHeader: "Temperature Detector",
        spec: [
            { row1: 'Model:', row2: 'PROSIXTEMP' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Surface mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },

            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Detection Principle:', row2: 'High-Temperature Detection (HOT) & Low-Temperature Detection (COLD)'},


            { row1: 'High-Temperature Activation Threshold:', row2: ' Above 95°F for 10 Minutes'},
            { row1: 'Low-Temperature Activation Threshold:', row2: ' Below 45°F for 10 Minutes'},

            { row1: 'Indoor/Outdoor Use:', row2: 'Indoor Use Only' },


            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Product Length:', row2: '3.1 in, 78.7 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Product Height:', row2: '1.4 in, 35.6 mm' },
            { row1: 'Approvals and Standards:', row2: 'ETL Listed' },
        ],
        info: [
            {
                header: 'Temperature Detector: Monitoring for Safety',
                text: 'The PROSIXTEMP temperature detector is designed to monitor temperature changes and detect extreme temperatures, ensuring the safety and protection of your property and assets.',
                section: [
                    { title: "Temperature Monitoring" },
                    { text: "The PROSIXTEMP utilizes advanced temperature sensing technology to monitor changes in temperature. It can detect both high and low temperature thresholds, providing early warning of potential hazards." },
                    
                    { title: "Reliable Performance" },
                    { text: "Engineered for reliability, the PROSIXTEMP temperature detector delivers consistent and accurate detection without false alarms. Its robust design and quality construction ensure optimal performance and longevity, providing peace of mind in critical situations." },
                    
                    { title: "Wireless Connectivity" },
                    { text: "The PROSIXTEMP is a wireless temperature detector that seamlessly integrates with compatible security systems, eliminating the need for complex wiring and installation. This wireless connectivity enhances flexibility and allows for easy placement in strategic locations throughout your property." },
                    
                    { title: "Tamper-Resistant Design" },
                    { text: "With a tamper-resistant design, the PROSIXTEMP provides added security against tampering or interference. Its durable construction and secure mounting ensure reliable operation and protection against unauthorized access." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive instant alerts and notifications on your mobile device or control panel in the event of extreme temperature detection. Stay informed and take prompt action to mitigate risks and ensure the safety of your property and assets." },
                    
                    { title: "Battery-Powered" },
                    { text: "The PROSIXTEMP operates on long-lasting batteries, providing continuous protection even during power outages. Its low-battery indicator alerts you when battery replacement is needed, ensuring uninterrupted functionality." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The PROSIXTEMP temperature detector meets industry standards and certifications for safety, ensuring compliance with local regulations and building codes. It is an essential component of a comprehensive safety plan for residential and commercial properties." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The PROSIXTEMP temperature detector operates using advanced temperature sensing technology to monitor and detect extreme temperatures.',
                section: [
                    { title: "Temperature Sensing" },
                    { text: "The PROSIXTEMP is equipped with a highly sensitive temperature sensor that monitors the ambient temperature in the area where it is installed. It can detect both high and low temperature thresholds." },
                    
                    { title: "Detection Algorithm" },
                    { text: "Upon detecting temperature changes, the detector's built-in detection algorithm analyzes the temperature data to determine if the levels are hazardous. This analysis helps differentiate between normal fluctuations and potentially dangerous temperature extremes." },
                    
                    { title: "Alarm Activation" },
                    { text: "If the temperature is determined to be hazardous, the PROSIXTEMP temperature detector activates its alarm system. The alarm includes audible alerts and visual indicators to alert occupants of the potential danger." },
                    
                    { title: "Wireless Communication" },
                    { text: "The PROSIXTEMP is a wireless temperature detector, meaning it communicates wirelessly with a compatible security system or control panel. When the alarm is triggered, the detector sends an immediate signal to the control panel, which then initiates appropriate response actions." },
                    
                    { title: "Notification and Response" },
                    { text: "Upon receiving the alarm signal, the control panel notifies the designated individuals or monitoring service about the temperature detection event. This notification can be sent via mobile app notifications, text messages, email alerts,  depending on the configured settings." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of temperature detectors is crucial to their effectiveness in monitoring temperature changes and providing timely alerts. Here are some key guidelines for where to place temperature detectors in your home or property:',
                section: [
                    { title: "Basements" },
                    { text: "Install temperature detectors in basements and areas prone to temperature fluctuations. Early detection in these areas can prevent extensive damage due to freezing or overheating." },
                    
                    { title: "Attics" },
                    { text: "Place temperature detectors in attics to monitor for extreme heat during summer months or freezing temperatures in winter. This helps prevent damage to stored items and structural components." },
                    
                    { title: "Living Areas" },
                    { text: "Install temperature detectors in living rooms, family rooms, and other common areas where people spend significant time. This ensures comprehensive coverage and early detection of temperature changes." },
                    
                    { title: "Kitchens" },
                    { text: "Place temperature detectors in kitchens to monitor for extreme heat from cooking appliances or potential overheating." },
                    
                    { title: "Bedrooms" },
                    { text: "Install temperature detectors in bedrooms to ensure a safe and comfortable sleeping environment, especially during extreme weather conditions." },
                    
                    { title: "Near HVAC Systems" },
                    { text: "Place temperature detectors near HVAC systems to monitor for malfunctions that could lead to extreme temperatures. Early detection can prevent damage to the system and maintain a comfortable indoor environment." },
                    
                    { title: "Ceiling or Wall Mounting" },
                    { text: "Mount temperature detectors on the ceiling or high on walls for optimal coverage. Ensure they are placed in areas where they can accurately monitor ambient temperature." },
                ]
            },

            {
                header: 'Common causes of false alarms',
                text: 'False alarms from temperature detectors can occur due to various reasons, some of which are common and can be addressed to prevent unnecessary disruptions. Here are common causes of false alarms from temperature detectors:',
                section: [
                    { title: "Rapid Temperature Changes" },
                    { text: "Sudden temperature changes due to HVAC system cycling or opening windows/doors can trigger false alarms. Ensure detectors are placed away from direct airflow to minimize false alarms." },
                    
                    { title: "Proximity to Heat Sources" },
                    { text: "Detectors placed too close to heat sources such as ovens, stoves, or heaters can trigger false alarms. Position detectors at a safe distance from these sources." },
                    
                    { title: "Direct Sunlight" },
                    { text: "Direct exposure to sunlight can cause temperature sensors to overheat and trigger false alarms. Install detectors in shaded areas to avoid direct sunlight." },
                    
                    { title: "High Humidity" },
                    { text: "High humidity levels in certain areas, such as bathrooms or basements, can affect sensor accuracy and cause false alarms. Ensure proper ventilation and consider using humidity-resistant detectors if needed." },
                    
                    { title: "Malfunctioning Detectors" },
                    { text: "Over time, detectors may develop faults or malfunctions due to age, wear, or exposure to environmental factors. Malfunctioning detectors may generate false alarms or fail to detect temperature changes when needed." },
                ]
            },
        ]
    }
    return sensor;
}
export default PROSIXTEMP;
