import css from './calcApp.module.css'
import React from 'react';
//import AppCard from "./AppCommonComponents/AppStructure/AppCard/AppCard"
import LoadingView from './LoadingView/LoadingView';


const CalcApp = ()=>{
    return(
        <div>
            <LoadingView/> 
        </div>
    )
}
export default CalcApp;