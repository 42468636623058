import css from './PlainPage.module.css';
import React from 'react';

const PlainPage = ({ 
    step, 
    setStep, 
    header='', 
    paragraph='', 
    backButtonDir=step-1,
    nextButtonDir=step+1,    
    backButtonLabel='Back',
    nextButtonLabel='Next',
    isBackButton=true,
    isNextButton=true
}) => {
    

 
    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <div className={css.titleBox}>
                    <h2>{header}</h2>
                </div>
                

                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>{paragraph}</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.buttonBoxWrapper}>
                    {isBackButton&&(
                        <button
                        className={css.buttonActive}
                        onClick={()=>setStep(backButtonDir)}
                        >
                        {backButtonLabel}
                        </button>
                    )}
                   {isNextButton&&(
                        <button
                            className={css.buttonActive }
                            onClick={()=>setStep(nextButtonDir)}
                        >
                            {nextButtonLabel}
                        </button>
                   )}
                    
                </div>
            </div>
        </div>
    );
};

export default PlainPage;
