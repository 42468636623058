import e1 from './img/e1.jpg';
import e2 from './img/e2.jpg';
import e3 from './img/e3.jpg';
import { getPrice } from '../../../../../Prices';

const C8CLiteEZVIZ = () => {
    const device = {
        title: '2MP Outdoor Wi-Fi Camera, 2-Month Recording Storage',
        model: '360° 2MP 256GB',
        sku: ['360 C-Lite'],
        modelViewImage: e3,
        image: [e3,e2,e1],
        shortDescription: 'A 360° pan and tilt outdoor camera featuring 2-month recording storage, 2MP resolution, night vision, and both motion and person detection.',
        price:getPrice('EZVIZ_360_256GB'),
        checkListKeyword: ['Video'],
        infoHeader: "Outdoor camera with 1-month recording storage.",
        spec: [
            { row1: 'Model:', row2: '360 C-Lite 256GB' },
            { row1: 'Video Storage:', row2: '256GB' },
            { row1: 'Cloud Storage:', row2: 'Optional, (requires additional subscription)' },
            { row1: 'Motorized:', row2: 'A 360° pan and tilt camera, controllable from a mobile app, with autonomous person auto-tracking.' },
            { row1: 'Brand:', row2: 'EZVIZ' },
            { row1: 'Resolution:', row2: '2MP (1080P)' },
            { row1: 'Connectivity:', row2: 'Wi-Fi' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Wall or Ceiling mount' },
            { row1: 'Power Supply:', row2: 'DC 12V' },
            { row1: 'Field of View:', row2: '360° Panoramic View' },
            { row1: 'Audio:', row2: 'Two-Way Audio' },
            { row1: 'Night Vision:', row2: 'Yes' },
            { row1: 'Motion Detection:', row2: 'Yes' },
            { row1: 'Dimensions:', row2: '6.0 x 6.0 x 5.0 inches' },
            { row1: 'Weatherproof:', row2: 'IP66' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'FCC, CE, RoHS' },
        ],
        info: [
            {
                header: 'High-Quality Surveillance and Monitoring',
                text: 'The 360 C-Lite EZVIZ 2MP Camera provides high-quality surveillance and monitoring for your outdoor spaces. With its advanced features and reliable performance, it ensures the security and safety of your property.',
                section: [
                    { title: "2MP Full HD Video" },
                    { text: "Capture clear and detailed video with the 360 C-Lite's 2MP (1080P) resolution. The high-definition video ensures you don't miss any important details." },
                    
                    { title: "360° Panoramic View" },
                    { text: "The camera offers a 360° panoramic view, allowing you to monitor a wide area with a single device. This comprehensive coverage enhances your security." },
                    
                    { title: "Advanced Motion Detection" },
                    { text: "The 360 C-Lite features advanced motion detection technology, alerting you to any movement within its field of view. Customize motion detection zones and sensitivity to reduce false alarms." },
                    
                    { title: "Enhanced Night Vision" },
                    { text: "Equipped with infrared night vision, the camera provides clear video even in low-light conditions. This ensures reliable monitoring around the clock." },
                    
                    { title: "Two-Way Audio" },
                    { text: "With built-in two-way audio, you can communicate with visitors or deter potential intruders. The high-quality microphone and speaker ensure clear conversations." },
                    
                    { title: "Wi-Fi Connectivity" },
                    { text: "The camera connects to your home Wi-Fi network, allowing you to access live video and recordings from your smartphone or computer. This wireless connectivity simplifies installation and ensures remote access." },
                    
                    { title: "Weatherproof Design" },
                    { text: "Designed for outdoor use, the 360 C-Lite features an IP66 weatherproof rating, protecting it from rain, snow, and other environmental factors. This ensures reliable performance in various weather conditions." },
                    
                    { title: "Tamper-Resistant" },
                    { text: "The camera includes tamper detection features, alerting you if the device is tampered with or removed. This enhances security and ensures continuous protection." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The 360 C-Lite EZVIZ 2MP Camera meets industry standards and certifications for safety and performance, including FCC, CE, and RoHS certifications." },
                ]
            },

            {
                header: 'Cutting-Edge Security Features',
                text: 'The 360 C-Lite EZVIZ 2MP Camera offers cutting-edge security features to keep your property safe and secure. From high-definition video to smart alerts, this camera is designed to provide comprehensive protection.',
                section: [
                    { title: "Smart Motion Alerts" },
                    { text: "Receive instant alerts on your smartphone when motion is detected. The smart alert system ensures that you are always aware of what is happening around your property." },
                    
                    { title: "Easy Installation" },
                    { text: "The camera's wireless connectivity and versatile mounting options make installation quick and easy. Whether you mount it on a wall or ceiling, you'll have the flexibility to choose the best location for optimal coverage." },
                    
                    { title: "Remote Monitoring" },
                    { text: "Monitor your property from anywhere with the EZVIZ app. Access live video feeds, review recorded footage, and manage camera settings remotely, giving you peace of mind no matter where you are." },
                    
                    { title: "Customizable Settings" },
                    { text: "Customize the camera's settings to suit your specific needs. Adjust motion detection zones, set alert preferences, and configure video recording schedules for personalized security." },
                    
                    { title: "Secure Cloud Storage" },
                    { text: "Store your video footage securely in the cloud. The 360 C-Lite offers cloud storage options to ensure that your recordings are safe and accessible whenever you need them." },
                    
                    { title: "Privacy Protection" },
                    { text: "The camera includes privacy protection features, allowing you to disable recording when privacy is required. This ensures that your personal space remains private and secure." },
                ]
            },
        ]
    }
    return device;
}
export default C8CLiteEZVIZ;
