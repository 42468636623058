
export default function SvgBlueWave(){
    return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="640" height="480" viewBox="0, 0, 640, 480">
    <g id="Layer_1">
      <path d="M-21.111,-33.257 L675.394,-33.257 L675.394,497.598 L-21.111,497.598 z" fill="#274E65"/>
      <path d="M-174.909,-16.989 L580.732,-275.347 C589.187,-278.238 597.213,-277.158 598.657,-272.934 L707.699,45.991 C709.143,50.215 703.46,55.983 695.004,58.874 L-60.636,317.232 C-69.092,320.123 -77.117,319.043 -78.562,314.819 L-187.604,-4.106 C-189.048,-8.33 -183.364,-14.098 -174.909,-16.989 z" fill="#30586F"/>
      <path d="M-214.833,-63.161 L472.431,-469.866 C480.122,-474.417 488.199,-474.992 490.472,-471.151 L662.126,-181.084 C664.399,-177.243 660.008,-170.439 652.318,-165.888 L-34.947,240.817 C-42.637,245.368 -50.715,245.943 -52.988,242.102 L-224.642,-47.965 C-226.915,-51.807 -222.524,-58.61 -214.833,-63.161 z" fill="#305D77"/>
      <path d="M-191.75,117.5 C-199.068,117.5 -205,110.225 -205,101.25 C-205,92.275 -199.068,85 -191.75,85 C-184.432,85 -178.5,92.275 -178.5,101.25 C-178.5,110.225 -184.432,117.5 -191.75,117.5 z" fill="#294460"/>
    </g>
  </svg>
  
  `;
}