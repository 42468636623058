import React from 'react';
import css from './Pay.module.css';
import { MdOutlineRequestQuote } from "react-icons/md";

const Pay=({view, setView})=>{
    return (
        <div className={view !== 'payment' ? `${css.box} ${css.inActive}` : `${css.box} ${css.active}`} onClick={() => setView('payment')}>
            <div className={css.iconWrapper}>
                <MdOutlineRequestQuote className={css.icon} />
            </div>
            <div className={css.label}>
                Plan
            </div>
        </div>
    );
}
export default Pay;