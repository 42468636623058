import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import css from './Navigation.module.css';
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import PhoneIcon from "../../nav/PhoneIcon/PhoneIcon";

const Navigation = () => {
  const location = useLocation(); // Get the current location
  const isPackagesPage = location.pathname === "/packages"; // Determine if it's the 'packages' page

  // Detect when on mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const onClick = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const closeMenu = () => {
    setBurgerMenuOpen(false);
    window.scrollTo(0, 0); // Scroll to top after closing the menu
  };

  // Add an event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Update isMobile based on the window width
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className={css.header}>
      {!isPackagesPage && ( // Hide nav on packages open
        <nav className={css.nav}>
          <div>
            {isMobile && (
              <div className={css.iconsWrapper}>
                <div className={css.burgerMenuIcon}>
                  {isMobile && <BurgerMenu burgerMenuOpen={burgerMenuOpen} onClick={onClick} />}
                </div>
                <div className={css.callIcon}>
                  {isMobile && <PhoneIcon />}
                </div>
              </div>
            )}

            {((isMobile && burgerMenuOpen) || !isMobile) && (
              <div className={css.linksWrapper}>
                <ul className={css.text_light}>
                  <li>
                    <Link to="/" className={css.link} onClick={closeMenu}>
                      <span>Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/packages" className={css.link} onClick={closeMenu}>
                      <span>Packages</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transfer" className={css.link} onClick={closeMenu}>
                      <span>Transfer</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className={css.link} onClick={closeMenu}>
                      <span>Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      )}
    </header>
  );
};

export default Navigation;
