import React from 'react';
import css from './Exit.module.css';
import { ImExit } from "react-icons/im";
import { useNavigate } from 'react-router-dom';

const Exit = () => {
    const navigate = useNavigate(); // Hook to navigate
    const goHome = () => {
        navigate('/'); // Navigate to the home page
    };
    
    return (
        <div className={css.box} onClick={goHome}>
            <div className={css.iconWrapper}>
                <ImExit className={css.icon} />
            </div>
            <div className={css.label}>
                Exit
            </div>
        </div>
    );
};

export default Exit;
