import css from './Recipient.module.css';
import React, { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { SlOptionsVertical, SlOptions } from "react-icons/sl";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { RiDragDropLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { FaStarOfLife } from "react-icons/fa6";


const ItemType = 'RECIPIENT';

const Recipient = ({ id, name, phone, passcode, valid, isChecked, deleteRecipient, handleUserInput, handleCheckboxChange, order, index, moveRecipient }) => {
    
    
    const [optionsOn, setOptionsOn] = useState(false);
    const handleOptions = () => {
        setOptionsOn(!optionsOn);
    };

    const ref = useRef(null);
    
    const [{ handlerId }, drop] = useDrop({
        accept: ItemType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4; // Adjusted trigger point
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            
            if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
                moveRecipient(dragIndex, hoverIndex);
                item.index = hoverIndex;
            } else if (dragIndex > hoverIndex && hoverClientY < hoverBoundingRect.bottom - hoverMiddleY) {
                moveRecipient(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const getOrderTitle = (order) => {
        switch(order) {
            case 1:
                return '1st person to call';
            case 2:
                return '2nd person to call';
            case 3:
                return '3rd person to call';
            default:
                return `${order}th person to call`;
        }
    };

    return (
        <div ref={ref} className={css.recipientBox} style={{ opacity: isDragging ? 0.7 : 1, backgroundColor: isDragging ? '#274E65' : 'white'   , color: isDragging ? 'white' : '#274E65'  }} data-handler-id={handlerId}>
            <div className={css.itemsWrapper}>
                <div className={css.headerBox}>
                    <div className={css.title}>
                        {!optionsOn && getOrderTitle(order)}
                    </div>
                    <div className={css.delete} onClick={handleOptions}>
                        {optionsOn ? <SlOptionsVertical /> : <SlOptions />}
                    </div>
                </div>
                {optionsOn ? (
                    <div className={css.inputs}>
                        <div className={css.optionsBox}>
                            <div className={css.option}>
                                <div>
                                    <RiDragDropLine className={css.dragIcon} />
                                    <p>Drag-and-Drop<br/> to change order</p>
                                </div>
                            </div>
                            <div className={css.option}>
                                <div className={css.deleteBox} onClick={() => deleteRecipient(id)}>
                                    <p>delete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={css.inputs}>
                        <div className={css.inputBox}>
                            <label className={css.label} htmlFor={`name-${id}`}>Name</label>
                            <input
                                id={`name-${id}`}
                                name='name'
                                className={css.input}
                                type='text'
                                value={name}
                                onChange={(e) => handleUserInput(id, e)}
                            />{valid.name?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputBox}>
                            <label className={css.label} htmlFor={`phone-${id}`}>Phone</label>
                            <input
                                id={`phone-${id}`}
                                name='phone'
                                className={css.input}
                                type='text'
                                value={phone}
                                onChange={(e) => handleUserInput(id, e)}
                            />{valid.phone?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputBox}>
                            <label className={css.label} htmlFor={`passcode-${id}`}>Passcode</label>
                            <input
                                id={`passcode-${id}`}
                                name='passcode'
                                className={css.input}
                                type='text'
                                value={passcode}
                                onChange={(e) => handleUserInput(id, e)}
                            />{valid.passcode?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.checkboxBox}>
                            <div className={css.checkbox}>
                                <div onClick={() => handleCheckboxChange(id)}>
                                    {isChecked ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}
                                </div>
                            </div>
                            <div className={css.term}>
                                <p>Person Authorized to Manage the Account</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Recipient;
