import React from 'react';
import css from './SectionTitleWithEditButton.module.css';
import { CiEdit } from "react-icons/ci";
const SectionTitleWithEditButton = ({title, setView})=>{
    
    return(
        <div className={css.optionBox}>
            <div className={css.optionTitleBox}>
                <h3>{title}</h3>
            </div>
            
        </div>
    )
}
export default SectionTitleWithEditButton;