
import Cr123aImage from '../../../Batteries/Cr123a.jpg'
import mot1 from './PROSIXPIRimg/mot1.jpg'
import mot2 from './PROSIXPIRimg/mot2.jpg'
import mot3 from './PROSIXPIRimg/mot3.jpg'
import mot4 from './PROSIXPIRimg/mot4.jpg'
import mot5 from './PROSIXPIRimg/mot5.jpg'
import mot6 from './PROSIXPIRimg/mot6.jpg'
import mot7 from './PROSIXPIRimg/mot7.jpg'
import { getPrice } from '../../../../../../Prices';

const PROSIXPIR =()=>{
    const sensor = {
        title: 'Motion Detector',
        model:'PROSIXPIR',
        sku:['PROSIXPIR'],
        modelViewImage:mot1,
        image:[ mot1,mot2,mot3,mot7,mot5,mot6,mot4,Cr123aImage,],
        shortDescription:'Advanced PIR technology for accurate motion detection.',
        price: getPrice('PROSIXPIR'),
        group:'sensor',
        checkListKeyword:['Burglar'],
        infoHeader:"Passive Infrared Detector",
        spec:[
            {row1:'Model:',row2:'PROSIXPIR'},
            {row1:'Brand:',row2:'Honeywell'},
            {row1:'Conectivity:',row2:'Wireless'},
            {row1:'Color:',row2:'White'},
            {row1:'Mounting:',row2:'Wall, Corner, Surface mount'},
            {row1:'Detection RangeMotion Detection:',row2:'Passive Infrared (PIR)'},
            {row1:'Mounting Height:',row2:'7 to 9 Feet (7.5 Feet Optimal)'},
            {row1:'Viewing Angle:',row2:'90 Degrees'},
            {row1:"Coverage Area:",row2:"40' by 56'"},
            {row1:'Wireless Range:',row2:'300+ Feet (Open Air) from main controler'},
            {row1:'Pet Immunity:',row2:'Up to 60 lbs'},
            {row1:'Power Supply:',row2:'Battery Powered'},
            {row1:'Battery Type:',row2:'Lithium'},
            {row1:'Battery Size:',row2:'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V'},
            {row1:'Number of Batteries:',row2:'1'},
            {row1:'Tamperproof:',row2:'Yes'},
            {row1:'Product Length:',row2:'2.3 in, 58.42 mm' },
            {row1:'Product Width:',row2:'2.38 in, 60.4 mm'},
            {row1:'Product Height:',row2:'4.13 in, 105 mm'},
            {row1:'Indoor/Outdoor',row2:'Indoor Use Only'},
        ],
        info:[
            {
                header:'How does it work?',
                text:'Introducing the PROSIXPIR Motion Detector',
                section: [
                    {title: "PROSIXPIR Motion Detector"},
                    {text: "The PROSIXPIR Motion Detector is a cutting-edge security solution designed to keep your property safe and secure. With its advanced passive infrared (PIR) technology, this sensor is highly sensitive to changes in infrared radiation caused by moving objects, ensuring accurate detection of motion within your designated area."},
                    {title: "Detection Principle"},
                    {text: "The PROSIXPIR sensor contains a pyroelectric sensor, which is capable of detecting changes in infrared radiation within its field of view."},
                    {title: "Infrared Radiation"},
                    {text: "All objects emit infrared radiation (heat) as a function of their temperature. When a person or object moves within the detection range of the sensor, there is a change in the infrared radiation pattern in the sensor's field of view."},
                    {title: "Detection Circuitry"},
                    {text: "The sensor's circuitry is designed to detect rapid changes in the infrared radiation pattern. When it senses such changes, it interprets this as movement or motion."},
        
                    {title: "Triggering Response"},
                    {text: "The sensor's circuitry is designed to detect rapid changes in the infrared radiation pattern. When it senses such changes, it interprets this as movement or motion."},
        
                    {title: "Adjustable Parameters"},
                    {text: "Modern motion detectors like the PROSIXPIR often come with adjustable parameters such as sensitivity, range, and pet immunity. These settings allow users to customize the detector's behavior based on their specific needs and environment."},
        
                    {title: "Detection Circuitry"},
                    {text: "The sensor's circuitry is designed to detect rapid changes in the infrared radiation pattern. When it senses such changes, it interprets this as movement or motion."},
                ]
            },
            {
                header:'Where to place it?',
                text:'When deciding where to place a PROSIXPIR motion detector or any similar motion sensor, consider the following guidelines',
                section: [
                    {title: "Height"},
                    {text: "Install the motion detector at a height of 6 to 8 feet above the floor. This height range is ideal for capturing movement across a room without being obstructed by furniture or other objects."},
                    
                    {title: "Line of Sight"},
                    {text: "Ensure that the sensor has a clear line of sight across the area you want to monitor. Avoid placing it behind large objects like furniture, curtains, or plants that could block its view and hinder detection."},
                    
                    {title: "Avoid Heat Sources"},
                    {text: "Position the detector away from heat sources such as radiators, heating vents, or direct sunlight. Heat sources can cause false alarms or affect the sensor's performance."},

                    {title: "Entry Points"},
                    {text: "Place motion detectors near entry points such as doors and windows to detect intruders entering or moving around these areas."},
        
                    {title: "Room Coverage"},
                    {text: "Aim to position the detector centrally within the room to maximize its coverage area. In larger rooms, consider using multiple detectors."},
        
                    {title: "Avoid Vibrations"},
                    {text: "Ensure that the sensor is mounted securely and stable to avoid false alarms triggered by vibrations or movements of the mounting surface."},
        
                    {title: "Pet Considerations"},
                    {text: "If you have pets, adjust the detector's sensitivity or use models with pet immunity features to prevent false alarms caused by pet movement. Place the sensor where pets are less likely to trigger it directly."},

                    {title: "Test Placement"},
                    {text: "Before finalizing the installation, test the detector's placement by walking through the monitored area and observing if the sensor detects your movement reliably without false alarms."},
                ]
            },
            {
                header:'When it may not detect?',
                text:'There are several scenarios in which a PROSIXPIR motion detector may not detect a person or may have reduced sensitivity',
                section: [
                    {title: "Out of Range"},
                    {text: "If a person is outside the detection range, more than 7 meters of the sensor, it will not detect their presence. Motion detectors have specific coverage areas, and positioning them correctly is essential for optimal performance."},
                    
                    {title: "Blocked Line of Sight"},
                    {text: "If there are obstacles blocking the line of sight between the sensor and the person, such as furniture, walls, or curtains, the detector may not detect the person's movement."},
                    
                    {title: "Slow or Minimal Movement"},
                    {text: "PROSIXPIR detectors are designed to detect rapid changes in infrared radiation patterns, which typically occur with human movement. Slow or minimal movement may not trigger the sensor."},

                    {title: "Pet Immunity"},
                    {text: "Some motion detectors, including PROSIXPIR models, have pet immunity features designed to reduce false alarms caused by pets moving within the detection area. However, very large pets or pets that move rapidly may still trigger the sensor."},
        
                    {title: "Environmental Factors"},
                    {text: "Extreme temperatures, direct sunlight, or rapid temperature changes in the environment can affect the performance of PIR sensors. It's essential to install the detector in a suitable location to minimize these effects."},
        
                    {title: "Tampering or Damage"},
                    {text: "Physical damage to the sensor or tampering with its components can also lead to reduced sensitivity or failure to detect motion."},
        
                    {title: "Alberta's Winter and PIR Motion Detector"},
                    {text: "In Alberta's winter conditions, where temperatures can plummet well below freezing, there can be a detection problem with any motion detectors when a burglar spends an extended period outside, causing their jacket to become cold. Imagine a burglar attempting to break into a home during a cold winter night. As they enter the property, the motion detector is designed to detect any movement within its range. However, because the burglar has been outside in freezing temperatures for some time, their clothing, including their jacket, has become extremely cold. The issue arises because the motion detector primarily relies on changes in infrared radiation, typically caused by the warmth of a person's body, to detect motion. In this case, the burglar's jacket, being cold from the winter weather, doesn't emit enough infrared radiation to be distinguished as a warmer object by the detector.  This situation highlights a limitation of PIR-based motion detectors in extreme cold conditions, where the contrast between the cold ambient temperature and the person's body heat diminishes, making it challenging for the sensor to detect the person as a moving object. To address this issue in such cold climates, you may need to consider additional security measures or alternative sensor technologies that are less affected by extreme temperature differentials. This could include using a window sensors, glasbreak or vibration detectors"},
                ]
            },
            {
                header:'Common Cause of False Alarms',
                text:'A common cause of false alarms from motion detectors, including PROSIXPIR motion detectors, is environmental factors. Here are some specific scenarios that can lead to false alarms',
                section: [
                    {title: "Pets"},
                    {text: "Pets moving within the detection area can trigger motion detectors that are not designed with pet immunity features. Even with pet-immune sensors, very large pets or pets that move rapidly may still cause false alarms."},
                    
                    {title: "Insects and Small Animals"},
                    {text: "Flying insects, spiders, or small animals like rodents can trigger motion sensors, especially if they move close to the sensor or directly in front of it."},
                    
                    {title: "Moving Objects"},
                    {text: "Objects such as curtains, flags, party balloons or hanging decorations that move due to wind or air currents can trigger motion detectors, especially if they are within the sensor's field of view."},

                    {title: "Reflections and Glare"},
                    {text: "Mirrors, reflective surfaces, or highly polished objects can reflect infrared radiation, leading to false detections when the reflected heat is interpreted as movement by the sensor."},
        
                    {title: "Sunlight and Heat Sources"},
                    {text: "Direct sunlight entering through windows or heating sources (like radiators) can cause rapid temperature changes within the sensor's detection area, triggering false alarms."},
                ]
            }
           
        ]
    }
    return sensor
}
export default PROSIXPIR;