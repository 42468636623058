import React from 'react';
import css from './GraphicCheckBoxWithLabel.module.css';
import { FaCheck } from "react-icons/fa";
import { TiInputCheckedOutline } from "react-icons/ti";
const GraphicCheckBoxWithLabel = ({title='',checked=false})=>{
    return(
        <div className={css.elementBox}>
            <div className={css.elementCheckBox}>
                {checked && (<FaCheck />)}
            </div>
            <div className={css.elementCheckBoxTitle}>
                {title}
            </div>
        </div>
    )
}
export default GraphicCheckBoxWithLabel;