import React, {useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import TextInputWithLabel from "../../Form/FormElements/TextInputWithLabel/TextInputWithLabel"
import SubmitButton from "../../Form/FormElements/SubmitButton/SubmitButton"
import TextAreaWithLabel from "../../Form/FormElements/TextAreaWithLabel/TextAreaWithLabel"
import css from './ContactFormWithEmailSender.module.css'

const ContactFormWithEmailSender = ()=>{
    const[confirmation, setConfirmation] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_52qj8o7', 'template_oy1eewy', form.current, 'Pgvr9-YrZHu7sbMO4')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        setConfirmation(true);
    };
    return(
        <div className={css.formWrapper}>
            <form ref={form} onSubmit={sendEmail}  autoComplete="on">
                <div><h3>{confirmation ? 'Message has been sent!':'Send us a message'}</h3></div>
                
                <div className={css.box}>
                </div>

                <TextInputWithLabel name="name" autoComplete="name" id="name" label="Name: " placeholder="First, Last Name" required="required"/>
                <TextInputWithLabel name="email" type="email" autoComplete="email" id="email" label="Email: " placeholder="Email Address" required="required"/>
                <TextInputWithLabel name="phone" type="texty" autoComplete="phone" id="phone" label="Phone: " placeholder="Phone Number" required="required"/>
                <TextInputWithLabel name="address" type="texty" autoComplete="address" id="address" label="Address: " placeholder="Your Address" required="required"/>
                <TextInputWithLabel name="city" type="texty" autoComplete="city" id="city" label="City: " placeholder="City" required="required"/>
                <TextInputWithLabel name="postal" type="texty" autoComplete="postal" id="postal" label="Postal: " placeholder="Postal Code" required="required"/>
                <TextAreaWithLabel rows="6" maxLength="1028" name="message" autoComplete="message" type="texty" id="message" label="Message: " placeholder="Your Message" required="required"/>
                <SubmitButton text="Send" value="send"/>
            </form>
        </div>
    )
}
export default ContactFormWithEmailSender;