import React from 'react';
import css from "./ContentText.module.css";

export default function ContentText({content='', settings, fontSize}){

    return(
        <>
        {content && (
            <div className={css.contentWrapper}>
                <div className={`${css.content} ${settings.darkBackground ? css.brightFont: ''}`}>
                    <p style={{fontSize: `clamp(1rem, 3vw, ${fontSize}rem)`}} dangerouslySetInnerHTML={{ __html: content }}></p>
                </div>
            </div>
        )}
        </>
    )
}