import v1 from './img/v1.jpg';
import v2 from './img/v2.jpg';
import v3 from './img/v3.jpg';
import v4 from './img/v4.jpg';
import v5 from './img/v5.jpg';
import { getPrice } from '../../../../Prices';
const GE45603ZW = () => {
    const device = {
        title: 'GE 45603 Z-Wave Smart Switch',
        model: 'GE45603ZW',
        sku: ['GE45603ZW'],
        modelViewImage: v1,
        image: [v1,v2,v3,v4,v5],
        shortDescription: 'Smart switch with Z-Wave technology for convenient home automation.',
        price:getPrice('GE45603ZW'),
        group: 'automation',
        checkListKeyword: ['automation'],
        infoHeader: "Smart Switch",
        spec: [
            { row1: 'Model:', row2: '45603' },
            { row1: 'Brand:', row2: 'GE' },
            { row1: 'Connectivity:', row2: 'Z-Wave' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Plug' },
            { row1: 'Power Supply:', row2: 'AC 120V' },
            { row1: 'Compatibility:', row2: 'Works with most lighting fixtures' },
            { row1: 'Smartphone Control:', row2: 'Yes, TC 2.0 alarm app' },
            { row1: 'Dimensions:', row2: '4.5 x 2.75 x 1.75 inches' },
            { row1: 'Weight:', row2: '0.3 lbs' },
        ],
        info: [
            {
                header: 'Convenient Home Automation',
                text: 'The GE 45603 Z-Wave Smart Switch provides convenient home automation with advanced features and smart technology. Control your lighting from anywhere with ease.',
                section: [
                    { title: "Smart Control" },
                    { text: "Control your lighting remotely with your smartphone or Z-Wave controller. The 45603 offers seamless integration with smart home systems, providing remote access and control." },
                    
                    { title: "Z-Wave Technology" },
                    { text: "The built-in Z-Wave technology allows for integration with other Z-Wave devices in your smart home network. This enhances the functionality and automation of your home lighting." },
                    
                    { title: "Easy Installation" },
                    { text: "Designed for easy installation, the GE 45603 fits most standard wall boxes and comes with detailed instructions. The wall mount design ensures a clean and integrated look." },
                    
                    { title: "Energy Efficiency" },
                    { text: "The smart switch helps improve your home’s energy efficiency by allowing you to create custom schedules and control lighting remotely. Save on energy costs while maintaining comfort." },
                    
                    { title: "Voice Control" },
                    { text: "Compatible with popular voice assistants like Amazon Alexa and Google Assistant, allowing you to control your lighting with simple voice commands." },
                    
                    { title: "Real-Time Alerts" },
                    { text: "Receive real-time alerts on your smartphone for switch status, tamper alerts, and more. Stay informed and in control no matter where you are." },
                    
                    { title: "Customizable Settings" },
                    { text: "Customize the switch’s settings to suit your specific needs. Set lighting schedules, adjust settings remotely, and manage notifications through the dedicated app." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The GE 45603 smart switch meets industry standards and certifications for safety and performance, including ETL listing and FCC certification." },
                ]
            },

            {
                header: 'Smart Features for Modern Living',
                text: 'The GE 45603 Z-Wave Smart Switch offers smart features to enhance your home’s lighting control. From remote access to energy savings, this switch is designed to provide convenience and efficiency.',
                section: [
                    { title: "Remote Access" },
                    { text: "Manage your home’s lighting from anywhere using the dedicated app. Adjust settings, create schedules, and receive alerts for complete control over your home’s lighting." },
                    
                    { title: "Energy Savings" },
                    { text: "The 45603 helps you save on energy costs by allowing you to create custom lighting schedules that fit your daily routine. Reduce energy consumption without sacrificing comfort." },
                    
                    { title: "Smart Alerts" },
                    { text: "Receive smart alerts for switch status, tamper detection, and more. Stay informed and proactive about your home’s lighting control." },
                    
                    { title: "Sleek and Modern Design" },
                    { text: "The GE 45603 features a sleek and modern design that complements any home decor. The intuitive interface provides easy access to all settings and information." },
                    
                    { title: "Compatibility" },
                    { text: "The switch is compatible with most lighting fixtures, ensuring that your home’s lighting control is efficient and reliable." },
                ]
            },
        ]
    }
    return device;
}
export default GE45603ZW;
