
import PROSIXCT from '../alarms/ProSeriressPlus/Sensors/PROSIXCT/PROSIXCT';
import PROSIXCTOHR from '../alarms/ProSeriressPlus/Sensors/PROSIXCTOHR/PROSIXCTOHR';
import PROSIXCTOHC from '../alarms/ProSeriressPlus/Sensors/PROSIXCTOHC/PROSIXCTOHC';
import PROSIXSHOCK from './ProSeriressPlus/Sensors/PROSIXSHOCK/PROSIXSHOCK';
import PROSIXPIR from './ProSeriressPlus/Sensors/PROSIXPIR/PROSIXPIR'
import PROA7PLUSWIFI from '../alarms/ProSeriressPlus/Controler/Proa7plusWifi';
import PROSIXSMOKEV from './ProSeriressPlus/Sensors/PROSIXSMOKEV/PROSIXSMOKEV';
import PROSIXHEAT from '../alarms/ProSeriressPlus/Sensors/PROSIXHEAT/PROSIXHEAT';
import PROSIXCO from '../alarms/ProSeriressPlus/Sensors/PROSIXCO/PROSIXCO';
import PROSIXCOMBO from '../alarms/ProSeriressPlus/Sensors/PROSIXCOMBO/PROSIXCOMBO';
import Wifi from '../alarms/ProSeriressPlus/Monitoring/Wifi/Wifi';
import PROSIXGB from'../alarms/ProSeriressPlus/Sensors/PROSIXGB/PROSIXGB';
import PROSIXFLOOD from'../alarms/ProSeriressPlus/Sensors/PROSIXFLOOD/PROSIXFLOOD';
import PROSIXTEMP from'../alarms/ProSeriressPlus/Sensors/PROSIXTEMP/PROSIXTEMP';
import PROSIXPANIC from'../alarms/ProSeriressPlus/Sensors/KEYFOB/PROSIXPANIC/PROSIXPANIC';
import PROSIXKEYFOB from'../alarms/ProSeriressPlus/Sensors/KEYFOB/PROSIXKEYFOB/PROSIXKEYFOB';
import PROSIXMED from'../alarms/ProSeriressPlus/Sensors/KEYFOB/PROSIXMED/PROSIXMED';
import WifiCell from '../alarms/ProSeriressPlus/Monitoring/WifiCell/WifiCell';
import ToatlConnect2 from '../alarms/ProSeriressPlus/Monitoring/TotalConnect2/TotalConnect2';
import PROWLTOUCHwall from './ProSeriressPlus/Addons/PROWLTOUCHwall/PROWLTOUCH';
import PROWLTOUCHdesk from './ProSeriressPlus/Addons/PROWLTOUCHwDeskStand/PROWLTOUCHwDeskStand';
import PROSIXLCDKP from './ProSeriressPlus/Addons/PROSIXLCDKP/PROSIXLCDKP';
import PROSIXSIRENO from './ProSeriressPlus/Addons/PROSIXSIRENO/PROSIXSIRENO';
import PROSIXSIREN from './ProSeriressPlus/Addons/PROSIXSIREN/PROSIXSIREN';

import C8CLite128 from '../Cameras/EZVIZ/C8CLite/C8CLite128';
import C8CLite256 from '../Cameras/EZVIZ/C8CLite/C8CLite256';
import DSHD2 from '../Doorbells/HIK-VISION-DS-HD2/DSHD2'

import Weiser10s from '../Locks/Weiser10s/Weiser10s';
import ExistingLock from '../Locks/Existing/Lock';

import T6PRO from '../Thermostat/T6PRO/T6PRO';
import ExistingThermostat from '../Thermostat/Existing/Thermostat';

import GD00Z8GC from '../GarageOpeners/GoControl/GD00Z8GC'
import ExistingGarageOpener from '../GarageOpeners/Existing/GarageOpener';

import GE45603ZW from '../Light/GE45603/GE45603'
import ExistingLight from '../Light/Existing/Light';

import ExistingDoor from './ProSeriressPlus/Sensors/ExistingWiredSensors/Door/Door';
import ExistingMotion from './ProSeriressPlus/Sensors/ExistingWiredSensors/Motion/Motion';
import ExistingWindow from './ProSeriressPlus/Sensors/ExistingWiredSensors/Window/Window';
import ExistingGlass from './ProSeriressPlus/Sensors/ExistingWiredSensors/Glass/Glass';
import ExistingSmoke from './ProSeriressPlus/Sensors/ExistingWiredSensors/Smoke/Smoke';
import ExistingHeat from './ProSeriressPlus/Sensors/ExistingWiredSensors/Heat/Heat';
import ExistingCo from './ProSeriressPlus/Sensors/ExistingWiredSensors/Co/Co';
import ExistingWater from './ProSeriressPlus/Sensors/ExistingWiredSensors/Water/Water';
import ExistingFreez from './ProSeriressPlus/Sensors/ExistingWiredSensors/Freez/Freez';
import ExistingPanic from './ProSeriressPlus/Sensors/ExistingWiredSensors/Panic/Panic';
import ExistingOH from './ProSeriressPlus/Sensors/ExistingWiredSensors/OH/OH';

import ExistingWirelessDoor from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Door/Door';
import ExistingWirelessMotion from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Motion/Motion';
import ExistingWirelessWindow from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Window/Window';
import ExistingWirelessGlass from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Glass/Glass';
import ExistingWirelessSmoke from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Smoke/Smoke';
import ExistingWirelessHeat from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Heat/Heat';
import ExistingWirelessCo from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Co/Co';
import ExistingWirelessWater from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Water/Water';
import ExistingWirelessFreez from './ProSeriressPlus/Sensors/ExistingWirelessSensors/Freez/Freez';
import ExistingWirelessOH from './ProSeriressPlus/Sensors/ExistingWirelessSensors/OH/OH';


import {getPrice} from '../../../Prices';

const KitProPlus7=()=>
{
    const checker = {
        isAppInBasket:(basket, category)=>{
            const MobileApp={
                cat:'Alarm Mobile App',
            }

            if(basket.find((obj)=>obj.group=== category ) && !basket.find((obj)=>obj.cat ==='Alarm Mobile App')){
                basket.push(MobileApp);
            }
            return basket
        },

        addAutomationFee: (basket)=>{
            //add this object to basket
            const automationFee={
                cat:'Automation',
                isService: true,//this seperate hardware from services 
                displayNoQty:true,
                loc: 'Alarm Mobile App Feature',
                model:'TC2.0 AUTOMATION',
                monthly: 4,
                price: 0,
                checkListKeyword:['Automation'],
            }
    
            //check for automation group in the basket
            let automation = basket.find((obj)=>obj.group==='automation')
            
            //check for automation fee in the basket
            let fee = basket.find((obj)=>obj.cat === 'Automation')
            
            //if automation group and no fee add fee 
            if(automation && !fee)
            {
                basket.push(automationFee)
                
            }
    
            //if fee but no automation remove fee
            if(fee && !automation)
            {
                return basket.filter((obj)=>obj.cat!=='Automation')
            }
            basket = checker.isAppInBasket(basket, 'automation');
            return basket
        },
        

        addEzvizApp: (basket) => {
            // Define the ezvizApp object to be added to the basket
            const ezvizApp = {
                cat: 'Camera Mobile App',
                displayNoQty: true,
                isService: true,// remove for hardware the whole isService: true; do not change to false
                loc:'Up to 99 Users',
                model:'EZVIZ',
                monthly: 0,
                price: 0,
                checkListKeyword: ['Video'],
            };
        
            // Check if there is an object with the group 'ezviz' in the basket
            const ezviz = basket.some(obj => obj.group === 'ezviz');
            // Check if there is already an object with the category 'Camera App' in the basket
            const cameraApp = basket.some(obj => obj.cat === 'Camera Mobile App');
        
            // If there is an 'ezviz' group in the basket and no 'Camera App', add the ezvizApp to the basket
            if (ezviz && !cameraApp) {
                basket.push(ezvizApp);
            } 
            // If there is no 'ezviz' group in the basket but 'Camera App' is present, remove the 'Camera App' from the basket
            else if (!ezviz && cameraApp) {
                basket = basket.filter(obj => obj.cat !== 'Camera Mobile App');
            }
        
            // Return the updated basket
            return basket;
        },
        
        addCloudStorageFee: (basket)=>{
            //add this object to basket
            const cloudStorageFee={
                cat:'Cloud Storage',
                displayNoQty:true,
                monthly: 6,
                price: 0,
                checkListKeyword:['Video'],
            }
    
            //check for automation group in the basket
            let cloud = basket.find((obj)=>obj.group==='cloud')
            
            //check for automation fee in the basket
            let fee = basket.find((obj)=>obj.cat === 'Cloud Storage')
            
            //if automation group and no fee add fee 
            if(cloud && !fee)
            {
                basket.push(cloudStorageFee)
            }
    
            //if fee but no automation remove fee
            if(fee && !cloud)
            {
                return basket.filter((obj)=>obj.cat!=='Cloud Storage')
            }
            basket = checker.isAppInBasket(basket, 'cloud');
            return basket
        },
        addWireToWirelessModule: (basket) => {
            // Define the WireToWireless object
            const WireToWireless = {
                cat: 'Wire-To-Wireless 8-Zone Converter',
                loc: 'Existing Alarm Panel',
                model: 'PROSIXC2W',
                price: getPrice('PROSIXC2W'),
            };
            
            const wiredZones = basket.filter(obj=>obj.group==='wired');
            const wiredCount = wiredZones.length;
            // Calculate the required number of converters
            const requiredConverters = Math.ceil(wiredCount / 8);

            const converters = basket.filter(obj=>obj.cat==='Wire-To-Wireless 8-Zone Converter')
            const conventerCount = converters.length;

            if(requiredConverters !== conventerCount){
                basket = basket.filter(obj=>obj.cat!=='Wire-To-Wireless 8-Zone Converter')
                for (let i = 0; i < requiredConverters; i++) {
                    basket.push(WireToWireless);
                }
            }

            return basket;
        },
        addWirelessTakeOverModule: (basket) => {
            // Define the TakeoverModule object
            const takeoverModule = {
                cat: 'Wireless Zone Takeover Module',
                loc: 'Built-in Panel',
                model: 'PROTAKEOVER',
                price: getPrice('PROTAKEOVER'),
            };
            
            const takeover = basket.filter(obj=>obj.group==='takeover');
            const addedModule = basket.filter(obj=>obj.cat==='Wireless Zone Takeover Module');
            if(takeover.length>0 && addedModule.length == 0){
                
                    basket.push(takeoverModule);   
            }
            
            if(takeover.length == 0 && addedModule.length != 0)
            {
                return basket.filter(obj=>obj.cat!=='Wireless Zone Takeover Module')
                
            }

            return basket;
        },

        run: (basket)=>{
            basket = checker.addWireToWirelessModule(basket);
            basket = checker.addCloudStorageFee(basket);
            basket = checker.addAutomationFee(basket);
            basket = checker.addEzvizApp(basket);
            basket = checker.addWirelessTakeOverModule(basket);
            return basket;
        },
        runChecker: (basket) => checker.run(basket)
    }
    

    
    //add to garbage each category removed or changed so it will be removed from users baskets by cleanUpBasket func at LoadingView.js.
    //otherwise these items will remain in basket saved in local storage and its price will be added to the total.
    const garbage=[
        'Camera App',
        'Door Sensorx'
    ]

    

    const checkList=[
        {
            title:'Features Checklist',
            list:[
                {name:'Wifi'},
                {name:'Cellular'},
                {name:'App'},
                {name:'Automation'},
                {name:'Video'},
                ]
        },

        {
            title:"Your Package Monitors",
            list:[
                {name:'Burglar'},
                {name:'Fire'},
                {name:'CO'},
                {name:'Flood'},
                {name:'Freez'},
            ]
        } 
    ]

    const displayOrder=[ 
        'Touchscreen Alarm Panel',
        '24/7 Monitoring via WiFi',
        '24/7 Monitoring via Wifi & Cellular',
        'Cellular Monitoring',
        'Alarm Mobile App',
        'Camera Mobile App',
        'Cloud Storage',
        'Automation',
        'Wire-To-Wireless 8-Zone Converter',
        'Wireless Zone Takeover Module',
        'Additional Touchscreen',
        'Additional Keypad',
        'Additional Siren',
        'Door Sensor',
        'Overhead Door',
        'Motion Detector',
        'Window Sensor',
        'Glass Break Detector',
        'Smoke Detector',
        'Heat Detector',
        'CO Detector',
        'Water Detector',
        'Freez Detector',
        'Remote Button',
        'Doorbell Camera',
        'Outdoor Cameras',
        'Door Lock',
        'Thermostat',
        'Garage Door Opener',
        'Light Switch',
        
    ]

    const defaultPackage = (str)=>{
        let p;
    
        switch (str) {
            case 'doorbell':
                p= [
                    { cat: 'Touchscreen Alarm Panel' },
                    { cat: 'Alarm Mobile App' },
                    { cat: '24/7 Monitoring via WiFi' },
                    { cat: 'Door Sensor' },
                    { cat: 'Motion Detector' },
                    { cat: 'Doorbell Camera' }
                ];
                break;
                case 'camera':
                p= [
                    { cat: 'Touchscreen Alarm Panel' },
                    { cat: 'Alarm Mobile App' },
                    { cat: '24/7 Monitoring via WiFi' },
                    { cat: 'Door Sensor' },
                    { cat: 'Motion Detector' },
                    { cat: 'Outdoor Cameras' }
                ];
                break;
                case 'lock':
                p= [
                    { cat: 'Touchscreen Alarm Panel' },
                    { cat: 'Alarm Mobile App' },
                    { cat: '24/7 Monitoring via WiFi' },
                    { cat: 'Door Sensor' },
                    { cat: 'Motion Detector' },
                    { cat: 'Door Lock' }
                ];
                break;
                case 'thermostat':
                    p= [
                        { cat: 'Touchscreen Alarm Panel' },
                        { cat: 'Alarm Mobile App' },
                        { cat: '24/7 Monitoring via WiFi' },
                        { cat: 'Door Sensor' },
                        { cat: 'Motion Detector' },
                        { cat: 'Thermostat' }
                    ];
                    break;
                    case 'garageopener':
                    p= [
                        { cat: 'Touchscreen Alarm Panel' },
                        { cat: 'Alarm Mobile App' },
                        { cat: '24/7 Monitoring via WiFi' },
                        { cat: 'Door Sensor' },
                        { cat: 'Motion Detector' },
                        { cat: 'Garage Door Opener' }
                    ];
                    break;
                case 'light':
                p= [
                    { cat: 'Touchscreen Alarm Panel' },
                    { cat: 'Alarm Mobile App' },
                    { cat: '24/7 Monitoring via WiFi' },
                    { cat: 'Door Sensor' },
                    { cat: 'Motion Detector' },
                    { cat: 'Light Switch' }
                ];
                break;
            default:
                p = [
                    { cat: 'Touchscreen Alarm Panel' },
                    { cat: 'Alarm Mobile App' },
                    { cat: '24/7 Monitoring via WiFi' },
                    { cat: 'Door Sensor' },
                    { cat: 'Motion Detector' }
                ];
                break;
        }
    
        return p;
    }
    //basket is set to this value when user press reset
    const resetBasket=()=>([
        {cat: 'Touchscreen Alarm Panel' },
        { cat: 'Alarm Mobile App' },
        { cat: '24/7 Monitoring via WiFi' },
    ])
    
    const info={
        checker:checker,
        checkList: checkList,
        displayOrder:displayOrder,
        defaultPackage: defaultPackage,
        resetBasket: resetBasket,
        garbage:garbage,
        components:[
            //Panel
            {   
                editViewSectionTitle: "Alarm Panel",
                type:[
                    {
                        title:'Touchscreen Alarm Panel',
                        labelEnding:'touchscreen',
                        totalQty: 1,
                        editable:false,  
                        //available panels
                        models:[
                            PROA7PLUSWIFI(),
                        ]
                    },
                    {
                        title:'Alarm Mobile App',
                        labelEnding:'mobile app',
                        displayNoQty:true,
                        totalQty: 1,
                        fixedLocation: 'Up to 96 Users',
                        //available panels
                        models:[
                            ToatlConnect2()
                        ]
                    },
                    {
                        title:'24/7 Monitoring via WiFi',
                        displayNoQty:true,
                        labelEnding:'',
                        totalQty: 1,
                        radioName:'moni',
                        fixedLocation: 'Build-in comunicator',
                        //available panels
                        models:[
                            Wifi(),
                        ]
                    },
                    {
                        title:'24/7 Monitoring via Wifi & Cellular',
                        displayNoQty:true,
                        labelEnding:'',
                        totalQty: 1,
                        radioName:'moni',
                        fixedLocation: 'Build-in communicators',
                        //available panels
                        models:[
                            WifiCell()
                        ]
                    },
                ],
            },
            
            //Sensors
            {
                editViewSectionTitle: "Sensors",
                type:[
                    {
                        title:'Door Sensor',
                        labelEnding:'door.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXCT(),
                            PROSIXSHOCK(),
                            
                            ExistingWirelessDoor(),
                            ExistingDoor(),
                        ]

                    },
                    {
                        title:'Motion Detector',
                        labelEnding:'motion.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXPIR(),
                            
                            ExistingWirelessMotion(),
                            ExistingMotion(),
                        ]

                    },
                    {
                        title:'Window Sensor',
                        labelEnding:'window.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXCT(),
                            PROSIXSHOCK(),
                            
                            ExistingWirelessWindow(),
                            ExistingWindow(),
                        ]

                    },
                    {
                        title:'Glass Break Detector',
                        labelEnding:'glass break.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXGB(),
                            
                            ExistingWirelessGlass(),
                            ExistingGlass(),
                        ]

                    },
                    {
                        title:'Smoke Detector',
                        labelEnding:'smoke.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXSMOKEV(),
                            PROSIXCOMBO(),
                            
                            ExistingWirelessSmoke(),
                            ExistingSmoke(),
                        ]

                    },
                    {
                        title:'Heat Detector',
                        labelEnding:'heat.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXHEAT(),
                            
                            ExistingWirelessHeat(),
                            ExistingHeat(),
                        ]

                    },
                    {
                        title:'CO Detector',
                        labelEnding:'CO.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXCO(),
                            PROSIXCOMBO(),
                            
                            ExistingWirelessCo(),
                            ExistingCo(),

                        ]

                    },
                    {
                        title:'Water Detector',
                        labelEnding:'water.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXFLOOD(),
                            
                            ExistingWirelessWater(),
                            ExistingWater(),
                        ]

                    },
                    {
                        title:'Freez Detector',
                        labelEnding:'low. temp.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXTEMP(),
                           
                            ExistingWirelessFreez(),
                            ExistingFreez(),
                        ]

                    },
                    {
                        title:'Remote Button',
                        labelEnding:'button.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXKEYFOB(),
                            PROSIXPANIC(),
                            PROSIXMED(),
                            ExistingPanic(),
                        ]

                    },
                    {
                        title:'Overhead Door',
                        labelEnding:'garage door.',
                        totalQty: 99,  
                        //available sensors
                        models:[
                            PROSIXCTOHR(),
                            PROSIXCTOHC(),
                            
                            ExistingWirelessOH(),
                            ExistingOH(),
                        ]

                    },
                ],
            },
            
             //Cameras
             {
                editViewSectionTitle: "Cameras",
                type:[
                    {
                        title:'Doorbell Camera',
                        labelEnding:'',
                        totalQty: 16,  
                        //available sensors
                        models:[
                            DSHD2()
                        ]

                    },
                    {
                        title:'Outdoor Cameras',
                        labelEnding:'',
                        totalQty: 32,  
                        //available sensors
                        models:[
                            C8CLite128(),
                            C8CLite256()
                        ]

                    },
                ]
             },
              //Automation
              {
                editViewSectionTitle: "Automation",
                type:[
                    {
                        title:'Door Lock',
                        labelEnding:'door.',
                        totalQty: 4,  
                        //available sensors
                        models:[
                            Weiser10s(),
                            ExistingLock(),
                        ]

                    },
                    {
                        title:'Thermostat',
                        labelEnding:'',
                        totalQty: 6,  
                        //available sensors
                        models:[
                            T6PRO(),
                            ExistingThermostat(),
                        ]

                    },
                    {
                        title:'Garage Door Opener',
                        labelEnding:'garage.',
                        totalQty: 6,  
                        //available sensors
                        models:[
                            GD00Z8GC(),
                            ExistingGarageOpener(),
                        ]

                    },
                    {
                        title:'Light Switch',
                        labelEnding:'',
                        totalQty: 60,  
                        //available sensors
                        models:[
                            GE45603ZW(),
                            ExistingLight(),
                        ]

                    },
                ]
             },
             //Addons
             {
                editViewSectionTitle: "Add-ons",
                type:[
                    {
                        title:'Additional Touchscreen',
                        labelEnding:'touchscreen.',
                        totalQty: 8,  
                        //available sensors
                        models:[
                            PROWLTOUCHwall(),
                            PROWLTOUCHdesk()
                        ]

                    },
                    {
                        title:'Additional Keypad',
                        labelEnding:'keypad.',
                        totalQty: 8,  
                        //available sensors
                        models:[
                            PROSIXLCDKP(),
                        ]

                    },
                    {
                        title:'Additional Siren',
                        labelEnding:'siren.',
                        totalQty: 60,  
                        //available sensors
                        models:[
                            PROSIXSIRENO(),
                            PROSIXSIREN(),
                            
                        ]

                    },
                ]
             },
            //communicators: proComms,
            //controller: proMain,
            //subcontrollers: proSubs,
            //expanders: proExpanders,
            //sensors: proSensors,
            //automations: porAutomation,
            //cameras: proCams,
            //extras: proExtras
        ]
        
    }

    return info;
}
export default KitProPlus7;

