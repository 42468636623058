import React from 'react';
import css from './ProductImage.module.css';

const ProductImage = ({projectDeviceImage, projectDeviceName})=>{
    return(
        <div className={css.productImageBox}>
            <img className={css.image} src={projectDeviceImage} alt={'image of '+projectDeviceName}/>
        </div>
    )
}
export default ProductImage;
